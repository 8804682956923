const DuctlessProducts = t => [
  {
    text: t('hvacAndWater.ductless.dC6000WF1001.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/DC6000WF1001 - Honeywell Home.zip?sp=rl&st=2021-03-10T15:28:11Z&se=2168-02-11T15:28:00Z&sv=2020-02-10&sr=b&sig=yf7eZ4HoFLi7KzK6F0CwnM3UEEZ9dVBZ2J1q6LOvW8o%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.ductless.dC6000WF1001.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
];

export default DuctlessProducts;
