import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Text } from '@resideo/blueprint-react';

const AccessDenied: FC = () => {
  const { t } = useTranslation();
  return (
    <Card borderRadius={[0, 'medium']} padding='large' data-test-access-denied>
      <h1>{t('accessDenied.heading')}</h1>
      <Text>{t('accessDenied.content')}</Text>
    </Card>
  );
};

export default AccessDenied;
