import React, { useState, Suspense } from 'react';
import { Box, InputField } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import AutocompleteComponent from './Autocomplete';

const LoadingInput = () => {
  const { t } = useTranslation();
  return (
    <Box marginBottom={['medium']}>
      <InputField
        version='v2'
        name={'address1'}
        label={t('common.address.address1')}
        defaultValue={`${t('Loading...')}`}
      />
    </Box>
  );
};

const AutocompleteWrapper = ({
  initialValue,
  setCountry,
  setStateValue,
  fieldName,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  isLoaded,
  loadError,
  setAutoCompleteSelected,
}) => {
  const { t } = useTranslation();

  const [address1, setAddress1] = useState(initialValue);

  const handleChange = event => {
    event.preventDefault();
    setAddress1(event.target.value);
    setFieldValue('address1', event.target.value);
    setFieldTouched('address1', true);
  };

  // if the google maps api throws an error, use a regular input field for address1
  if (loadError) {
    return (
      <Box marginBottom={['medium']}>
        data-test-address-address1
        <InputField
          version='v2'
          name={'address1'}
          label={t('common.address.address1')}
          value={address1}
          onChange={handleChange}
          onBlur={() => {
            setFieldTouched('address1', true, true);
          }}
          required
        />{' '}
      </Box>
    );
  }

  return isLoaded ? (
    <AutocompleteComponent
      initialValue={initialValue}
      setCountry={setCountry}
      setStateValue={setStateValue}
      fieldName={fieldName}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      touched={touched}
      errors={errors}
      setAutoCompleteSelected={setAutoCompleteSelected}
    />
  ) : (
    <LoadingInput />
  );
};

const AutocompleteWrapperSuspense = props => {
  return (
    <Suspense fallback={<LoadingInput />}>
      <AutocompleteWrapper {...props} />
    </Suspense>
  );
};

export default AutocompleteWrapperSuspense;
