/* eslint-disable react/display-name */
import React from 'react';
import SeverityBanner from '../components/SeverityBanner';
import { useTranslation } from 'react-i18next';
import CustomerCell from './CustomerCell';
import AlertCell from './AlertCell';
import ActionsCell from './ActionsCell';
import DismissedByCell from './DismissedByCell';
import { AlertsTab } from '../types';
import DateCell from './DateCell';

const Header = ({ translationKey }) => {
  const { t } = useTranslation();
  return t(translationKey);
};

export const severityColumn = {
  id: 'severity',
  accessorKey: 'severity',
  header: () => <Header translationKey='alertsDashboard.tableHeaderSeverity' />,
  cell: ({ getValue }) => <SeverityBanner severity={getValue()} />,
};

export const alertColumn = (tab: AlertsTab) => ({
  id: 'title',
  accessorKey: 'title',
  enableSorting: false,
  header: () => <Header translationKey='alertsDashboard.tableHeaderAlert' />,
  cell: ({ row }) => <AlertCell tab={tab} row={row.original} />,
});

export const customerColumn = (tab: AlertsTab) => ({
  id: 'customer.name',
  accessorKey: 'customer',
  header: () => <Header translationKey='alertsDashboard.tableHeaderCustomer' />,
  cell: ({ getValue }) => <CustomerCell tab={tab} customer={getValue()} />,
});

export const dismissedByColumn = {
  id: 'statusUpdatedBy',
  accessorKey: 'statusUpdatedBy.id',
  enableSorting: false,
  header: () => (
    <Header translationKey='alertsDashboard.tableHeaderDismissedBy' />
  ),
  cell: ({ row }) => <DismissedByCell row={row.original} />,
};

export const actionsColumn = (tab: AlertsTab) => ({
  id: 'actions',
  header: '',
  enableSorting: false,
  cell: ({ row }) => <ActionsCell tab={tab} row={row.original} />,
});

export const dateColumn = {
  id: 'createdAt',
  accessorKey: 'createdAt',
  header: () => <Header translationKey={'alertsDashboard.tableHeaderDate'} />,
  cell: ({ row }) => <DateCell row={row.original} />,
};
