import { useFeatureFlags } from 'context/FeatureFlags';

export const useServicesLinks = () => {
  const {
    ENABLE_PROIQ_SERVICES_SUBSCRIPTIONS_LINKS,
    PROIQ_ESSENTIALS_LINK,
    PROIQ_SERVICES_SUBSCRIPTIONS_LINK,
  } = useFeatureFlags();
  let proIQTilesPath = `${window.location.origin}/mybusiness/services-subscriptions/pdp/home-owner`;
  let servicesPath = `${window.location.origin}/mybusiness/services-subscriptions`;

  if (ENABLE_PROIQ_SERVICES_SUBSCRIPTIONS_LINKS && PROIQ_ESSENTIALS_LINK) {
    proIQTilesPath = PROIQ_ESSENTIALS_LINK;
  }

  if (
    ENABLE_PROIQ_SERVICES_SUBSCRIPTIONS_LINKS &&
    PROIQ_SERVICES_SUBSCRIPTIONS_LINK
  ) {
    servicesPath = PROIQ_SERVICES_SUBSCRIPTIONS_LINK;
  }

  return { servicesPath, proIQTilesPath };
};
