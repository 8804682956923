import { useState } from 'react';
import { LANGUAGES } from 'utils/languages';

export const useLanguageCode = () => {
  const defaultLanguageCode = LANGUAGES.find(
    language => language.text === 'English'
  )?.value;

  const storedLanguageCode = localStorage.getItem('i18nextLng');

  const isLanguageCodeExists =
    storedLanguageCode &&
    LANGUAGES.find(language => language.value === storedLanguageCode);

  const currentLanguageCode = isLanguageCodeExists
    ? storedLanguageCode
    : defaultLanguageCode;

  const [languageCode, setCurrentLanguageCode] = useState(currentLanguageCode);

  const setLanguageCode = (newLanguageCode: string) => {
    localStorage.setItem('i18nextLng', newLanguageCode);
    setCurrentLanguageCode(newLanguageCode);
  };

  return [languageCode, setLanguageCode] as const;
};
