const DataSheets = t => [
  {
    text: t('proSeries.dataSheets.a7New.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/ProSeries_A7_Plus_Touchscreen.pdf',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.a7New.desc'),
  },
  {
    text: t('proSeries.dataSheets.a7.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PS7AIO2D_D ProSeries 7in AIO Touchscreen DS-US_06-22-21_HiRes.pdf?sp=rl&st=2021-06-28T18:25:14Z&se=2025-11-12T18:25:00Z&sv=2020-02-10&sr=b&sig=Qt%2Bov%2BLyJHYflmF15aIq05yaMWsYF2El7mzsW1V9OnI%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.a7.desc'),
  },
  {
    text: t('proSeries.dataSheets.a7FR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PS7AIO2FD_D_FRCA_AF_FINAL.pdf',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.a7FR.desc'),
  },
  {
    text: t('proSeries.dataSheets.a7ES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PS7AIO2SD_D ProSeries 7in AIO Touchscreen DS.pdf?sp=r&st=2021-10-14T18:50:12Z&se=2221-10-15T02:50:12Z&spr=https&sv=2020-08-04&sr=b&sig=lLwybWRr8Z8uahxhRXG%2FIfGTobkwVMsZFp38NaoHMfk%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.a7ES.desc'),
  },
  {
    text: t('proSeries.dataSheets.AIOtouch.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PSKPTSD_D ProSeries Touchscreens Touchpads DS_06-24-21_AR.pdf?sp=rl&st=2021-06-28T18:13:45Z&se=2025-11-05T18:13:00Z&sv=2020-02-10&sr=b&sig=KMRoDMQydcL5LAroN58vdjUl8ADOkp6g%2Bhf%2F6f7K%2BPw%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.AIOtouch.desc'),
  },
  {
    text: t('proSeries.dataSheets.zWaveCompatibility.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/ProSeries_Zwave_Compatability_List_Flyer.pdf',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.zWaveCompatibility.desc'),
  },
  {
    text: t('proSeries.dataSheets.zWaveCompatibilityFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/L_PSZWVFCCHT_D_ProSeries_Zwave_Compatability_Flyer_FRCA.pdf',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.zWaveCompatibilityFR.desc'),
  },
  {
    text: t('proSeries.dataSheets.indoor.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/ProSeries Indoor MotionViewer Data Sheet.pdf?sp=rl&st=2020-11-02T20:04:29Z&se=2120-11-02T20:04:00Z&sv=2019-12-12&sr=b&sig=dQS%2B3326KGvU%2Bbe%2BMcZN%2F65KXWQ1IQjf4Z%2BHX2t2GDk%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.indoor.desc'),
  },
  {
    text: t('proSeries.dataSheets.indoorFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/ProSeries Indoor MotionViewer Data Sheet (French).pdf?sp=rl&st=2021-02-16T20:45:49Z&se=2152-06-17T20:45:00Z&sv=2019-12-12&sr=b&sig=AqLq6piB1UE6sEMd1baNODWObNU%2BFVcmGxLTjWWbbWU%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.indoorFR.desc'),
  },
  {
    text: t('proSeries.dataSheets.indoorES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PSINDMOVSD_D_ESLA.pdf?sp=r&st=2021-10-14T18:50:56Z&se=2221-10-15T02:50:56Z&spr=https&sv=2020-08-04&sr=b&sig=rmfkpqPNCLQG43RmamQWBd%2F%2FPmha7GKkvSWZ7Y%2FCAzk%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.indoorES.desc'),
  },
  {
    text: t('proSeries.dataSheets.LTEen.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data%20Sheets/L_PSLTEWMD_D_AF_FINAL.pdf',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.LTEen.desc'),
  },
  {
    text: t('proSeries.dataSheets.LTEfr.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data%20Sheets/L_PSLTEWMFD_D_AF_FINAL.pdf',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.LTEfr.desc'),
  },
  {
    text: t('proSeries.dataSheets.LTEes.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PSLTEWMSD_D.pdf?sp=r&st=2021-10-14T18:52:29Z&se=2221-10-15T02:52:29Z&spr=https&sv=2020-08-04&sr=b&sig=qYR8lCGBT5OLnzXTLeei5NqsyKP1%2FmsFHqaMTFTxpwI%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.LTEes.desc'),
  },
  {
    text: t('proSeries.dataSheets.sixSensorEN.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data%20Sheets/L_PSR62WWSD_D_AF_FINAL.pdf',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.sixSensorEN.desc'),
  },
  {
    text: t('proSeries.dataSheets.sixSensorFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data%20Sheets/L_PSR62WWSFD_D_AF_FINAL.pdf',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.sixSensorFR.desc'),
  },
  {
    text: t('proSeries.dataSheets.sixSensorES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PSR62WWSSD_D ProSeries - SiX Sensors Technology Family Data Sheet Update- RESIDEO SPANISH LATIN AMERICA.pdf?sp=r&st=2021-10-14T18:51:38Z&se=2221-10-15T02:51:38Z&spr=https&sv=2020-08-04&sr=b&sig=iueBsudmTeXE%2BEGTEJtU677A38A0P9OL5ejZI1lmoG4%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.sixSensorES.desc'),
  },
  {
    text: t('proSeries.dataSheets.wirelessEN.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/ProSeries SiX Wireless Converter Data Sheet.pdf?sp=rl&st=2020-11-02T20:07:02Z&se=2136-07-18T20:07:00Z&sv=2019-12-12&sr=b&sig=86nvovLBSuE53zs8YcCA%2Bs6AsMhXJaF74FMG42fczzQ%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.wirelessEN.desc'),
  },
  {
    text: t('proSeries.dataSheets.wirelessFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/ProSeries SiX Wireless Converter Data Sheet (French).pdf?sp=rl&st=2020-11-02T20:06:36Z&se=2130-11-16T20:06:00Z&sv=2019-12-12&sr=b&sig=DNhnth3lHF0eCn0kAxEHW5cjCQJ6q%2F5vLt3jcf%2B9%2FYk%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.wirelessFR.desc'),
  },
  {
    text: t('proSeries.dataSheets.wirelessES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PSSIXC2WASD_D ProSeries SiX Wireless Converter_esLA.pdf?sp=r&st=2021-10-14T18:53:26Z&se=2221-10-15T02:53:26Z&spr=https&sv=2020-08-04&sr=b&sig=83tmDpDuRjtCqvenOpjq8ROTKT3TJATpB7CepQmWIq4%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.wirelessES.desc'),
  },
  {
    text: t('proSeries.dataSheets.touchscreen.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PSKPTSD_D ProSeries Touchscreens Touchpads DS_06-24-21_AR.pdf?sp=rl&st=2021-06-28T18:13:45Z&se=2025-11-05T18:13:00Z&sv=2020-02-10&sr=b&sig=KMRoDMQydcL5LAroN58vdjUl8ADOkp6g%2Bhf%2F6f7K%2BPw%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.touchscreen.desc'),
  },
  {
    text: t('proSeries.dataSheets.touchscreenFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/ProSeries 7in AIO Touchscreen Panel Data Sheet (French).pdf?sp=r&st=2021-10-14T14:42:04Z&se=2221-10-14T22:42:04Z&spr=https&sv=2020-08-04&sr=b&sig=NXD7IPk20KwKA38oe9RzkgdzCu7qlLwyjxPTGakyFnc%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.touchscreenFR.desc'),
  },
  {
    text: t('proSeries.dataSheets.touchscreenES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PSKPTSSD_D ProSeries Touchscreens Touchpads DS.pdf?sp=r&st=2021-10-14T18:54:08Z&se=2221-10-15T02:54:08Z&spr=https&sv=2020-08-04&sr=b&sig=NqhRyrllEaW3%2Fr5iAu3FfkUCU4bjapq3Lsc5Nkzxx00%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.touchscreenES.desc'),
  },
  {
    text: t('proSeries.dataSheets.takeover.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/ProSeries Wireless Takeover Module Data Sheet.pdf?sp=rl&st=2020-11-02T20:07:48Z&se=2128-10-18T20:07:00Z&sv=2019-12-12&sr=b&sig=%2FNijgbupuWb9X%2F8eoxX8gOimDaNvuZF8IEaSgYRn0cs%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.takeover.desc'),
  },
  {
    text: t('proSeries.dataSheets.takeoverFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/ProSeries Wireless Takeover Module Data Sheet (French).pdf?sp=rl&st=2021-02-15T18:48:45Z&se=2148-06-12T18:48:00Z&sv=2019-12-12&sr=b&sig=aSWu45U7d9mFk8vQgdEzHZVZOQZaJycCFIvzysdchrM%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.takeoverFR.desc'),
  },
  {
    text: t('proSeries.dataSheets.takeoverES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PSTCVTSD_D ProSeries Wireless Takeover Module_esla.pdf?sp=r&st=2021-10-14T18:54:35Z&se=2221-10-15T02:54:35Z&spr=https&sv=2020-08-04&sr=b&sig=5igAUgTF6YhTj53YCvNM7DAfV2xmax7zy1TC5p7IfA0%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.takeoverES.desc'),
  },
  {
    text: t('proSeries.dataSheets.zWave.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/ProSeries ZWave WiFi Module Data Sheet.pdf?sp=rl&st=2021-02-16T20:51:55Z&se=2161-10-12T20:51:00Z&sv=2019-12-12&sr=b&sig=TauE1k3wX12ah8YuN4gqcQd%2FIkP16xMTJ%2Bbzxpm2Fkg%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.zWave.desc'),
  },
  {
    text: t('proSeries.dataSheets.zWaveFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/ProSeries ZWave WiFi Module Data Sheet (French).pdf?sp=rl&st=2021-02-15T18:43:00Z&se=2139-12-02T18:43:00Z&sv=2019-12-12&sr=b&sig=725d06lI26aioRC5x53WsZPg7rLdvlVG9MhiUZ9%2BJEY%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.zWaveFR.desc'),
  },
  {
    text: t('proSeries.dataSheets.zWaveES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PSZWWFMSD_D ProSeries ZWave WiFi Module_ESLA.pdf?sp=r&st=2021-10-14T18:55:25Z&se=2221-10-15T02:55:25Z&spr=https&sv=2020-08-04&sr=b&sig=AQMKwKMwfjNwc7zMnjVlV3c4gWcqeK5tY6OPD9E4vGg%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.zWaveES.desc'),
  },
  {
    text: t('proSeries.dataSheets.glance.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PSAAGB_D ProSeries At_A_Glance Brochure_v6 June Final.pdf?sp=rl&st=2021-06-28T18:14:48Z&se=2025-10-15T18:14:00Z&sv=2020-02-10&sr=b&sig=HeoMz3POaC%2BWX1eXQJ4%2FZ0NKpZUN8o0jpfpQnaJFdGo%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.glance.desc'),
  },
  {
    text: t('proSeries.dataSheets.glanceFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/L_PSAAGFB_D_ProSeries_At-A-Glance_Brochure_FRCA.pdf',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.glanceFR.desc'),
  },
  {
    text: t('proSeries.dataSheets.glanceES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PSAAGSB_D ProSeries At_A_Glance Brochure_ESLA_v2FINAL.pdf?sp=r&st=2021-10-14T18:56:01Z&se=2221-10-15T02:56:01Z&spr=https&sv=2020-08-04&sr=b&sig=2B7hO5uCfIGwp1DpQc%2BdsIsVYa4Qxhu%2B3N1ZrgldRBQ%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.glanceES.desc'),
  },
  {
    text: t('proSeries.dataSheets.repeaterES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PSRPTRSD_D ProSeries Repeater DS_ESAR.pdf?sp=r&st=2021-10-14T18:56:31Z&se=2221-10-15T02:56:31Z&spr=https&sv=2020-08-04&sr=b&sig=fWl8in2LrNo%2BDgPFbpbYitSMjuMdYUqZ5z8q08I3AIw%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.repeaterES.desc'),
  },
];

export default DataSheets;
