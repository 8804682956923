const HumidificationProducts = t => [
  {
    text: t('hvacAndWater.humidification.he100A1000'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HE100A1000 - Honeywell Home.zip?sp=rl&st=2021-03-10T15:34:37Z&se=2139-11-11T15:34:00Z&sv=2020-02-10&sr=b&sig=AGIMU0DIw%2FB2SKLaWSoFj0DpJbmhrGQgdtwX1rJz27k%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.humidification.descriptions.small'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.humidification.he105C1000'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HE105C1000 - Honeywell Home.zip?sp=rl&st=2021-03-10T15:35:01Z&se=2147-03-11T15:35:00Z&sv=2020-02-10&sr=b&sig=EcVa0hDy9mEsA5zpSWPY26%2BIJ4AKOaKYhB0p4Bs8xX4%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.humidification.descriptions.home'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.humidification.he150A1005'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HE150A1005 - Honeywell Home.zip?sp=rl&st=2021-03-10T15:35:13Z&se=2198-06-11T15:35:00Z&sv=2020-02-10&sr=b&sig=EzpvN2kLXNeiulZedyZitcLV2YJvInRvvuypT%2BbCKc4%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.humidification.descriptions.small'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.humidification.he200A1000'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HE200A1000 - Honeywell Home.zip?sp=rl&st=2021-03-10T15:35:37Z&se=2141-11-11T15:35:00Z&sv=2020-02-10&sr=b&sig=zVbSeQwCXmAgFCiphZ%2Fzwb5ZXz3WU%2F0l1h8PuQr4x7I%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.humidification.descriptions.large'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.humidification.he205A1000'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HE205A1000 - Honeywell Home.zip?sp=rl&st=2021-03-10T15:36:08Z&se=2236-10-11T15:36:00Z&sv=2020-02-10&sr=b&sig=EfHmHVxqmumo7EZFFqr7CR0o8ZOGHGmGW3fDRElOAy8%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.humidification.descriptions.home'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.humidification.he250A1005'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HE250A1005 - Honeywell Home.zip?sp=rl&st=2021-03-10T15:36:32Z&se=2168-06-11T15:36:00Z&sv=2020-02-10&sr=b&sig=h3z%2F%2FleFNb6efK1WRqjOD3o8OyEbIx1TfVl6HgQzC8Y%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.humidification.descriptions.large'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.humidification.he300A1005'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HE300A1005 - Honeywell Home.zip?sp=rl&st=2021-03-10T15:36:57Z&se=2193-06-11T15:36:00Z&sv=2020-02-10&sr=b&sig=5f2%2Bxs%2FB13OviYefX25DiKb6QHOVTrw3ZMrvEB3sOsE%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.humidification.descriptions.advanced'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.humidification.hm700ACYL2.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HM700ACYL2 - Honeywell Home.zip?sp=rl&st=2021-03-10T15:37:21Z&se=2159-06-11T15:37:00Z&sv=2020-02-10&sr=b&sig=28kK5N0D8odAWYOii0uGLupfscEv5%2BuIs%2FSLDfrkbgo%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.humidification.hm700ACYL2.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.humidification.hm750A1000.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HM750A1000 - Honeywell Home.zip?sp=rl&st=2021-03-10T15:37:35Z&se=2159-06-11T15:37:00Z&sv=2020-02-10&sr=b&sig=ixS5sSR6RdN4dDr4ITVt9lFBzrXajLzu2gKztlpi7Pg%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.humidification.hm750A1000.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
];

export default HumidificationProducts;
