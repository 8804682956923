import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUser } from 'context/CurrentUser';

const SentryUser = () => {
  const { isAuthenticated } = useAuth0();
  const { id, contactEmail } = useCurrentUser();

  useEffect(() => {
    if (isAuthenticated && id && contactEmail) {
      Sentry?.setUser({
        id: id as string,
        email: contactEmail as string,
      });
    } else {
      Sentry?.setUser(null);
    }
  }, [isAuthenticated, id, contactEmail]);

  return null;
};

export default SentryUser;
