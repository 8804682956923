import React, { FC, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import {
  Heading,
  Button,
  CopyButton,
  Flex,
  Text,
} from '@resideo/blueprint-react';
import styled from 'styled-components';
import { CustomerAccountInformationProps } from './types';
import { useTranslation } from 'react-i18next';
import { CardLabel } from './CustomerAccount.styles';
import EditCustomerForm from '../AddCustomer/Steps/EditCustomerForm';
import { useModalContext } from 'context/ModalProvider';

const AccountNumber = styled(Flex)`
  gap: 10px;
  align-items: center;
  cursor: default;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-wrap: wrap;
    align-items: end;
    max-width: 145px;
  }
`;

const AccountId = styled.div`
  @media (max-width: 1540px) {
    inline-size: 145px;
    overflow-wrap: break-word;
  }
`;

const CustomerField = styled.div`
  padding-bottom: 30px;
`;

const CustomerDiv = styled.div``;

const CustomerDetailsDiv = styled.div`
  margin-left: 25%;
  padding-top: 4px;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-left: 0;
  }
`;

const CustomerInfo = styled.div`
  display: -webkit-box;
  min-width: 0;
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 15px;
  margin-right: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 6% 0 3% 0;
  }
`;

const EditButtonContainer = styled.div`
  box-sizing: border-box;
  width: max-content;
  margin-left: auto;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    position: relative;
    padding-left: 4%;
    padding-top: 2%;
  }
`;

const EditIcon = styled(MdEdit)`
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
  color: #1c6fb9;
`;

const CustomerDetailsInfo = styled(Flex)`
  display: -webkit-box;
  min-width: 0;
  padding-left: 30px;
  padding-top: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    padding-top: 4%;
    padding-left: 15px;
  }
`;

const CustomerAccountInformation: FC<CustomerAccountInformationProps> = ({
  customerResponse,
}) => {
  const { t } = useTranslation();
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const { openModal, closeModal } = useModalContext();
  const id = customerResponse?.id;
  const email = customerResponse?.email;
  const firstName = customerResponse?.firstName;
  const lastName = customerResponse?.lastName;
  const phoneNumber = customerResponse?.phoneNumber;

  const renderAccountNumber = () => {
    return (
      <Text paddingTop='4px'>
        <AccountNumber>
          {showAccountNumber && <AccountId>{id}</AccountId>}
          <Button
            onClick={() => {
              setShowAccountNumber(!showAccountNumber);
            }}
            variant='tertiary'
            tertiaryLink>
            {t(showAccountNumber ? 'common.hide' : 'common.show')}
          </Button>
          <CopyButton
            successText={t('customerDetails.copied')}
            tooltipText={t('customerDetails.copyAccountNumber')}
            copyText={id || ''}
          />
        </AccountNumber>
      </Text>
    );
  };
  return (
    <>
      <CustomerInfo>
        <Heading as={'h3'} fontSize='large'>
          {t('customers.customerInformation')}
        </Heading>
        <EditButtonContainer
          onClick={() => {
            openModal({
              title: t('customerDetails.editCustomer.title'),
              children: (
                <EditCustomerForm
                  closeModal={closeModal}
                  initialValues={customerResponse}
                />
              ),
            });
          }}>
          <EditIcon />
          <Button size='large' variant='tertiary' tertiaryLink>
            {t('common.edit')}
          </Button>
        </EditButtonContainer>
      </CustomerInfo>
      <CustomerDetailsInfo>
        <CustomerDiv>
          <CustomerField>
            <CardLabel>{t('customerDetails.name')}</CardLabel>
            <Text paddingTop='4px'>
              {lastName}
              {','} {firstName}
            </Text>
          </CustomerField>
          <CustomerField>
            <CardLabel>{t('customerDetails.email')}</CardLabel>
            <Text paddingTop='4px'>
              <Text>{email}</Text>
            </Text>
          </CustomerField>
        </CustomerDiv>
        <CustomerDetailsDiv>
          <CustomerField>
            <CardLabel>{t('customerDetails.phone')}</CardLabel>
            <Text paddingTop='4px'>{phoneNumber}</Text>
          </CustomerField>
          <CustomerField>
            <CardLabel>{t('customerDetails.acctNumber')}</CardLabel>
            {renderAccountNumber()}
          </CustomerField>
        </CustomerDetailsDiv>
      </CustomerDetailsInfo>
    </>
  );
};

export default CustomerAccountInformation;
