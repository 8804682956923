import { Box } from '@resideo/blueprint-react';
import { InputField, PhoneInputField } from '@resideo/blueprint-formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrandApprovalFormValues } from '../interfaces';
import { FormikErrors } from 'formik';
import { checkEmail, checkPhoneNumber, checkUrl } from 'utils/validation';
import { TFunction } from 'i18next';

export const validateCustomerServiceInformationFields = (
  values: BrandApprovalFormValues,
  t: TFunction
) => {
  const errors: FormikErrors<BrandApprovalFormValues> = {};

  if (values.customerServiceNumber?.trim() === '') {
    errors.customerServiceNumber = t(
      'mybusiness.services.brandProfile.validation.required'
    );
  } else if (!checkPhoneNumber(values.customerServiceNumber))
    errors.customerServiceNumber = t(
      'mybusiness.services.brandProfile.validation.invalidPhoneNumber'
    );

  if (values.businessEmail?.trim() === '') {
    errors.businessEmail = t(
      'mybusiness.services.brandProfile.validation.required'
    );
  } else if (!checkEmail(values.businessEmail)) {
    errors.businessEmail = t(
      'mybusiness.services.brandProfile.validation.invalidEmail'
    );
  }

  if (!values.websiteUrl?.trim()) {
    errors.websiteUrl = t(
      'mybusiness.services.brandProfile.validation.required'
    );
  } else if (values.websiteUrl && !checkUrl(values.websiteUrl)) {
    errors.websiteUrl = t(
      'mybusiness.services.brandProfile.validation.invalidUrl'
    );
  }

  return errors;
};

const CustomerServiceInformationFields = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box
        data-test-brand-profile-step2-phone
        marginBottom='medium'
        maxWidth='400px'>
        <PhoneInputField
          version='v2'
          name='customerServiceNumber'
          label={t(
            `mybusiness.services.brandProfile.labels.customerServiceNumber`
          )}
          required
          phoneInputConfig={{
            preferredCountries: ['us', 'ca'],
            country: 'us',
            countryCodeEditable: false,
          }}
        />
      </Box>
      <Box
        data-test-brand-profile-step2-email
        marginBottom='medium'
        maxWidth='400px'>
        <InputField
          version='v2'
          name='businessEmail'
          label={t(
            `mybusiness.services.brandProfile.labels.customerServiceEmail`
          )}
          required
        />
      </Box>
      <Box
        data-test-brand-profile-step2-url
        marginBottom='medium'
        maxWidth='400px'>
        <InputField
          version='v2'
          name='websiteUrl'
          label={t(
            `mybusiness.services.brandProfile.labels.customerServiceUrl`
          )}
          required
        />
      </Box>
    </>
  );
};

export default CustomerServiceInformationFields;
