import React, { useState } from 'react';

interface ILayoutContext {
  mobileMenuOpen: boolean;
  setMobileMenuOpen: (isOpen: boolean) => void;
}

const LayoutContext = React.createContext<ILayoutContext>({
  mobileMenuOpen: false,
  setMobileMenuOpen: () => ({}),
});

export const LayoutProvider = ({ children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <LayoutContext.Provider value={{ mobileMenuOpen, setMobileMenuOpen }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => React.useContext(LayoutContext);
