import React from 'react';

import {
  FILEUPLOAD_FLAG,
  REQUESTED_ACCESS_FLAG,
  LEGACY_COMPANY_UPDATE,
  PRO_IQ_ADVANCED,
  ENABLE_PROIQ_SERVICES_SUBSCRIPTIONS_LINKS,
  TABLE_RESULTS_PER_PAGE,
  PROIQ_SERVICES_SUBSCRIPTIONS_LINK,
  PROIQ_ESSENTIALS_LINK,
  ENABLE_HIGH_RADIUS_TILE,
  HIGH_RADIUS_TILE_REGISTER_LINK,
  HIGH_RADIUS_ACCESS_ACCOUNT_LINK,
  NEW_ACADEMY_LINK,
  PRO_IQ_TILES,
  INVITE_ACCEPT_ASYNC,
  RESIDEO_ID_INVITES,
  CUSTOMER_DETAILS_REDESIGN,
  CUSTOMER_ALERTS,
  AGREEMENT_CHECK,
  COMPLETE_SERVICE_SETUP,
  ENABLE_CUSTOMIZE_ALERT_SETTINGS,
} from 'config/flags';
import { isEMEA } from 'config/regions';
import { useCurrentUser } from 'context/CurrentUser';

export interface IFeatureFlagsContext {
  FILEUPLOAD_FLAG: boolean;
  REQUESTED_ACCESS_FLAG: boolean;
  PRODUCTS_FLAG: boolean;
  SOLUTIONS_FLAG: boolean;
  RESOURCES_FLAG: boolean;
  PERKS_FLAG: boolean;
  MANAGE_MY_INVOICES_AND_PAYMENTS_FLAG: boolean;
  INSTALL_PRO_APP_FLAG: boolean;
  HVAC_AND_WATER_ASSETS_FOR_DISTRIBUTORS_FLAG: boolean;
  INTEGRATE_AND_DEPLOY_FLAG: boolean;
  PRO_SERIES_SECURITY_SYSTEM_FLAG: boolean;
  LEGACY_COMPANY_UPDATE: boolean;
  PRO_IQ_ADVANCED: boolean;
  ENABLE_PROIQ_SERVICES_SUBSCRIPTIONS_LINKS: boolean;
  TABLE_RESULTS_PER_PAGE: number;
  PROIQ_SERVICES_SUBSCRIPTIONS_LINK: string;
  PROIQ_ESSENTIALS_LINK: string;
  BUSINESS_INTELLIGENCE_FLAG: boolean;
  SERVICES_SUBSCRIPTIONS_FLAG: boolean;
  IS_EMEA_OR_GB_MARKET: boolean;
  ENABLE_HIGH_RADIUS_TILE: boolean;
  HIGH_RADIUS_TILE_REGISTER_LINK: string;
  HIGH_RADIUS_ACCESS_ACCOUNT_LINK: string;
  NEW_ACADEMY_LINK: boolean;
  PRO_IQ_TILES: boolean;
  INVITE_ACCEPT_ASYNC: boolean;
  RESIDEO_ID_INVITES: boolean;
  CUSTOMER_DETAILS_REDESIGN: boolean;
  CUSTOMER_ALERTS: boolean;
  AGREEMENT_CHECK: boolean;
  COMPLETE_SERVICE_SETUP: boolean;
  ENABLE_CUSTOMIZE_ALERT_SETTINGS: boolean;
}

const defaultFlags: IFeatureFlagsContext = {
  FILEUPLOAD_FLAG,
  REQUESTED_ACCESS_FLAG,
  PRODUCTS_FLAG: true,
  SOLUTIONS_FLAG: true,
  RESOURCES_FLAG: true,
  PERKS_FLAG: true,
  MANAGE_MY_INVOICES_AND_PAYMENTS_FLAG: true,
  INSTALL_PRO_APP_FLAG: true,
  HVAC_AND_WATER_ASSETS_FOR_DISTRIBUTORS_FLAG: true,
  INTEGRATE_AND_DEPLOY_FLAG: true,
  PRO_SERIES_SECURITY_SYSTEM_FLAG: true,
  LEGACY_COMPANY_UPDATE,
  PRO_IQ_ADVANCED,
  ENABLE_PROIQ_SERVICES_SUBSCRIPTIONS_LINKS,
  TABLE_RESULTS_PER_PAGE,
  PROIQ_SERVICES_SUBSCRIPTIONS_LINK,
  PROIQ_ESSENTIALS_LINK,
  ENABLE_HIGH_RADIUS_TILE,
  HIGH_RADIUS_TILE_REGISTER_LINK,
  HIGH_RADIUS_ACCESS_ACCOUNT_LINK,
  SERVICES_SUBSCRIPTIONS_FLAG: true,
  BUSINESS_INTELLIGENCE_FLAG: true,
  IS_EMEA_OR_GB_MARKET: false,
  NEW_ACADEMY_LINK,
  PRO_IQ_TILES,
  INVITE_ACCEPT_ASYNC,
  RESIDEO_ID_INVITES,
  CUSTOMER_DETAILS_REDESIGN,
  CUSTOMER_ALERTS,
  AGREEMENT_CHECK,
  COMPLETE_SERVICE_SETUP,
  ENABLE_CUSTOMIZE_ALERT_SETTINGS,
};

const flagsForEMEA: Partial<IFeatureFlagsContext> = {
  PRODUCTS_FLAG: false,
  SOLUTIONS_FLAG: false,
  RESOURCES_FLAG: false,
  PERKS_FLAG: false,
  MANAGE_MY_INVOICES_AND_PAYMENTS_FLAG: false,
  INSTALL_PRO_APP_FLAG: true,
  HVAC_AND_WATER_ASSETS_FOR_DISTRIBUTORS_FLAG: false,
  INTEGRATE_AND_DEPLOY_FLAG: false,
  PRO_SERIES_SECURITY_SYSTEM_FLAG: false,
  BUSINESS_INTELLIGENCE_FLAG: false,
  SERVICES_SUBSCRIPTIONS_FLAG: false,
  PRO_IQ_ADVANCED: false,
  IS_EMEA_OR_GB_MARKET: true,
};

const FeatureFlagsContext = React.createContext<IFeatureFlagsContext>(
  defaultFlags
);

export const FeatureFlagsProvider = ({ children }) => {
  const { countryCode } = useCurrentUser();

  let flags = defaultFlags;

  if (countryCode && isEMEA(countryCode)) {
    flags = {
      ...flags,
      ...flagsForEMEA,
      PERKS_FLAG: flagsForEMEA.PERKS_FLAG || countryCode === 'GB',
    };
  }
  return (
    <FeatureFlagsContext.Provider value={flags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => React.useContext(FeatureFlagsContext);
