import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Notifications from '../notifications';
import { MobileMenuToggle } from './MobileMenu';
import { Flex } from '@resideo/blueprint-react';
import ProfileMenu from './ProfileMenu';
import { noLinkPages } from 'config/header';
import primaryNavigation from 'config/navigation';
import PerksNavLink from './PerksNavLink';
import HeaderNavLink from './HeaderNavLink';
import ResideoKnockoutLogo from 'components/logo/resideo-knockout-logo.svg?react';
import { Link, Link as RouterLink } from 'react-router-dom';
import { useFeatureFlags } from 'context/FeatureFlags';

const StyledHeader = styled.header`
  width: 100%;
  height: 60px;
`;

const FixedHeader = styled.div`
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  padding: 0 30px;
  background-color: ${({ theme }) => theme.colors.nav.background};
  box-shadow: 0 2px 10px #0000003a;
  z-index: var(--zidx-header);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.breakpoints.mLarge}) {
    padding: 0 15px;
  }
`;

const LogoContainer = styled(Link)`
  margin-right: ${({ theme }) => theme.space.medium};
`;

const PrimaryNavLinksContainer = styled.div`
  display: none;
  margin-right: auto;
  font-weight: 500;

  @media (min-width: ${({ theme }) => theme.breakpoints.mLarge}) {
    display: block;
  }
`;

const SignOutLink = styled(RouterLink)`
  color: #fff !important;
  text-decoration: none;
  font-weight: 500;
  margin-right: 18px;
  font-size: 14px;
`;

const StyledProfileMenu = styled(ProfileMenu)`
  display: none;
  margin-left: ${({ theme }) => theme.space.medium};

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
  }
`;

const Header: FC<{ userStatus?: string }> = ({ userStatus }): JSX.Element => {
  const { t } = useTranslation();
  const featureFlags = useFeatureFlags();
  const isNoLinkPage = noLinkPages.some(path =>
    location.pathname.includes(path)
  );
  const hideLinks =
    isNoLinkPage || userStatus === 'INACTIVE' || userStatus === 'ARCHIVED';

  return (
    <StyledHeader data-test-header>
      <FixedHeader>
        {!hideLinks && <MobileMenuToggle />}
        <LogoContainer aria-label={t('header.primary.home')} to='/'>
          <ResideoKnockoutLogo />
        </LogoContainer>
        {!hideLinks ? (
          <>
            <PrimaryNavLinksContainer data-test-primary-web-nav>
              {primaryNavigation(featureFlags).map(navItem =>
                navItem.name === 'perks' ? (
                  <PerksNavLink
                    key={navItem.name}
                    linkPath={navItem.linkPath}
                    name={navItem.name}
                    linkTextKey={navItem.linkTextKey}
                    isExternalLink={navItem.isExternalLink}
                  />
                ) : (
                  <HeaderNavLink
                    key={navItem.name}
                    linkPath={navItem.linkPath}
                    name={navItem.name}
                    linkTextKey={navItem.linkTextKey}
                    isExternalLink={navItem.isExternalLink}
                  />
                )
              )}
            </PrimaryNavLinksContainer>
            <Flex>
              <Notifications />
              <StyledProfileMenu />
            </Flex>
          </>
        ) : (
          <SignOutLink to='/sign-out' data-test-customer-link>
            <Flex alignItems='center'>
              {t('header.signout').toLocaleUpperCase()}
            </Flex>
          </SignOutLink>
        )}
      </FixedHeader>
    </StyledHeader>
  );
};

export default Header;
