import React, { useEffect, useState } from 'react';
import { TableV2 } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from 'context/CurrentUser';
import { hasAdminRole } from 'utils/common';
import { useIsMounted } from 'hooks/useIsMounted';
import { useFeatureFlags } from '../../../context/FeatureFlags';
import { IModalProps, useModalContext } from 'context/ModalProvider';
import RestoreEmployeeModalContent from './RestoreEmployeeModalContent';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';

const ArchivedEmployeesList = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalContext();
  const isMounted = useIsMounted();
  const [orderBy, setOrderBy] = useState<any>(null);
  const [partnerUsers, setPartnerUsers] = useState<any>([]);
  const { TABLE_RESULTS_PER_PAGE } = useFeatureFlags();
  const rowsPerPage = TABLE_RESULTS_PER_PAGE;
  const { client } = useRisClient();
  const { partnerAccountId } = useCurrentUser();
  const [pageInfo, setPageInfo] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    first: rowsPerPage,
    last: undefined,
    before: undefined,
    after: undefined,
  });

  const statuses = {};
  statuses[t('common.roles.TECHNICIAN')] = 'TECHNICIAN';
  statuses[t('common.roles.ADMIN')] = 'ADMIN';

  const getOrderBy = (sortColumn: string, sortDirection: string) => {
    if (sortColumn === 'Name') {
      return {
        'user.lastName': sortDirection,
        'user.firstName': sortDirection,
      };
    }

    if (sortColumn === 'contactEmail') {
      return { 'user.contactEmail': sortDirection };
    }
  };

  const goToPageZero = () => {
    setPagination({
      first: rowsPerPage,
      last: undefined,
      before: undefined,
      after: undefined,
    });
  };

  const { data: RisData, error } = useSuspenseQuery({
    queryKey: ['getArchivedEmployees', { orderBy, pagination }],
    queryFn: async () => {
      const res = await client.myProAccounts.myProAccountsSearchPartnerUser(
        partnerAccountId as string,
        JSON.stringify([{ status: 'ARCHIVED' }]),
        orderBy
          ? JSON.stringify(orderBy)
          : JSON.stringify({
              'user.lastName': 'ASC',
              'user.firstName': 'ASC',
            }),
        pagination.first,
        pagination.last,
        pagination.before,
        pagination.after
      );
      return res;
    },
  });

  useEffect(() => {
    const tempPartnerUsers =
      RisData?.data?.filter(partnerUser => {
        return (
          partnerUser?.user?.firstName &&
          partnerUser?.user?.lastName &&
          partnerUser?.user?.contactEmail
        );
      }) || [];

    setPartnerUsers(tempPartnerUsers);

    if ((RisData as any)?.pageInfo) {
      setPageInfo((RisData as any)?.pageInfo);
    }
  }, [RisData]);

  const getRows = () => {
    return partnerUsers?.map(employee => {
      const userInfo = employee?.user;
      if (userInfo?.firstName && userInfo?.lastName && userInfo?.contactEmail) {
        return {
          id: employee?.id,
          name: `${employee?.user?.lastName}, ${employee?.user?.firstName}`,
          email: employee?.user?.contactEmail,
          status: t(`common.statuses.${employee?.status}`),
          actions: [
            {
              name: t('common.restore'),
              ctaTag: 'restore',
              text: t('common.restore'),
              disabled: hasAdminRole(employee?.roles),
              action: () => {
                openModal({
                  title: t('mybusiness.employees.restoreModal.title'),
                  children: (
                    <div data-test-restore-modal>
                      <RestoreEmployeeModalContent
                        closeModal={closeModal}
                        id={employee?.id}
                        name={`${employee?.user?.lastName}, ${employee?.user?.firstName}`}
                        email={employee?.user?.contactEmail}
                      />
                    </div>
                  ),
                } as IModalProps);
              },
            },
          ],
        };
      }
    });
  };

  return (
    <>
      <TableV2
        headers={[
          {
            displayName: 'id',
            isSortable: false,
            isHiddenColumn: true,
          },
          {
            displayName: t('mybusiness.employees.name'),
            isSortable: true,
            onSortColumnName: 'Name',
            useEllipses: true,
            onColumnCellClick: row => {
              navigate(`/mybusiness/company/employees/${row.id}/view`);
            },
          },
          {
            displayName: t('common.emailShort'),
            isSortable: true,
            onSortColumnName: 'contactEmail',
            notBlueprintEllipses: true,
          },
          {
            displayName: 'Status',
            isSortable: false,
            isHiddenColumn: false,
            notBlueprintEllipses: true,
          },
          {
            displayName: '',
            isSortable: false,
          },
        ]}
        isError={
          error
            ? {
                errorHeader: t('common.tableErrors.header'),
                errorMessage: t('common.tableErrors.text'),
              }
            : undefined
        }
        rows={getRows()}
        onSort={(sortColumn, sortDirection) => {
          if (sortColumn && sortDirection && isMounted()) {
            setOrderBy(getOrderBy(sortColumn, sortDirection));
            goToPageZero();
          }
        }}
        initialSortColumn={'Name'}
        initialSortDirection={'ASC'}
        hasPagination
        hasNext={pageInfo?.hasNextPage}
        hasPrevious={pageInfo?.hasPreviousPage}
        nextButtonLabel={t('common.next')}
        previousButtonLabel={t('common.previous')}
        onPaginationNextClick={() => {
          setPagination({
            first: rowsPerPage,
            last: undefined,
            before: undefined,
            after: pageInfo?.endCursor,
          });
        }}
        onPaginationPrevClick={() => {
          setPagination({
            first: undefined,
            last: rowsPerPage,
            before: pageInfo?.startCursor,
            after: undefined,
          });
        }}
        NoDataMessage={t('mybusiness.employees.archived.noArchivedEmployees')}
      />
    </>
  );
};

export default ArchivedEmployeesList;
