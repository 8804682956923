import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@resideo/blueprint-react';
import LeftNavigation from '@resideo/blueprint-react/dist/components/LeftNavigation/LeftNavigation';
import styled from 'styled-components';
import { useFeatureFlags } from 'context/FeatureFlags';
import { useLayout } from 'context/Layout';
import { useCurrentUser } from 'context/CurrentUser';
import { NavLink, useLocation } from 'react-router-dom';
import useMedia from 'hooks/useMedia';
import primaryNavigation from 'config/navigation';
import { useServicesLinks } from 'hooks/useServicesLinks';

interface LeftNavWrapper {
  mobileMenuOpen: boolean;
}

const LeftNavWrapper = styled(Flex)<LeftNavWrapper>`
  background: #f7f7f7;
  width: 280px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mLarge}) {
    ${({ mobileMenuOpen }) => (mobileMenuOpen ? '' : 'display: none')};
    width: auto;
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }
`;

const NavContainer = styled(Flex)`
  position: relative;
`;

const NavWrapper = styled.div`
  position: fixed;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
  width: 280px;
  height: 100%;
  z-index: var(--zidx-main-content);
  @media (max-width: ${({ theme }) => theme.breakpoints.mLarge}) {
    width: 100%;
  }
`;

const RightPanel = styled(Flex)`
  flex: 1;
  overflow: auto;
`;

const LeftNavLayout = ({ children }: { children?: any }) => {
  const { t } = useTranslation();
  const featureFlags = useFeatureFlags();
  const {
    BUSINESS_INTELLIGENCE_FLAG,
    SERVICES_SUBSCRIPTIONS_FLAG,
    IS_EMEA_OR_GB_MARKET,
    CUSTOMER_ALERTS,
  } = featureFlags;
  const { mobileMenuOpen, setMobileMenuOpen } = useLayout();
  const isMobile = useMedia('(max-width: 68rem)');
  const { isOwner, isAdmin, hasActivePartnerBrandProfile } = useCurrentUser();
  const { servicesPath } = useServicesLinks();
  const viewerHasPermissions = isOwner || isAdmin;
  const customerAccountPermissions =
    (isOwner || isAdmin) && !IS_EMEA_OR_GB_MARKET;
  const location = useLocation();

  const onMenuItemClick = () => {
    setMobileMenuOpen(false);
  };

  const CustAccountsSubnav = [
    {
      name: 'customerList',
      title: 'customers.customerList',
      link: '/mybusiness/customers/account-lookup',
    },
  ];

  if (CUSTOMER_ALERTS) {
    CustAccountsSubnav.push({
      name: 'alertsDashboard',
      title: 'alertsDashboard.title',
      link: '/mybusiness/alerts/dashboard',
      end: false,
      isActive: (path: string) => path.startsWith('/mybusiness/alerts'),
    } as any);
  }

  const nav = [
    {
      name: 'home',
      title: 'header.primary.home',
      link: '/mybusiness/home',
    },
    {
      name: 'customerAccounts',
      title: 'customers.title',
      hide: !customerAccountPermissions,
      subNav: CustAccountsSubnav,
    },
    {
      name: 'manageCompany',
      title: 'company.manageCompany',
      hide: !viewerHasPermissions,
      subNav: [
        {
          name: 'companyInformation',
          title: 'leftNav.companyInfo',
          link: '/mybusiness/company/contact',
        },
        {
          name: 'employees',
          title: 'leftNav.employees',
          link: '/mybusiness/company/employees',
          end: false,
        },
      ],
    },
    {
      name: 'servicesSubscriptions',
      title: 'leftNav.servicesSubscriptions',
      link: servicesPath,
      hide: !viewerHasPermissions || !SERVICES_SUBSCRIPTIONS_FLAG,
      end: false,
    },
    {
      name: 'businessIntelligence',
      title: 'leftNav.businessIntelligence',
      link: '/mybusiness/business-intelligence',
      hide:
        !viewerHasPermissions ||
        !hasActivePartnerBrandProfile ||
        !BUSINESS_INTELLIGENCE_FLAG,
    },
    {
      name: 'trainingSupport',
      title: 'leftNav.trainingSupport',
      link: '/mybusiness/training',
    },
  ];

  const menuItems = nav.filter(({ hide }) => {
    return !(typeof hide === 'boolean' && hide);
  });

  const mobileMenuItems = [
    ...primaryNavigation(featureFlags).map(
      ({ name, linkTextKey, linkPath, isExternalLink }) => ({
        name,
        title: linkTextKey,
        link: linkPath,
        isExternalLink,
      })
    ),
    ...menuItems,
    {
      name: 'profile',
      title: 'header.profile',
      link: '/profile',
    },
    {
      name: 'signout',
      title: 'header.signout',
      link: '/sign-out',
    },
  ];

  return (
    <Flex flexDirection='row' width='100%' height='100%'>
      <LeftNavWrapper
        flexDirection='column'
        height='100%'
        mobileMenuOpen={mobileMenuOpen}>
        <NavContainer data-test-left-nav flexDirection='column'>
          <NavWrapper>
            <LeftNavigation
              linkRenderer={({ link, title, isExternalLink, end, isActive }) =>
                !isExternalLink ? (
                  <NavLink
                    className={navLinkData => {
                      if (isActive) {
                        return isActive(location.pathname) ? 'active' : '';
                      }
                      if (title !== 'leftNav.servicesSubscriptions') {
                        return navLinkData.isActive ? 'active' : '';
                      }
                      return location.pathname.includes('/mybusiness/services')
                        ? 'active'
                        : '';
                    }}
                    to={link}
                    end={end}
                    onClick={onMenuItemClick}
                    data-test-secondary-nav-link-item>
                    {t(title)}
                  </NavLink>
                ) : (
                  <a href={link} target={'_blank'} rel='noreferrer'>
                    {t(title)}
                  </a>
                )
              }
              menuItems={isMobile ? mobileMenuItems : menuItems}
              data-test-menu
            />
          </NavWrapper>
        </NavContainer>
      </LeftNavWrapper>
      <RightPanel flexDirection='column' backgroundColor='white' height='100%'>
        {children}
      </RightPanel>
    </Flex>
  );
};

export default LeftNavLayout;
