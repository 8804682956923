import React from 'react';
import styled from 'styled-components';
import { SelectField } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { DashboardMetricsInterval } from '@resideo/web-integration-services-api-client';
import { BUSINESS_INTELLIGENCE_DASHBOARD_UPDATES } from 'config/flags';

const SelectWrapper = styled.div`
  width: 150px;
  margin-top: 6px;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
  }
  select {
    &:focus {
      border: 1px solid #dadada;
      border-radius: 6px;
      border-color: #4f7285;
      box-shadow: none !important;
    }
  }
`;

const TimePeriodFilter = props => {
  const { t } = useTranslation();
  const options = BUSINESS_INTELLIGENCE_DASHBOARD_UPDATES
    ? [
        {
          value: DashboardMetricsInterval.LAST_WEEK,
          label: 'cobrandingDashboard.last7Days',
        },
        {
          value: DashboardMetricsInterval.LAST_THIRTY_DAYS,
          label: 'cobrandingDashboard.last30Days',
        },
        {
          value: DashboardMetricsInterval.LIFE_TIME,
          label: 'cobrandingDashboard.lifetime',
        },
      ]
    : [
        { value: 'Last 7 Days', label: 'cobrandingDashboard.last7Days' },
        { value: 'Last 30 Days', label: 'cobrandingDashboard.last30Days' },
      ];

  const [value, setValue] = React.useState('Last 7 Days');

  const handleChange = event => {
    setValue(event.target.value);
    props.getTimeFilterValue(event.target.value);
  };

  return (
    <div>
      <SelectWrapper>
        <SelectField
          name=''
          label=''
          value={value}
          onChange={handleChange}
          version='v2'>
          {options.map((option, i) => (
            <option key={i} value={option.value}>
              {t(option.label)}
            </option>
          ))}
        </SelectField>
      </SelectWrapper>
    </div>
  );
};

export default TimePeriodFilter;
