import React, { ComponentType, lazy } from 'react';
import { Titled } from 'components/common/Titled';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Error from 'routes/Error';
import { useCurrentUser } from 'context/CurrentUser';
import { RESIDEO_ID_URL } from '../../config';

type LazyModulePromise = Promise<{ default: ComponentType<any> }>;

const Status = lazy((): LazyModulePromise => import('./Status'));
const CompanyUpdate = lazy((): LazyModulePromise => import('./CompanyUpdate'));

export const CompanyLayout = () => {
  const { t } = useTranslation();

  return (
    <Titled title={t('company.pageTitle')}>
      <Outlet />
    </Titled>
  );
};

const ApplicationRoot = () => {
  const { partnerAccount, inviteStatus } = useCurrentUser();

  if (partnerAccount || inviteStatus === 'REJECTED') {
    return <Navigate to='/mybusiness/home' replace />;
  } else {
    window.location.href = `${RESIDEO_ID_URL}/sign-up/company-info`;
  }
};

const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to='/company/application' replace />,
  },
  {
    path: 'application',
    element: <ApplicationRoot />,
  },
  {
    path: 'application/status',
    element: <Status />,
  },
  {
    path: 'application/update',
    element: <CompanyUpdate />,
  },
  {
    path: '*',
    element: <Error />,
  },
];

export default routes;
