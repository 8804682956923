import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import InfoIcon from './infoIcon';

export const ActionButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  min-width: 25px;
  font-weight: 500;
  font-size: 14px;
  color: #153755;
  font-family: 'Roboto', sans-serif;

  &:active {
    color: #1c6fb9;
  }

  svg {
    vertical-align: middle;
  }
`;

export const ControlsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 20px 0 20px;
  @media (max-width: 1500px) {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 20px;
  }
`;

const StyledAlertsHeader = styled.h3`
  margin: 0;
  font-size: 21px;
  font-weight: 500;
`;

const StyledTooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
    box-shadow: 0 2px 10px #0000002b;
    font-size: 14px;
    font-weight: 400;
  }
`;

const StyledTooltipIcon = styled(InfoIcon)`
  margin-left: 12px;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
`;

export const AlertsHeader = ({
  tooltip,
  children,
}: {
  tooltip?: string;
  children: React.ReactNode;
}) => {
  return (
    <StyledAlertsHeader>
      {children}
      {tooltip && (
        <>
          <StyledTooltipIcon data-tip data-for={'alerts-header'} />
          <StyledTooltip
            type='light'
            id={'alerts-header'}
            place='right'
            effect='solid'
            delayHide={500}
            delayUpdate={500}>
            {tooltip}
          </StyledTooltip>
        </>
      )}
    </StyledAlertsHeader>
  );
};
