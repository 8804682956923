import React from 'react';
import { Box, Card, Flex, Button, Text } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import {
  DataSheets,
  HomeOwnerSales,
  InfoForDealers,
  ApplicationImages,
  ProductImages,
  GroupImages,
  SmbOwnerSales,
} from 'components/proseries';
import {
  WaterProducts,
  ZoningProducts,
  ThermalSolutions,
  ThermostatProducts,
  DuctlessProducts,
  DehumidificationProducts,
  HumidificationProducts,
  FiltrationProducts,
  ForceAirZoning,
} from 'components/hvacWater';
import DownloadAllButton from './DownloadAllButton';

const dynamicSort = property => (a, b) => {
  return a[property].localeCompare(b[property]);
};

const DownloadCard = ({ show, type, param }) => {
  const { t } = useTranslation();
  const getDataType = (
    type: string
  ): {
    text: string;
    download: any;
    ext: string;
    desc: string;
    brand?: string;
  }[] => {
    switch (type) {
      case 'data-sheets':
        return DataSheets(t);
      case 'dealer-info':
        return InfoForDealers(t);
      case 'home-owner':
        return HomeOwnerSales(t);
      case 'smb-owner':
        return SmbOwnerSales(t);
      case 'application-images':
        return ApplicationImages(t);
      case 'product-images':
        return ProductImages(t);
      case 'group-images':
        return GroupImages(t);
      case 'water':
        return WaterProducts(t).sort(dynamicSort('text'));
      case 'zoning':
        return ZoningProducts(t).sort(dynamicSort('text'));
      case 'thermal':
        return ThermalSolutions(t).sort(dynamicSort('text'));
      case 'ductless':
        return DuctlessProducts(t).sort(dynamicSort('text'));
      case 'dehumidification':
        return DehumidificationProducts(t).sort(dynamicSort('text'));
      case 'filtration':
        return FiltrationProducts(t).sort(dynamicSort('text'));
      case 'humidification':
        return HumidificationProducts(t).sort(dynamicSort('text'));
      case 'thermostats':
        return ThermostatProducts(t).sort(dynamicSort('text'));
      case 'force':
        return ForceAirZoning(t).sort(dynamicSort('text'));
      case 'all':
        return [
          ...WaterProducts(t),
          ...ZoningProducts(t),
          ...ThermalSolutions(t),
          ...DuctlessProducts(t),
          ...DehumidificationProducts(t),
          ...FiltrationProducts(t),
          ...HumidificationProducts(t),
          ...ThermostatProducts(t),
          ...ForceAirZoning(t),
        ].sort(dynamicSort('text'));

      default:
        return [{ text: '', download: '', ext: '', desc: '', brand: '' }];
    }
  };

  return (
    <Card hidden={show} padding='medium'>
      {param === 'hvacWater' && (
        <>
          <Flex
            alignItems='center'
            paddingY='medium'
            flexDirection={['column', 'column', 'column', 'row']}
            textAlign={['center', 'center', 'center', 'inherit']}
            display={['none', 'none', 'none', 'flex']}>
            <Box marginBottom={['small', 'small', 'small', 0]} flexBasis='20%'>
              <Text>{t('hvacAndWater.titles.sku')}</Text>
            </Box>
            <Box marginBottom={['small', 'small', 'small', 0]} flexBasis='40%'>
              <Text>{t('hvacAndWater.titles.name')}</Text>
            </Box>
            <Box marginBottom={['small', 'small', 'small', 0]} flexBasis='25%'>
              <Text>{t('hvacAndWater.titles.brand')}</Text>
            </Box>
            <DownloadAllButton alignItems='end' type={type} />
          </Flex>
          <DownloadAllButton
            display={['flex', 'flex', 'flex', 'none']}
            alignItems='center'
            type={type}
          />
        </>
      )}
      {getDataType(type).map(({ text, download, ext, desc, brand }, i) => (
        <Flex
          key={`${text}-${i}`}
          alignItems='center'
          paddingY='medium'
          flexDirection={['column', 'column', 'column', 'row']}
          textAlign={['center', 'center', 'center', 'inherit']}>
          <Box
            marginBottom={['small', 'small', 'small', 0]}
            flexBasis={param === 'proseries' ? '45%' : '25%'}>
            <Text>{text}</Text>
          </Box>
          <Box flexBasis={param === 'proseries' ? '75%' : '50%'}>
            <Text>{desc}</Text>
          </Box>
          {brand && <Box flexBasis='25%'>{brand}</Box>}
          <Box paddingTop={['small', '']} flexBasis='25%' textAlign='right'>
            {download !== '' && (
              <a
                href={download}
                download={`${text}${ext}`}
                target='_blank'
                rel='noopener noreferrer'>
                <Button>{t('proSeries.download')}</Button>
              </a>
            )}
          </Box>
        </Flex>
      ))}
    </Card>
  );
};

export default DownloadCard;
