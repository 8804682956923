import React, { FC, RefObject, Suspense, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Avatar } from '@resideo/blueprint-react';
import ProfileAvatar from 'components/common/ProfileAvatar';
import ProfileMenuOptions from './ProfileMenuOptions';
import { useCurrentUser } from 'context/CurrentUser';
import useOutsideClick from 'hooks/useOutsideClick';

const Menu = styled.div`
  position: relative;
`;

const ProfileMenuButton = styled.button`
  appearance: none;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0;
  border: none;
  border-radius: 50%;
  max-width: 2.25rem;
  max-height: 2.25rem;
  outline: none;
  &:focus {
    box-shadow: 0 0 0.1rem 0.1rem #ccc;
  }
`;

const ProfileMenuDropDownList = styled.div`
  display: block;
  white-space: nowrap;
  border: 0;
  background: #032a3c;
  box-shadow: -6px 5px 15px -3px #00000040;
  outline: none;
  padding: 1rem 0;
  position: absolute;
  top: calc(100% + 10px);
  right: -10px;
  text-align: left;
  z-index: var(--zidx-profile-menu);

  &:after {
    content: '';
    position: absolute;
    right: 20px;
    bottom: 100%;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid
      ${({ theme }) => theme.colors.header.primaryBackground};
    clear: both;
  }
`;

const ProfileMenu: FC = rest => {
  const { t } = useTranslation();
  const ref: RefObject<any> = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  const { firstName, lastName, photoUrl, isAdmin, isOwner } = useCurrentUser();

  return (
    <Menu>
      <ProfileMenuButton
        ref={ref}
        data-test-profile-menu-button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        {...rest}>
        <Suspense
          fallback={
            <Avatar aria-label={t('header.primary.settings')} width='100%' />
          }>
          <ProfileAvatar
            firstName={firstName || ''}
            lastName={lastName || ''}
            photoUrl={photoUrl}
            variant='small'
          />
        </Suspense>
      </ProfileMenuButton>
      {isOpen && (
        <ProfileMenuDropDownList data-test-profile-menu>
          <ProfileMenuOptions isAdmin={!!isAdmin} isOwner={!!isOwner} />
        </ProfileMenuDropDownList>
      )}
    </Menu>
  );
};

export default ProfileMenu;
