import styled from 'styled-components';
import { Flex, Button } from '@resideo/blueprint-react';
import WarningIcon from '../icons/alert.svg?react';

export const ButtonContainer = styled(Flex)`
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 35px;
`;

export const ModalContent = styled(Flex)`
  line-height: 24px;
  flex-direction: column;
`;

export const StyledWarningIcon = styled(WarningIcon)`
  height: 38px;
`;

export const WarningContainer = styled(Flex)`
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
`;

export const DeleteButton = styled(Button)`
  background-color: #e41b1e;
  border: 1px solid #e41b1e;
  &:hover,
  :focus {
    background-color: #e41b1e;
    border: 1px solid #e41b1e;
  }
`;
