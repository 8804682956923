import React from 'react';
import footerLegalLinks from '../../config/footer';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const FooterOuter = styled.footer`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
`;

interface Link {
  externalLink?: boolean;
}

const Link = styled.a<Link>`
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-decoration: underline;
  display: inline-block;
  svg {
    margin-left: ${({ theme, externalLink }) =>
      externalLink ? `${theme.space.small}` : '0'};
  }
`;

const BottomLinks = styled.p`
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.small};
  margin-top: ${({ theme }) => theme.space.medium};
  padding-left: ${({ theme }) => theme.space.medium};
  padding-right: ${({ theme }) => theme.space.medium};
  text-align: center;
  word-break: break-word;
  a {
    color: ${({ theme }) => theme.colors.secondaryLink};
    font-size: ${({ theme }) => theme.fontSizes.xSmall};
    &:after {
      content: '|';
      padding: 0 ${({ theme }) => theme.space.small};
    }
    &:last-of-type:after {
      content: '';
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
  }
`;

const TrademarkSection = styled.p`
  align-items: center;
  color: ${({ theme }) => theme.colors.footerLink};
  font-size: ${({ theme }) => theme.fontSizes.xxSmall};
  margin-top: ${({ theme }) => theme.space.xSmall};
  padding: 0 ${({ theme }) => theme.space.small};
  text-align: center;
`;

const trademarkYear = new Date().getFullYear();

const Footer = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FooterOuter data-test-footer>
      <BottomLinks data-test-secondary-footer-nav-links>
        {footerLegalLinks.map(function(link, i): JSX.Element {
          return (
            <Link
              href={link.linkPath}
              key={i}
              {...(link.target ? { target: link.target } : {})}>
              {t(link.textKey)}
            </Link>
          );
        })}
      </BottomLinks>
      <TrademarkSection data-test-footer-copyright>
        {t('footer.copyright', { year: trademarkYear })}
      </TrademarkSection>
    </FooterOuter>
  );
};

export default Footer;
