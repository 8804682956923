import React from 'react';
import { intlFormatDistance, formatRelative } from 'date-fns';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AlertListModel } from '@resideo/web-integration-services-api-client';

const StyledAlertCell = styled.div`
  font-size: 12px;
  color: #303030;

  span {
    display: block;
    font-size: 16px;
  }
`;

const AlertCell = ({ row }: { row: AlertListModel }) => {
  const { i18n } = useTranslation();
  const { statusUpdatedBy, statusUpdatedAt } = row;
  const { firstName, lastName } = statusUpdatedBy?.user || {};

  return (
    <StyledAlertCell>
      <span>{`${firstName} ${lastName}`}</span>
      {statusUpdatedAt &&
        formatRelative(new Date(statusUpdatedAt), new Date())}{' '}
      <br />(
      {statusUpdatedAt &&
        intlFormatDistance(new Date(statusUpdatedAt), new Date(), {
          locale: i18n.language,
        })}
      )
    </StyledAlertCell>
  );
};

export default AlertCell;
