import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, Button } from '@resideo/blueprint-react';
import { InputField, newUseCountrySelector } from '@resideo/blueprint-formik';
import { AddressType, validateAddress } from 'components/common/Address';
import styled from 'styled-components';
import { AUTOCOMPLETE_ADDRESS_FIELD } from 'config/flags';
import AutocompleteWrapper from '../AutocompleteWrapper';

export interface Location extends AddressType {
  locationName?: string;
}

interface CustomerLocationFormProps {
  onSubmit: (values, autoCompleteSelected: boolean) => void;
  onCancel?: () => void;
  isStep?: boolean;
  initialValues: any;
  isLoaded?: any;
  loadError?: any;
  locations?: any;
  isBackBtnClickedFromVerificationModal?: boolean;
}

const CityStateContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
`;

const CustomerLocationForm = ({
  onSubmit,
  onCancel,
  isStep,
  initialValues,
  isLoaded,
  loadError,
  locations,
  isBackBtnClickedFromVerificationModal,
}: CustomerLocationFormProps) => {
  const { t } = useTranslation();
  const apiKey = import.meta.env.REACT_APP_COUNTRY_STATE_API_KEY || '';
  const {
    CountrySelectorWrapper: NewCountryField,
    StateSelectorWrapper: NewStateField,
    IsStateFieldEnabled,
    setCountry,
    setStateValue,
  } = newUseCountrySelector({
    apiKey,
  });
  const formikRef = React.useRef<any>(null);
  const [autoCompleteSelected, setAutoCompleteSelected] = useState<boolean>(
    false
  );

  useEffect(() => {
    if (formikRef?.current?.values) {
      formikRef?.current?.validateForm();
    }
  }, [formikRef?.current?.values]);

  const validate = (values: Location) => {
    const errors: FormikErrors<Location> = {};

    const existingLocationNames = locations?.filter(
      location => location.name === values.locationName
    );

    const existingLocationName =
      existingLocationNames?.length && existingLocationNames[0]?.name;

    if (!values.locationName?.trim()) {
      errors.locationName = t('locations.locationNameRequired');
    }
    if (
      existingLocationName &&
      existingLocationName !== initialValues?.locationName
    ) {
      errors.locationName = t('locations.existingLocationName');
    }
    validateAddress(errors, t, values, '');
    return errors;
  };

  return AUTOCOMPLETE_ADDRESS_FIELD ? (
    <Formik
      initialValues={initialValues}
      onSubmit={values => onSubmit(values, autoCompleteSelected)}
      validate={validate}
      innerRef={formikRef}>
      {({
        dirty,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        values,
        errors,
        touched,
        isValid,
      }) => {
        return (
          <Form data-test-customer-info noValidate>
            <Text
              data-test-required-fields
              marginBottom='medium'
              fontSize='small'
              fontStyle='italic'>
              {t('common.requiredFields')}
            </Text>
            <Box data-test-location-name>
              <InputField
                version='v2'
                name='locationName'
                label={t('customerLocationForm.locationName')}
                marginBottom='medium'
                required
                data-test-location-name
              />
            </Box>
            <AutocompleteWrapper
              initialValue={initialValues?.address1}
              setCountry={setCountry}
              setStateValue={setStateValue}
              fieldName={'address1'}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              touched={touched}
              errors={errors}
              isLoaded={isLoaded}
              loadError={loadError}
              setAutoCompleteSelected={setAutoCompleteSelected}
            />
            <Box data-test-address-address2 marginBottom={['medium']}>
              <InputField
                version='v2'
                name={'address2'}
                label={t('customerLocationForm.address2')}
              />
            </Box>
            <Box data-test-address-zip marginBottom={['medium']}>
              <InputField
                version='v2'
                name={'zip'}
                label={t('customerLocationForm.zip')}
                required
              />
            </Box>
            <CityStateContainer>
              <Box data-test-address-city marginBottom={['medium']}>
                <InputField
                  version='v2'
                  name={'city'}
                  label={t('customerLocationForm.city')}
                  required
                />
              </Box>
              <Box data-test-address-state marginBottom={['medium']}>
                <NewStateField
                  version='v2'
                  name={'state'}
                  label={t('customerLocationForm.state')}
                  variant='primary'
                  required
                  initialValue={values.state}
                  value={values.state}
                  blankOption={t('common.forms.select')}
                  error={touched.state && errors.state}
                  onStateChange={val => {
                    setFieldValue('state', val);
                  }}
                />
              </Box>
            </CityStateContainer>
            <Box data-test-address-country marginBottom={['medium']}>
              <NewCountryField
                version='v2'
                label={t('customerLocationForm.country')}
                name={'country'}
                onCountryChange={val => {
                  setFieldValue('country', val);
                }}
                blankOption={t('common.forms.select')}
                initialValue={values.country}
                error={touched.country && errors.country}
                required
              />
            </Box>
            <Flex
              alignItems='center'
              justifyContent='flex-end'
              paddingX={['medium', 0]}
              paddingTop='large'>
              {onCancel && (
                <Button
                  data-test-add-customer-back-btn
                  marginX='large'
                  onClick={() => {
                    onCancel();
                  }}
                  type='button'
                  variant='secondary'>
                  {t(isStep ? 'common.back' : 'common.cancel')}
                </Button>
              )}
              <Button
                data-test-add-customer-save-btn
                disabled={
                  isBackBtnClickedFromVerificationModal
                    ? !isBackBtnClickedFromVerificationModal
                    : !dirty || isSubmitting || !isValid
                }
                type='submit'
                variant='primary'
                width={[1, 'auto']}>
                {t(isStep ? 'common.submit' : 'common.save')}
              </Button>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={values => onSubmit(values, false)}
      validate={validate}>
      {({
        dirty,
        isSubmitting,
        setFieldValue,
        values,
        errors,
        touched,
        isValid,
      }) => (
        <Form data-test-customer-info noValidate>
          <Text
            data-test-required-fields
            marginBottom='medium'
            fontSize='small'
            fontStyle='italic'>
            {t('common.requiredFields')}
          </Text>
          <Box data-test-location-name>
            <InputField
              version='v2'
              name='locationName'
              label={t('locations.locationName')}
              marginBottom='medium'
              required
              data-test-location-name
            />
          </Box>
          <Box data-test-address-country marginBottom={['medium']}>
            <NewCountryField
              version='v2'
              label={t('common.address.country')}
              name={'country'}
              onCountryChange={val => {
                setFieldValue('country', val);
              }}
              blankOption={t('common.forms.select')}
              initialValue={values.country}
              error={touched.country && errors.country}
              required
            />
          </Box>
          <Flex marginBottom='medium' flexDirection={['column', 'row']}>
            <Box
              data-test-address-address1
              width={[1, 2 / 3]}
              marginBottom={['medium', 0]}>
              <InputField
                version='v2'
                name={'address1'}
                label={t('common.address.address1')}
                required
              />
            </Box>
            <Box
              data-test-address-address2
              width={[1, 1 / 3]}
              marginLeft={[0, 'medium']}>
              <InputField
                version='v2'
                name={'address2'}
                label={t('common.address.address2')}
              />
            </Box>
          </Flex>
          <Box data-test-address-city marginBottom={['medium']}>
            <InputField
              version='v2'
              name={'city'}
              label={t('common.address.city')}
              required
            />
          </Box>
          <Box data-test-address-state marginBottom={['medium']}>
            <NewStateField
              version='v2'
              name={'state'}
              label={t('common.address.state')}
              variant='primary'
              required
              initialValue={values.state}
              blankOption={t('common.forms.select')}
              error={
                IsStateFieldEnabled ? touched.state && errors.state : false
              }
              onStateChange={val => {
                setFieldValue('state', val);
              }}
            />
          </Box>
          <Box data-test-address-zip marginBottom={['medium']}>
            <InputField
              version='v2'
              name={'zip'}
              label={t('common.address.zip')}
              required
            />
          </Box>
          <Flex
            alignItems='center'
            justifyContent='flex-end'
            paddingX={['medium', 0]}
            paddingTop='large'>
            {onCancel && (
              <Button
                data-test-add-customer-back-btn
                marginX='large'
                onClick={() => {
                  onCancel();
                }}
                type='button'
                variant='secondary'>
                {t(isStep ? 'common.back' : 'common.cancel')}
              </Button>
            )}
            <Button
              data-test-add-customer-save-btn
              disabled={
                isBackBtnClickedFromVerificationModal
                  ? !isBackBtnClickedFromVerificationModal
                  : !dirty || !isValid || isSubmitting
              }
              type='submit'
              variant='primary'
              width={[1, 'auto']}>
              {t(isStep ? 'common.submit' : 'common.save')}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default CustomerLocationForm;
