import React, { RefObject, Suspense, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';
import {
  useToastContext,
  Flex,
  Box,
  Heading,
  Breadcrumbs,
  Text,
  Button,
  Link as BPLink,
  useVerificationModal,
} from '@resideo/blueprint-react';
import { Titled } from 'components/common/Titled';
import { RemoveCustomerConfirmationModalContent } from '../RemoveCustomer/RemoveCustomerModals';
import RecordNotFound from 'components/common/RecordNotFound';
import styled from 'styled-components';
import CustomerLocations from './CustomerLocations';
import TableLoading from 'components/common/TableLoading';
import { IModalProps, useModalContext } from 'context/ModalProvider';
import { useRisClient } from 'hooks/useRisClient';
import {
  useSuspenseQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useCurrentUser } from 'context/CurrentUser';
import EditCustomerForm from '../AddCustomer/Steps/EditCustomerForm';
import {
  CreatePartnerDeviceLocationModel,
  PartnerCustomerModel,
} from '@resideo/web-integration-services-api-client';
import CustomerLocationForm from '../AddCustomer/CustomerLocationForm';
import {
  ButtonContainer,
  StyledWarningIcon,
  WarningContainer,
} from '../customers.style';
import { AiFillCaretDown } from 'react-icons/ai';
import PhoneIcon from 'components/icons/phone.svg?react';
import EmailIcon from 'components/icons/email.svg?react';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { useFeatureFlags } from 'context/FeatureFlags';
import CustomerAccountInformation from './CustomerAccountInformation';
import { GOOGLE_MAPS_API_KEY } from 'config';
import { parseGeoAddress } from '../../../utils/common';
import { AddressType } from './types';

const PageContainer = styled.div`
  width: 100%;
  scrollbar-width: 1px;
  scrollbar-color: #fff #fff;
  display: relative;
`;

const Header = styled.div`
  background-color: #ffffff !important;
  position: fixed;
  z-index: 2;
  width: -webkit-fill-available;
`;

const HeadingContainer = styled(Flex)`
  display: -webkit-box;
  min-width: 0;
  padding: 5px 30px 10px 30px;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    box-shadow: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;
  }
`;

const HeadingContainerOld = styled(Flex)`
  display: -webkit-box;
  min-width: 0;
  box-shadow: 0 1px 7px -5px;
  border-bottom: 5px;
  padding: 15px 30px 30px 30px;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    box-shadow: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;
  }
`;

const SubHeader = styled.div`
  display: -webkit-box;
  min-width: 0;
  gap: 30px;
  padding: 10px 30px 0px 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: grid;
    box-shadow: none;
    padding-left: 0;
    padding-right: 15px;
    padding-bottom: 0;
  }
`;

const PhoneEmailLink = styled.a`
  text-decoration: none;
  color: #153755;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-items: center;
  justify-content: start;
  font-size: 16px;
  &:hover {
    color: #1c6fb9;
    fill: #1c6fb9;
  }
`;

const StyledLink = styled(BPLink)`
  text-decoration: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 0.875rem;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: max-content;
  margin-left: auto;
  padding-top: 19px;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    position: relative;
    padding-top: 3%;
    width: 100%;
  }
`;

const Dropdown = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #dadada;
  color: #1c6fb9;
  display: flex;
  font-size: 14px;
  padding: 10px 25px;

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: #dbebfe;
    border-color: #1c6fb9;
    color: black;
  }

  &.active {
    cursor: pointer;
    background-color: #dbebfe;
    border-color: #1c6fb9;
    color: black;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const DropdownOption = styled.div`
  background-color: white;
  border: none;
  display: block;
  padding: 10px 40px 10px 20px;
  text-align: left;
  font-size: 16px;
  min-width: max-content;

  &:hover {
    cursor: pointer;
    background-color: #ededed;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const DropdownMenuContainer = styled.div`
  margin-top: 10px;
  background-color: white;
  box-shadow: 0 3px 6px #00000029;
  position: absolute;
  right: 0;
  z-index: 100;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    left: 0;
  }
`;

const PhoneIconStyled = styled(PhoneIcon)`
  width: 28px;
  height: 28px;
`;

const EmailIconStyled = styled(EmailIcon)`
  width: 28px;
  height: 28px;
`;

const libraries: Libraries = ['places', 'marker', 'maps'];
const RemoveButtonContainer = styled.div`
  box-sizing: border-box;
  width: max-content;
  margin-left: auto;
  padding-top: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    position: relative;
    padding-top: 3%;
    width: 100%;
  }
`;

const CustomerAccount = () => {
  const { id: customerId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addToast } = useToastContext();
  const { openModal, closeModal } = useModalContext();
  const { client } = useRisClient();
  const { partnerAccountId } = useCurrentUser();
  const { CUSTOMER_DETAILS_REDESIGN } = useFeatureFlags();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const dropdownRef: RefObject<any> = useRef();
  const [locationValues, setLocationValues] = useState<any>({});
  const [prevPage, setPrevPage] = useState<string | undefined>();
  const {
    openAddressVerificationModal,
    closeAddressVerificationModal,
    AddAddressVerificationModal,
  } = useVerificationModal();
  const [suggestedAddress, setSuggestedAddress] = useState<AddressType>({
    address1: '',
    address2: '',
    city: '',
    country: '',
    locationName: '',
    state: '',
    zip: '',
  });
  const addCustomerDeviceLocation = useMutation({
    mutationKey: ['addCustomerDeviceLocation'],
    mutationFn: async (variables: {
      partnerCustomerId: string;
      loc: CreatePartnerDeviceLocationModel;
    }) => {
      await client.myProAccounts.myProAccountsCreatePartnerDeviceLocation(
        partnerAccountId as string,
        variables?.partnerCustomerId as string,
        {
          name: variables?.loc?.name,
          address: {
            addressLine1: variables?.loc?.address?.addressLine1,
            addressLine2: variables?.loc?.address?.addressLine2,
            city: variables?.loc?.address?.city,
            stateProvinceRegionCode:
              variables?.loc?.address?.stateProvinceRegionCode,
            zipPostalCode: variables?.loc?.address?.zipPostalCode,
            countryCode: variables?.loc?.address?.countryCode,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['partnerCustomerLocations'],
      });
      closeModal();
      addToast({
        toastType: 'Success',
        message: t('customerDetails.addLocationToast.success'),
      });
    },
    onError: () => {
      openModal({
        title: t('customers.addCustomer'),
        children: (
          <Flex flexDirection='column' alignItems='left' width={[1]}>
            <WarningContainer>
              <StyledWarningIcon />
              <Text fontSize='large' fontWeight='bold'>
                {t('customers.addLocationErrors.title')}
              </Text>
            </WarningContainer>
            <p> {t('customers.addLocationErrors.error')}</p>
            <p>
              {t('customers.addLocationErrors.contactSupport')}{' '}
              <StyledLink href='mailto:proIQsupport@resideo.com'>
                proIQsupport@resideo.com
              </StyledLink>
              {'.'}
            </p>
            <ButtonContainer>
              <Button variant='primary' onClick={() => closeModal()}>
                {t('common.ok')}
              </Button>
            </ButtonContainer>
          </Flex>
        ),
      });
    },
  });

  const updateCustomerLocation = (values?) => {
    // parse these values and pass it to service
    const apiValues = values ? values : locationValues;

    addCustomerDeviceLocation.mutate({
      partnerCustomerId: customerId || '',
      loc: {
        name: apiValues?.locationName,
        address: {
          addressLine1: apiValues?.address1,
          addressLine2: apiValues?.address2,
          city: apiValues?.city,
          stateProvinceRegionCode: apiValues?.state,
          zipPostalCode: apiValues?.zip,
          countryCode: apiValues?.country,
        },
      },
    });
  };

  const handleCreateDeviceLocation = async values => {
    const {
      geoAddress,
      result,
      isLocationTypeRoofTop,
      partialMatch,
      shortNames,
    } = await parseGeoAddress(values);

    // check the response structure for geoAddress
    if ((result && isLocationTypeRoofTop && !partialMatch) || !geoAddress) {
      updateCustomerLocation();
    } else {
      closeModal();
      setSuggestedAddress(shortNames);
      openAddressVerificationModal();
    }
  };

  const { data: locations } = useSuspenseQuery({
    queryKey: ['partnerCustomerLocations'],
    queryFn: async () => {
      const res = await client.myProAccounts.myProAccountsSearchPartnerCustomerLocations(
        partnerAccountId || '',
        customerId || ''
      );
      return res?.data;
    },
  });

  const addCustomerLocation = (
    AddressDetails?,
    isBackbuttonClicked = false
  ) => {
    const defaultValues = {
      locationName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    };
    const address = isBackbuttonClicked ? AddressDetails : defaultValues;
    setIsDropdownOpen(!isDropdownOpen);
    openModal({
      title: t('locations.addLocation'),
      children: (
        <CustomerLocationForm
          initialValues={{
            ...address,
          }}
          onSubmit={(values, autoCompleteSelected) => {
            if (autoCompleteSelected) {
              updateCustomerLocation(values);
            } else {
              setLocationValues(values);
              handleCreateDeviceLocation(values);
            }
          }}
          onCancel={() => closeModal()}
          isLoaded={isLoaded}
          loadError={loadError}
          locations={locations}
          isBackBtnClickedFromVerificationModal={isBackbuttonClicked}
        />
      ),
    });
  };

  const editCustomer = () => {
    setIsDropdownOpen(!isDropdownOpen);
    openModal({
      title: t('customerDetails.editCustomer.title'),
      children: (
        <EditCustomerForm
          closeModal={closeModal}
          initialValues={customer as PartnerCustomerModel}
        />
      ),
    } as IModalProps);
  };

  const removeCustomer = () => {
    setIsDropdownOpen(!isDropdownOpen);
    openModal({
      title: t('customerDetails.removeCustomer.title'),
      children: (
        <RemoveCustomerConfirmationModalContent
          onCancel={closeModal}
          onSubmit={() => {
            handleRemoveCustomerSubmit(customer?.id);
          }}
        />
      ),
    } as IModalProps);
  };

  const dropdownClickHandler = (event: MouseEvent) => {
    const target = event.target as Node | null;
    if (!dropdownRef?.current?.contains(target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('click', dropdownClickHandler);
    } else {
      document.removeEventListener('click', dropdownClickHandler);
    }

    return () => {
      document.removeEventListener('click', dropdownClickHandler);
    };
  }, [isDropdownOpen]);

  const DropdownItems: any[] = [
    {
      name: 'addCustomerLocation',
      text: t('customerDetails.addCustomerLocationBtn'),
      action: addCustomerLocation,
    },
    {
      name: 'editCustomer',
      text: t('customerDetails.editCustomerBtn'),
      action: editCustomer,
    },
    {
      name: 'removeCustomer',
      text: t('customerDetails.removeCustomerBtn'),
      action: removeCustomer,
      color: 'red',
    },
  ];

  const { data: customer } = useSuspenseQuery({
    queryKey: ['partnerCustomer'],
    queryFn: async () => {
      const res = await client.myProAccounts.myProAccountsGetPartnerCustomer(
        partnerAccountId || '',
        customerId || ''
      );
      return res?.data;
    },
  });

  const deletePartnerCustomer = useMutation({
    mutationKey: ['deletePartnerCustomer'],
    mutationFn: async (partnerCustomerId: string) => {
      await client.myProAccounts.myProAccountsDeletePartnerCustomer(
        partnerAccountId as string,
        partnerCustomerId as string
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['partnerCustomer', 'partnerCustomers'],
      });
      addToast({
        toastType: 'Success',
        message: t('customerDetails.removeCustomer.success'),
      });
      closeModal();
      navigate('/mybusiness/customers/account-lookup');
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('customerDetails.removeCustomer.tryAgain'),
        linkHref:
          'mailTo:proIQsupport@resideo.com?subject=Removing a Customer Account',
        linkText: t('customerDetails.removeDevice.contactSupport'),
        target: '_blank',
      });
      closeModal();
    },
  });

  const handleRemoveCustomerSubmit = partnerCustomerId => {
    deletePartnerCustomer.mutate(partnerCustomerId);
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  useEffect(() => {
    if (location?.state?.prevPage) {
      switch (location?.state?.prevPage) {
        case 'alerts':
          setPrevPage('/mybusiness/alerts/dashboard');
          break;
        case 'flagged':
          setPrevPage('/mybusiness/alerts/dashboard/flagged');
          break;
        case 'dismissed':
          setPrevPage('/mybusiness/alerts/dashboard/dismissed');
          break;
        default:
          setPrevPage(undefined);
      }
    }
  }, [setPrevPage, location]);

  return (
    <PageContainer>
      {customer ? (
        <Titled title={t('customers.customer')}>
          {CUSTOMER_DETAILS_REDESIGN ? (
            <Header>
              <HeadingContainer>
                <Box>
                  <Breadcrumbs>
                    {prevPage ? (
                      <>
                        <Link to={prevPage}>
                          {t('customerDetails.customerAlerts')}
                        </Link>
                        <Link to='#'>{`${customer.firstName} ${customer.lastName}`}</Link>
                      </>
                    ) : (
                      <>
                        <Link to='/mybusiness/customers/account-lookup'>
                          {t('customerDetails.customerLookup')}
                        </Link>
                        <Link to='#'>{`${customer.firstName} ${customer.lastName}`}</Link>
                      </>
                    )}
                  </Breadcrumbs>
                  <Heading
                    as='h1'
                    fontSize='30px'
                    paddingTop='10px'
                    data-test-name>
                    {customer?.firstName && customer?.lastName
                      ? `${customer.firstName} ${customer.lastName}`
                      : ''}
                  </Heading>
                  <SubHeader>
                    {customer?.phoneNumber ? (
                      <PhoneEmailLink
                        href={`tel:${customer?.phoneNumber}`}
                        data-test-phone>
                        <PhoneIconStyled />
                        {customer?.phoneNumber}
                      </PhoneEmailLink>
                    ) : null}
                    {customer?.email ? (
                      <PhoneEmailLink
                        href={`mailto:${customer?.email}`}
                        data-test-email>
                        <EmailIconStyled />
                        {customer?.email}
                      </PhoneEmailLink>
                    ) : null}
                  </SubHeader>
                </Box>
                <DropdownContainer ref={dropdownRef}>
                  <Dropdown
                    data-test-options
                    className={isDropdownOpen ? 'active' : ''}
                    onClick={() => {
                      setIsDropdownOpen(!isDropdownOpen);
                    }}>
                    Options &nbsp;&nbsp;
                    <AiFillCaretDown />
                  </Dropdown>

                  {isDropdownOpen && (
                    <DropdownMenuContainer data-test-options-container>
                      {DropdownItems?.map(({ name, action, text, color }) => (
                        <DropdownOption
                          key={name}
                          onClick={action}
                          style={{ color }}>
                          {text}
                        </DropdownOption>
                      ))}
                    </DropdownMenuContainer>
                  )}
                </DropdownContainer>
              </HeadingContainer>
            </Header>
          ) : (
            <HeadingContainerOld>
              <Box>
                <Breadcrumbs>
                  <Link to='/mybusiness/customers/account-lookup'>
                    {t('customerDetails.customerLookup')}
                  </Link>
                </Breadcrumbs>
                <Heading as='h1' fontSize='xLarge' paddingTop='1rem'>
                  {customer?.firstName && customer?.lastName
                    ? `${customer.firstName} ${customer.lastName}`
                    : ''}
                </Heading>
              </Box>
              <RemoveButtonContainer>
                <Button
                  data-test-remove-customer
                  variant={'destructiveSecondary'}
                  width={[1, 'auto']}
                  onClick={() => {
                    openModal({
                      title: t('customerDetails.removeCustomer.title'),
                      children: (
                        <RemoveCustomerConfirmationModalContent
                          onCancel={closeModal}
                          onSubmit={() => {
                            handleRemoveCustomerSubmit(customer?.id);
                          }}
                        />
                      ),
                    } as IModalProps);
                  }}>
                  {t('customerDetails.removeCustomerBtn')}
                </Button>
              </RemoveButtonContainer>
            </HeadingContainerOld>
          )}
          <>
            {CUSTOMER_DETAILS_REDESIGN ? (
              <>
                <AddAddressVerificationModal
                  suggestedAddress={suggestedAddress}
                  originalAddress={locationValues}
                  closeAddressVerificationModal={closeAddressVerificationModal}
                  backButtonClicked={address => {
                    addCustomerLocation(address, true);
                    closeAddressVerificationModal();
                  }}
                  saveButtonClicked={values => {
                    updateCustomerLocation(values);
                    closeAddressVerificationModal();
                  }}
                  translations={{
                    ModalTitle: t('locations.addLocation'),
                    ModalHeader: t('verificationModal.modalHeader'),
                    ModalText: t('verificationModal.modalText'),
                    ModalChooseText: t('verificationModal.modalChooseText'),
                    OriginalAddressLabel: t(
                      'verificationModal.originalAddressLabel'
                    ),
                    SuggestedAddressLabel: t(
                      'verificationModal.suggestedAddressLabel'
                    ),
                    BackBtnLabel: t('common.back'),
                    SaveBtnLabel: t('common.save'),
                  }}
                />
              </>
            ) : (
              <>
                <CustomerAccountInformation customerResponse={customer} />
              </>
            )}
            {isLoaded && (
              <Suspense fallback={<TableLoading />}>
                <CustomerLocations
                  customerId={customerId}
                  customer={customer}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                  isLoaded={isLoaded}
                  loadError={loadError}
                />
              </Suspense>
            )}
          </>
        </Titled>
      ) : (
        <RecordNotFound
          description={t('customerDetails.customerNotFound')}></RecordNotFound>
      )}
    </PageContainer>
  );
};

export default CustomerAccount;
