import { useFeatureFlags } from 'context/FeatureFlags';
import { useEffect, useState } from 'react';
import { useAuth } from 'utils/auth';
import { useCurrentUser } from 'context/CurrentUser';
import config from '../config';
import * as Sentry from '@sentry/react';
import { useAuth0 } from '@auth0/auth0-react';
import { AGREEMENT_CHECK } from 'config/flags';

export const useRedirect = () => {
  const { LEGACY_COMPANY_UPDATE, INVITE_ACCEPT_ASYNC } = useFeatureFlags();
  const [isVerified, setIsVerified] = useState<boolean | null | undefined>(
    null
  );
  const [inviteId, setInviteId] = useState('');
  const [status, setStatus] = useState<string>();
  const [redirect, setRedirect] = useState<null | string>(null);
  const { logout } = useAuth0();
  const { getIdTokenClaims, isAuthenticated } = useAuth();
  const {
    accountStatus,
    isOwnerAdmin,
    partnerUsersLength,
    inviteStatus,
    error,
    viewerExists,
    consentRequired,
  } = useCurrentUser();

  if (!viewerExists) {
    // Logging out user, if the viewer id doesn't exist in Current user Query
    logout({ returnTo: window.location.origin });
  }

  useEffect(() => {
    if (isAuthenticated) {
      const fetchClaims = async () => {
        const claims = await getIdTokenClaims();
        setIsVerified(claims?.email_verified);
        setInviteId(claims ? claims[`${config.claimsNamespace}/inviteId`] : '');
      };
      fetchClaims();
    }
  }, [getIdTokenClaims, setIsVerified, isAuthenticated]);

  useEffect(() => {
    if (!error) {
      return;
    }

    Sentry.addBreadcrumb({
      message: 'CurrentUser Error',
      level: 'debug',
      data: error,
    });
    Sentry.captureMessage(
      'GraphQL API Call Error - CurrentUser (useRedirect)',
      'fatal'
    );
  }, [error]);

  useEffect(() => {
    if (!status) {
      if (accountStatus === undefined) {
        setStatus('UNDEFINED STATUS');
      }

      if (accountStatus === null) {
        setStatus('NULL STATUS');
      }

      if (accountStatus) {
        setStatus(accountStatus);
      }
    }
  }, [accountStatus, setStatus]);

  useEffect(() => {
    // status or isVerified have not been set yet, so don't run the check
    if (!status || isVerified === null) {
      return;
    }

    // No need to redirect for this status
    if (status === 'ACTIVE') {
      return;
    }

    if (INVITE_ACCEPT_ASYNC) {
      // Has an invite ID and no company association, so accept the invite
      if (inviteId && status !== 'NULL STATUS' && partnerUsersLength === 0) {
        setRedirect(`/sign-up/accept-invite?invite=${inviteId}`);
        return;
      }
    }

    if (!isVerified && status !== 'NULL STATUS') {
      // This is here to redirect users creating an account who have not verified their email address
      // Create account is on the home screen and users could use a spam email address
      setRedirect('/email-verify');
    } else if (
      LEGACY_COMPANY_UPDATE &&
      status === 'NULL STATUS' &&
      isOwnerAdmin
    ) {
      setRedirect('/company/application/update');
    } else if (
      status !== 'NULL STATUS' &&
      partnerUsersLength === 0 &&
      !inviteStatus
    ) {
      setRedirect('/company/application');
    } else if (
      status === 'ARCHIVED' ||
      status === 'PENDING' ||
      status === 'INACTIVE' ||
      inviteStatus === 'REJECTED' ||
      inviteStatus === 'REQUESTED'
    ) {
      // We are not redirecting customer, when status is Active or null
      setRedirect('/company/application/status');
    } else {
      setRedirect(null);
    }
  }, [status, isVerified, inviteId]);

  useEffect(() => {
    if (!AGREEMENT_CHECK) return;

    // status or isVerified have not been set yet, so don't run the check
    if (!status || isVerified === null) return;

    // only run this check for active users
    if (status !== 'ACTIVE') return;

    if (consentRequired) {
      setRedirect('/agreement-check');
    }
  }, [status, isVerified, consentRequired, AGREEMENT_CHECK]);

  return redirect;
};

export default useRedirect;
