import React from 'react';
import styled from 'styled-components';
import { REZI_COUNTRIES } from '.././../../../constants/countries';

import { useTranslation } from 'react-i18next';
import { Text, Button, Flex } from '@resideo/blueprint-react';
import {
  ButtonContainer as BorderedButtonContainer,
  ModalContent,
  DeleteButton,
  WarningContainer,
} from '../../customers.style';
import useStateData from '../../../../hooks/useStateData';

const StrongText = styled(Text)`
  font-weight: bold;
`;

const ButtonContainer = styled(BorderedButtonContainer)`
  border: 0;
`;

const RemoveDeviceModal = ({ onCancel, onSubmit, locationData, device }) => {
  const { t } = useTranslation();
  const { stateData } = useStateData();
  const {
    addressLine1,
    addressLine2,
    city,
    zipPostalCode,
    countryCode,
    stateProvinceRegionCode,
  } = locationData?.address;

  return (
    <ModalContent>
      <WarningContainer>
        <Text fontSize='medium'>{t('customerDetails.removeDevice.text')}</Text>
      </WarningContainer>
      <WarningContainer>
        <Flex flexDirection={'column'}>
          <StrongText>{device?.typeName}</StrongText>
          <Text>{device?.deviceId}</Text>
        </Flex>
      </WarningContainer>
      <WarningContainer>
        <Text>{t('customerDetails.removeDevice.text2')}</Text>
      </WarningContainer>
      <Flex flexDirection={'column'}>
        <StrongText>{locationData?.node?.name}</StrongText>
        <Text>{`${addressLine1} ${addressLine2 || ''}`}</Text>
        <Text>{`${city} ${stateData?.find(
          state =>
            state.country_code === countryCode &&
            state.iso2 === stateProvinceRegionCode
        )?.name ?? ''} ${zipPostalCode}`}</Text>
        <Text>{`${REZI_COUNTRIES[countryCode]}`}</Text>
      </Flex>
      <ButtonContainer>
        <DeleteButton variant='primary' onClick={onSubmit}>
          {t('customerDetails.remove')}
        </DeleteButton>
        <Button variant='secondary' onClick={onCancel}>
          {t('common.cancel')}
        </Button>
      </ButtonContainer>
    </ModalContent>
  );
};

export default RemoveDeviceModal;
