import React, { useState } from 'react';
import {
  Button,
  Flex,
  Text,
  useToastContext,
  SelectField,
} from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from 'context/CurrentUser';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';
import { PartnerRole } from '@resideo/web-integration-services-api-client';

const UpdateEmployeeRoleModalContent = ({ closeModal, id, role, name }) => {
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const { isOwner, partnerAccountId } = useCurrentUser();
  const [submitting, setSubmitting] = useState(false);
  const [newRole, setNewRole] = useState(role);
  const { client } = useRisClient();
  const queryClient = useQueryClient();

  const updateEmployeeRole = useMutation({
    mutationKey: ['archiveEmployee'],
    mutationFn: async (id: string) => {
      await client.myProAccounts.myProAccountsUpdatePartnerUser(
        partnerAccountId as string,
        id,
        { roles: [newRole as PartnerRole] }
      );
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
      setSubmitting(false);
      closeModal();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getPartnerUserById'],
      });
      addToast({
        toastType: 'Success',
        message: t('mybusiness.employees.toast.roleUpdated'),
      });
      setSubmitting(false);
      closeModal();
    },
  });

  const updateRole = () => {
    setSubmitting(true);
    updateEmployeeRole.mutate(id);
  };

  return (
    <Flex data-test-modal-container flexDirection='column'>
      <Text marginBottom='small' fontWeight='bold'>
        {t('mybusiness.employees.employeeName')}
      </Text>
      <Text marginBottom='large'>{name}</Text>

      <SelectField
        label={t('mybusiness.employees.role')}
        name='role'
        value={newRole}
        onChange={e => {
          setNewRole(e.target.value);
        }}
        version='v2'
        marginBottom='large'>
        {isOwner && <option value='OWNER'>{t('common.roles.OWNER')}</option>}
        <option value='COMPANY_ADMIN'>{t('common.roles.COMPANY_ADMIN')}</option>
        <option value='TECHNICIAN'>{t('common.roles.TECHNICIAN')}</option>
      </SelectField>

      <Flex justifyContent='flex-end'>
        <Button
          data-test-archive-modal-cancel-button
          onClick={() => closeModal()}
          marginRight='large'
          variant='tertiary'>
          {t('common.cancel')}
        </Button>
        <Button
          data-test-archive-modal-confirm-button
          disabled={submitting}
          onClick={() => updateRole()}
          variant='primary'>
          {t('common.confirm')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default UpdateEmployeeRoleModalContent;
