import React, { FC, useState, useEffect } from 'react';
import { Titled } from 'components/common/Titled';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumbs,
  Button,
  Box,
  Link as BPLink,
  Text,
} from '@resideo/blueprint-react';
import BrandProfileForm from 'components/services/BrandProfile/BrandProfileForm';
import styled from 'styled-components';
import RightPanelHeader from 'components/common/RightPanelHeader';
import {
  Link,
  Navigate,
  Outlet,
  RouteObject,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import Error from 'routes/Error';
import Page from 'components/common/Page';
import BrandProfileView from 'components/services/BrandProfile/BrandProfileView';
import PageLoading from 'components/common/PageLoading';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';
import { ApprovalStatus } from '../../utils/common';

const Header = styled.div`
  margin-left: 2rem;
  padding-top: 5px;
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
    margin-left: 1rem;
  }
`;

const BrandProfileBox = styled(Box)``;

const BrandProfileDiv = styled.div`
  box-shadow: 0 0 1rem 0.1rem #ccc;
  margin-bottom: 2rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
  }
`;

const StyledText = styled(Text)<{ $showHeader: boolean }>`
  margin: 4rem 0;

  margin-left: ${props => (props.$showHeader ? '0' : '30px')};
`;

const HelpText = styled(Text)`
  margin: 1rem 0;
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  font-family: Roboto, serif;
`;

const StyledLink = styled(BPLink)`
  text-decoration: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 0.875rem;
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 100%;
  }
`;

const CancelButtonDesktop = styled(Button)`
  height: fit-content;
  margin-left: auto;
  margin-right: 2%;
  margin-top: 2%;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-top: auto;
    margin-left: 23%;
    margin-bottom: 4%;
  }
`;

type ContextType = { setShowHeader: any; prevPage: any };

const BrandProfileEditRoot = () => {
  const {
    loading,
    setLoading,
    partnerAccount,
    partnerBrandProfile,
  } = useOutletContext<any>();

  if (partnerBrandProfile?.status === ApprovalStatus.ARCHIVED) {
    return <Navigate to='/mybusiness/services/brand-profile/view' replace />;
  }

  if (partnerBrandProfile?.status) {
    return (
      <BrandProfileForm
        partnerAccountId={partnerAccount?.id}
        partnerBrandProfile={partnerBrandProfile}
        setLoading={setLoading}
        loading={loading}
      />
    );
  } else {
    return <Navigate to='/mybusiness/services/brand-profile/new' replace />;
  }
};

const BrandProfileNewRoot = () => {
  const {
    loading,
    setLoading,
    partnerAccount,
    partnerBrandProfile,
  } = useOutletContext<any>();

  if (partnerBrandProfile?.status) {
    return <Navigate to='/mybusiness/services/brand-profile/view' replace />;
  } else {
    return (
      <BrandProfileForm
        partnerAccountId={partnerAccount?.id}
        partnerBrandProfile={partnerBrandProfile}
        setLoading={setLoading}
        loading={loading}
      />
    );
  }
};

const BrandProfileViewRoot = () => {
  const { partnerBrandProfile } = useOutletContext<any>();
  if (partnerBrandProfile?.status) {
    return <BrandProfileView partnerBrandProfile={partnerBrandProfile} />;
  } else {
    return <Navigate to='/mybusiness/services/brand-profile/new' replace />;
  }
};

export interface PrevPageInfo {
  url: string;
  displayName: string;
}

export const ManageBrandProfileLayout: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [prevPage, setPrevPage] = useState<PrevPageInfo | undefined>();

  const isEdit =
    location?.pathname
      .split('/')
      .pop()
      ?.toLocaleLowerCase() === 'edit';

  const isView =
    location?.pathname
      .split('/')
      .pop()
      ?.toLocaleLowerCase() === 'view';
  const { client } = useRisClient();

  const { data } = useSuspenseQuery({
    queryKey: ['viewer'],
    queryFn: async () =>
      (await client.myProUsers.myProUsersGetMyProUser()).data,
  });

  const partnerAccount = data?.partnerUsers?.[0]?.partnerAccount;

  const partnerBrandProfile = partnerAccount?.partnerBrandProfile;

  const renderPageHeader = () => {
    if (isEdit) {
      if (partnerBrandProfile) {
        return t('mybusiness.services.coBranding.editTitle');
      } else {
        return t('mybusiness.services.coBranding.createTitle');
      }
    }
    return t('mybusiness.services.brandProfile.header.title');
  };

  const handleCancel = () => {
    return navigate('/mybusiness/services/brand-profile/view');
  };

  useEffect(() => {
    if (location?.state?.prevPage) {
      switch (location?.state?.prevPage) {
        case 'business-intelligence':
          setPrevPage({
            url: '/mybusiness/business-intelligence',
            displayName: t(
              'mybusiness.services.brandProfile.header.businessIntelligence'
            ),
          });
          break;
        case 'complete-service-setup':
          setPrevPage({
            url: '/mybusiness/services/setup',
            displayName: t(
              'mybusiness.services.brandProfile.header.completeServiceSetup'
            ),
          });
          break;
        default:
          setPrevPage(undefined);
      }
    }
  }, [setPrevPage, location, t]);

  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    if (
      window.location.pathname === '/mybusiness/services/brand-profile/view'
    ) {
      setShowHeader(true);
    }
  }, [showHeader, window.location.pathname]);

  return (
    <Titled
      title={
        isEdit
          ? t('mybusiness.services.coBranding.editTitle')
          : t('mybusiness.services.coBranding.title')
      }>
      <BrandProfileBox>
        {showHeader && (
          <BrandProfileDiv>
            <>
              <Header>
                <Breadcrumbs>
                  <>
                    {prevPage ? (
                      <Link to={prevPage.url}>{prevPage.displayName}</Link>
                    ) : (
                      <Link
                        data-test-breadcrumbs-business-intelligence
                        to='/mybusiness/business-intelligence'>
                        {t(
                          'mybusiness.services.brandProfile.header.businessIntelligence'
                        )}
                      </Link>
                    )}
                    <Link data-test-view-brand-profile-breadcrumb to='#'>
                      {t(
                        'mybusiness.services.brandProfile.header.viewBrandProfile'
                      )}
                    </Link>
                  </>
                </Breadcrumbs>
              </Header>
              <RightPanelHeader
                data-test-brand-profile-title
                subtitle={
                  <Text style={{ marginTop: '-10px' }}>
                    {isView
                      ? t(
                          'mybusiness.services.brandProfile.header.subtitleView'
                        )
                      : t(
                          'mybusiness.services.brandProfile.header.subtitleEdit'
                        )}
                  </Text>
                }
                cta={
                  isView ? (
                    <ButtonContainer>
                      <Button
                        data-test-brand-profile-edit-btn
                        variant='secondary'
                        type='button'
                        disabled={partnerBrandProfile?.status !== 'ACTIVE'}
                        onClick={() => {
                          navigate('/mybusiness/services/brand-profile/edit');
                        }}>
                        {t('common.edit')}
                      </Button>
                      <Button
                        variant='primary'
                        type='button'
                        onClick={() => {
                          navigate(
                            prevPage
                              ? prevPage.url
                              : '/mybusiness/business-intelligence'
                          );
                        }}>
                        {prevPage?.url === '/mybusiness/services/setup'
                          ? t('common.done')
                          : t(
                              'mybusiness.services.brandProfile.header.businessIntelligence'
                            )}
                      </Button>
                    </ButtonContainer>
                  ) : (
                    <CancelButtonDesktop
                      id='CancelButton'
                      data-test-brand-profile-cancel-btn
                      type='button'
                      variant={'secondary'}
                      onClick={() => {
                        handleCancel();
                      }}>
                      {t('common.cancel')}
                    </CancelButtonDesktop>
                  )
                }>
                <>
                  <div>{renderPageHeader()}</div>
                </>
              </RightPanelHeader>
            </>
          </BrandProfileDiv>
        )}
        {loading ? (
          <PageLoading />
        ) : (
          <Page
            as='main'
            paddingY='none'
            paddingX={showHeader ? ['large', 'small'] : 'none'}
            marginLeft={showHeader ? [0, 'large'] : 'none'}
            marginRight={showHeader ? ['small', 0] : 'none'}
            maxWidth={showHeader ? '65rem' : 'unset'}>
            <Outlet
              context={{
                loading,
                setLoading,
                setShowHeader,
                prevPage,
                partnerAccount,
                partnerBrandProfile,
              }}
            />
            <StyledText $showHeader={showHeader}>
              <HelpText>
                {t('mybusiness.services.coBranding.customerSupportHelp')}
              </HelpText>
              {t('mybusiness.services.coBranding.customerSupportFooter')}{' '}
              <StyledLink href='mailto:proIQsupport@resideo.com?subject=Brand Profile Support'>
                proIQsupport@resideo.com
              </StyledLink>
              {'.'}
            </StyledText>
          </Page>
        )}
      </BrandProfileBox>
    </Titled>
  );
};

export function useBrandProfileContext() {
  return useOutletContext<ContextType>();
}

const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to='/mybusiness/services/brand-profile/view' replace />,
  },
  {
    path: 'edit',
    element: <BrandProfileEditRoot />,
  },
  {
    path: 'new',
    element: <BrandProfileNewRoot />,
  },
  {
    path: 'view',
    element: <BrandProfileViewRoot />,
  },
  {
    path: '*',
    element: <Error />,
  },
];

export default routes;
