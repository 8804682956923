import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useServicesLinks } from '../../hooks/useServicesLinks';
import {
  Box,
  Button,
  Card,
  Heading,
  Flex,
  Text,
} from '@resideo/blueprint-react';
import CloseIcon from '../icons/circle-close.svg?react';
import styled from 'styled-components';
import PageLoading from './PageLoading';

const PageContainer = styled(Box)`
  padding-top: 87px;
  padding-left: 160px;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding-left: 0;
  }
`;

const HeadingContent = styled(Flex)`
  height: 100%;
`;

const HeadingText = styled(Heading)`
  padding-left: 15px;
  font: normal normal bold 34px/34px Roboto;
  letter-spacing: 0.38px;
`;

const PageText = styled(Text)`
  font-size: 18px;
  line-height: normal;
  padding-top: 18px;
  padding-bottom: 20px;
  width: 75%;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 100%;
  }
`;

const PageUnavailable: FC = (): JSX.Element => {
  const [isLoader, setIsLoader] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { servicesPath } = useServicesLinks();

  // Displaying the loader when Go to Services & Subscription button is clicked
  if (isLoader) {
    return <PageLoading />;
  }

  return (
    <PageContainer data-test-page-un-available>
      <Card borderRadius={[0, 'medium']} padding='large'>
        <HeadingContent>
          <CloseIcon data-test-close-icon />
          <HeadingText>
            {t('mybusiness.services.brandProfile.pageUnavailable')}
          </HeadingText>
        </HeadingContent>
        <PageText data-test-page-not-available-info>
          {t('mybusiness.services.brandProfile.NoAccess')}
        </PageText>
        <Flex>
          <Button
            data-test-page-not-available-gohome-button
            variant={'secondary'}
            onClick={() => {
              navigate('/mybusiness/home');
            }}>
            {t('mybusiness.services.brandProfile.homeLabel')}
          </Button>
          <Button
            data-test-page-not-available-goservices-button
            variant='primary'
            marginLeft='medium'
            onClick={() => {
              setIsLoader(true);
              window.location.href = servicesPath;
            }}>
            {t('mybusiness.services.brandProfile.serviceLabel')}
          </Button>
        </Flex>
      </Card>
    </PageContainer>
  );
};

export default PageUnavailable;
