import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  SearchField,
  TableV2,
  Text,
  useToastContext,
} from '@resideo/blueprint-react';
import { useCurrentUser } from 'context/CurrentUser';
import { formatDateTime } from 'utils/common';
import { useFeatureFlags } from '../../../context/FeatureFlags';
import styled from 'styled-components';
import { IModalProps, useModalContext } from 'context/ModalProvider';
import { useRisClient } from 'hooks/useRisClient';
import {
  useSuspenseQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

const ModalText = styled(Text)`
  line-height: 1.5;
  span {
    font-weight: bold;
  }
`;

const SearchContainer = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.space.medium};
  justify-content: end;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 0 1rem;
  }
`;

const isExpired = expirationTime => {
  const msPerDay = 24 * 60 * 60 * 1000; // ms of day
  const expDate = new Date(expirationTime);
  const today = new Date();
  const daysLeft = (expDate.getTime() - today.getTime()) / msPerDay;
  return daysLeft <= 0;
};

const InvitedEmployeesList = (): JSX.Element => {
  const { t } = useTranslation();
  const { partnerAccountId, locale } = useCurrentUser();
  const { openModal, closeModal } = useModalContext();
  const { addToast } = useToastContext();
  const [invites, setInvites] = useState<any[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<any>({ createdAt: 'DESC' });
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const { TABLE_RESULTS_PER_PAGE } = useFeatureFlags();
  const rowsPerPage = TABLE_RESULTS_PER_PAGE;
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const [pageInfo, setPageInfo] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    first: rowsPerPage,
    last: undefined,
    before: undefined,
    after: undefined,
  });

  const getOrderBy = (sortColumn: string, sortDirection: string) => {
    return {
      [sortColumn]: sortDirection,
    };
  };

  const goToPageZero = () => {
    setPagination({
      first: rowsPerPage,
      last: undefined,
      before: undefined,
      after: undefined,
    });
  };

  const { data: RisData, error } = useSuspenseQuery({
    queryKey: [
      'getInvitedEmployees',
      {
        orderBy,
        pagination,
        filter,
      },
    ],
    queryFn: async () => {
      const res = await client.myProAccounts.myProAccountsSearchInvitedEmployees(
        partnerAccountId as string,
        JSON.stringify([
          {
            sendToEmail: filter,
            status: 'PENDING',
          },
        ]),
        orderBy
          ? JSON.stringify(orderBy)
          : JSON.stringify({
              sendToEmail: 'ASC',
            }),
        pagination.first,
        pagination.last,
        pagination.before,
        pagination.after
      );
      return res;
    },
  });

  const cancelInviteMutation = useMutation({
    mutationKey: ['cancelInvite'],
    mutationFn: async (id: string) => {
      await client.myProAccounts.myProAccountsCancelEmployeeInvite(
        partnerAccountId as string,
        id
      );
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('company.invite.show.toast.error'),
      });
      setSubmitting(false);
      closeModal();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getInvitedEmployees'],
      });

      addToast({
        toastType: 'Success',
        message: t('company.invite.show.toast.inviteDeleted'),
      });
      goToPageZero();
      setSubmitting(false);
      closeModal();
    },
  });

  const resendInviteMutation = useMutation({
    mutationKey: ['resendInvite'],
    mutationFn: async (variables: { id: string; expiresAt: Date }) => {
      const res = await client.myProAccounts.myProAccountsSendEmployeeInvite(
        variables?.id,
        partnerAccountId as string,
        {
          baseUrl: `${window.location.origin}/sign-up`,
          expiresAt: variables?.expiresAt.toISOString(),
          templateLanguage: locale || 'en',
        }
      );
      return res;
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('company.user.show.toast.error'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getInvitedEmployees'],
      });
      addToast({
        toastType: 'Success',
        message: t('common.toast.inviteSent'),
      });
    },
  });

  useEffect(() => {
    const partnerUserInvites =
      (RisData as any).data
        ?.filter(partnerUser => partnerUser?.invite)
        .map(partnerUser => {
          let invite: any = {
            ...partnerUser?.invite,
          };
          if (partnerUser?.roles) {
            invite = {
              ...invite,
              role: partnerUser?.roles[0],
            };
          }
          return invite;
        }) || [];

    setInvites(partnerUserInvites);

    if ((RisData as any)?.pageInfo) {
      setPageInfo((RisData as any)?.pageInfo);
    }
  }, [RisData]);

  const handleResendInvite = (id: string) => {
    const expiresAt = new Date();
    expiresAt.setDate(expiresAt.getDate() + 7);

    resendInviteMutation.mutate({ id, expiresAt });
  };

  const openCancelInviteModal = (id: string, sendToEmail: string) => {
    openModal({
      title: t('mybusiness.employees.invited.cancelInvite'),
      testAttribute: {
        'data-test-invite-cancel-modal': true,
      },
      children: (
        <Flex flexDirection='column'>
          <ModalText marginBottom='medium'>
            {t('mybusiness.employees.invited.cancelInveteModalText')}
            <span>{sendToEmail}</span>
            {t('mybusiness.employees.invited.cancelInveteModalText2')}
          </ModalText>
          <Flex justifyContent='flex-end'>
            <Button
              variant='secondary'
              onClick={() => closeModal()}
              marginRight='large'
              paddingY='0.75em'>
              {t('common.goBack')}
            </Button>
            <Button
              data-test-invite-cancel-invite-button
              variant='destructivePrimary'
              disabled={submitting}
              onClick={() => {
                handleCancelInvite(id);
              }}>
              {t('mybusiness.employees.invited.cancelInvite')}
            </Button>
          </Flex>
        </Flex>
      ),
    } as IModalProps);
  };

  const getRows = () => {
    return invites.map(invite => {
      const { id, sendToEmail, sentAt, expiresAt, role } = invite;
      const expired = isExpired(expiresAt);
      const row = {
        id,
        email: sendToEmail || '',
        role: t(`common.roles.${role}`),
        sentAt: sentAt
          ? formatDateTime(sentAt, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          : t('common.unknown'),
        expiresAt: expired
          ? t(`mybusiness.employees.invited.expired`)
          : t(`mybusiness.employees.invited.sent`),
        actions: [
          {
            name: t(
              `mybusiness.employees.invited.${
                expired ? 'resendInvite' : 'cancelInvite'
              }`
            ),
            ctaTag: 'restore',
            disabled: false,
            text: t(
              `mybusiness.employees.invited.${
                expired ? 'resendInvite' : 'cancelInvite'
              }`
            ),
            action: () => {
              expired
                ? handleResendInvite(id || '')
                : openCancelInviteModal(id, sendToEmail);
            },
          },
        ],
      };

      row.expiresAt = expiresAt
        ? formatDateTime(expiresAt, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        : t('common.unknown');
      row.actions = [
        {
          name: t(`mybusiness.employees.invited.cancel`),
          ctaTag: 'restore',
          text: t(`mybusiness.employees.invited.cancel`),
          disabled: expired,
          action: () => {
            openCancelInviteModal(id, sendToEmail);
          },
        },
        {
          name: t(`mybusiness.employees.invited.resend`),
          ctaTag: 'restore',
          text: t(`mybusiness.employees.invited.resend`),
          disabled: false,
          action: () => {
            handleResendInvite(id || '');
          },
        },
      ];

      return row;
    });
  };

  const handleCancelInvite = async id => {
    setSubmitting(true);
    cancelInviteMutation.mutate(id);
  };

  return (
    <>
      <SearchContainer>
        <SearchField
          type='text'
          name='search'
          id='search'
          value={search}
          onChange={e => {
            e.preventDefault();
            setSearch(e.target.value);
          }}
          placeholder={t('common.search')}
          onSearchClick={() => {
            setFilter(search);
            goToPageZero();
          }}
          onClearClick={() => {
            setSearch('');
            setFilter('');
            goToPageZero();
          }}
        />
      </SearchContainer>
      <TableV2
        headers={[
          {
            displayName: 'id',
            isSortable: false,
            isHiddenColumn: true,
          },
          {
            displayName: t('common.emailShort'),
            isSortable: true,
            useEllipses: true,
            onSortColumnName: 'sendToEmail',
          },
          {
            displayName: t('mybusiness.employees.invited.role'),
            isSortable: false,
            useEllipses: true,
            onSortColumnName: 'role',
            isHiddenColumn: false,
          },
          {
            displayName: t('mybusiness.employees.invited.sent'),
            isSortable: false,
            onSortColumnName: 'sentAt',
            notBlueprintEllipses: true,
          },
          {
            displayName: t('mybusiness.employees.invited.dateExpires'),
            isSortable: false,
            notBlueprintEllipses: true,
          },
          {
            displayName: '',
            isSortable: false,
          },
        ]}
        isError={
          error
            ? {
                errorHeader: t('common.tableErrors.header'),
                errorMessage: t('common.tableErrors.text'),
              }
            : undefined
        }
        rows={getRows()}
        onSort={(sortColumn, sortDirection) => {
          if (sortColumn && sortDirection) {
            setOrderBy(getOrderBy(sortColumn, sortDirection));
            goToPageZero();
          }
        }}
        hasPagination
        hasNext={pageInfo?.hasNextPage}
        hasPrevious={pageInfo?.hasPreviousPage}
        nextButtonLabel={t('common.next')}
        previousButtonLabel={t('common.previous')}
        initialSortColumn={'createdAt'}
        initialSortDirection={'DESC'}
        onPaginationNextClick={() => {
          setPagination({
            first: rowsPerPage,
            last: undefined,
            before: undefined,
            after: pageInfo?.endCursor,
          });
        }}
        onPaginationPrevClick={() => {
          setPagination({
            first: undefined,
            last: rowsPerPage,
            before: pageInfo?.startCursor,
            after: undefined,
          });
        }}
        NoDataMessage={t('mybusiness.employees.invited.noInvitedEmployees')}
      />
    </>
  );
};

export default InvitedEmployeesList;
