const ThermostatProducts = t => [
  {
    text: t('hvacAndWater.thermostats.th3110D'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH3110D - TH3210D - Honeywell Home.zip?sp=rl&st=2021-03-04T21:37:53Z&se=2156-10-12T21:37:00Z&sv=2020-02-10&sr=b&sig=YmH5pITWwir%2BrKSguuqDNji58QbgwDk%2Flg2hgvnodj0%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.descriptions.pro300'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th4110U2005'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH4110U2005 - Honeywell Home.zip?sp=rl&st=2021-03-04T21:38:41Z&se=2213-06-05T21:38:00Z&sv=2020-02-10&sr=b&sig=s%2FepeXFUNd97ArDQpFeWxJc21loz5Wnx%2BgNPQOHya%2Bw%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.descriptions.t4'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th4210U2002'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH4210U2002 - Honeywell Home.zip?sp=rl&st=2021-03-04T22:26:24Z&se=2130-06-05T22:26:00Z&sv=2020-02-10&sr=b&sig=9CXQGEhmA5Er0cGQR8IKkKrHAktum%2FtFkW9Kyl6OtHc%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.descriptions.t4'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th6110D'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH6110D - Honeywell Home.zip?sp=rl&st=2021-03-04T21:40:00Z&se=2242-02-05T21:40:00Z&sv=2020-02-10&sr=b&sig=RlP18r%2B%2F650jBL9v3HSoS3mamljcjI0GSx4MYQNzeUY%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.descriptions.focus'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th6210U2001'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH6210U2001 - Honeywell Home.zip?sp=rl&st=2021-03-04T21:40:31Z&se=2164-06-05T21:40:00Z&sv=2020-02-10&sr=b&sig=ZGddop5CRAuPkblZGavmayf6Cu9KyCTcKk9xSEQb4d0%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.descriptions.t6'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th6220D'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH6220D - Honeywell Home.zip?sp=rl&st=2021-03-04T21:40:46Z&se=2136-10-05T21:40:00Z&sv=2020-02-10&sr=b&sig=HOTYRjD6KIXbk755mr8jvCIUrqM5Rwy7X2%2FV0CFbdjU%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.descriptions.focus'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th6220U2000'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH6220U2000 - Honeywell Home.zip?sp=rl&st=2021-03-04T21:43:11Z&se=2127-07-05T21:43:00Z&sv=2020-02-10&sr=b&sig=HIzlxVhvQLYeVVJj2FU7qKjEn8RPqeJ4gWkibsb%2Ft%2F4%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.descriptions.t6'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th6220WF2006'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH6220WF2006 - Honeywell Home.zip?sp=rl&st=2021-03-04T22:03:15Z&se=2180-06-29T22:03:00Z&sv=2020-02-10&sr=b&sig=wDAKnlofBIS3%2Bvu0Oybob3GkLSct%2Br1kR7XhDBUBpF8%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.descriptions.t6Smart'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th6320R1004.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH6320R1004 - Honeywell Home.zip?sp=rl&st=2021-03-04T21:44:13Z&se=2170-10-05T21:44:00Z&sv=2020-02-10&sr=b&sig=Yr0e9JbL9I%2BFyEEVbl8PnE4jjqfW5BwOYciF6p8rpmk%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.th6320R1004.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th6320U2008'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH6320U2008 - Honeywell Home.zip?sp=rl&st=2021-03-04T21:44:31Z&se=2163-06-05T21:44:00Z&sv=2020-02-10&sr=b&sig=Quwx2KLWLnQXS0k%2FNkQRqZqc05u57JvFjbKmzVzC094%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.descriptions.t6'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th6320WF2003'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH6320WF2003 - Honeywell Home.zip?sp=rl&st=2021-03-04T22:08:41Z&se=2133-10-05T22:08:00Z&sv=2020-02-10&sr=b&sig=AyfYKEo5RqUmVB3h7ZVp8%2BwMMkcT1VJlQ6AhjD%2FeK8c%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.descriptions.t6Smart'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th6320ZW2003.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH6320ZW2003 - Honeywell Home.zip?sp=rl&st=2021-03-04T22:01:30Z&se=2115-06-11T22:01:00Z&sv=2020-02-10&sr=b&sig=duekpUQ8bthNGn7XAdzz8PBD0dn%2B%2BNnvf3A28eLtePI%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.th6320ZW2003.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th8110R1008'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH8110R1008 - Honeywell Home%20!!!.zip?sp=r&st=2021-10-14T15:36:50Z&se=2221-10-14T23:36:50Z&spr=https&sv=2020-08-04&sr=b&sig=WWQ4jr%2Fn2Zti%2FxVF62IEZ4HX0NKjx84c4xr7Q5pX6Ig%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.descriptions.vision'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th8'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH8320R - TH8321R - Honeywell Home.zip?sp=rl&st=2021-03-04T22:16:51Z&se=2131-06-05T22:16:00Z&sv=2020-02-10&sr=b&sig=MDGtGHl6QH4eUe6rKkq6OyXiwgtQWwU5QwMfUO5DLuw%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.descriptions.vision'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th8321WF1001.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH8321WF1001 - Honeywell Home.zip?sp=rl&st=2021-03-04T22:15:54Z&se=2209-06-05T22:15:00Z&sv=2020-02-10&sr=b&sig=3Ll%2F22pIwNkBc%2BvZzRVgad6CkUB9Han7e5BKMR%2Bbbfk%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.th8321WF1001.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th8732WFH5002.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH8732WFH5002 - Honeywell Home.zip?sp=rl&st=2021-03-04T22:15:39Z&se=2167-07-15T22:15:00Z&sv=2020-02-10&sr=b&sig=ZudqDqcGlZIh600VsufilXILqeosNWybfdrdnuKt5hc%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.th8732WFH5002.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.th9320WF5003.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TH9320WF5003 - Honeywell Home.zip?sp=r&st=2021-10-14T15:38:49Z&se=2221-10-14T23:38:49Z&spr=https&sv=2020-08-04&sr=b&sig=BNo1eWxjY0P1FPpsRewhecrz9w6DL9fmXkhELUKJOmw%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.th9320WF5003.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.thX9421R5021WW.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/THX9421R5021WW - Honeywell Home.zip?sp=rl&st=2021-03-04T22:14:58Z&se=2158-07-05T22:14:00Z&sv=2020-02-10&sr=b&sig=h%2FzucNKbsLMndflattgVGU0oZPE3IcHT%2B3iJVvat3h8%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.thX9421R5021WW.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.ytH6320R1001.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/YTH6320R1001 - Honeywell Home.zip?sp=r&st=2021-10-14T15:47:47Z&se=2221-10-14T23:47:47Z&spr=https&sv=2020-08-04&sr=b&sig=kT2CCFallae6C36JM%2FVNNsGtIr63Krgc4OYNjHoQ7Gg%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.ytH6320R1001.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.ytHX9421R'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/YTHX9421R - Honeywell Home.zip?sp=rl&st=2021-03-04T22:13:17Z&se=2187-06-06T22:13:00Z&sv=2020-02-10&sr=b&sig=vznYDfcrnIRVCfHIi07CcX%2BxZxGbAoJtAv3YSpycDk0%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.descriptions.prestige'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.thermostats.THX.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/THX321WFS2001W - Honeywell Home.zip?sp=r&st=2021-10-14T15:40:16Z&se=2221-10-14T23:40:16Z&spr=https&sv=2020-08-04&sr=b&sig=9KXuEes9mqXIos0rIK3LLtNrb3hfgJeFOm1TgrBMsxc%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermostats.THX.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
];

export default ThermostatProducts;
