import React from 'react';
import AlertFilterButton from './AlertFilterButton';
import { DeviceAlertSeverity } from '../types';
import styled from 'styled-components';

const FilterButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px;
`;

export type Filter = {
  urgent: boolean;
  warning: boolean;
  notice: boolean;
};

const SeverityFilter = ({
  messagesCount,
  filter,
  onChange,
}: {
  messagesCount: { urgent: number; warning: number; notice: number };
  filter: Filter;
  onChange: (filter: Filter) => void;
}) => {
  const updateFilter = severity => {
    onChange({ ...filter, [severity]: !filter[severity] });
  };
  return (
    <FilterButtonsWrapper>
      <AlertFilterButton
        severity={DeviceAlertSeverity.URGENT}
        active={filter.urgent}
        messagesCount={messagesCount.urgent}
        onChange={() => {
          updateFilter('urgent');
        }}
      />
      <AlertFilterButton
        severity={DeviceAlertSeverity.WARNING}
        active={filter.warning}
        messagesCount={messagesCount.warning}
        onChange={() => {
          updateFilter('warning');
        }}
      />
      <AlertFilterButton
        severity={DeviceAlertSeverity.NOTICE}
        active={filter.notice}
        messagesCount={messagesCount.notice}
        onChange={() => {
          updateFilter('notice');
        }}
      />
    </FilterButtonsWrapper>
  );
};

export default SeverityFilter;
