import React from 'react';
import styled from 'styled-components';
import { AlertListModel } from '@resideo/web-integration-services-api-client';
import { formatDateTime } from 'utils/common';

const StyledDateCell = styled.div`
  font-size: 16px;
  color: #303030;
`;

const DateCell = ({ row }: { row: AlertListModel }) => {
  const { createdAt } = row;

  return (
    <StyledDateCell>
      {createdAt &&
        formatDateTime(createdAt, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}
    </StyledDateCell>
  );
};

export default DateCell;
