import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import Error from 'routes/Error';

const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to='/mybusiness/business-intelligence' replace />,
  },
  {
    path: 'dashboard',
    element: <Navigate to='/mybusiness/business-intelligence' replace />,
  },
  {
    path: 'completeServiceSetup',
    element: <Navigate to='/mybusiness/services/setup' replace />,
  },
  {
    path: 'logo',
    element: <Navigate to='/mybusiness/services/brand-profile/view' replace />,
  },
  {
    path: 'faqs',
    element: <Navigate to='/mybusiness/training' replace />,
  },
  {
    path: '*',
    element: <Error />,
  },
];

export default routes;
