import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button } from '@resideo/blueprint-react';
import { ButtonContainer, ModalContent } from '../../../customers.style';
import styled from 'styled-components';

const StrongText = styled(Text)`
  font-weight: bold;
`;

export const DeactivateServiceModal = ({
  onCancel,
  onSubmit,
  serviceName,
  location,
}) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <Text data-test-deactivate-service-text1 marginBottom='large'>
        {t('customerDetails.deactivateService.text')}
      </Text>
      <StrongText data-test-deactivate-service-servicename marginBottom='small'>
        {serviceName}
      </StrongText>
      <Text data-test-deactivate-service-text2 marginBottom='large'>
        {t('customerDetails.deactivateService.text2')}
      </Text>
      <StrongText data-test-deactivate-service-location marginBottom='small'>
        {location?.locationName}
      </StrongText>
      <Text>
        {location?.addressLine1} {location?.addressLine2}
      </Text>
      <Text>
        {location?.city} {location?.state} {location?.zipPostalCode}
      </Text>
      <Text marginBottom='large'>{location?.countryCode}</Text>
      <ButtonContainer>
        <Button
          data-test-deactivate-service-cancel-btn
          variant='secondary'
          onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button
          data-test-deactivate-service-deactivate-btn
          variant='destructivePrimary'
          onClick={onSubmit}>
          {t('customerDetails.deactivateService.deactivate')}
        </Button>
      </ButtonContainer>
    </ModalContent>
  );
};
