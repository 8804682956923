import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Text,
  Link as BPLink,
  TableV2,
  Option,
} from '@resideo/blueprint-react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { formatDateTime } from 'utils/common';
import { useIsMounted } from 'hooks/useIsMounted';
import { useFeatureFlags } from '../../context/FeatureFlags';
import useStateData from 'hooks/useStateData';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';

const StyledTooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
    box-shadow: 0px 2px 10px #0000004d;
  }
`;

const TooltipLocation = styled(Box)<{ locations: number }>`
  padding-top: ${props =>
    props.locations === 0
      ? props.theme.space.xSmall
      : props.theme.space.medium};
`;

interface PropsPartnerCustomers {
  partnerAccountId: string;
  filter: string | undefined;
  filterBy: Option | undefined;
}

const CustomersList = ({
  partnerAccountId,
  filter,
  filterBy,
}: PropsPartnerCustomers) => {
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState<any>({ updatedAt: 'DESC' });
  const isMounted = useIsMounted();
  const { TABLE_RESULTS_PER_PAGE } = useFeatureFlags();
  const rowsPerPage = TABLE_RESULTS_PER_PAGE;
  const { stateData } = useStateData();
  const { client } = useRisClient();

  const { data, error } = useSuspenseQuery({
    queryKey: ['partnerCustomers', { filter, sortBy }],
    queryFn: async () => {
      let customerFilter = '';

      if (filterBy?.value === 'MAC/Device ID') {
        customerFilter = JSON.stringify([{ 'device.deviceId': filter || '' }]);
      } else if (filterBy?.value === 'Email') {
        customerFilter = JSON.stringify([{ email: filter || '' }]);
      } else if (filterBy?.value === 'Location') {
        const stateAbbreviation =
          stateData?.find(state => state.name === filter)?.iso2 ?? null;

        customerFilter = JSON.stringify([
          { 'address.addressLine1': filter },
          { 'address.city': filter },
          { 'address.stateProvinceRegionCode': stateAbbreviation || filter },
          { 'address.zipPostalCode': filter },
        ]);
      } else {
        customerFilter = JSON.stringify([
          {
            lastName: filter,
          },
          {
            firstName: filter,
          },
        ]);
      }

      const result = await client.myProAccounts.myProAccountsSearchPartnerCustomers(
        partnerAccountId,
        customerFilter,
        JSON.stringify(sortBy)
      );
      return result?.data;
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    setPage(0);
  }, [sortBy, filter, filterBy]);

  const getLocationCell = (id, deviceLocations) => {
    const locations = deviceLocations?.map(loc => {
      const addressField = loc?.address;
      if (addressField) {
        const {
          addressLine1,
          addressLine2,
          city,
          stateProvinceRegionCode,
          zipPostalCode,
          countryCode,
        } = addressField;
        const stateName =
          stateData?.find(
            state =>
              state.country_code === countryCode &&
              state.iso2 === stateProvinceRegionCode
          )?.name ?? '';
        return (
          <>
            <Text fontSize='medium'>
              {addressLine1} {addressLine2}
            </Text>
            <Text fontSize='medium'>{`${city} ${stateName} ${zipPostalCode}`}</Text>
          </>
        );
      }
    });

    return (
      <>
        {/* Display first location */}
        {locations && locations[0]}
        {/* More than one location, show tooltip */}
        {locations && locations.length > 1 && (
          <>
            <BPLink
              data-tip
              data-for={'locations-' + id}>{`+${locations.length -
              1} More`}</BPLink>
            <StyledTooltip
              type='light'
              id={'locations-' + id}
              place='bottom'
              effect='solid'
              delayHide={500}
              delayUpdate={500}>
              {locations.slice(1).map((loc, i) => (
                <TooltipLocation locations={i} key={i}>
                  {loc}
                </TooltipLocation>
              ))}
            </StyledTooltip>
          </>
        )}
      </>
    );
  };

  const getDeviceCell = (id, deviceLocations) => {
    // Get all devices in all locations
    const deviceList = [] as { loc: any; id: string }[];
    deviceLocations?.forEach(loc => {
      loc.devices?.forEach(device => {
        const deviceId = device.deviceId;
        // Add device ID, and if it matches the search term move it to the top
        if (deviceId === filter) {
          deviceList.unshift({ loc: loc, id: deviceId });
        } else if (deviceId) {
          deviceList.push({ loc: loc, id: deviceId });
        }
      });
    });

    return (
      <>
        {/* Display first three device IDs */}
        {deviceList.slice(0, 3).map((device, i) => {
          return <Text key={i}>{device.id}</Text>;
        })}
        {/* More than three devices, show tooltip */}
        {deviceList.length > 3 && (
          <>
            <BPLink data-tip data-for={'devices-' + id}>{`+${deviceList.length -
              3} More`}</BPLink>
            <StyledTooltip
              type='light'
              id={'devices-' + id}
              place='bottom'
              effect='solid'
              delayHide={500}
              delayUpdate={500}>
              {deviceList.slice(3).map((device, i, devices) => (
                <div key={i}>
                  {/* Show address header? */}
                  {i === 0 || device?.loc?.id !== devices[i - 1]?.loc?.id ? (
                    <>
                      <Text
                        fontSize='medium'
                        paddingTop={i === 0 ? 'xSmall' : 'medium'}
                        fontWeight='bold'>
                        {device?.loc?.address?.addressLine1}{' '}
                        {device?.loc?.address?.addressLine2}
                      </Text>
                    </>
                  ) : null}
                  <Text fontSize='medium'>{device.id}</Text>
                </div>
              ))}
            </StyledTooltip>
          </>
        )}
      </>
    );
  };

  const partnerCustomersCount = data?.length || 0;

  const pageInfo = {
    hasNextPage: page * rowsPerPage + rowsPerPage < partnerCustomersCount,
    hasPreviousPage: page !== 0,
  };

  const linkRenderer = ({ href, children, className }) => {
    return (
      <Link to={href} className={className}>
        {children}
      </Link>
    );
  };

  const getRows = () => {
    return (
      data
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(node => {
          return {
            id: node?.id,
            name: `${node?.lastName}, ${node?.firstName}`,
            email: node?.email,
            location: getLocationCell(node?.id, node?.deviceLocations),
            devices: getDeviceCell(node?.id, node?.deviceLocations),
            updatedAt: node?.updatedAt
              ? formatDateTime(node?.updatedAt, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })
              : '',
            actions: [
              {
                name: 'View',
                ctaTag: 'view',
                text: t('common.view'),
                href: `/mybusiness/customers/account-lookup/${node?.id}`,
                linkRenderer,
              },
            ],
          };
        }) || []
    );
  };

  return (
    <TableV2
      dataTestAttribute='data-test-customers-table'
      headers={[
        {
          displayName: 'id',
          isSortable: false,
          isHiddenColumn: true,
        },
        {
          displayName: t('mybusiness.employees.name'),
          isSortable: true,
          useEllipses: true,
          onSortColumnName: 'lastName',
          onColumnCellClick: row => {
            navigate(`/mybusiness/customers/account-lookup/${row.id}`);
          },
        },
        {
          displayName: t('common.emailShort'),
          isSortable: true,
          useEllipses: true,
          onSortColumnName: 'email',
        },
        {
          displayName: t('common.location'),
          isSortable: false,
          notBlueprintEllipses: true,
        },
        {
          displayName: t('customers.deviceId'),
          isSortable: false,
        },
        {
          displayName: t('customers.updatedAt'),
          isSortable: true,
          onSortColumnName: 'updatedAt',
        },
        { displayName: '', isSortable: false },
      ]}
      isError={
        error
          ? {
              errorHeader: t('common.tableErrors.header'),
              errorMessage: t('common.tableErrors.text'),
            }
          : undefined
      }
      rows={getRows()}
      onSort={(sortColumn, sortDirection) => {
        if (sortColumn && sortDirection && isMounted()) {
          setPage(0);
          setSortBy({
            [sortColumn]: sortDirection,
          });
        }
      }}
      NoDataMessage={t('customers.noCustomersFound')}
      initialSortColumn={'updatedAt'}
      initialSortDirection={'DESC'}
      hasPagination
      hasNext={pageInfo?.hasNextPage}
      hasPrevious={pageInfo?.hasPreviousPage}
      nextButtonLabel={t('common.next')}
      previousButtonLabel={t('common.previous')}
      onPaginationNextClick={() => {
        setPage(prev => prev + 1);
      }}
      onPaginationPrevClick={() => {
        setPage(prev => prev - 1);
      }}
    />
  );
};

export default CustomersList;
