import React, { FC } from 'react';
import { Box, Flex, Heading, Text } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';

import {
  useTerms,
  HeadingContainer,
  StyledHeader,
  OutsideScrollContainer,
  TermsContainer,
} from 'components/terms';

const Terms: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { title, lastUpdated, region, content } = useTerms();

  return (
    <Box data-test-routes-terms-page as='main' height='100%' padding='medium'>
      <Box paddingTop='large' marginX='auto' maxWidth='72rem'>
        <Heading
          as='h1'
          fontSize='xxxLarge'
          marginBottom='medium'
          textAlign='center'>
          {t('terms.pageTitle')}
        </Heading>

        <Box backgroundColor='#e5e5e5' paddingBottom='xLarge'>
          <Flex justifyContent='space-between' alignContent='center'>
            <HeadingContainer flexDirection='column'>
              <StyledHeader>{title}</StyledHeader>
              <Text>{lastUpdated}</Text>
              <Text>{region}</Text>
            </HeadingContainer>
          </Flex>
          <OutsideScrollContainer>
            <TermsContainer
              data-test-signup-terms
              flexDirection='column'
              backgroundColor='separator'>
              {content}
            </TermsContainer>
          </OutsideScrollContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Terms;
