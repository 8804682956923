import React, { FC } from 'react';
import { Component, ReactNode } from 'react';
import { ErrorProps } from './PageError';

interface ErrorBoundaryState {
  error?: Error;
}

interface ErrorBoundaryProps {
  children: ReactNode;
  handler: FC<ErrorProps>;
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { error: undefined };
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render = () => {
    if (this.state.error) {
      const Handler = this.props.handler;
      return <Handler />;
    }
    return this.props.children;
  };
}
