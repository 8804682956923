import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { InviteForm } from 'components/signup/InviteForm';
import PageError from 'components/common/PageError';
import { deserializeInviteToken } from 'utils/common';

const SignUp: FC = (): JSX.Element => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const inviteToken = searchParams.get('invite') || '';
  const invited = !!inviteToken;
  const { inviteId = '', email, company } = deserializeInviteToken(inviteToken);

  if (invited) {
    return (
      <InviteForm
        {...{
          inviteId,
          email,
          company,
          inviteToken,
        }}
      />
    );
  } else {
    return <PageError />;
  }
};

export default SignUp;
