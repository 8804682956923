import React from 'react';
import styled from 'styled-components';
import FlagButton from '../components/FlagButton';
import DismissButton from '../components/DismissButton';
import RestoreButton from '../components/RestoreButton';
import { AlertsTab } from '../types';
import {
  UpdateAlertModel,
  DeviceAlertStatus,
} from '@resideo/web-integration-services-api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';
import { useCurrentUser } from 'context/CurrentUser';
import { useToastContext } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { AlertListModel } from '@resideo/web-integration-services-api-client';

const StyledMultiSelectActionsContainer = styled.div`
  padding-left: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;

  span {
    color: #0f5eea;
  }

  button {
    margin-left: 30px;
    vertical-align: middle;

    &:first-child {
      margin-left: 0;
    }
  }

  svg {
    margin-right: 5px;
  }
`;

const getMultiSelectActions = (tab: AlertsTab) => ({ table }) => {
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const { client } = useRisClient();
  const { partnerAccountId } = useCurrentUser();
  const queryClient = useQueryClient();
  const selectedRows: AlertListModel[] = table
    .getSelectedRowModel()
    .rows.map(r => r.original);

  interface UpdateAlertWithAction extends UpdateAlertModel {
    actionType: 'addFlag' | 'removeFlag' | 'dismiss' | 'restore';
  }

  const successMessages: {
    [key in UpdateAlertWithAction['actionType']]: string;
  } = {
    addFlag: t('alertsDashboard.toast.flaggedSuccessMultiple', {
      number: selectedRows.length,
    }),
    removeFlag: t('alertsDashboard.toast.flagRemovedSuccessMultiple', {
      number: selectedRows.length,
    }),
    dismiss: t('alertsDashboard.toast.dismissedSuccessMultiple', {
      number: selectedRows.length,
    }),
    restore: t('alertsDashboard.toast.restoredSuccessMultiple', {
      number: selectedRows.length,
    }),
  };

  const updateAlerts = useMutation({
    mutationFn: async ({ status, flagged }: UpdateAlertWithAction) => {
      return client.myProAccounts.myProAccountsUpdateAlerts(
        partnerAccountId as string,
        selectedRows.map(alert => ({
          id: alert.id,
          ...(flagged !== undefined ? { flagged } : {}),
          ...(status !== undefined ? { status } : {}),
        }))
      );
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
    },
    onSuccess: async (_data, variables) => {
      const { actionType } = variables;
      const message = successMessages[actionType] || t('common.toast.success');
      table.toggleAllRowsSelected(false);

      await queryClient.invalidateQueries({ queryKey: ['alerts'] });
      await queryClient.invalidateQueries({ queryKey: ['alertsInsights'] });

      addToast({
        toastType: 'Success',
        message,
      });
    },
  });

  return selectedRows.length ? (
    <StyledMultiSelectActionsContainer>
      <span>{selectedRows.length} Selected</span>
      {selectedRows.some(a => !a.flagged) && (
        <FlagButton
          onClick={() => {
            updateAlerts.mutate({
              actionType: 'addFlag',
              flagged: true,
            });
          }}>
          {t('alertsDashboard.flag')}
        </FlagButton>
      )}
      {selectedRows.some(a => a.flagged) && (
        <FlagButton
          active
          onClick={() => {
            updateAlerts.mutate({
              actionType: 'removeFlag',
              flagged: false,
            });
          }}>
          {t('alertsDashboard.removeFlag')}
        </FlagButton>
      )}
      {tab === AlertsTab.Active && (
        <DismissButton
          onClick={() => {
            updateAlerts.mutate({
              actionType: 'dismiss',
              status: DeviceAlertStatus.DISMISSED,
            });
          }}>
          {t('alertsDashboard.dismiss')}
        </DismissButton>
      )}
      {tab === AlertsTab.Dismissed && (
        <RestoreButton
          onClick={() => {
            updateAlerts.mutate({
              actionType: 'restore',
              status: DeviceAlertStatus.ACTIVE,
            });
          }}>
          {t('alertsDashboard.moveToActive')}
        </RestoreButton>
      )}
    </StyledMultiSelectActionsContainer>
  ) : (
    <div />
  );
};

export default getMultiSelectActions;
