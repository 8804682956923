import React, { useState } from 'react';
import { Box, Button, Flex, Link } from '@resideo/blueprint-react';
import styled, { css } from 'styled-components';
import { GoTriangleUp } from 'react-icons/go';
import { FaRegQuestionCircle } from 'react-icons/fa';
import TimePeriodFilter from './TimePeriodFilter';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import { useTranslation } from 'react-i18next';
import useMedia from 'hooks/useMedia';
import ReactTooltip from 'react-tooltip';
import { useCurrentUser } from 'context/CurrentUser';
import { format } from 'date-fns';
import { Titled } from 'components/common/Titled';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';
import { DashboardMetricsInterval } from '@resideo/web-integration-services-api-client';
import { light } from '@resideo/blueprint-theme';
import { useNavigate } from 'react-router-dom';

const COLORS = [light.colors.faded, '#d2272f'];

const DashboardContainer = styled(Flex)`
  flex-direction: column;
  padding: 2rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 15px;
  }
`;

const StyledTooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
    box-shadow: 0px 2px 10px #0000004d;
  }
`;

const Border = styled(Box)`
  box-shadow: 0 4px 2px -1px #f4f4f4;
  overflow: auto;
  margin-left: -2rem;
  margin-right: -2rem;
  padding-bottom: 45px;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-right: -0.5rem;
  }
`;

const DashboardHeader = styled(Flex)`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
    padding-bottom: 0;
    align-items: stretch;
    gap: 20px;
    border: 0;
  }
`;

const ViewBrandProfileBtn = styled(Button)`
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
  }
`;

const DashboardSubheading = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.separator};
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
    align-items: stretch;
    border: 0;
    padding: 0;
    margin-bottom: 10px;
  }
`;

const StyledHeading = styled.h1`
  font-size: 30px;
  margin: 0;
`;

const DateBlock = styled.div`
  width: 150px;
  p {
    margin: 0;
  }
`;

const SummaryBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 350px);
  grid-template-rows: 176px 250px;
  gap: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const DashboardFilters = styled(Flex)`
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column-reverse;
    align-items: stretch;
    gap: 10px;
  }
`;

const DashboardFiltersLeft = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-right: 10px;
`;

const MoreInfoIcon = styled(FaRegQuestionCircle)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #0f5eea;
  border-radius: 50%;
  &:hover {
    background-color: #0f5eea;
    color: white;
  }
`;

const DashboardSubheader = styled(Flex)`
  h2 {
    font-size: 25px;
  }
  h3 {
    margin-top: 64px;
    margin-bottom: 15px;
    font-weight: 450;
    font-size: 21px;
    @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      margin-top: 32px;
    }
  }
  align-items: center;
`;

const Triangle = styled(GoTriangleUp)`
  padding-right: 4px;
`;

const Percentages = styled.span`
  color: ${({ theme }) => theme.colors.success};
`;

const TotalUnregistered = styled(Flex)`
  font-size: 40px;
  font-weight: 700;
  align-items: center;
  height: 35px;
  margin: 0;
  color: #303030;
  color: #d2272f;
`;

const GraphTitle = styled.h3`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-align: center;
  font-weight: 500;
  color: #303030;
`;

const NoData = styled.p`
  position: absolute;
  top: 40%;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin: 0;
`;

const TimePeriod = styled.div`
  p {
    margin: 0;
    font-size: 14px;
    color: #575757;
    padding: 5px;
  }
`;

const Graph = styled(Flex)`
  justify-content: center;
  .recharts-default-legend {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: left;
    margin: 0 15px !important;
    gap: 10px;
  }

  .recharts-legend-item {
    display: flex !important;
    gap: 8px;
    width: 100%;
  }

  .recharts-legend-item > svg {
    margin: 0 !important;
    margin-top: 3px !important;
  }

  .recharts-legend-item-text {
    color: black !important;
    font-size: 14px;
    text-align: left;
  }
`;

const ServiceSummary = styled(Flex)`
  gap: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }
`;

const CardMixin = css`
  border: 1px solid #f4f4f4;
  width: 350px;
  box-sizing: border-box;
  min-height: 100px;
  border-radius: 10px;
  padding: 15px;
  margin: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
  }
`;

const Card = styled.div`
  height: 180px;
  ${CardMixin}
`;

const Card1 = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  height: 375px;
  ${CardMixin}
`;

const Card2 = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  height: 180px;
  ${CardMixin}
`;

const Card3 = styled.div`
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  height: 180px;
  ${CardMixin}
`;

const Card4 = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  height: 180px;
  ${CardMixin}
`;

const CardContent = styled(Flex)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  p {
    margin: 0;
  }
`;

const TotalNumber = styled(Flex)`
  font-size: 40px;
  font-weight: 700;
  align-items: center;
  height: 35px;
  color: #303030;
  margin: 0;
`;

const CardSubheading = styled.h4`
  font-size: 18px;
  font-weight: 350;
  color: #303030;
  margin: 0;
`;

const PercentagePeriod = styled.span`
  color: #767676;
  font-size: 14px;
`;

const BusinessIntelligence = () => {
  const { t } = useTranslation();
  const { partnerAccountId } = useCurrentUser();
  const [timeFilterValue, setTimeFilterValue] = useState(
    DashboardMetricsInterval.LAST_WEEK
  );
  const navigate = useNavigate();
  const isMobile = useMedia('(max-width: 62rem)');

  const { client } = useRisClient();

  const { data } = useSuspenseQuery({
    queryKey: ['getPartnerAccount', timeFilterValue],
    queryFn: async () =>
      (
        await client.myProAccounts.myProAccountsGetDashboardMetrics(
          partnerAccountId as string,
          timeFilterValue
        )
      ).data,
  });

  const graphData = [
    {
      name: t('cobrandingDashboard.locationsRegisteredDevices'),
      value: data?.locationsWithRegisteredDevices?.current || 0,
    },
    {
      name: t('cobrandingDashboard.locationsUnregisteredDevices'),
      value: data?.locationsWithUnregisteredDevices?.current || 0,
    },
  ];

  const renderPercentages = (percentage?: number | null) => {
    if (timeFilterValue === DashboardMetricsInterval.LIFE_TIME) {
      return;
    }
    const percentageValue = `${percentage || 0}% `;
    return (
      <p>
        {percentage ? (
          <Percentages>
            <Triangle />
            {percentageValue}
          </Percentages>
        ) : (
          `${percentageValue} `
        )}
        <PercentagePeriod>
          {t(
            timeFilterValue === DashboardMetricsInterval.LAST_WEEK
              ? 'cobrandingDashboard.inLast7Days'
              : 'cobrandingDashboard.inLast30Days'
          )}
        </PercentagePeriod>
      </p>
    );
  };

  const renderTooltip = (id: string, text: string) => {
    return (
      <>
        <MoreInfoIcon data-tip data-for={id} />
        <StyledTooltip
          type='light'
          id={id}
          place={isMobile ? 'right' : 'top'}
          effect='solid'
          delayHide={500}
          delayUpdate={500}>
          {text}
        </StyledTooltip>
      </>
    );
  };

  const noData = graphData.every(item => !item.value);

  return (
    <DashboardContainer>
      <Titled title={t('cobrandingDashboard.businessIntelligence')} />
      <DashboardHeader>
        <StyledHeading data-test-brand-profile-header>
          {t('cobrandingDashboard.businessIntelligence')}
        </StyledHeading>
        <Link href='/mybusiness/co-branding/logo'>
          <ViewBrandProfileBtn>
            {t('cobrandingDashboard.viewBrandProfile')}
          </ViewBrandProfileBtn>
        </Link>
      </DashboardHeader>
      <Border />
      <DashboardSubheading>
        <DashboardSubheader data-test-dashboard-title>
          <h2>{t('cobrandingDashboard.brandingIQInsights')}</h2>
        </DashboardSubheader>
        <DateBlock data-test-date-block>
          <p style={{ fontSize: '14px', color: '#575757' }}>
            {t('cobrandingDashboard.date')}
          </p>
          <p style={{ fontWeight: 400 }}>{format(new Date(), 'MMMM d, y')}</p>
        </DateBlock>
      </DashboardSubheading>
      <DashboardFilters>
        <DashboardFiltersLeft>
          <DashboardSubheader data-test-dashboard-title>
            <h3>{t('cobrandingDashboard.serviceSummary')}</h3>
          </DashboardSubheader>
        </DashboardFiltersLeft>
        <TimePeriod data-test-time-period>
          <p>{t('cobrandingDashboard.timePeriod')}</p>
          <TimePeriodFilter
            getTimeFilterValue={value => {
              setTimeFilterValue(value);
            }}
            data-test-time-period-filter></TimePeriodFilter>
        </TimePeriod>
      </DashboardFilters>
      <ServiceSummary>
        <Card data-test-total-locations>
          <CardContent data-test-total-locations-number>
            <TotalNumber>
              {data?.deviceLocationsWithHomeownerAppBranding?.current || 0}
            </TotalNumber>
            <CardSubheading>
              {t(
                'cobrandingDashboard.locationsWithHomeownerAppBrandingService'
              )}
            </CardSubheading>
            {renderPercentages(
              data?.deviceLocationsWithHomeownerAppBranding?.percentChange
            )}
            {renderTooltip(
              'locationsWithHomeownerAppBrandingServiceTooltip',
              t(
                'cobrandingDashboard.locationsWithHomeownerAppBrandingServiceTooltip'
              )
            )}
          </CardContent>
        </Card>
      </ServiceSummary>
      <DashboardSubheader data-test-dashboard-title>
        <h3>{t('cobrandingDashboard.locationSummary')}</h3>
      </DashboardSubheader>
      <SummaryBlock>
        <Card1>
          <GraphTitle>{t('cobrandingDashboard.graphTitle')}</GraphTitle>
          <Graph>
            {noData && <NoData>{t('cobrandingDashboard.noData')}</NoData>}
            <PieChart width={350} height={300}>
              <Pie
                data={graphData}
                cx={175}
                cy={120}
                innerRadius={60}
                outerRadius={100}
                paddingAngle={1}
                dataKey='value'>
                {graphData.map((entry, index) => (
                  <Cell key={entry.name} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Legend iconType='square' />
            </PieChart>
          </Graph>
        </Card1>

        <Card2 data-test-total-locations>
          <CardContent data-test-total-locations-number>
            <TotalUnregistered>
              {data?.locationsWithUnregisteredDevices?.current || 0}
            </TotalUnregistered>
            <CardSubheading>
              {t('cobrandingDashboard.locationsUnregisteredDevices')}
            </CardSubheading>
            {renderPercentages(
              data?.locationsWithUnregisteredDevices?.percentChange
            )}
            <Flex justifyContent='space-between' alignItems='end'>
              {renderTooltip(
                'locationsUnregisteredTooltip',
                t('cobrandingDashboard.locationsUnregisteredTooltip')
              )}
              <Button
                variant='secondary'
                onClick={() => {
                  navigate('/mybusiness/business-intelligence/unregistered');
                }}>
                {t('cobrandingDashboard.viewList')}
              </Button>
            </Flex>
          </CardContent>
        </Card2>
        <Card3 data-test-total-locations>
          <CardContent data-test-total-locations-number>
            <TotalNumber>
              {data?.locationsWithRegisteredDevices?.current || 0}
            </TotalNumber>
            <CardSubheading>
              {t('cobrandingDashboard.locationsRegisteredDevices')}
            </CardSubheading>
            {renderPercentages(
              data?.locationsWithRegisteredDevices?.percentChange
            )}
            {renderTooltip(
              'locationsRegisteredDevicesTooltip',
              t('cobrandingDashboard.locationsRegisteredDevicesTooltip')
            )}
          </CardContent>
        </Card3>
        <Card4 data-test-locations-added>
          <CardContent data-test-total-locations-number>
            <TotalNumber>{data?.locationsAdded?.current || 0}</TotalNumber>
            <CardSubheading>
              {t('cobrandingDashboard.locationsAdded')}
            </CardSubheading>
            {renderPercentages(data?.locationsAdded?.percentChange)}
            {renderTooltip(
              'locationsAddedTooltip',
              t('cobrandingDashboard.locationsAddedTooltip')
            )}
          </CardContent>
        </Card4>
      </SummaryBlock>
    </DashboardContainer>
  );
};
export default BusinessIntelligence;
