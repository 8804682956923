export const LANGUAGES = [
  { value: 'bg', text: 'български' },
  { value: 'cs', text: 'Čeština' },
  { value: 'de', text: 'Deutsch' },
  { value: 'en', text: 'English' },
  { value: 'es', text: 'Español' },
  { value: 'es-419', text: 'Español - América Latina' },
  { value: 'fr', text: 'Français' },
  { value: 'fr-CA', text: 'Français-Canada' },
  { value: 'el', text: 'Ελληνικά' },
  { value: 'hr', text: 'Hrvatski' },
  { value: 'it', text: 'Italiano' },
  { value: 'hu', text: 'Magyar' },
  { value: 'nl', text: 'Nederlands' },
  { value: 'pl', text: 'Polski' },
  { value: 'pt', text: 'Português' },
  { value: 'pt-BR', text: 'Português - Brasil' },
  { value: 'sk', text: 'Slovenčina' },
  { value: 'sl', text: 'Slovene' },
  { value: 'ro', text: 'Română' },
];

//EMEA Only
export const EMEA_LANGUAGES = [
  { value: 'bg', text: 'български (bãlgarski)' },
  { value: 'cz', text: 'Čeština' },
  { value: 'de', text: 'Deutsch' },
  { value: 'en', text: 'English' },
  { value: 'es', text: 'Español' },
  { value: 'fr', text: 'Français' },
  { value: 'gr', text: 'Ελληνικά' },
  { value: 'hr', text: 'Hrvatski' },
  { value: 'it', text: 'Italiano' },
  { value: 'hu', text: 'Magyar' },
  { value: 'nl', text: 'Nederlands' },
  { value: 'pl', text: 'Polski' },
  { value: 'pt', text: 'Português' },
  { value: 'sk', text: 'Slovenčina' },
  { value: 'si', text: 'Slovene' },
  { value: 'ro', text: 'Română' },
];

//North America Only
export const NA_LANGUAGES = [
  { value: 'en', text: 'English' },
  { value: 'es-a', text: 'Español' },
  { value: 'fr-c', text: 'Français-Canada' },
  { value: 'pt-b', text: 'Português-Brasil' },
];
