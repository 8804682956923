/* eslint-disable no-useless-escape */
import {
  getCountryCodeForRegionCode,
  parsePhoneNumber,
} from 'awesome-phonenumber';
import { fromUrl, parseDomain, ParseResultType } from 'parse-domain';

export { checkName, checkEmail } from '@resideo/validation';

const postalCodeRegex = {
  US: /^(\d{5})(?:[-\s](\d{4}))?$/,
  CA: /^(?!.*[DFIOQU])[A-VXY]\d[A-Z] \d[A-Z]\d$/,
};

export const isNumber = (value: number | string) => {
  return Number.isInteger(+value);
};

export function checkPhoneNumber(value: string) {
  const pn = parsePhoneNumber(value);
  return pn.isValid();
}

export function checkPhoneNumberForCountryCode(value: string) {
  const pn = parsePhoneNumber(value);
  return pn.getCountryCode();
}

export function getCountryCodeForRegion(regionCode: string) {
  return getCountryCodeForRegionCode(regionCode);
}

export function checkPostalCode(
  postalCode: string,
  countryCode = 'US',
  postalCodeRegexRules = postalCodeRegex
) {
  const regExRule =
    postalCodeRegexRules[countryCode] || postalCodeRegexRules.US;
  return regExRule.test(postalCode);
}

export function checkUrl(value: string) {
  const parseResult = parseDomain(fromUrl(value));

  return parseResult.type === ParseResultType.Listed;
}

export function urlEnsureHttps(url: string) {
  if (url) {
    /*
     * Checks for https:// first, then for http://, then just finds the beginning of the string.
     * Will replace whichever it finds first with 'https://'
     * Accounts for accidental whitespace at the beginning of an input
     */
    return url.replace(/^\s*https:\/\/|^\s*http:\/\/|^\s*/i, 'https://');
  }
  return '';
}

export interface singleAddressType {
  country?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export function validateSingleAddress(
  errors,
  t,
  values: singleAddressType,
  prefix = ''
) {
  if (!values[`address1${prefix}`]) {
    errors[`address1${prefix}`] = t(
      'common.validation.address.addressRequired'
    );
  }

  if (!values[`city${prefix}`]) {
    errors[`city${prefix}`] = t('common.validation.address.cityRequired');
  }

  if (!values[`state${prefix}`]) {
    errors[`state${prefix}`] =
      !values[`country${prefix}`] || values[`country${prefix}`] === 'US'
        ? t('common.validation.address.stateRequired')
        : t('common.validation.address.provinceRequired');
  }
  if (!values[`zip${prefix}`]) {
    errors[`zip${prefix}`] = t('common.validation.address.zipRequired');
  }

  if (!values[`country${prefix}`]) {
    errors[`country${prefix}`] = t('common.validation.address.countryRequired');
  }

  return errors;
}
