import { DeviceAlertSeverity } from '@resideo/web-integration-services-api-client';

export { DeviceAlertSeverity };

export type AlertItemType = {
  title: string;
  description: string;
  type: DeviceAlertSeverity;
  customer: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
  date: string;
  locationAddress: string;
};

export type AlertsListType = {
  items: AlertItemType[];
  total: {
    low: number;
    medium: number;
    high: number;
    critical: number;
  };
};

export enum AlertsTab {
  Active,
  Flagged,
  Dismissed,
}
