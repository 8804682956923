import React from 'react';
import styled from 'styled-components';

const StyledInfoIcon = styled.svg`
  width: 20px;
  height: 20px;

  & > circle {
    stroke: #153755;
    fill: #fff;
  }
  g {
    fill: #153755;
  }

  &:hover {
    & > circle {
      fill: #153755;
    }
    g {
      fill: #fff;
    }
  }
`;

const InfoIcon = props => {
  return (
    <StyledInfoIcon viewBox='0 0 20 20' fill='none' {...props}>
      <circle cx='10' cy='10' r='9' strokeWidth='1' />
      <g>
        <rect x='9.15' y='7.65' width='1.7' height='6.35' />
        <circle cx='10' cy='6' r='1' />
      </g>
    </StyledInfoIcon>
  );
};

export default InfoIcon;
