import React from 'react';
import { Titled } from 'components/common/Titled';
import { useTranslation } from 'react-i18next';
import { RouteObject, Outlet } from 'react-router-dom';
import Error from 'routes/Error';
import BusinessIntelligenceUpdated from '../../components/services/BusinessIntelligenceUpdated';
import BusinessIntelligence from '../../components/services/BusinessIntelligence';
import { BUSINESS_INTELLIGENCE_DASHBOARD_UPDATES } from 'config/flags';
import LocationsWithUnregisteredDevicesList from 'components/services/LocationsWithUnregisteredDevicesList';

export const ServicesRoutesLayout = () => {
  const { t } = useTranslation();

  return (
    <Titled title={t('mybusiness.services.servicesSubscriptions')}>
      <Outlet />
    </Titled>
  );
};

const routes: RouteObject[] = [
  {
    index: true,
    element: BUSINESS_INTELLIGENCE_DASHBOARD_UPDATES ? (
      <BusinessIntelligenceUpdated />
    ) : (
      <BusinessIntelligence />
    ),
  },
  {
    path: 'unregistered',
    element: BUSINESS_INTELLIGENCE_DASHBOARD_UPDATES ? (
      <LocationsWithUnregisteredDevicesList />
    ) : (
      <BusinessIntelligence />
    ),
  },
  {
    path: '*',
    element: <Error />,
  },
];

export default routes;
