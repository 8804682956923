import React, { FC, useState, useEffect } from 'react';
import AuthenticationContext, { Session } from './context';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { local } from 'utils/storage';

export const SESSION_KEY = 'session';

export const MockAuth0Provider: FC<{
  children: React.ReactNode;
}> = ({ children }): JSX.Element => {
  const [session, setSession] = useState<Session>();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const logout = async () => {
    setIsAuthenticated(false);
    setSession(undefined);
    local.removeItem(SESSION_KEY);
    navigate('/sign-in');
  };

  const loginWithRedirect = async () => {
    navigate('/sign-in');
  };

  const restoreSession = () => {
    let session;
    const json = local.getItem(SESSION_KEY);
    if (json) {
      try {
        session = JSON.parse(json) as Session;
      } catch (e) {
        throw new Error(t('error.description'));
      }
    }
    setSession(session);
    return session;
  };

  const getAccessTokenSilently = () =>
    Promise.resolve(session?.accessToken ?? '');

  const getIdTokenClaims = () =>
    Promise.resolve({ email_verified: session?.emailVerified, __raw: '' });

  useEffect(() => {
    const session = restoreSession();
    setIsAuthenticated(!!session);
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        restoreSession,
        setIsAuthenticated,
        loginWithRedirect,
        logout,
        getAccessTokenSilently,
        getIdTokenClaims,
      }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
