import { IFeatureFlagsContext } from 'context/FeatureFlags';

export interface PrimaryNavigationItem {
  name: string;
  linkTextKey: string;
  linkPath: string;
  isExternalLink?: boolean;
}

const primaryNavigation = ({
  PRODUCTS_FLAG,
  SOLUTIONS_FLAG,
  RESOURCES_FLAG,
  PERKS_FLAG,
}: IFeatureFlagsContext): PrimaryNavigationItem[] => [
  ...(PRODUCTS_FLAG
    ? [
        {
          name: 'products',
          linkTextKey: 'header.primary.products',
          linkPath: 'https://www.resideo.com/us/en/pro/products/air/',
          isExternalLink: true,
        },
      ]
    : []),
  ...(SOLUTIONS_FLAG
    ? [
        {
          name: 'services',
          linkTextKey: 'header.primary.services',
          linkPath: 'https://www.resideo.com/us/en/pro/products/air/services/',
          isExternalLink: true,
        },
      ]
    : []),
  ...(RESOURCES_FLAG
    ? [
        {
          name: 'resources',
          linkTextKey: 'header.primary.resources',
          linkPath: 'https://www.resideo.com/us/en/pro/resources/',
          isExternalLink: true,
        },
      ]
    : []),
  ...(PERKS_FLAG
    ? [
        {
          name: 'perks',
          linkTextKey: 'header.primary.perks',
          linkPath: 'https://rewards.resideo.com',
          isExternalLink: true,
        },
      ]
    : []),

  {
    name: 'academy',
    linkTextKey: 'header.primary.academy',
    linkPath: 'https://academy.resideo.com/learn',
    isExternalLink: true,
  },
  {
    name: 'mybusiness',
    linkTextKey: 'header.primary.mybusiness',
    linkPath: '/mybusiness',
    isExternalLink: false,
  },
];

export { primaryNavigation as default };
