const ProductImages = t => [
  {
    text: t('proSeries.productImages.LTE.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Product Images/LTE Communicator.png?sp=rl&st=2020-11-02T20:21:36Z&se=2125-10-16T20:21:00Z&sv=2019-12-12&sr=b&sig=PSmcd%2FsVldeuafpFsFsb9u0L9nBkgCJH7eULS8d%2FYYo%3D',
    ext: t('proSeries.ext.png'),
    desc: '',
  },
  {
    text: t('proSeries.productImages.motionViewer.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Product Images/Motionviewer.jpg?sp=rl&st=2020-11-02T20:21:56Z&se=2146-10-10T20:21:00Z&sv=2019-12-12&sr=b&sig=hk6lAnb7tte%2Bya%2BszhdjDuGM7jc4Mrey%2FJz01YcWrJc%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.productImages.panel.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Product Images/ProSeries Panel.png?sp=rl&st=2020-11-02T20:22:15Z&se=2136-10-03T20:22:00Z&sv=2019-12-12&sr=b&sig=96vdZfWVAvbnDm1bZyVKkkyoJAx%2FPcpmfLtQdnAGQhI%3D',
    ext: t('proSeries.ext.png'),
    desc: '',
  },
  {
    text: t('proSeries.productImages.combo.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Product Images/PROSIXCOMBO.png?sp=rl&st=2020-11-02T20:24:03Z&se=2127-09-15T20:24:00Z&sv=2019-12-12&sr=b&sig=bFjUco0uiJEnlJX7eTPem6KGnzSJhXOkul6FBUy6LQ8%3D',
    ext: t('proSeries.ext.png'),
    desc: '',
  },
  {
    text: t('proSeries.productImages.ct.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Product Images/PROSIXCT.png?sp=rl&st=2020-11-02T20:24:21Z&se=2146-11-16T20:24:00Z&sv=2019-12-12&sr=b&sig=GspWZM8%2BTsW4EvJCrZiaFWIiNfYo%2BWLcjry0xywgpI0%3D',
    ext: t('proSeries.ext.png'),
    desc: '',
  },
  {
    text: t('proSeries.productImages.flood.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Product Images/PROSIXFLOOD.png?sp=rl&st=2020-11-02T20:24:37Z&se=2223-06-10T20:24:00Z&sv=2019-12-12&sr=b&sig=itzELabmUOBkfQlTvuwIQHgmYgMPBxoVMvpFxNEHhN8%3D',
    ext: t('proSeries.ext.png'),
    desc: '',
  },
  {
    text: t('proSeries.productImages.fob.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Product Images/PROSiXFOB_HI.jpg?sp=rl&st=2020-11-02T20:24:56Z&se=2145-06-22T20:24:00Z&sv=2019-12-12&sr=b&sig=IBGz9WlcfMnJL5PvG23roaS%2B1qIFRHz4z2i8LwQBaVw%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.productImages.gb.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Product Images/PROSIXGB_Glassbreak.png?sp=rl&st=2020-11-02T20:25:13Z&se=2130-06-03T20:25:00Z&sv=2019-12-12&sr=b&sig=3QeTvkuYLbo4SQWWO9rsMO6s0of0aMLuVLHON6um5yQ%3D',
    ext: t('proSeries.ext.png'),
    desc: '',
  },
  {
    text: t('proSeries.productImages.heatv.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Product Images/PROSIXHEATV.png?sp=rl&st=2020-11-02T20:25:30Z&se=2137-10-14T20:25:00Z&sv=2019-12-12&sr=b&sig=41W%2FodcCgsCH5nUqILUDCfHilQr%2BNJrAItH2SqV7fcg%3D',
    ext: t('proSeries.ext.png'),
    desc: '',
  },
  {
    text: t('proSeries.productImages.med.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Product Images/PROSIXMED.png?sp=rl&st=2020-11-02T20:25:47Z&se=2125-11-22T20:25:00Z&sv=2019-12-12&sr=b&sig=YGlt6jmx6n74zdDaJgpesGQyFQKtNXvOrDAHoEdhYpQ%3D',
    ext: t('proSeries.ext.png'),
    desc: '',
  },
  {
    text: t('proSeries.productImages.minict.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Product Images/PROSIXMINICT.png?sp=rl&st=2020-11-02T20:26:06Z&se=2131-03-07T20:26:00Z&sv=2019-12-12&sr=b&sig=F6UJbTzCcXaJCl%2Bb17CBXqc0na0oEzgub3Q3VOTOy5k%3D',
    ext: t('proSeries.ext.png'),
    desc: '',
  },
  {
    text: t('proSeries.productImages.panic.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Product Images/PROSIXPANIC.png?sp=rl&st=2020-11-02T20:26:28Z&se=2134-10-12T20:26:00Z&sv=2019-12-12&sr=b&sig=OdmD6mtLFaDtBAd%2BtjNu2NJK3ME5Alc%2FAS%2FtsYd6E%2BY%3D',
    ext: t('proSeries.ext.png'),
    desc: '',
  },
  {
    text: t('proSeries.productImages.pir.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Product Images/PROSIXPIR_MotionSensor.png?sp=rl&st=2020-11-02T20:26:49Z&se=2124-05-15T20:26:00Z&sv=2019-12-12&sr=b&sig=uLocDJFlFZQlydCjkCxzztpbexsQaECj1UkB7Z1utoc%3D',
    ext: t('proSeries.ext.png'),
    desc: '',
  },
  {
    text: t('proSeries.productImages.proa7plus.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Product Images/PROA7PLUSC.PNG?sp=rl&st=2021-02-01T20:30:12Z&se=2118-10-11T20:30:00Z&sv=2019-12-12&sr=b&sig=eWsyy9Z39%2Fba5uwMJ2fn3jEpQidMe6w7%2FE78k4gW8Zk%3D',
    ext: '',
    desc: '',
  },
  {
    text: t('proSeries.productImages.proSeriesAllInOnePanel.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROA7CN%20Canadian%20French.png?sp=r&st=2021-07-27T21:37:09Z&se=2121-07-08T05:37:09Z&spr=https&sv=2020-08-04&sr=b&sig=lucRhsMGG4iwRdxVFF%2FDvvF%2BJuy9p3M98RwTdWh5IuU%3D',
    ext: '',
    desc: '',
  },
  {
    text: t('proSeries.productImages.proSeriesAllInOnePlusPanel.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROA7PlusCN%20Canadian%20French.png?sp=r&st=2021-07-27T21:38:45Z&se=2121-07-28T05:38:45Z&spr=https&sv=2020-08-04&sr=b&sig=tCh4ASE0rSbOf0axRt0uCnnni%2BsMw8Iv%2FrhBABumxKM%3D',
    ext: '',
    desc: '',
  },
  {
    text: t('proSeries.productImages.proSeriesExtendedLTEAntenna.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROLTE-ANT_s.jpg?sp=r&st=2021-07-27T21:39:53Z&se=2121-07-28T05:39:53Z&spr=https&sv=2020-08-04&sr=b&sig=1HMcsF%2BdPrA7HOTtjJm%2BclNwsMc7izKeD%2B0TiwjmF7A%3D',
    ext: '',
    desc: '',
  },
  {
    text: t('proSeries.productImages.proSeriesCarbonMonoxideDetector.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROSIXCOCN.jpg?sp=r&st=2021-07-29T14:18:12Z&se=2121-07-29T22:18:12Z&spr=https&sv=2020-08-04&sr=b&sig=sDTHKoZdkjAzL7J6SZp5ehs7d9q4oYgNbPJihwAcs2o%3D',
    ext: '',
    desc: '',
  },
  {
    text: t('proSeries.productImages.proSeriesWirelessLCDKeypad.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROSIXLCDKCN.jpg?sp=r&st=2021-07-29T14:19:01Z&se=2121-07-29T22:19:01Z&spr=https&sv=2020-08-04&sr=b&sig=iXEq0olzMp8y2lhEcw9lByPoIBiHfQqJnuo%2FCFEH63U%3D',
    ext: '',
    desc: '',
  },
  {
    text: t('proSeries.productImages.proSeriesWRLSMiniContact.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROSIXMINI_2.png?sp=r&st=2021-07-29T14:19:30Z&se=2121-07-29T22:19:30Z&spr=https&sv=2020-08-04&sr=b&sig=LemOePfgf%2BVaUvJjxqDJ6rHfgSkmV85LvXT6XeGKiIQ%3D',
    ext: '',
    desc: '',
  },
  {
    text: t('proSeries.productImages.proSeriesWirelessIndoorSiren.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROSIXSIREN_s.jpg?sp=r&st=2021-07-29T14:19:57Z&se=2121-07-29T22:19:57Z&spr=https&sv=2020-08-04&sr=b&sig=yesEzDCq8Ip0WyffHYCdcFixvV%2FYn24IkxLmTlErC9k%3D',
    ext: '',
    desc: '',
  },
  {
    text: t('proSeries.productImages.proSeriesWirelessWeatherproofSiren.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROSIXSIRENO_s.jpg?sp=r&st=2021-07-29T14:20:28Z&se=2121-07-29T22:20:28Z&spr=https&sv=2020-08-04&sr=b&sig=2Ee8sNmFjvYvzKxsYaiMN6a00QYhJijr4%2FT4OmdHlUA%3D',
    ext: '',
    desc: '',
  },
  {
    text: t('proSeries.productImages.proSeriesWRLSSmokeDetector.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROSIXSMOKEV_s.jpg?sp=r&st=2021-07-29T14:21:23Z&se=2121-07-29T22:21:23Z&spr=https&sv=2020-08-04&sr=b&sig=PEjKKjjdLl%2BRJQgB%2BhKXKgrdT66DoukU800tlm5UVko%3D',
    ext: '',
    desc: '',
  },
  {
    text: t('proSeries.productImages.proSeriesFixedTempHeatDetector.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROSIXTEMP_s.jpg?sp=r&st=2021-07-29T14:21:58Z&se=2121-07-29T22:21:58Z&spr=https&sv=2020-08-04&sr=b&sig=gs%2FUrRh5E72IO5k0vICsoI1ip8ILKFypVl2uRTZVEgk%3D',
    ext: '',
    desc: '',
  },
  {
    text: t(
      'proSeries.productImages.proSeriesMultiWirelessFormatTakeoverModule.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROTAKEOV_s.jpg?sp=r&st=2021-07-29T14:23:02Z&se=2121-07-29T22:23:02Z&spr=https&sv=2020-08-04&sr=b&sig=cHLA%2FBqt2NKG3iuIvzXPwVi3%2FimCdWSm2Z7g4OmNX2A%3D',
    ext: '',
    desc: '',
  },
  {
    text: t('proSeries.productImages.wifiModuleForPROA7.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROWIFI_s.jpg?sp=r&st=2021-07-29T14:24:12Z&se=2121-07-29T22:24:12Z&spr=https&sv=2020-08-04&sr=b&sig=65qCWvgEJUQl0fVEqT0asO2hCasx7McymLP0cumfBfw%3D',
    ext: '',
    desc: '',
  },
  {
    text: t('proSeries.productImages.proSeriesWifiZWaveModule.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROWIFIZW_s.jpg?sp=r&st=2021-07-29T14:24:32Z&se=2121-07-29T22:24:32Z&spr=https&sv=2020-08-04&sr=b&sig=HGwwsUerTAUsu4YmNTIEWtLXzdo45f81psHI4TdLhBg%3D',
    ext: '',
    desc: '',
  },
  {
    text: t(
      'proSeries.productImages.proSeriesWirelessTouchscreenSecondaryKeyboard.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROWLTOUCH.jpg?sp=r&st=2021-07-29T14:25:06Z&se=2121-07-29T22:25:06Z&spr=https&sv=2020-08-04&sr=b&sig=nJFf6kTcTJ%2BlcrsfV%2FIaLpDhWNcx7yBjMGmBjQsMgNc%3D',
    ext: '',
    desc: '',
  },
  {
    text: t('proSeries.productImages.proSeriesDeskMountForProwlTouch.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROWLTOUCHDM%20DeskMount.jpg?sp=r&st=2021-07-29T14:25:23Z&se=2121-07-29T22:25:23Z&spr=https&sv=2020-08-04&sr=b&sig=Ldgd6sVOF%2B5zKa5zLirk33Fmb1B1Q%2Bc%2FCcSAIwmGmLo%3D',
    ext: '',
    desc: '',
  },
  {
    text: t('proSeries.productImages.proSeriesWallMountForProwlTouch.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Product%20Images/PROWLTOUCHWM.jpg?sp=r&st=2021-07-29T14:26:06Z&se=2121-07-29T22:26:06Z&spr=https&sv=2020-08-04&sr=b&sig=Ul6NaOif0i9qbJojsF%2BsFD7dMVRSGfNQ%2FZJ3qaEi%2BhU%3D',
    ext: '',
    desc: '',
  },
];

export default ProductImages;
