import React from 'react';
import { Titled } from 'components/common/Titled';
import { useTranslation } from 'react-i18next';
import { Outlet, RouteObject } from 'react-router-dom';
import AppLayoutAnonymous from 'components/layouts/AppLayoutAnonymous';
import Error from 'routes/Error';
import SignUp from './SignUp';
import { withProtectedRoute } from 'components/common/Route';
import AcceptInvite from 'components/signup/AcceptInvite';
import AcceptInviteLanding from 'components/signup/AcceptInviteLanding';
import { RESIDEO_ID_URL } from 'config';

export const SignUpLayout = () => {
  const { t } = useTranslation();
  return (
    <Titled title={t('signUp.pageTitle')}>
      <Outlet />
    </Titled>
  );
};

const SignUpRedirect = () => {
  const location = window.location.search;
  const hasParams = new URLSearchParams(location).toString().length > 0;
  if (hasParams) {
    return <SignUp />;
  } else {
    window.location.href = `${RESIDEO_ID_URL}/sign-up?userType=partner`;
  }
};

const routes: RouteObject[] = [
  {
    index: true,
    element: <SignUpRedirect />,
  },
  {
    path: 'accept-invite-landing',
    element: <AcceptInviteLanding />,
  },
  {
    path: 'accept-invite',
    Component: () => withProtectedRoute(AcceptInvite),
  },
  {
    path: '*',
    element: (
      <AppLayoutAnonymous>
        <Error />
      </AppLayoutAnonymous>
    ),
  },
];

export default routes;
