import React from 'react';
import styled from 'styled-components';
import { ActionButton } from './DashboardComponents';

const StyledRestoreButton = styled(ActionButton)`
  path {
    fill: #153755;
  }

  &:hover,
  &:active {
    path {
      fill: #1c6fb9;
    }
  }
`;

const RestoreButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <StyledRestoreButton onClick={onClick}>
      <svg
        width='24'
        height='20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.397 19a9 9 0 1 0-8.996-8.75h3.393L3.897 14 0 10.25h3.4a10.184 10.184 0 0 1-.003-.25c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10a9.966 9.966 0 0 1-6.928-2.789l.723-.692A8.97 8.97 0 0 0 13.397 19Z'
        />
      </svg>
      {children}
    </StyledRestoreButton>
  );
};

RestoreButton.displayName = 'RestoreButton';

export default RestoreButton;
