import React from 'react';
import { useTranslation } from 'react-i18next';

import { Titled } from 'components/common/Titled';
import { ToastContextProvider } from '@resideo/blueprint-react';
import PageError from 'components/common/PageError';
import ErrorBoundary from 'components/common/ErrorBoundary';
import AuthProvider from 'context/AuthProvider';
import SentryUser from 'components/global/SentryUser';
import { FeatureFlagsProvider } from 'context/FeatureFlags';
import { Outlet } from 'react-router-dom';
import { ModalProvider } from 'context/ModalProvider';
import { CurrentUserProvider } from 'context/CurrentUser';

const Root = () => {
  const { t } = useTranslation();

  return (
    <AuthProvider>
      <ErrorBoundary handler={PageError}>
        <CurrentUserProvider>
          <SentryUser />
          <FeatureFlagsProvider>
            <Titled title={t('title')}>
              <ToastContextProvider marginTop={'60px'}>
                <ModalProvider>
                  <Outlet />
                </ModalProvider>
              </ToastContextProvider>
            </Titled>
          </FeatureFlagsProvider>
        </CurrentUserProvider>
      </ErrorBoundary>
    </AuthProvider>
  );
};

export default Root;
