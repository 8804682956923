import React, { useState, useEffect, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import {
  Breadcrumbs,
  Button,
  MultiSelect,
  Toggle,
  CheckboxV2,
  Flex,
  Box,
  useToastContext,
  Spinner,
} from '@resideo/blueprint-react';
import {
  FeatureType,
  DeviceAlertSeverity,
} from '@resideo/web-integration-services-api-client';
import { Titled } from 'components/common/Titled';
import { useRisClient } from 'hooks/useRisClient';
import { useCurrentUser } from 'context/CurrentUser';
import { useModalContext } from 'context/ModalProvider';
import QuestionIcon from 'components/icons/question.svg?react';

const Header = styled.div`
  margin: 0 15px;
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dadada;

  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: 0 16px;
    padding: 0 0 15px;
    flex-direction: column;

    h1 {
      margin: 10px 0;
    }

    & > div {
      width: 100%;
    }

    button {
      margin: 0 10px;
      width: calc(100% - 20px);
    }
  }
`;

const Content = styled.div`
  margin: 0 30px;
  padding: 0 0 30px;
  max-width: 715px;
  box-sizing: border-box;

  hr {
    background: #dadada;
    border: none;
    display: block;
    height: 1px;
    margin: 20px 0;
  }

  h3 {
    margin: 20px 0;
    font-size: 21px;
    font-weight: 700;
    line-height: 25px;
    color: #303030;
  }

  h4 {
    font-weight: 700;
    font-size: 18px;
    color: #303030;
    margin: 0 0 12px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: 0 16px;
  }
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #767676;
  margin-bottom: 8px;

  h4 + & {
    margin-top: -6px;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 32px;
  margin-top: 15px;
  font-size: 14px;
`;

const StyledTooltip = styled(ReactTooltip)`
  max-width: 260px;
  &.show {
    opacity: 1 !important;
    box-shadow: 0 2px 10px #0000002b;
    font-size: 14px;
    font-weight: 400;
  }
`;

const StyledQuestionIcon = styled(QuestionIcon)`
  margin-left: 12px;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
`;

type DeviceOptionType = {
  value: number;
  text: string;
};

type EmailOptionType = {
  value: string;
  text: string;
};

const AlertsServiceSettings: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const { partnerAccountId } = useCurrentUser();
  const { openModal, closeModal } = useModalContext();
  const { addToast } = useToastContext();

  const ALL_DEVICES_OPTION: DeviceOptionType = {
    value: -1,
    text: t('mybusiness.services.alertsServiceSettings.allDevices'),
  };

  const [selectedDevices, setSelectedDevices] = useState<DeviceOptionType[]>([
    ALL_DEVICES_OPTION,
  ]);
  const [selectedEmails, setSelectedEmails] = useState<EmailOptionType[]>([]);
  const [applyToExistingInstalls, setApplyToExistingInstalls] = useState<
    boolean
  >(false);
  const [emailEnabled, setEmailEnabled] = useState<boolean>(false);
  const [emailValidationError, setEmailValidationError] = useState<boolean>(
    false
  );
  const [emailSeverities, setEmailSeverities] = useState<DeviceAlertSeverity[]>(
    []
  );

  const handleEmailSeverityChange = (severity: DeviceAlertSeverity) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;
    setEmailSeverities(prev =>
      isChecked
        ? prev.includes(severity)
          ? prev
          : [...prev, severity]
        : prev.filter(s => s !== severity)
    );
  };

  const { data: compatibleDevices } = useSuspenseQuery({
    queryKey: ['compatibleDevices'],
    queryFn: async () =>
      (await client.core.coreGetProductGroups(FeatureType.PRO_ALERTS)).data,
  });

  const { data: recipientEmails } = useSuspenseQuery({
    queryKey: ['recipientEmails'],
    queryFn: async () =>
      (
        await client.myProAccounts.myProAccountsGetAdminEmails(
          partnerAccountId as string
        )
      ).data,
  });

  const { data: proAlertProfile } = useSuspenseQuery({
    queryKey: ['proAlertProfile'],
    queryFn: async () =>
      (
        await client.myProAccounts.myProAccountsGetProAlertProfile(
          partnerAccountId as string
        )
      ).data,
  });

  const getProductGroupIds = () =>
    selectedDevices.some(option => option.value === ALL_DEVICES_OPTION.value)
      ? []
      : selectedDevices.map(option => option.value);

  const getEmailRecipients = () =>
    selectedEmails.length > 0 ? selectedEmails.map(option => option.value) : [];

  const createProAlertProfile = useMutation({
    mutationKey: ['createProAlertProfile'],
    mutationFn: async () => {
      await client.myProAccounts.myProAccountsCreateProAlertProfile(
        partnerAccountId as string,
        {
          applyToExistingInstalls,
          productGroupIds: getProductGroupIds(),
          emailEnabled,
          emailSeverities,
          emailRecipients: getEmailRecipients(),
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['proAlertProfile'],
      });
      navigate('/mybusiness/services/setup');
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
    },
  });

  const updateProAlertProfile = useMutation({
    mutationKey: ['updateProAlertProfile'],
    mutationFn: async () => {
      await client.myProAccounts.myProAccountsUpdateProAlertProfile(
        partnerAccountId as string,
        {
          productGroupIds: getProductGroupIds(),
          emailEnabled,
          emailSeverities,
          emailRecipients: getEmailRecipients(),
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['proAlertProfile'],
      });
      addToast({
        toastType: 'Success',
        message: t('common.toast.success'),
      });
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
    },
  });

  const compatibleDevicesOptions: DeviceOptionType[] = compatibleDevices?.length
    ? compatibleDevices.map(d => ({
        value: d.id as number,
        text: d.name || '',
      }))
    : [];

  const deviceOptions: DeviceOptionType[] = [
    ALL_DEVICES_OPTION,
    ...compatibleDevicesOptions,
  ];

  const recipientEmailsOptions: EmailOptionType[] = recipientEmails?.length
    ? recipientEmails
        .filter(e => e.email)
        .map(e => ({
          value: e.email as string,
          text: e.email || '',
        }))
    : [];

  useEffect(() => {
    if (proAlertProfile) {
      if (
        !proAlertProfile.productGroups ||
        proAlertProfile.productGroups.length === 0
      ) {
        setSelectedDevices([ALL_DEVICES_OPTION]);
      } else {
        setSelectedDevices(
          deviceOptions.filter(
            option =>
              proAlertProfile.productGroups?.some(
                pg => pg.id === option.value
              ) ?? false
          )
        );
      }
      setSelectedEmails(
        proAlertProfile.emailRecipients
          ? recipientEmailsOptions.filter(
              option =>
                proAlertProfile.emailRecipients?.includes(option.value) ?? false
            )
          : []
      );
      setApplyToExistingInstalls(
        proAlertProfile.applyToExistingInstalls ?? false
      );
      setEmailEnabled(proAlertProfile.emailEnabled ?? false);
      setEmailSeverities(proAlertProfile.emailSeverities ?? []);
    }
  }, [proAlertProfile, compatibleDevices, recipientEmails]);

  const handleDevicesChange = (newSelection: DeviceOptionType[]) => {
    setSelectedDevices(prevSelected => {
      if (newSelection.length === 0) {
        return [ALL_DEVICES_OPTION];
      }
      const wasAllDevicesPreviously = prevSelected.some(
        option => option.value === ALL_DEVICES_OPTION.value
      );
      const hasAllDevicesNew = newSelection.some(
        option => option.value === ALL_DEVICES_OPTION.value
      );
      const hasOtherDevicesNew = newSelection.some(
        option => option.value !== ALL_DEVICES_OPTION.value
      );

      if (!wasAllDevicesPreviously && hasAllDevicesNew) {
        return [ALL_DEVICES_OPTION];
      } else if (wasAllDevicesPreviously && hasOtherDevicesNew) {
        return newSelection.filter(
          option => option.value !== ALL_DEVICES_OPTION.value
        );
      } else {
        return newSelection;
      }
    });
  };

  const isDeviceSelected = selectedDevices.some(
    option => option.value === ALL_DEVICES_OPTION.value
  )
    ? false
    : selectedDevices.length !== 0;
  const isProAlertProfileCreated = !!(proAlertProfile && proAlertProfile.id);
  const isApplyToExistingCustomersDisabled =
    isProAlertProfileCreated || isDeviceSelected;

  return (
    <Titled title={t('mybusiness.services.alertsServiceSettings.title')}>
      <Header>
        <div>
          <Breadcrumbs>
            <NavLink to='/mybusiness/services-subscriptions'>
              {t('leftNav.servicesSubscriptions')}
            </NavLink>
            {!isProAlertProfileCreated && (
              <NavLink to='/mybusiness/services/setup/'>
                {t('mybusiness.services.completeServiceSetup.title')}
              </NavLink>
            )}
            <NavLink to='#'>
              {t('mybusiness.services.alertsServiceSettings.title')}
            </NavLink>
          </Breadcrumbs>
          <h1>{t('mybusiness.services.alertsServiceSettings.title')}</h1>
        </div>
        <div>
          <Button
            disabled={
              createProAlertProfile.isPending ||
              updateProAlertProfile.isPending ||
              emailValidationError
            }
            variant='primary'
            onClick={() => {
              if (emailEnabled && selectedEmails.length < 1) {
                setEmailValidationError(true);
                return;
              }
              if (isProAlertProfileCreated) {
                updateProAlertProfile.mutate();
              } else if (applyToExistingInstalls) {
                openModal({
                  title: t(
                    'mybusiness.services.alertsServiceSettings.applySettingsModal.title'
                  ),
                  children: (
                    <>
                      <Box marginBottom='20px'>
                        {t(
                          'mybusiness.services.alertsServiceSettings.applySettingsModal.content',
                          { number: proAlertProfile?.eligibleLocationsCount }
                        )}
                      </Box>
                      <Flex justifyContent='right'>
                        <Button
                          variant='secondary'
                          onClick={closeModal}
                          marginRight='20px'>
                          {t('common.back')}
                        </Button>
                        <Button
                          variant='primary'
                          onClick={() => {
                            closeModal();
                            createProAlertProfile.mutate();
                          }}>
                          {t('common.confirm')}
                        </Button>
                      </Flex>
                    </>
                  ),
                  outsideClick: true,
                });
              } else {
                createProAlertProfile.mutate();
              }
            }}>
            {createProAlertProfile.isPending ||
            updateProAlertProfile.isPending ? (
              <Spinner
                aria-label={t('common.saving')}
                marginX='small'
                color='primary'
                verticalAlign='text-top'
              />
            ) : isProAlertProfileCreated ? (
              t('common.save')
            ) : (
              t('common.saveAndContinue')
            )}
          </Button>
        </div>
      </Header>
      <Content>
        <h3>
          {t('mybusiness.services.alertsServiceSettings.serviceSettings')}
        </h3>
        <h4>{t('mybusiness.services.alertsServiceSettings.applyToDevices')}</h4>
        <Description>
          {t(
            'mybusiness.services.alertsServiceSettings.applyToDevicesDescription'
          )}
        </Description>
        <MultiSelect
          label=''
          name={'devices'}
          onChange={handleDevicesChange}
          value={selectedDevices}
          options={deviceOptions}
          disabled={applyToExistingInstalls && !isProAlertProfileCreated}
        />
        <hr />
        <ToggleContainer>
          <div>
            <h4
              style={{
                color: isApplyToExistingCustomersDisabled ? '#767676' : '',
              }}>
              {t('mybusiness.services.alertsServiceSettings.ApplyToCustomers')}
              <StyledQuestionIcon data-tip data-for={'alerts-header'} />
              <StyledTooltip
                type='light'
                id={'alerts-header'}
                place='top'
                effect='solid'
                delayHide={500}
                delayUpdate={500}>
                {isApplyToExistingCustomersDisabled
                  ? t(
                      'mybusiness.services.alertsServiceSettings.ApplyToCustomersToolTipDisabled'
                    )
                  : t(
                      'mybusiness.services.alertsServiceSettings.ApplyToCustomersToolTip',
                      { number: proAlertProfile?.eligibleLocationsCount }
                    )}
              </StyledTooltip>
            </h4>
            <Description>
              {t(
                'mybusiness.services.alertsServiceSettings.ApplyToCustomersDescription'
              )}
            </Description>
          </div>
          <div>
            <Toggle
              disabled={isApplyToExistingCustomersDisabled}
              name='applyToExistingCustomers'
              checked={applyToExistingInstalls}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setApplyToExistingInstalls(e.target.checked)
              }
            />
          </div>
        </ToggleContainer>
        <hr />
        <h3 style={{ marginTop: '40px' }}>
          {t('mybusiness.services.alertsServiceSettings.notificationSettings')}
        </h3>
        <ToggleContainer>
          <div>
            <h4>
              {t(
                'mybusiness.services.alertsServiceSettings.emailNotifications'
              )}
            </h4>
            <Description>
              {t(
                'mybusiness.services.alertsServiceSettings.emailNotificationsDescription'
              )}
            </Description>
          </div>
          <div>
            <Toggle
              name='emailNotifications'
              checked={emailEnabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const checked = e.target.checked;
                setEmailEnabled(checked);
                if (!checked) setEmailValidationError(false);
              }}
            />
          </div>
        </ToggleContainer>
        <hr />
        <h4>{t('mybusiness.services.alertsServiceSettings.alertSeverity')}</h4>
        <CheckboxContainer>
          <CheckboxV2
            label={t('alertsDashboard.urgent')}
            checked={emailSeverities.includes(DeviceAlertSeverity.URGENT)}
            onChange={handleEmailSeverityChange(DeviceAlertSeverity.URGENT)}
            disabled={!emailEnabled}
          />
          <CheckboxV2
            label={t('alertsDashboard.warning')}
            checked={emailSeverities.includes(DeviceAlertSeverity.WARNING)}
            onChange={handleEmailSeverityChange(DeviceAlertSeverity.WARNING)}
            disabled={!emailEnabled}
          />
          <CheckboxV2
            label={t('alertsDashboard.notice')}
            checked={emailSeverities.includes(DeviceAlertSeverity.NOTICE)}
            onChange={handleEmailSeverityChange(DeviceAlertSeverity.NOTICE)}
            disabled={!emailEnabled}
          />
        </CheckboxContainer>
        <hr />
        <h4>
          {t('mybusiness.services.alertsServiceSettings.emailRecipients')}
        </h4>
        <MultiSelect
          label=''
          name={'recipients'}
          onChange={(value: []) => {
            setSelectedEmails(value);
            setEmailValidationError(value.length < 1);
          }}
          value={selectedEmails}
          options={recipientEmailsOptions}
          noOptionsMessage={
            <div style={{ padding: '10px' }}>
              {t('common.forms.noMoreOptionsAvailable')}
            </div>
          }
          disabled={!emailEnabled}
          error={
            emailEnabled && emailValidationError
              ? t(
                  'mybusiness.services.alertsServiceSettings.emailRecipientsValidationError'
                )
              : null
          }
        />
      </Content>
    </Titled>
  );
};

export default AlertsServiceSettings;
