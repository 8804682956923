import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToastContext } from '@resideo/blueprint-react';
import CustomerInfoForm from './CustomerInfoForm';
import { getInternationalPhone } from 'utils/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { checkPhoneNumberForCountryCode } from 'utils/validation';
import { useRisClient } from 'hooks/useRisClient';
import { UpdatePartnerCustomerModel } from '@resideo/web-integration-services-api-client';
import { useCurrentUser } from 'context/CurrentUser';

const EditCustomerForm = ({ closeModal, initialValues }) => {
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const { client } = useRisClient();
  const { partnerAccountId } = useCurrentUser();
  const queryClient = useQueryClient();

  const editCustomer = useMutation({
    mutationFn: async (vars: {
      partnerCustomerId;
      cust: UpdatePartnerCustomerModel;
    }) => {
      return await client.myProAccounts.myProAccountsUpdatePartnerCustomer(
        partnerAccountId as string,
        vars.partnerCustomerId,
        {
          firstName: vars.cust.firstName,
          lastName: vars.cust.lastName,
          email: vars.cust.email,
          phoneNumber: vars.cust.phoneNumber,
        }
      );
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['partnerCustomer'] });

      addToast({
        toastType: 'Success',
        message: t('customerDetails.editCustomerSaved'),
      });
      closeModal();
    },
  });

  const onSubmit = async values => {
    const countryCodeIsTheOnlyInput =
      values?.phoneNumber &&
      `+${checkPhoneNumberForCountryCode(values?.phoneNumber).toString()}` ===
        values?.phoneNumber;

    editCustomer.mutate({
      partnerCustomerId: initialValues?.id,
      cust: {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phoneNumber: countryCodeIsTheOnlyInput ? '' : values?.phoneNumber,
      },
    });
  };

  return (
    <CustomerInfoForm
      {...{
        closeModal,
        onSubmit,
        isEditMode: true,
        initialValues: {
          ...initialValues,
          phoneNumber: getInternationalPhone(initialValues.phoneNumber),
        },
      }}
    />
  );
};

export default EditCustomerForm;
