import { Flex } from '@resideo/blueprint-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AlertsTab } from '../types';

const Container = styled.div`
  width: 551px;
  height: 100px;
  box-sizing: border-box;
  text-align: left;
  background: #ffffff;
  color: #303030;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Heading = styled.div`
  height: 38px;
  box-sizing: border-box;
  background-color: #153755;
  color: white;
  border-radius: 10px 10px 0 0;
  padding: 10px 20px;
`;

const HeaderText = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 700;
`;

const Content = styled(Flex)`
  padding: 10px 20px;
  justify-content: space-between;
`;

const ActivityContainer = styled(Flex)`
  width: 120px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const NumberContainer = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const WordWrapContainer = styled.div`
  white-space: normal;
  word-wrap: break-word;
  font-size: 12px;
`;

const RecentActivity = ({
  firstColumnCount,
  secondColumnCount,
  thirdColumnCount,
  tab,
}: {
  firstColumnCount: number;
  secondColumnCount: number;
  thirdColumnCount: number;
  tab: AlertsTab;
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Heading>
        <HeaderText>{t('alertsDashboard.recentActivity')}</HeaderText>
      </Heading>
      <Content>
        {tab === AlertsTab.Active ? (
          <>
            <ActivityContainer>
              <NumberContainer>{firstColumnCount}</NumberContainer>
              <WordWrapContainer>
                {t('alertsDashboard.flaggedLast24Hours')}
              </WordWrapContainer>
            </ActivityContainer>
            <ActivityContainer>
              <NumberContainer>{secondColumnCount}</NumberContainer>
              <WordWrapContainer>
                {t('alertsDashboard.dismissedLast24Hours')}
              </WordWrapContainer>
            </ActivityContainer>
            <ActivityContainer>
              <NumberContainer>{thirdColumnCount}</NumberContainer>
              <WordWrapContainer>
                {t('alertsDashboard.dismissedLast7Days')}
              </WordWrapContainer>
            </ActivityContainer>
          </>
        ) : null}
        {tab === AlertsTab.Flagged ? (
          <>
            <ActivityContainer>
              <NumberContainer>{firstColumnCount}</NumberContainer>
              <WordWrapContainer>
                {t('alertsDashboard.totalFlagged')}
              </WordWrapContainer>
            </ActivityContainer>
            <ActivityContainer>
              <NumberContainer>{secondColumnCount}</NumberContainer>
              <WordWrapContainer>
                {t('alertsDashboard.flaggedLast24Hours')}
              </WordWrapContainer>
            </ActivityContainer>
            <ActivityContainer>
              <NumberContainer>{thirdColumnCount}</NumberContainer>
              <WordWrapContainer>
                {t('alertsDashboard.flaggedLast7Days')}
              </WordWrapContainer>
            </ActivityContainer>
          </>
        ) : null}
        {tab === AlertsTab.Dismissed ? (
          <>
            <ActivityContainer>
              <NumberContainer>{firstColumnCount}</NumberContainer>
              <WordWrapContainer>
                {t('alertsDashboard.totalDismissed')}
              </WordWrapContainer>
            </ActivityContainer>
            <ActivityContainer>
              <NumberContainer>{secondColumnCount}</NumberContainer>
              <WordWrapContainer>
                {t('alertsDashboard.dismissedLast24Hours')}
              </WordWrapContainer>
            </ActivityContainer>
            <ActivityContainer>
              <NumberContainer>{thirdColumnCount}</NumberContainer>
              <WordWrapContainer>
                {t('alertsDashboard.dismissedLast7Days')}
              </WordWrapContainer>
            </ActivityContainer>
          </>
        ) : null}
      </Content>
    </Container>
  );
};

export default RecentActivity;
