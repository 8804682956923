// TODO: We need move this button link component to blue print
import styled from 'styled-components';
import { Link } from '@resideo/blueprint-react';

const ButtonLink = styled(Link)`
  margin-bottom: 3%;
  &:hover {
    background-color: transparent;
    border-color: transparent;
  }
`;

export default ButtonLink;
