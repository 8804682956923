import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex } from '@resideo/blueprint-react';
import HeaderLogo from './HeaderLogo';

const StyledHeader = styled.header`
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.nav.background};
  box-shadow: 0 2px 10px #0000003a;
  font-weight: bold;
  z-index: var(--zidx-header);
`;

const FixedHeader = styled.div`
  width: 100%;
  position: fixed;
  top: 0px;
  background-color: ${({ theme }) => theme.colors.nav.background};
`;

const DummyHeader = styled.div`
  width: 100%;
  height: 3.5rem;
`;

const PrimaryNavOuter = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.nav.background};
  @media (max-width: ${({ theme }) => theme.breakpoints.xLarge}) {
    height: auto;
  }
`;

const StyledHeaderLogo = styled(HeaderLogo)`
  margin-left: 3px;
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-left: 2rem;
  }
`;

export const AnonymousHeader: FC = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyledHeader data-test-header>
      <FixedHeader>
        <PrimaryNavOuter aria-label={t('title')}>
          <Flex
            flex={1}
            as='section'
            flexWrap='wrap'
            flexDirection='row'
            alignItems='center'
            justifyContent={['space-between']}>
            <StyledHeaderLogo />
          </Flex>
        </PrimaryNavOuter>
      </FixedHeader>
      <DummyHeader />
    </StyledHeader>
  );
};
