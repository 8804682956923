import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from '@tanstack/react-query';
import { AlertListModel } from '@resideo/web-integration-services-api-client';
import { TableV3 } from '@resideo/blueprint-react';
import { useCurrentUser } from 'context/CurrentUser';
import { useRisClient } from 'hooks/useRisClient';
import LoadMore from './LoadMore';
import { DeviceAlertSeverity } from '../types';
import { Filter } from '../components/SeverityFilter';
import getMultiSelectActions from './getMultiSelectActions';

const getFilterBy = (filter: any, severityFilter: Filter) => {
  const { urgent, warning, notice } = severityFilter;
  if (urgent && warning && notice) return [filter];
  if (!(urgent || warning || notice)) return [filter];

  const filterBy: any = [];
  if (urgent)
    filterBy.push({ ...filter, severity: DeviceAlertSeverity.URGENT });
  if (warning)
    filterBy.push({ ...filter, severity: DeviceAlertSeverity.WARNING });
  if (notice)
    filterBy.push({ ...filter, severity: DeviceAlertSeverity.NOTICE });

  return filterBy;
};

const AlertsTable = ({
  columns,
  filterBy,
  severityFilter,
  orderBy,
  setOrderBy,
  tab,
}) => {
  const { client } = useRisClient();
  const { partnerAccountId } = useCurrentUser();
  const { i18n } = useTranslation();
  const { id, desc } = orderBy[0];
  const filter = getFilterBy(filterBy, severityFilter);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ['alerts', filter, orderBy],
    queryFn: async ({ pageParam }) => {
      return client.myProAccounts.myProAccountsSearchAlerts(
        partnerAccountId as string,
        i18n.language,
        JSON.stringify(filter),
        JSON.stringify({ [id]: desc ? 'DESC' : 'ASC' }),
        10,
        undefined,
        undefined,
        pageParam ? pageParam : undefined
      );
    },
    initialPageParam: '',
    getNextPageParam: lastPage => {
      if (lastPage?.pageInfo?.hasNextPage) {
        return lastPage.pageInfo.endCursor;
      }
      return undefined;
    },
  });

  const flatData = data?.pages.flatMap(page => page.data);

  return (
    <div>
      <TableV3<AlertListModel>
        columns={columns}
        data={flatData?.length ? (flatData as AlertListModel[]) : []}
        config={{
          getRowId: row => row.id || '',
          sorting: orderBy,
          setSorting: setOrderBy,
          enableRowSelection: true,
          renderBefore: getMultiSelectActions(tab),
        }}
      />
      <LoadMore
        loadMore={() => {
          if (hasNextPage) {
            fetchNextPage();
          }
        }}
        loading={status === 'pending' || isFetchingNextPage}
      />
    </div>
  );
};

export default AlertsTable;
