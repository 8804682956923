import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TableV2 } from '@resideo/blueprint-react';
import { useCurrentUser } from 'context/CurrentUser';
import { useFeatureFlags } from '../../../context/FeatureFlags';
import { IModalProps, useModalContext } from 'context/ModalProvider';
import RequestedAccessModalContent from './RequestedAccessModalContent';
import DeclineAccessModalContent from './DeclineAccessModalContent';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';
import { InviteModel } from '@resideo/web-integration-services-api-client';

const RequestedList = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [requestedUsers, setRequestedUsers] = useState<InviteModel[]>([]);
  const { partnerAccountId } = useCurrentUser();
  const { TABLE_RESULTS_PER_PAGE } = useFeatureFlags();
  const rowsPerPage = TABLE_RESULTS_PER_PAGE;
  const { openModal, closeModal } = useModalContext();
  const { client } = useRisClient();

  const { data, error } = useSuspenseQuery({
    queryKey: ['getRequested'],
    queryFn: async () => {
      if (partnerAccountId) {
        const res = await client.myProAccounts.myProAccountsGetAddRequests(
          partnerAccountId as string
        );
        return res?.data;
      } else {
        return null;
      }
    },
  });

  useEffect(() => {
    if (data) {
      // filter out rejected users so they don't show up in the table
      const user = data
        ?.filter(requestedUser => requestedUser?.status !== 'REJECTED')
        .map(requestedUser => {
          return requestedUser;
        });
      setRequestedUsers(user);
    }
  }, [data]);

  const getRows = () =>
    requestedUsers
      ?.filter(requestedUser => requestedUser?.status !== 'ACCEPTED')
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(requestedUser => {
        return {
          id: requestedUser?.id,
          name: `${requestedUser?.user?.lastName}, ${requestedUser?.user?.firstName}`,
          email: requestedUser?.sendToEmail,
          status: t(`common.statuses.PENDING`),
          actions: [
            {
              name: t('common.approve'),
              ctaTag: 'approve',
              text: t('common.approve'),
              action: () => {
                openModal({
                  title: t('mybusiness.requested.approveAccessModalTitle'),
                  children: (
                    <RequestedAccessModalContent
                      closeModal={closeModal}
                      id={requestedUser?.id}
                      firstName={requestedUser?.user?.firstName}
                      lastName={requestedUser?.user?.lastName}
                      contactEmail={requestedUser?.user?.contactEmail}
                      templateLanguage={requestedUser?.user?.language || 'en'}
                    />
                  ),
                } as IModalProps);
              },
            },
            {
              name: t('common.decline'),
              ctaTag: 'decline',
              text: t('common.decline'),
              action: () => {
                openModal({
                  title: t('mybusiness.requested.declineAccessModalTitle'),
                  children: (
                    <DeclineAccessModalContent
                      closeModal={closeModal}
                      id={requestedUser?.id}
                      name={`${requestedUser?.user?.firstName} ${requestedUser?.user?.lastName}`}
                    />
                  ),
                } as IModalProps);
              },
            },
          ],
        };
      });

  const pageInfo = {
    hasNextPage: page * rowsPerPage + rowsPerPage < requestedUsers.length,
    hasPreviousPage: page !== 0,
  };

  return (
    <div>
      {requestedUsers && (
        <TableV2
          hasPagination
          NoDataMessage={t('mybusiness.requested.noEmployees')}
          hasNext={pageInfo?.hasNextPage}
          hasPrevious={pageInfo?.hasPreviousPage}
          nextButtonLabel={t('common.next')}
          previousButtonLabel={t('common.previous')}
          onPaginationNextClick={() => {
            setPage(page + 1);
          }}
          onPaginationPrevClick={() => {
            setPage(page - 1);
          }}
          headers={[
            { isHiddenColumn: true, displayName: 'id', isSortable: false },
            {
              displayName: t('mybusiness.employees.name'),
              isSortable: false,
              useEllipses: true,
            },
            {
              displayName: t('common.emailShort'),
              isSortable: false,
              notBlueprintEllipses: true,
            },
            {
              displayName: 'Status',
              isSortable: false,
              notBlueprintEllipses: true,
            },
            { displayName: '', isSortable: false },
          ]}
          rows={getRows()}
          isError={
            error
              ? {
                  errorHeader: t('common.tableErrors.header'),
                  errorMessage: t('common.tableErrors.text'),
                }
              : undefined
          }
        />
      )}
    </div>
  );
};

export default RequestedList;
