import React from 'react';
import { Titled } from 'components/common/Titled';
import { useTranslation } from 'react-i18next';
import { Flex, Text, Box, Card } from '@resideo/blueprint-react';
import { AnonymousHeader } from 'components/header/AnonymousHeader';

const InviteExpired = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Titled title={t('user.signUp.inviteExpired.title')}>
      <AnonymousHeader />
      <Card
        height='100%'
        padding={['medium', 'large']}
        data-test-invite-expired-page>
        <Flex
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          marginBottom='medium'>
          <Flex marginBottom='2rem'>
            <Box>
              <h1>{t('user.signUp.inviteExpired.title')}</h1>
              <Text>{t('user.signUp.inviteExpired.body')}</Text>
            </Box>
          </Flex>
        </Flex>
      </Card>
    </Titled>
  );
};

export default InviteExpired;
