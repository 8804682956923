import React, { FC } from 'react';
import { Titled } from 'components/common/Titled';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import {
  Card,
  Flex,
  Heading,
  Text,
  RectangularButton,
} from '@resideo/blueprint-react';

import ResideoProLogo from 'components/logo/resideo-pro-logo.svg?react';

const BackLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
`;

const Border = styled(Flex)`
  min-width: 100%;
  border: 0.5px solid black;
  margin-bottom: ${({ theme }) => theme.space.large};
  margin-top: ${({ theme }) => theme.space.large};
`;

const GetStartedHeading = styled(Heading)`
  text-transform: uppercase;
`;

const PanelsContainer = styled(Flex)`
  justify-content: space-between;
`;

const PanelParagraph = styled.p`
  margin-bottom: ${({ theme }) => theme.space.large};
  margin-top: ${({ theme }) => theme.space.large};
`;

const RolePanel = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.separator};
  padding: ${({ theme }) => theme.space.large};
`;

const RoleHeading = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.xLarge};
  font-weight: ${({ theme }) => theme.fontWeights.heading};
  letter-spacing: 1.5px;
  line-height: ${({ theme }) => theme.lineHeights.heading};
  text-transform: uppercase;
`;

const StyledResideoProLogo = styled(ResideoProLogo)`
  fill: 'black';
  height: 28px;
`;

const GettingStarted: FC = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Titled title={t('accessRequest.gettingStarted.title')}>
      <Card
        borderRadius={[0, 0]}
        height={['auto', '100%']}
        padding={['medium', 'large']}
        marginBottom='large'
        data-test-get-started>
        <Flex flexDirection='column' alignItems='center'>
          <Flex justifyContent='center'>
            <StyledResideoProLogo />
          </Flex>
          <Flex>
            <GetStartedHeading
              fontSize='xxxLarge'
              marginBottom='large'
              paddingTop='large'
              data-test-get-started-header>
              {t('accessRequest.gettingStarted.title')}
            </GetStartedHeading>
          </Flex>
          <PanelsContainer
            flexDirection={['column', 'row']}
            marginBottom={['medium', 'xLarge']}
            width={[1, 1 / 2]}>
            <RolePanel
              flex='1'
              flexDirection='column'
              alignItems='center'
              marginBottom={['medium', 0]}
              marginRight={[0, 'medium']}>
              <RoleHeading data-test-get-started-owner-section>
                {t('accessRequest.gettingStarted.ownerAdmin')}
              </RoleHeading>
              <PanelParagraph>
                {t('accessRequest.gettingStarted.ownerAdminInfo')}
              </PanelParagraph>
              <NavLink to={`/access/request/form?by=owner`}>
                <RectangularButton
                  variant='primary'
                  data-test-get-started-owner-button>
                  {t('accessRequest.gettingStarted.button')}
                </RectangularButton>
              </NavLink>
              <Border />
              <RoleHeading data-test-get-started-employee-section>
                {t('accessRequest.gettingStarted.employee')}
              </RoleHeading>
              <PanelParagraph>
                {t('accessRequest.gettingStarted.employeeInfo')}
              </PanelParagraph>
            </RolePanel>
            <RolePanel
              flex='1'
              flexDirection='column'
              alignItems='center'
              marginLeft={[0, 'medium']}>
              <RoleHeading data-test-get-started-distributor-section>
                {t('accessRequest.gettingStarted.distributor')}
              </RoleHeading>
              <PanelParagraph>
                {t('accessRequest.gettingStarted.distributorInfo')}
              </PanelParagraph>
              <NavLink to={`/access/request/form?by=distributor`}>
                <RectangularButton
                  variant='primary'
                  type='button'
                  data-test-get-started-distributor-button>
                  {t('accessRequest.gettingStarted.button')}
                </RectangularButton>
              </NavLink>
            </RolePanel>
          </PanelsContainer>
          <Flex justifyContent='center' marginBottom={['medium', 0]}>
            <BackLink
              data-test-back-link
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30117}
              to={'/sign-out'}>
              {t('accessRequest.gettingStarted.backToSignIn')}
            </BackLink>
          </Flex>
        </Flex>
      </Card>
    </Titled>
  );
};

export default GettingStarted;
