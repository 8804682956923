import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button } from '@resideo/blueprint-react';
import {
  ButtonContainer,
  ModalContent,
  StyledWarningIcon,
} from '../../../customers.style';
import { styled } from 'styled-components';

const ModalHeader = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const StrongText = styled(Text)`
  font-weight: bold;
  font-size: 21px;
  padding-top: 10px;
  margin-left: 13px;
`;

const ModalText = styled(Text)`
  font-size: 14px;
`;

export const ActivateServiceErrorModal = ({ closeModal, deviceId }) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <ModalHeader>
        <StyledWarningIcon />
        <StrongText
          data-test-activate-service-error-header
          marginBottom='small'>
          {t('customerDetails.activateService.errorHeader')}
        </StrongText>
      </ModalHeader>
      <ModalText>{`${deviceId} ${t(
        'customerDetails.activateService.errorDeviceText'
      )}`}</ModalText>
      <ButtonContainer>
        <Button variant='primary' onClick={closeModal}>
          {t('common.ok')}
        </Button>
      </ButtonContainer>
    </ModalContent>
  );
};
