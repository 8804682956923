/**
 * This file needs to be used when Integrating Resend Consent GraphQL Service
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button } from '@resideo/blueprint-react';
import { ButtonContainer, ModalContent } from '../../../customers.style';

export const ResendConsentServiceModal = ({
  onCancel,
  onSubmit,
  serviceName,
}) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <Text marginBottom='large'>
        {t('customerDetails.resendConsent.text')}
      </Text>
      <Text>
        {t('customerDetails.resendConsent.subject')} {serviceName}{' '}
        {t('customerDetails.resendConsent.subject1')}
      </Text>
      <ButtonContainer>
        <Button variant='secondary' onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button variant='primary' onClick={onSubmit}>
          {t('customerDetails.resendConsent.consent')}
        </Button>
      </ButtonContainer>
    </ModalContent>
  );
};
