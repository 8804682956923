import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'components/common/Page';
import { getInternationalPhone } from 'utils/common';
import { Titled } from 'components/common/Titled';
import { convertSchemaAddress } from 'components/common/Address';
import { useRisClient } from 'hooks/useRisClient';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useToastContext,
} from '@resideo/blueprint-react';
import styled from 'styled-components';
import { FaPen } from 'react-icons/fa';
import { useModalContext } from 'context/ModalProvider';
import ContactInfoModal from 'components/company/ContactInfoModal';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from 'config';
import { UpdatePartnerAccountModel } from '@resideo/web-integration-services-api-client/dist';

const PageContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: 1px;
  scrollbar-color: #fff #fff;
  display: relative;
`;

const Header = styled.div`
  background-color: #ffffff !important;
  position: sticky !important;
  top: 0;
  z-index: 2;
`;

const HeadingContainer = styled(Flex)`
  display: -webkit-box;
  min-width: 0;
  padding: 30px 45px 45px 45px;
  width: 100%;
  box-shadow: 0 4px 2px -1px #f4f4f4;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 5px 20px 30px 20px;
  }
`;

const SubHeadingContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 10px;
`;

const PenIcon = styled(FaPen)`
  margin-right: 8px;
`;

const ButtonStyles = styled(Button)`
  min-width: 0;
`;

const Border = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  overflow: auto;
  margin-left: -15px;
  margin-right: -15px;
`;

const CompanyProfileDetails = styled(Box)`
  padding-top: 20px;
`;

const CompanyCategory = styled(Text)`
  font-weight: bold;
  margin-bottom: 5px;
`;

const CompanyDetails = styled(Text)`
  margin-bottom: 20px;
`;

const AddressContainer = styled(Box)`
  margin-bottom: 20px;
`;

const AddressText = styled(Text)`
  line-height: 1.2;
`;

const libraries: Libraries = ['places', 'marker', 'maps'];

const ContactInfo: FC = () => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModalContext();
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const { addToast } = useToastContext();
  const { data } = useSuspenseQuery({
    queryKey: ['viewer'],
    queryFn: async () =>
      (await client.myProUsers.myProUsersGetMyProUser()).data,
  });

  const partnerAccount = data?.partnerUsers?.[0]?.partnerAccount;
  const partnerAccountId = data?.partnerUsers?.[0]?.partnerAccount?.id;

  const values = {
    companyName: partnerAccount?.name,
    companyPhone: getInternationalPhone(partnerAccount?.primaryPhoneNumber),
    ...convertSchemaAddress(partnerAccount?.shipToAddress ?? {}),
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  const editCompanyProfile = useMutation({
    mutationKey: ['editCompanyProfile'],
    mutationFn: async (vars: {
      partnerAccountId: string;
      company: UpdatePartnerAccountModel;
    }) => {
      await client.myProAccounts.myProAccountsUpdateMyPartnerAccount(
        partnerAccountId as string,
        {
          name: vars?.company?.name,
          shipToAddress: {
            addressLine1: vars?.company?.shipToAddress?.addressLine1,
            addressLine2: vars?.company?.shipToAddress?.addressLine2,
            city: vars?.company?.shipToAddress?.city,
            stateProvinceRegionCode:
              vars?.company?.shipToAddress?.stateProvinceRegionCode,
            zipPostalCode: vars?.company?.shipToAddress?.zipPostalCode,
            countryCode: vars?.company?.shipToAddress?.countryCode,
          },
          primaryPhoneNumber: vars?.company?.primaryPhoneNumber,
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['viewer'],
      });
      addToast({
        toastType: 'Success',
        message: t('company.profile.changesSaved'),
      });
      closeModal();
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
    },
  });

  const handleUpdateCompany = values => {
    editCompanyProfile.mutate({
      partnerAccountId: partnerAccountId ?? '',
      company: {
        name: values?.companyName,
        shipToAddress: {
          addressLine1: values?.address1,
          addressLine2: values?.address2,
          city: values?.city,
          stateProvinceRegionCode: values?.state,
          zipPostalCode: values?.zip,
          countryCode: values?.country,
        },
        primaryPhoneNumber: values?.companyPhone ?? '',
      },
    });
  };

  return (
    <PageContainer>
      <Titled title={t('company.profile.companyInformation')}>
        <Header>
          <HeadingContainer>
            <Box>
              <Heading as='h1' fontSize='30px' paddingTop='10px' data-test-name>
                {t('company.profile.companyInformation')}
              </Heading>
            </Box>
          </HeadingContainer>
        </Header>
        <Page
          as='main'
          paddingY='0'
          paddingX='medium'
          marginLeft={['small', 'large']}
          marginRight={['small', 'large']}
          maxWidth='100%'
          height='100%'
          data-test-company-contact-info>
          <SubHeadingContainer>
            <Heading
              data-test-company-contact-info-header
              as='h2'
              fontSize='xLarge'>
              {t('company.profile.header')}
            </Heading>
            <ButtonStyles
              data-test-edit-btn
              variant='tertiary'
              onClick={() => {
                openModal({
                  title: t('company.profile.header'),
                  children: (
                    <ContactInfoModal
                      initialValues={{
                        companyName: values?.companyName ?? '',

                        address1: values?.address1 ?? '',
                        address2: values?.address2 ?? '',
                        city: values?.city,
                        state: values?.state ?? '',
                        zip: values?.zip ?? '',
                        country: values?.country ?? '',

                        companyPhone: values?.companyPhone ?? '',
                      }}
                      onSubmit={values => handleUpdateCompany(values)}
                      onCancel={() => closeModal()}
                      isLoaded={isLoaded}
                      loadError={loadError}
                    />
                  ),
                  outsideClick: true,
                });
              }}>
              <PenIcon />
              {t('common.edit')}
            </ButtonStyles>
          </SubHeadingContainer>
          <Border />

          <CompanyProfileDetails>
            <CompanyCategory data-test-company-name-label>
              {t('company.profile.name')}
            </CompanyCategory>
            <CompanyDetails data-test-company-name>
              {values.companyName}
            </CompanyDetails>

            <CompanyCategory data-test-company-address-label>
              {t('company.profile.address')}
            </CompanyCategory>
            <AddressContainer>
              <AddressText data-test-company-address1>
                {values.address1}
              </AddressText>
              {values.address2 && (
                <AddressText data-test-company-address2>
                  {values.address2}
                </AddressText>
              )}
              <AddressText>
                <span data-test-company-city> {`${values?.city},`}</span>
                <span data-test-company-state> {values?.state}</span>
                <span data-test-company-zip> {values?.zip}</span>
              </AddressText>
              <AddressText data-test-company-country>
                {t(`common.countries.${values.country}`)}
              </AddressText>
            </AddressContainer>

            <CompanyCategory data-test-company-phone-label>
              {t('company.profile.phone')}
            </CompanyCategory>
            <CompanyDetails data-test-company-phone>
              {values.companyPhone}
            </CompanyDetails>
          </CompanyProfileDetails>
        </Page>
      </Titled>
    </PageContainer>
  );
};

export default ContactInfo;
