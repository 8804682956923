export const EMEA = [
  'ae',
  'at',
  'ax',
  'az',
  'ba',
  'be',
  'bf',
  'bg',
  'bh',
  'bi',
  'bj',
  'bl',
  'bv',
  'by',
  'cd',
  'cf',
  'cg',
  'ch',
  'ci',
  'ck',
  'cm',
  'cv',
  'cy',
  'cz',
  'de',
  'dj',
  'dk',
  'dz',
  'ee',
  'eg',
  'eh',
  'er',
  'es',
  'et',
  'fi',
  'fo',
  'fr',
  'ga',
  'gb',
  'ge',
  'gf',
  'gg',
  'gh',
  'gi',
  'gl',
  'gm',
  'gn',
  'gp',
  'gq',
  'gr',
  'gw',
  'hm',
  'hr',
  'hu',
  'ie',
  'il',
  'im',
  'iq',
  'ir',
  'is',
  'it',
  'je',
  'jo',
  'ke',
  'kg',
  'km',
  'kw',
  'kz',
  'lb',
  'li',
  'lr',
  'ls',
  'lt',
  'lu',
  'lv',
  'ly',
  'ma',
  'mc',
  'md',
  'me',
  'mf',
  'mg',
  'mk',
  'ml',
  'mq',
  'mr',
  'mt',
  'mu',
  'mw',
  'mz',
  'na',
  'nc',
  'ne',
  'ng',
  'nl',
  'no',
  'om',
  'pf',
  'pl',
  'pm',
  'ps',
  'pt',
  'qa',
  're',
  'ro',
  'rs',
  'ru',
  'rw',
  'sa',
  'sc',
  'sd',
  'se',
  'sh',
  'si',
  'sk',
  'sl',
  'sm',
  'sn',
  'so',
  'ss',
  'st',
  'sx',
  'sy',
  'sz',
  'td',
  'tf',
  'tg',
  'tj',
  'tm',
  'tn',
  'tr',
  'tz',
  'ua',
  'ug',
  'uz',
  'va',
  'wf',
  'ye',
  'yt',
  'za',
  'zm',
  'zw',
];

const NA_REGIONS = [
  'AR',
  'AU',
  'AW',
  'BB',
  'BR',
  'BM',
  'BO',
  'CA',
  'CL',
  'CN',
  'CO',
  'CR',
  'CW',
  'DO',
  'EC',
  'GT',
  'GU',
  'HN',
  'IN',
  'JM',
  'JP',
  'KR',
  'KY',
  'MX',
  'MY',
  'NI',
  'NZ',
  'PA',
  'PE',
  'PR',
  'PY',
  'SG',
  'SR',
  'SV',
  'TT',
  'UM',
  'US',
  'UY',
  'VG',
  'VI',
];

export const getRegion = country => {
  const NARegions = NA_REGIONS;
  return NARegions.includes(country) ? 'NA' : 'EMEA';
};

export const isEMEA = countryCode => EMEA.includes(countryCode.toLowerCase());
