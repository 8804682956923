import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from 'context/CurrentUser';
import DefaultNotificationComponent from './DefaultNotificationComponent';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';
import {
  InviteModel,
  InviteStatus,
} from '@resideo/web-integration-services-api-client';

const RequestAccessNotification = ({ requestedUser }) => {
  const { t } = useTranslation();

  return (
    <DefaultNotificationComponent
      link='/mybusiness/company/employees/requested'
      content={
        <>
          <strong>{`${requestedUser?.user?.lastName}, ${requestedUser?.user?.firstName} `}</strong>
          {t('mybusiness.requested.requestedAccessToCompany')}
        </>
      }
      date={requestedUser?.createdAt || ''}
      data-test-request-access-notifications
    />
  );
};

export const useRequestAccessNotifications = () => {
  const [requestedUsers, setRequestedUsers] = useState<InviteModel[]>([]);
  const { partnerAccountId, isOwnerAdmin } = useCurrentUser();
  const { client } = useRisClient();

  const { data, error } = useSuspenseQuery({
    queryKey: ['getRequested'],
    queryFn: async () => {
      if (partnerAccountId && isOwnerAdmin) {
        const res = await client.myProAccounts.myProAccountsGetAddRequests(
          partnerAccountId as string
        );
        return res?.data;
      } else {
        return null;
      }
    },
  });

  useEffect(() => {
    if (data && !error) {
      setRequestedUsers(data as any[]);
    }
  }, [data, error]);

  return requestedUsers
    .filter(requestedUser =>
      [InviteStatus.PENDING, InviteStatus.REQUESTED].includes(
        requestedUser?.status as InviteStatus
      )
    )
    .map(requestedUser => ({
      data: requestedUser,
      component: <RequestAccessNotification requestedUser={requestedUser} />,
    }));
};
