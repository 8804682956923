import React from 'react';
import { Titled } from 'components/common/Titled';
import { useTranslation } from 'react-i18next';
import { Flex, Text, Box, Card, Heading } from '@resideo/blueprint-react';
import { AnonymousHeader } from 'components/header/AnonymousHeader';

const InviteError = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Titled title={t('404.header')}>
      <AnonymousHeader />
      <Card
        height='100%'
        padding={['medium', 'large']}
        data-test-invite-expired-page>
        <Flex
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          marginBottom='medium'>
          <Flex marginBottom='2rem'>
            <Box>
              <Heading as='h1' fontSize='xxLarge' marginBottom='medium'>
                {t('404.header')}
              </Heading>
              <Text fontSize='medium' data-test-page-not-found-desc>
                {t('404.description')}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Card>
    </Titled>
  );
};

export default InviteError;
