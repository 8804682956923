import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertsTab } from './types';
import SeverityFilter, { Filter } from './components/SeverityFilter';
import RecentActivity from './components/RecentActivity';
import AlertsTable from './AlertsList/AlertsTable';
import {
  alertColumn,
  customerColumn,
  severityColumn,
  actionsColumn,
  dateColumn,
} from './AlertsList/TableColumns';
import {
  ControlsContainer,
  AlertsHeader,
} from './components/DashboardComponents';
import { useAlertCounts } from './useAlertCounts';
import { SearchField } from '@resideo/blueprint-react';
import styled from 'styled-components';

const SearchContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  width: 430px;
`;

const NewSearchField = styled(SearchField)`
  border-radius: 6px;
  &:hover {
    background: #ddebff;
  }
`;

const flaggedAlertsTableColumns = [
  dateColumn,
  severityColumn,
  alertColumn(AlertsTab.Flagged),
  customerColumn(AlertsTab.Flagged),
  actionsColumn(AlertsTab.Flagged),
];

const Flagged = () => {
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState([{ id: 'createdAt', desc: true }]);
  const [severityFilter, setSeverityFilter] = useState<Filter>({
    urgent: true,
    warning: true,
    notice: true,
  });

  const { data } = useAlertCounts();
  const flaggedAlerts = data?.flaggedAlerts || {};
  const {
    urgentCount = 0,
    warningCount = 0,
    noticeCount = 0,
    totalCount = 0,
    last24HoursCount = 0,
    last7DaysCount = 0,
  } = flaggedAlerts;

  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('');

  return (
    <>
      <AlertsHeader tooltip={t('alertsDashboard.flaggedAlertsHeaderTooltip')}>
        {t('alertsDashboard.flaggedAlertsHeader')}
      </AlertsHeader>
      <ControlsContainer>
        <SeverityFilter
          messagesCount={{
            urgent: urgentCount,
            warning: warningCount,
            notice: noticeCount,
          }}
          filter={severityFilter}
          onChange={setSeverityFilter}
        />
        <RecentActivity
          firstColumnCount={totalCount}
          secondColumnCount={last24HoursCount}
          thirdColumnCount={last7DaysCount}
          tab={AlertsTab.Flagged}
        />
      </ControlsContainer>
      <SearchContainer>
        <NewSearchField
          type='text'
          name='search'
          id='search'
          value={search}
          onChange={e => {
            e.preventDefault();
            setSearch(e.target.value);
          }}
          placeholder={t('common.search')}
          onSearchClick={() => {
            setFilter(search);
          }}
          onClearClick={() => {
            setSearch('');
            setFilter('');
          }}
        />
      </SearchContainer>
      <AlertsTable
        columns={flaggedAlertsTableColumns}
        filterBy={{ flagged: 'true', 'customer.*': filter }}
        severityFilter={severityFilter}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        tab={AlertsTab.Flagged}
      />
    </>
  );
};

export default Flagged;
