import React, { ReactNode, FC } from 'react';
import styled, { css } from 'styled-components';
import { Box, Flex, Text } from '@resideo/blueprint-react';
import { NavLink as RRDNavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const linkStyle = css`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
`;

const HtmlLink = styled.a`
  ${linkStyle}
`;

const NavLink = styled(RRDNavLink)`
  ${linkStyle}
`;

export type CustomBlocksType = (existingBlocks: ReactNode[]) => ReactNode[];

export interface SharedNoticeLayoutFooterProps {
  /**
   * This prop should be a function that receives the default
   * footer links and allow you to override them or merge
   * the default ones with the ones you're adding
   *
   * @param existingBlocks default links
   *
   * @returns ReactNode array with all the links you want to show
   *
   * @example
   * This is how you use it
   * ```ts
   * const { Text, NavLink, HtmlLink } = NoticeLayoutFooter;
   * // ...
   * // ...
   * // ...
   * <NoticeLayout
   *   customFooterBlocks={(existing) => [
   *     <NavLink key='CustomBlock1' to='/'>
   *       This link goes inside the same app
   *     </NavLink>,
   *     <Text key='CustomBlock2'>
   *       This block contains text and{' '}
   *       <HtmlLink href='https://example.com'>
   *         a link to other website
   *       </HtmlLink>
   *     </Text>
   *     ...existing, // merging the existing links with the new ones
   *   ]}>
   *   // ...
   * ```
   * @see {@link http://example.com/@internal | the @internal tag}
   */
  customFooterBlocks?: CustomBlocksType;
}

type NoticeLayoutFooterProps = FC<SharedNoticeLayoutFooterProps> & {
  HtmlLink: FC<any>;
  NavLink: FC<any>;
  Text: FC<any>;
};

const NoticeLayoutFooter: NoticeLayoutFooterProps = ({
  customFooterBlocks,
}) => {
  const { t } = useTranslation();

  const defaultBlocks = [
    <Text key='link-sign-in' textAlign='center'>
      <NavLink data-test-sign-in-link to='/'>
        {t('common.backToSignIn')}
      </NavLink>
    </Text>,
    // Todo: Hide until link is live
    // <HtmlLink
    //   key='link-about-rid'
    //   data-test-about-link
    //   href='https://resideo.com/aboutresideoid'
    //   target='_blank'
    //   rel='noreferrer'>
    //   {t('user.signUp.aboutResideoId')}
    // </HtmlLink>,
  ];

  const renderBlocks = blocks => {
    return blocks.map((block, idx) => (
      <Flex key={`Block-${idx}`} justifyContent='center' marginBottom={8}>
        {block}
      </Flex>
    ));
  };

  return (
    <Box justifyContent='center' marginBottom={['medium', 0]}>
      {customFooterBlocks
        ? renderBlocks(customFooterBlocks(defaultBlocks))
        : renderBlocks(defaultBlocks)}
    </Box>
  );
};

NoticeLayoutFooter.HtmlLink = HtmlLink;
NoticeLayoutFooter.NavLink = NavLink;
NoticeLayoutFooter.Text = Text;

export default NoticeLayoutFooter;
