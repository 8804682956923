import React, { FC, useEffect } from 'react';
import { useAuth } from 'utils/auth';
import { Titled } from 'components/common/Titled';
import AppLayoutAnonymous from 'components/layouts/AppLayoutAnonymous';
import PageLoading from 'components/common/PageLoading';
import { useTranslation } from 'react-i18next';
import { deserializeInviteToken } from 'utils/common';

const AcceptInviteLanding: FC = () => {
  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth();
  const inviteToken = new URLSearchParams(location.search).get('invite');
  const { email } = deserializeInviteToken(inviteToken);

  useEffect(() => {
    // Redirect to login with special parameters, then back to accept-invite
    loginWithRedirect({
      isNewUser: true,
      email: email,
      appState: {
        returnTo: `/sign-up/accept-invite?invite=${inviteToken}`,
      },
    });
  }, []);

  return (
    <Titled title={t('user.signUp.termsConditions')}>
      <AppLayoutAnonymous showHeader={false}>
        <PageLoading />
      </AppLayoutAnonymous>
    </Titled>
  );
};

export default AcceptInviteLanding;
