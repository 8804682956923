import React from 'react';
import styled from 'styled-components';
import { Flex } from '@resideo/blueprint-react';

const StyledGrayPanel = styled(Flex)`
  max-width: 480px;
  background-color: ${({ theme }) => theme.colors.separator};
  padding: ${({ theme }) =>
    `3rem ${theme.space.large} ${theme.space.xLarge} ${theme.space.large}`};

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
  }
`;

const GrayPanel = ({ children, ...rest }) => (
  <StyledGrayPanel
    flex='1'
    flexDirection='column'
    alignItems='center'
    justifyContent='center'
    marginBottom={['medium', 0]}
    {...rest}>
    {children}
  </StyledGrayPanel>
);

export default GrayPanel;
