import React, { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'utils/auth';
import * as Sentry from '@sentry/react';
import PageError from 'components/common/PageError';
import PageLoading from 'components/common/PageLoading';
import { useLogin } from 'hooks/useLogin';
import useRedirect from 'hooks/useRedirect';
import { useFeatureFlags } from 'context/FeatureFlags';
import Error from 'routes/Error';

export const withAnonymousRoute = Component => (
  <Sentry.ErrorBoundary
    fallback={({ resetError }) => <PageError resetError={resetError} />}>
    <Suspense fallback={<PageLoading />}>
      <Component />
    </Suspense>
  </Sentry.ErrorBoundary>
);

export const withRoute = (Component, redirectCheck = true) => {
  const { isAuthenticated, isLoading } = useAuth();
  const redirect = redirectCheck ? useRedirect() : null;

  return (
    <>
      {isAuthenticated && !isLoading ? (
        withAnonymousRoute(Component)
      ) : (
        <PageLoading />
      )}
      {redirect && <Navigate to={redirect} replace />}
    </>
  );
};

export const withProtectedRoute = Component => {
  useLogin();

  return withRoute(Component, false);
};

export const withFlaggedRoute = (
  Component,
  flagName: string,
  fallback = <Error />
) => {
  const flag = useFeatureFlags()[flagName];

  return flag ? <Component /> : fallback;
};
