import React, { FC } from 'react';
import NotificationsComponent from './Notifications';
import { useRequestAccessNotifications } from './useRequestAccessNotifications';
import { useFeatureFlags } from 'context/FeatureFlags';

const Notifications: FC = () => {
  const { REQUESTED_ACCESS_FLAG } = useFeatureFlags();

  const requestAccessNotifications = REQUESTED_ACCESS_FLAG
    ? useRequestAccessNotifications()
    : [];
  const notificationsList = [
    ...requestAccessNotifications.map(({ component }) => component),
  ];

  return <NotificationsComponent notifications={notificationsList} />;
};

export default Notifications;
