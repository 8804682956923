import React, { useEffect, useState } from 'react';
import { Titled } from 'components/common/Titled';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Link,
  Text,
} from '@resideo/blueprint-react';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useAMSClient } from 'hooks/useAMSClient';
import { useCurrentUser } from 'context/CurrentUser';
import { useNavigate } from 'react-router-dom';
import { AMS_APPLICATION_ID } from 'config/flags';
import styled from 'styled-components';
import { getRegion } from 'config/regions';

const TitledContainer = styled(Titled)`
  background-color: white;
`;

const Page = styled.div`
  height: 100%;
  display: grid;
  background-color: white;
  padding-left: 100px;
  padding-top: 80px;
`;

const Container = styled(Card)`
  background-color: white;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const getProTermsAgreementId = (agreements: any[]) => {
  // 5 is pro terms agreement - https://resideo.atlassian.net/wiki/spaces/PCS/pages/3110934518/API+Specification#Enum-Values
  const proTermsAgreement = agreements?.find(
    agreement => agreement?.agreementType?.code === 5
  );

  return proTermsAgreement?.entityContext?.entityId;
};

const AgreementCheck = () => {
  const { t } = useTranslation();
  const { client } = useAMSClient();
  const {
    countryCode,
    locale,
    id,
    setIsPollingViewer,
    consentRequired,
  } = useCurrentUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [version, setVersion] = useState<string>('NA');
  const [navigateTo, setNavigateTo] = useState<string | null | undefined>();

  // Cleanup polling when component unmounts
  useEffect(() => {
    return () => {
      setIsPollingViewer(false);
    };
  }, []);

  useEffect(() => {
    if (countryCode) {
      const tempVersion = getRegion(countryCode);
      setVersion(tempVersion);
    }
  }, [countryCode, setVersion]);

  useEffect(() => {
    if (!consentRequired && navigateTo) {
      setIsPollingViewer(false);
      navigate(navigateTo);
    }
  }, [consentRequired, navigateTo, setIsPollingViewer, navigate]);

  const { data: agreements } = useSuspenseQuery({
    queryKey: ['getAgreements', { locale, countryCode }],
    queryFn: async () => {
      const res = await client.getLatestAgreements.userAgreementLatest(
        AMS_APPLICATION_ID,
        id as any,
        locale && countryCode
          ? `locale eq '${(locale as string).toLowerCase()}_${(countryCode as string).toLowerCase()}'`
          : `locale eq 'en_us'`
      );

      return res;
    },
  });

  const acceptAgreement = useMutation({
    mutationFn: async (vars: { acceptEntityId }) => {
      return client.consentTheAgreement.postUserAgreement(
        AMS_APPLICATION_ID,
        id as any,
        undefined,
        {
          consentStatusCode: 1,
          entityId: vars?.acceptEntityId,
          consentedFor: id,
        } as any
      );
    },
    onSuccess: async () => {
      setNavigateTo('/mybusiness/home');
      setIsPollingViewer(true);
    },
  });

  const rejectAgreement = useMutation({
    mutationFn: async (vars: { rejectEntityId }) => {
      return client.consentTheAgreement.postUserAgreement(
        AMS_APPLICATION_ID,
        id as any,
        undefined,
        {
          consentStatusCode: 2,
          entityId: vars?.rejectEntityId,
          consentedFor: id,
        }
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['getAgreements'] });
      navigate('/sign-out');
    },
  });

  return (
    <TitledContainer title={t('agreementCheck.title')}>
      <Page>
        <Container padding='medium'>
          <Heading
            as='h1'
            fontSize='xxLarge'
            marginBottom='medium'
            data-test-agreement-header>
            {t('agreementCheck.header')}
          </Heading>
          <Text fontSize='medium' data-test-agreement-text>
            {t('agreementCheck.info1')}
            <StyledLink
              target='_blank'
              rel='noreferrer'
              href={
                version === 'NA'
                  ? 'https://resideo.com/us/en/corporate/legal/pro-terms/north-america/english/'
                  : 'https://www.resideo.com/us/en/corporate/legal/pro-terms/emea/en/'
              }>
              {t('agreementCheck.link')}
            </StyledLink>
            {t('agreementCheck.info2')}
          </Text>
          <Flex paddingTop='large'>
            <Box paddingRight='medium'>
              <Button
                onClick={() => {
                  const agreementEntityId = getProTermsAgreementId(agreements);

                  if (agreementEntityId) {
                    rejectAgreement.mutate({
                      rejectEntityId: agreementEntityId,
                    });
                  }
                }}
                type='button'
                variant='secondary'>
                {t('common.decline')}
              </Button>
            </Box>
            <Box>
              <Button
                onClick={async () => {
                  const agreementEntityId = getProTermsAgreementId(agreements);

                  if (agreementEntityId) {
                    acceptAgreement.mutate({
                      acceptEntityId: agreementEntityId,
                    });
                  }
                }}
                type='button'
                variant='primary'>
                {t('common.accept')}
              </Button>
            </Box>
          </Flex>
        </Container>
      </Page>
    </TitledContainer>
  );
};

export default AgreementCheck;
