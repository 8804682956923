import HeaderNavLink from './HeaderNavLink';
import React from 'react';

const PerksNavLink = ({
  name,
  linkPath = '',
  linkTextKey,
  isExternalLink = false,
}) => {
  return (
    <HeaderNavLink
      key={name}
      linkPath={linkPath}
      name={name}
      linkTextKey={linkTextKey}
      isExternalLink={isExternalLink}
    />
  );
};

export default PerksNavLink;
