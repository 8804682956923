import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Card, Flex, Link, Text } from '@resideo/blueprint-react';
import Page from 'components/common/Page';
import { useTranslation } from 'react-i18next';
import { ENVIRONMENT } from 'config';

export type ErrorProps = {
  header?: string;
  content?: string;
  resetError?: () => void;
};

const PageError: FC<ErrorProps> = ({ header, content, resetError }) => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    return () => {
      if (resetError) {
        resetError();
      }
    };
  }, [location]);

  return (
    <Page as='main' data-test-page-error>
      <Card borderRadius={[0, 'medium']} padding='large'>
        <h1>{header || t('pageError.defaultHeader')}</h1>
        <Text>{content || t('pageError.defaultContent')}</Text>
        {ENVIRONMENT.toUpperCase() !== 'PRODUCTION' && (
          <Flex paddingTop='large'>
            <Box>
              <Button
                variant='secondary'
                size='small'
                onClick={() => window.location.reload()}>
                {t('common.refresh')}
              </Button>
            </Box>
            <Box paddingLeft='medium'>
              <Link href='/sign-out'>
                <Button variant='destructiveSecondary' size='iconSmall'>
                  {t('common.logOut')}
                </Button>
              </Link>
            </Box>
          </Flex>
        )}
      </Card>
    </Page>
  );
};

export default PageError;
