import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonContainer } from '../../customers.style';
import { Flex, Text, Button } from '@resideo/blueprint-react';

export const RemoveCustomerConfirmationModalContent = ({
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection='column'>
      <Text
        data-test-remove-customer-text
        marginBottom='medium'
        fontSize='medium'>
        {t('customerDetails.removeCustomer.confirmation')}
      </Text>
      <ButtonContainer>
        <Button variant='secondary' onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button
          data-test-remove-customer-submit-btn
          variant='destructivePrimary'
          onClick={onSubmit}>
          {t('customerDetails.remove')}
        </Button>
      </ButtonContainer>
    </Flex>
  );
};
