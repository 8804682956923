const DehumidificationProducts = t => [
  {
    text: t('hvacAndWater.dehumidification.dr.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/DR65 - DR90 - DR120 - Honeywell Home.zip?sp=r&st=2021-10-14T15:08:09Z&se=2221-10-14T23:08:09Z&spr=https&sv=2020-08-04&sr=b&sig=HV%2FXsD3ERcZrST4owTOyjGqTM4sPG1%2BrrezVbFrIy%2BY%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.dehumidification.dr.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
];

export default DehumidificationProducts;
