import React, { FC, ReactElement } from 'react';
import useMedia from 'hooks/useMedia';
import styled from 'styled-components';
import { Heading, Flex } from '@resideo/blueprint-react';
import SuccessIcon from 'components/icons/yes.svg?react';
import AlertIcon from 'components/icons/alert.svg?react';
import InfoIcon from 'components/icons/info.svg?react';
import ExpiredIcon from 'components/icons/expired.svg?react';
import VerticalGrayPanel from './GrayPanel';

const icons = {
  success: styled(SuccessIcon)`
    fill: #258039;
  `,
  alert: styled(AlertIcon)`
    fill: #bd1b1b;
  `,
  info: styled(InfoIcon)`
    fill: #2678a7;
  `,
  expired: styled(ExpiredIcon)`
    fill: #353535;
  `,
};

const HorizontalGrayPanel = styled(Flex)`
  width: 95%;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.separator};
  padding: ${({ theme }) => theme.space.medium};
  margin-left: 2rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 92%;
    flex-direction: unset;
    margin-top: 1rem;
    margin-left: 1rem;
  }
`;

const PanelParagraph = styled.p`
  margin-bottom: ${({ theme }) => theme.space.medium};
  margin-top: ${({ theme }) => theme.space.medium};
`;

const Link = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: 1px;
  text-align: center;
  max-width: 80%;
  align-self: center;
`;

export interface NoticeGrayBoxProps {
  type?: keyof typeof icons;
  title: string;
  description?: string | ReactElement;
  description2?: string | ReactElement;
  linkText?: string | ReactElement;
  linkTo?: string;
  linkFunction?: () => any;
  descriptionAlign?: 'center' | 'left' | 'right';
  horizontal?: boolean;
}

const NoticeGrayBox: FC<NoticeGrayBoxProps> = ({
  type = null,
  title,
  description,
  description2,
  linkText,
  linkTo,
  linkFunction,
  descriptionAlign,
  horizontal = false,
  ...rest
}): JSX.Element => {
  const IconComponent = type ? icons[type] : null;
  const isMobile = useMedia('(max-width: 62rem)');
  const iconHeight = isMobile ? '5rem' : '7rem';
  if (!descriptionAlign) {
    descriptionAlign = horizontal ? 'left' : 'center';
  }

  if (horizontal) {
    return (
      <HorizontalGrayPanel>
        {IconComponent && (
          <Flex marginRight='12px' justifyContent='center' alignItems='center'>
            <IconComponent style={{ width: description ? '5em' : '4em' }} />
          </Flex>
        )}
        <Flex flexDirection='column' justifyContent='center'>
          <Heading
            fontSize={isMobile ? 'medium' : 'large'}
            marginBottom={0}
            {...(!horizontal ? { alignSelf: 'center' } : {})}>
            {title}
          </Heading>
          {description && (
            <PanelParagraph
              style={{ textAlign: descriptionAlign, marginTop: '8px' }}>
              {description}
            </PanelParagraph>
          )}
          {description2 && (
            <PanelParagraph style={{ textAlign: descriptionAlign }}>
              {description2}
            </PanelParagraph>
          )}
          {linkText && linkTo && (
            <Link data-test-link href={linkTo}>
              {linkText}
            </Link>
          )}
        </Flex>
      </HorizontalGrayPanel>
    );
  }

  return (
    <VerticalGrayPanel {...rest}>
      {IconComponent && (
        <Flex marginBottom='medium'>
          <IconComponent style={{ height: iconHeight }} />
        </Flex>
      )}
      <Flex flexDirection='column'>
        <Heading
          fontSize={isMobile ? 'medium' : 'large'}
          marginBottom={0}
          {...(!horizontal && { alignSelf: 'center' })}>
          {title}
        </Heading>
        {description && (
          <PanelParagraph style={{ textAlign: descriptionAlign }}>
            {description}
          </PanelParagraph>
        )}
        {description2 && (
          <PanelParagraph style={{ textAlign: descriptionAlign }}>
            {description2}
          </PanelParagraph>
        )}
        {linkText &&
          (linkTo ? (
            <Link data-test-link href={linkTo}>
              {linkText}
            </Link>
          ) : linkFunction ? (
            <Link data-test-function-link onClick={linkFunction}>
              {linkText}
            </Link>
          ) : null)}
      </Flex>
    </VerticalGrayPanel>
  );
};

export default NoticeGrayBox;
