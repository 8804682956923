import React, { useState } from 'react';
import { Titled } from 'components/common/Titled';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumbs,
  Button,
  Card,
  SetupStep,
  Text,
} from '@resideo/blueprint-react';
import styled from 'styled-components';
import RightPanelHeader from 'components/common/RightPanelHeader';
import Page from 'components/common/Page';
import { NavLink, useNavigate } from 'react-router-dom';

const Header = styled.div`
  margin-left: 32px;
  padding-top: 5px;
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-left: 16px;
  }
`;

const CompleteServiceSetupDiv = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-left: 16px;
  }
`;

const VideoContainer = styled.div`
  padding-top: 60px;
`;

const NewServicesText = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  color: #414141;
  padding-bottom: 10px;
`;

const NewServicesDescription = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: #414141;
`;

enum CompleteServiceSetupSteps {
  BrandProfile = 1,
  HomeownerAppBrandingServiceSettings = 2,
  CustomerAlertServiceSettings = 3,
  CustomizeAlertsSettings = 4,
  LearnAboutYourNewServices = 5,
}

const CompleteServiceSetup = ({
  hasActiveHomeOwnerAppBranding,
  hasActiveProIQAdvanced,
  hasActivePartnerBrandProfile,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const hasBrandProfileAndProIQAdvanced =
    hasActiveProIQAdvanced && hasActivePartnerBrandProfile;
  const onlyHasActiveHomeOwnerAppBranding =
    hasActiveHomeOwnerAppBranding && !hasActiveProIQAdvanced;

  const [currentStep, setCurrentStep] = useState<CompleteServiceSetupSteps>(
    (Number(
      localStorage.getItem('currentStep')
    ) as CompleteServiceSetupSteps) || CompleteServiceSetupSteps.BrandProfile
  );

  const updateCurrentStep = (step: CompleteServiceSetupSteps) => {
    if (
      step < CompleteServiceSetupSteps.BrandProfile ||
      step > CompleteServiceSetupSteps.LearnAboutYourNewServices
    )
      return;
    setCurrentStep(step);
    localStorage.setItem('currentStep', step.toString());
  };

  const getStepStateProps = (step: CompleteServiceSetupSteps) => ({
    stepNumber: step,
    upcoming: currentStep < step,
    inProgress: currentStep === step,
    complete: currentStep > step,
  });

  return (
    <>
      <Titled title={t('mybusiness.services.completeServiceSetup.title')}>
        <Card width='100%'>
          <CompleteServiceSetupDiv>
            <Header>
              <Breadcrumbs>
                <NavLink
                  data-test-breadcrumbs-service-and-subscriptions
                  to='/mybusiness/services-subscriptions'>
                  {t('leftNav.servicesSubscriptions')}
                </NavLink>
                <NavLink data-test-breadcrumbs-complete-service-setup to='#'>
                  {t('mybusiness.services.completeServiceSetup.title')}
                </NavLink>
              </Breadcrumbs>
            </Header>
            <RightPanelHeader
              subtitle={
                <Text data-test-subtitle>
                  {t('mybusiness.services.completeServiceSetup.subTitle')}
                </Text>
              }>
              {currentStep == 5
                ? t('mybusiness.services.completeServiceSetup.completedTitle')
                : t('mybusiness.services.completeServiceSetup.title')}
            </RightPanelHeader>
          </CompleteServiceSetupDiv>
          <Page
            as='main'
            paddingY='none'
            paddingX={['large', 'small']}
            marginLeft={[0, 'large']}
            marginRight={['small', 0]}>
            {hasBrandProfileAndProIQAdvanced ? (
              <SetupStep
                title={t(
                  'mybusiness.services.completeServiceSetup.steps.step1ExistingBrandProfile.title'
                )}
                description={t(
                  'mybusiness.services.completeServiceSetup.steps.step1ExistingBrandProfile.description'
                )}
                actions={
                  <>
                    <Button
                      data-test-skip-btn
                      variant='secondary'
                      onClick={() => updateCurrentStep(2)}>
                      {t(
                        'mybusiness.services.completeServiceSetup.buttons.skip'
                      )}
                    </Button>
                    <Button
                      data-test-review-profile-btn
                      onClick={() => {
                        updateCurrentStep(2);
                        navigate('/mybusiness/services/brand-profile/view', {
                          state: { prevPage: 'complete-service-setup' },
                        });
                      }}>
                      {t(
                        'mybusiness.services.completeServiceSetup.buttons.reviewProfile'
                      )}
                    </Button>
                  </>
                }
                {...getStepStateProps(1)}
              />
            ) : (
              <SetupStep
                title={t(
                  'mybusiness.services.completeServiceSetup.steps.step1NoBrandProfile.title'
                )}
                description={t(
                  'mybusiness.services.completeServiceSetup.steps.step1NoBrandProfile.description'
                )}
                actions={
                  <>
                    <Button
                      data-test-create-brand-profile-btn
                      onClick={() => {
                        updateCurrentStep(2);

                        // navigate('/mybusiness/services/brand-profile/new');
                      }}>
                      {t(
                        'mybusiness.services.completeServiceSetup.buttons.createBrandProfile'
                      )}
                    </Button>
                  </>
                }
                {...getStepStateProps(1)}
              />
            )}
            <SetupStep
              title={t(
                'mybusiness.services.completeServiceSetup.steps.step2.title'
              )}
              description={t(
                'mybusiness.services.completeServiceSetup.steps.step2.description'
              )}
              actions={
                hasBrandProfileAndProIQAdvanced ? (
                  <>
                    <Button
                      data-test-skip-btn
                      variant='secondary'
                      onClick={() => {
                        updateCurrentStep(3);
                      }}>
                      {t(
                        'mybusiness.services.completeServiceSetup.buttons.skip'
                      )}
                    </Button>
                    <Button
                      data-test-service-settings-btn
                      onClick={() => {
                        updateCurrentStep(3);
                        // this page needs to be set up
                        // navigate('/mybusiness/services/setup/brand-profile-settings')
                      }}>
                      {t(
                        'mybusiness.services.completeServiceSetup.buttons.serviceSettings'
                      )}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      data-test-service-settings-btn
                      onClick={() => {
                        {
                          onlyHasActiveHomeOwnerAppBranding
                            ? updateCurrentStep(5)
                            : updateCurrentStep(3);
                        }
                        // navigate('/mybusiness/services/setup/brand-profile-settings')
                      }}>
                      {t(
                        'mybusiness.services.completeServiceSetup.buttons.serviceSettings'
                      )}
                    </Button>
                  </>
                )
              }
              {...getStepStateProps(2)}
            />
            {!onlyHasActiveHomeOwnerAppBranding && (
              <SetupStep
                title={t(
                  'mybusiness.services.completeServiceSetup.steps.step3.title'
                )}
                description={t(
                  'mybusiness.services.completeServiceSetup.steps.step3.description'
                )}
                actions={
                  <>
                    <Button
                      data-test-service-settings-btn
                      onClick={() => {
                        updateCurrentStep(4);
                        // navigate('/mybusiness/services/setup/customer-alerts-settings')
                      }}>
                      {t(
                        'mybusiness.services.completeServiceSetup.buttons.serviceSettings'
                      )}
                    </Button>
                  </>
                }
                {...getStepStateProps(3)}
              />
            )}
            {!onlyHasActiveHomeOwnerAppBranding && (
              <SetupStep
                title={t(
                  'mybusiness.services.completeServiceSetup.steps.step4.title'
                )}
                description={t(
                  'mybusiness.services.completeServiceSetup.steps.step4.description'
                )}
                actions={
                  <>
                    <Button
                      data-test-not-now-btn
                      variant={'secondary'}
                      onClick={() => {
                        updateCurrentStep(5);
                      }}>
                      {t(
                        'mybusiness.services.completeServiceSetup.buttons.notNow'
                      )}
                    </Button>
                    <Button
                      data-test-customize-alerts-btn
                      onClick={() => {
                        updateCurrentStep(5);
                        navigate('/mybusiness/alerts/alert-settings', {
                          state: { prevPage: 'complete-service-setup' },
                        });
                      }}>
                      {t(
                        'mybusiness.services.completeServiceSetup.buttons.customizeAlerts'
                      )}
                    </Button>
                  </>
                }
                {...getStepStateProps(4)}
              />
            )}
            {currentStep == 5 && (
              <VideoContainer>
                <NewServicesText data-test-new-services-text>
                  {t(
                    'mybusiness.services.completeServiceSetup.steps.step5.title'
                  )}
                </NewServicesText>
                <NewServicesDescription data-test-new-services-desc>
                  {t(
                    'mybusiness.services.completeServiceSetup.steps.step5.description'
                  )}
                </NewServicesDescription>
              </VideoContainer>
            )}
          </Page>
        </Card>
      </Titled>
    </>
  );
};

export default CompleteServiceSetup;
