import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Link as BlueprintLink } from '@resideo/blueprint-react';

interface HeaderNavLinkProps {
  linkPath: string;
  name: string;
  linkTextKey: string;
  isExternalLink?: boolean;
}

const StyledHeaderNavLink = styled(({ isExternalLink, ...props }) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  isExternalLink ? <BlueprintLink {...props} /> : <NavLink {...props} />
)`
  display: inline-block;
  height: 100%;
  letter-spacing: 0.35px;
  line-height: 1.75rem;
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin: 0 20px;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  color: ${({ theme }) => theme.colors.header.primaryLink};
  transition: border-color 0.5s ease-out;
  svg {
    position: relative;
    top: 6px;
    fill: ${({ theme }) => theme.colors.header.primaryLink};
  }

  &:hover {
    border-bottom: ${({ theme }) =>
      `2px solid ${theme.colors.nav.selectedBorder}`};
  }
  &.active {
    border-bottom: 2px solid;
    svg {
      fill: ${({ theme }) => theme.colors.header.primaryLinkActive};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xLarge}) {
    margin: 0 20px;
  }
`;

const HeaderNavLink = ({
  linkPath,
  linkTextKey,
  isExternalLink = false,
}: HeaderNavLinkProps): JSX.Element => {
  const { t } = useTranslation();

  return isExternalLink ? (
    <StyledHeaderNavLink
      href={linkPath}
      isExternalLink={isExternalLink}
      target={'_blank'}>
      {t(linkTextKey)}
    </StyledHeaderNavLink>
  ) : (
    <StyledHeaderNavLink to={linkPath} isExternalLink={isExternalLink}>
      {t(linkTextKey)}
    </StyledHeaderNavLink>
  );
};

export default HeaderNavLink;
