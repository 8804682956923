import React, { FC } from 'react';
import styled from 'styled-components';
import { Flex, Text, BoxProps, Box } from '@resideo/blueprint-react';
import { Link as RouterLink } from 'react-router-dom';
import BackArrow from 'components/icons/back-arrow.svg?react';

const BackArrowIcon = styled(BackArrow)`
  margin-right: 0.75rem;
`;

const GoBackText = styled(Text)`
  margin-left: 1rem;
  color: #a3a3a3;
  margin-top: 4px;
  text-transform: none;

  &:before {
    top: 21%;
    left: -25%;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: 7px solid transparent;
    border-top-color: #a3a3a3;
    transform: rotateZ(90deg);
  }
`;

const HeadingContainer = styled(Flex)<{ hasNoBorder?: boolean }>`
  border-bottom: ${({ hasNoBorder }) =>
    hasNoBorder ? 'none' : '1px solid rgba(0, 0, 0, 0.1)'};
  min-width: 0;
  padding-bottom: ${({ theme }) => theme.space.medium};
`;

const GridContainer = styled(Box)`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    justify-content: normal;
    grid-auto-flow: row;
  }
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  font-weight: 500;
  margin-top: ${({ theme }) => theme.space.large};
`;

interface RightPanelHeaderProps {
  subtitle?: string | React.ReactNode;
  cta?: any;
  backToAddress?: string;
  backToText?: string;
  hasNoBorder?: boolean;
  isEmployeeForm?: boolean;
}
interface HeaderProps extends RightPanelHeaderProps, BoxProps {}

const RightPanelHeader: FC<HeaderProps> = ({
  subtitle,
  children,
  cta,
  backToAddress,
  backToText,
  hasNoBorder,
  isEmployeeForm,
  ...rest
}): JSX.Element => {
  return (
    <HeadingContainer
      flexDirection='column'
      marginBottom='medium'
      justifyContent='space-between'
      marginLeft={['medium', 'medium', 'medium', 'large']}
      marginRight={['medium', 'medium', 'medium', 'large']}
      hasNoBorder={hasNoBorder}
      {...rest}
      data-test-right-panel-header>
      {backToAddress && (
        <Link to={backToAddress}>
          <Flex alignItems='center' marginLeft={isEmployeeForm ? 'medium' : ''}>
            {isEmployeeForm ? (
              <>
                <GoBackText>{backToText}</GoBackText>
              </>
            ) : (
              <>
                <BackArrowIcon width='25px' />
                {backToText}
              </>
            )}
          </Flex>
        </Link>
      )}
      <GridContainer>
        <Flex flexDirection='column'>
          <h1>{children}</h1>
          {subtitle && <Text paddingBottom='small'>{subtitle}</Text>}
        </Flex>
        {cta && <Flex>{cta}</Flex>}
      </GridContainer>
    </HeadingContainer>
  );
};

export default RightPanelHeader;
