const footerLegalConfig = [
  {
    textKey: 'footer.legal.links.resideo',
    linkPath: 'https://resideo.com',
  },
  {
    textKey: 'footer.legal.links.honeywellhome',
    linkPath: 'https://honeywellhome.com',
  },
  {
    textKey: 'footer.legal.links.privacypolicy',
    linkPath: 'https://www.resideo.com/us/en/corporate/legal/privacy/english/',
  },
  {
    textKey: 'footer.legal.links.termsconditions',
    linkPath:
      'https://www.resideo.com/us/en/corporate/legal/terms-and-conditions/',
    target: '_blank',
  },
  {
    textKey: 'footer.legal.links.orderFaq',
    linkPath: 'https://www.resideo.com/us/en/pro/order-faq/',
    target: '_blank',
  },
];

export { footerLegalConfig as default };
