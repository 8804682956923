import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button } from '@resideo/blueprint-react';
import {
  ButtonContainer,
  DeleteButton,
  ModalContent,
  WarningContainer,
} from '../../../customers.style';

export const RemoveLocationModal = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <WarningContainer>
        <Text data-test-modal-text>
          {t('customerDetails.removeLocation.modalText')}
        </Text>
      </WarningContainer>
      <ButtonContainer>
        <Button data-test-cancel-btn variant='secondary' onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <DeleteButton data-test-delete-btn variant='primary' onClick={onSubmit}>
          {t('customerDetails.remove')}
        </DeleteButton>
      </ButtonContainer>
    </ModalContent>
  );
};
