const FiltrationProducts = t => [
  {
    text: t('hvacAndWater.filtration.f100.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/F100 - Honeywell Home.zip?sp=rl&st=2021-03-10T15:31:13Z&se=2121-06-11T15:31:00Z&sv=2020-02-10&sr=b&sig=HTQhi9L%2FSPhrxyFoCe62NDkLIJCGFBBhnNrGK0IqAl8%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.filtration.f100.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.filtration.f300.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/F300 - Honeywell Home.zip?sp=rl&st=2021-03-10T15:31:31Z&se=2147-10-11T15:31:00Z&sv=2020-02-10&sr=b&sig=1zOcxxoWnwDUJKtY0LRdBnTiKviLNYQqC3ri30DZeAo%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.filtration.f300.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
];

export default FiltrationProducts;
