import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { intlFormatDistance } from 'date-fns/intlFormatDistance';
import { NotificationContainer, NotificationsContext } from './Notifications';
import { Link } from 'react-router-dom';
import { Button } from '@resideo/blueprint-react';

const TimeAgo = styled.div`
  font-size: 12px;
  text-transform: capitalize;
  color: #767676;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  gap: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }

  button {
    min-width: 107px;
    min-height: 30px;
    padding: 0 14px;
  }
`;

export const NotificationLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
`;

const DefaultNotificationComponent = ({
  content,
  date,
  link,
  primaryAction,
  secondaryAction,
  ...rest
}: {
  content: any;
  date?: string;
  link?: string;
  primaryAction?: {
    label: string;
    onClick: () => void;
  };
  secondaryAction?: {
    label: string;
    onClick: () => void;
  };
}) => {
  const { i18n } = useTranslation();
  const { closeNotifications } = useContext(NotificationsContext);

  return (
    <NotificationContainer {...rest}>
      {link ? (
        <NotificationLink onClick={closeNotifications} to={link}>
          {content}
          {date && (
            <TimeAgo>
              {intlFormatDistance(
                new Date(new Date(date) + ' UTC'),
                new Date(),
                {
                  locale: i18n.language,
                }
              )}
            </TimeAgo>
          )}
        </NotificationLink>
      ) : (
        <>
          {content}
          {date && (
            <TimeAgo>
              {intlFormatDistance(new Date(date + ' UTC'), new Date(), {
                locale: i18n.language,
              })}
            </TimeAgo>
          )}
        </>
      )}
      {(primaryAction || secondaryAction) && (
        <ButtonsContainer>
          {primaryAction && (
            <Button
              size='small'
              variant='primary'
              onClick={() => {
                if (closeNotifications) closeNotifications();
                primaryAction.onClick();
              }}>
              {primaryAction.label}
            </Button>
          )}
          {secondaryAction && (
            <Button
              size='small'
              variant='secondary'
              onClick={() => {
                if (closeNotifications) closeNotifications();
                secondaryAction.onClick();
              }}>
              {secondaryAction.label}
            </Button>
          )}
        </ButtonsContainer>
      )}
    </NotificationContainer>
  );
};

export default DefaultNotificationComponent;
