import React from 'react';
import { intlFormatDistance, formatRelative } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AlertListModel } from '@resideo/web-integration-services-api-client';
import { AlertsTab } from '../types';
import AlertTooltip from './AlertTooltip';

const StyledAlertCell = styled.div`
  font-size: 12px;
  color: #303030;

  a {
    display: block;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    color: #1c6fb9;
  }
`;

const AlertCell = ({ row, tab }: { row: AlertListModel; tab: AlertsTab }) => {
  const { i18n } = useTranslation();
  const { id, title, createdAt } = row;

  return (
    <StyledAlertCell>
      <Link data-test-alert-cell-link to={`#${id}`}>
        {title}
        {id && <AlertTooltip id={id} />}
      </Link>

      {tab !== AlertsTab.Flagged && (
        <>
          {createdAt && formatRelative(new Date(createdAt), new Date())} <br />(
          {createdAt &&
            intlFormatDistance(new Date(createdAt), new Date(), {
              locale: i18n.language,
            })}
          )
        </>
      )}
    </StyledAlertCell>
  );
};

export default AlertCell;
