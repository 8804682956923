import React from 'react';
import { useTranslation } from 'react-i18next';
import { IAddCustomerProps, Steps } from '../types';
import CustomerLocationForm from '../CustomerLocationForm';
import { useCurrentUser } from 'context/CurrentUser';
import { Text, useToastContext } from '@resideo/blueprint-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { checkPhoneNumberForCountryCode } from 'utils/validation';
import { useRisClient } from 'hooks/useRisClient';
import {
  CreatePartnerCustomerModel,
  CreatePartnerDeviceLocationModel,
} from '@resideo/web-integration-services-api-client';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from 'config';
import { useFeatureFlags } from '../../../../context/FeatureFlags';

const initialLocationValues = {
  locationName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
};

const libraries: Libraries = ['places', 'marker', 'maps'];

const LocationModal: React.FC<IAddCustomerProps> = ({
  setStep,
  addValues,
  data,
}) => {
  const { partnerAccountId } = useCurrentUser();
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const { CUSTOMER_DETAILS_REDESIGN } = useFeatureFlags();

  const createCustomer = useMutation({
    mutationFn: async (cust: CreatePartnerCustomerModel) => {
      return await client.myProAccounts.myProAccountsCreatePartnerCustomer(
        partnerAccountId as string,
        {
          firstName: cust.firstName,
          lastName: cust.lastName,
          email: cust.email,
          phoneNumber: cust.phoneNumber,
        }
      );
    },
  });

  const createLocation = useMutation({
    mutationFn: async (vars: {
      partnerCustomerId;
      loc: CreatePartnerDeviceLocationModel;
    }) => {
      return client.myProAccounts.myProAccountsCreatePartnerDeviceLocation(
        partnerAccountId as string,
        vars.partnerCustomerId,
        {
          name: vars.loc.name,
          address: {
            addressLine1: vars.loc?.address?.addressLine1,
            addressLine2: vars.loc?.address?.addressLine2,
            city: vars.loc?.address?.city,
            stateProvinceRegionCode: vars.loc?.address?.stateProvinceRegionCode,
            zipPostalCode: vars.loc?.address?.zipPostalCode,
            countryCode: vars.loc?.address?.countryCode,
          },
        }
      );
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
    },
    onSuccess: response => {
      queryClient.invalidateQueries({ queryKey: ['partnerCustomers'] });

      if (response?.data) {
        setStep(Steps.Success);
      }
    },
  });

  const countryCodeIsTheOnlyInput =
    data?.phoneNumber &&
    `+${checkPhoneNumberForCountryCode(data?.phoneNumber).toString()}` ===
      data?.phoneNumber;

  const handleSubmit = async values => {
    addValues({ ...values });
    try {
      const cust = await createCustomer.mutateAsync({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phoneNumber: countryCodeIsTheOnlyInput ? '' : data?.phoneNumber,
      });

      const customerId = cust?.data?.id as string;

      if (customerId) {
        addValues({ customerId });

        createLocation.mutate({
          partnerCustomerId: customerId,
          loc: {
            name: values?.locationName,
            address: {
              addressLine1: values?.address1,
              addressLine2: values?.address2,
              city: values?.city,
              stateProvinceRegionCode: values?.state,
              zipPostalCode: values?.zip,
              countryCode: values?.country,
            },
          },
        });
      }
    } catch (error) {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
    }
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  return (
    <>
      <Text marginBottom='medium' as='h2' fontSize='xLarge'>
        {t('customers.location')}
      </Text>
      <CustomerLocationForm
        initialValues={data?.isBackButtonClicked ? data : initialLocationValues}
        onSubmit={(values, autoCompleteSelected) => {
          if (CUSTOMER_DETAILS_REDESIGN) {
            if (autoCompleteSelected) {
              handleSubmit(values);
            } else {
              addValues({ ...values });
              setStep(Steps.Verification);
            }
          } else {
            handleSubmit(values);
          }
        }}
        onCancel={() => {
          setStep(Steps.UserInfo);
        }}
        isStep
        isLoaded={isLoaded}
        loadError={loadError}
        isBackBtnClickedFromVerificationModal={data?.isBackButtonClicked}
      />
    </>
  );
};

export default LocationModal;
