import React from 'react';
import styled from 'styled-components';
import FlagButton from '../components/FlagButton';
import DismissButton from '../components/DismissButton';
import RestoreButton from '../components/RestoreButton';
import { AlertsTab } from '../types';
import {
  AlertListModel,
  UpdateAlertModel,
  DeviceAlertStatus,
} from '@resideo/web-integration-services-api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';
import { useCurrentUser } from 'context/CurrentUser';
import { useToastContext } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';

const StyledActionsCell = styled.div`
  text-align: right;
  padding-right: 10px;

  button {
    margin-right: 50px;
    vertical-align: middle;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ActionsCell = ({ row, tab }: { row: AlertListModel; tab: AlertsTab }) => {
  const { id = '', flagged, status } = row;
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const { client } = useRisClient();
  const { partnerAccountId } = useCurrentUser();
  const queryClient = useQueryClient();

  interface UpdateAlertWithAction extends UpdateAlertModel {
    actionType: 'addFlag' | 'removeFlag' | 'dismiss' | 'restore';
  }

  const successMessages: {
    [key in UpdateAlertWithAction['actionType']]: string;
  } = {
    addFlag: t('alertsDashboard.toast.flaggedSuccess'),
    removeFlag: t('alertsDashboard.toast.flagRemovedSuccess'),
    dismiss: t('alertsDashboard.toast.dismissedSuccess'),
    restore: t('alertsDashboard.toast.restoredSuccess'),
  };

  const editAlert = useMutation({
    mutationFn: async ({ status, flagged }: UpdateAlertWithAction) => {
      return client.myProAccounts.myProAccountsUpdateAlert(
        partnerAccountId as string,
        id,
        { status, flagged }
      );
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
    },
    onSuccess: async (_data, variables) => {
      const { actionType } = variables;
      const message = successMessages[actionType] || t('common.toast.success');

      await queryClient.invalidateQueries({ queryKey: ['alerts'] });
      await queryClient.invalidateQueries({ queryKey: ['alertsInsights'] });

      addToast({
        toastType: 'Success',
        message,
      });
    },
  });

  return (
    <StyledActionsCell>
      <FlagButton
        active={flagged}
        onClick={() => {
          editAlert.mutate({
            actionType: flagged ? 'removeFlag' : 'addFlag',
            flagged: !flagged,
            status,
          });
        }}
      />
      {status === DeviceAlertStatus.ACTIVE && tab !== AlertsTab.Flagged && (
        <DismissButton
          onClick={() => {
            editAlert.mutate({
              actionType: 'dismiss',
              flagged,
              status: DeviceAlertStatus.DISMISSED,
            });
          }}
        />
      )}
      {status === DeviceAlertStatus.DISMISSED && tab !== AlertsTab.Flagged && (
        <RestoreButton
          onClick={() => {
            editAlert.mutate({
              actionType: 'restore',
              flagged,
              status: DeviceAlertStatus.ACTIVE,
            });
          }}
        />
      )}
    </StyledActionsCell>
  );
};

export default ActionsCell;
