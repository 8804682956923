const ThermalSolutions = t => [
  {
    text: t('hvacAndWater.thermal.db7110U1000.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/DB7110U1000 - Resideo.zip?sp=rl&st=2021-03-04T20:58:34Z&se=2149-10-14T20:58:00Z&sv=2020-02-10&sr=b&sig=LJlL8SK%2B0BJUht6luiLPdu9CHU16ZNkmgoB3r1Gi54Q%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.db7110U1000.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.thermal.l7224.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/L7224U1002 - Resideo.zip?sp=rl&st=2021-03-04T20:58:56Z&se=2132-11-12T20:58:00Z&sv=2020-02-10&sr=b&sig=uVSo0tDAPIsMqqDxqL2PQduojVtecty7O2OLdM%2FEH2I%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.l7224.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.thermal.q314.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/Q314 - Resideo.zip?sp=rl&st=2021-03-04T21:00:32Z&se=2141-10-05T21:00:00Z&sv=2020-02-10&sr=b&sig=nDyE3jsCrT9cMZWWSps2u0E3oN6mNwgttiyqvDBrDTA%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.q314.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.thermal.q340.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/Q340 - Resideo.zip?sp=rl&st=2021-03-04T21:00:56Z&se=2147-10-11T21:00:00Z&sv=2020-02-10&sr=b&sig=Lat38hUj9MkgKxa4NGSmgjWUN8sMLkoxPR6JD5Lb9E0%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.q340.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.thermal.q3400A1024.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/Q3400A1024 - Resideo.zip?sp=r&st=2021-10-14T15:27:40Z&se=2221-10-14T23:27:40Z&spr=https&sv=2020-08-04&sr=b&sig=pvOgBvtSD5YDDaa%2FTowBxai8z0RPCM2siqpIp7cG%2F3A%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.q3400A1024.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.thermal.q345'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/Q345 - Resideo.zip?sp=rl&st=2021-03-04T21:01:41Z&se=2200-06-05T21:01:00Z&sv=2020-02-10&sr=b&sig=iZmqCS95G8fzOStkJSCxeKh1En%2BeeLX2LajdgnShfns%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.descriptions.burner'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.thermal.q3451U1000'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/Q3451U1000 - Resideo.zip?sp=rl&st=2021-03-04T21:02:04Z&se=2160-06-11T21:02:00Z&sv=2020-02-10&sr=b&sig=13IPXsnsmL5b3frbq0jpOqVwemsdEmCsIalTcmiwslw%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.descriptions.universalBurner'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.thermal.q348U1009'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/Q348U1009 - Resideo.zip?sp=rl&st=2021-03-04T21:02:36Z&se=2154-10-05T21:02:00Z&sv=2020-02-10&sr=b&sig=pAqjHLP0bdjURGaany9tWfcIA6cum6Xea9e9SU6VvFY%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.descriptions.universalBurner'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.thermal.q390A1061.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/Q390A1061 - Resideo.zip?sp=rl&st=2021-03-04T21:02:57Z&se=2181-06-13T21:02:00Z&sv=2020-02-10&sr=b&sig=KJuFAIEVBBD%2FtU3ONHc%2FEF0F3XCkoy7vvYzzLmMUffc%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.q390A1061.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.thermal.s8610U3009.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/S8610U3009 - Resideo.zip?sp=rl&st=2021-03-04T21:05:14Z&se=2141-06-13T21:05:00Z&sv=2020-02-10&sr=b&sig=VuvfwSO8rAxVYyeUN%2Fw4Lcq4oXju8bZ81EvfIxShj4M%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.s8610U3009.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.thermal.s8910U3000.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/S8910U3000 - Resideo.zip?sp=rl&st=2021-03-04T21:05:38Z&se=2171-10-09T21:05:00Z&sv=2020-02-10&sr=b&sig=5%2FuXpVN6ZCwlmKFTn0P%2BILbyw0LH6W5L9xWweKa5xCI%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.s8910U3000.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.thermal.s9200U1000.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/S9200U1000 - Resideo.zip?sp=rl&st=2021-03-04T21:06:12Z&se=2079-06-21T21:06:00Z&sv=2020-02-10&sr=b&sig=p8MxwEaO08505jjYzdn9%2FyyTOa296EmBguBxMSGRVTo%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.s9200U1000.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.thermal.sT9120U1011.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/ST9120U1011 - Resideo.zip?sp=rl&st=2021-03-04T21:06:49Z&se=2141-10-18T21:06:00Z&sv=2020-02-10&sr=b&sig=ZCLzVdktDlxUznDUXpMse6uqRfmzt0Zsec24Xx2gZUQ%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.sT9120U1011.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.thermal.sV9501M2528.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/SV9501M2528 - Resideo.zip?sp=rl&st=2021-03-04T21:07:10Z&se=2158-10-11T21:07:00Z&sv=2020-02-10&sr=b&sig=UbxLwqheDB0K3YWTOYOR7XjJtlcBknwc6stIVfy7K8A%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.sV9501M2528.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.thermal.vR8300A3500.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/VR8300A3500 - Resideo.zip?sp=rl&st=2021-03-04T21:08:19Z&se=2145-10-13T21:08:00Z&sv=2020-02-10&sr=b&sig=431iNRPmIq0mTsWH0h8buNFvJG%2BE%2BRxVR4gLNDhCaf8%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.vR8300A3500.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.thermal.vR8345M4302.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/VR8345M4302 - Resideo.zip?sp=rl&st=2021-03-04T21:08:51Z&se=2146-11-05T21:08:00Z&sv=2020-02-10&sr=b&sig=kvJzUarq0r4qESX2aCyxuYP624DB4jKtNtDNHqkrNo8%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.thermal.vR8345M4302.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
];

export default ThermalSolutions;
