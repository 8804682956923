import React, { FC } from 'react';
import { Titled } from 'components/common/Titled';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link as RRLink, useLocation, useNavigate } from 'react-router-dom';

import {
  Flex,
  Heading,
  Text,
  Box,
  Label,
  RectangularButton,
  useToastContext,
} from '@resideo/blueprint-react';
import {
  FloatingInputField,
  FloatingSelectField,
  Radio,
  Checkbox,
} from '@resideo/blueprint-formik';
import { Formik, Form, FormikErrors } from 'formik';

import ResideoProLogo from 'components/logo/resideo-pro-logo.svg?react';

import { checkEmail, isNumber } from 'utils/validation';
import StateSelect from 'components/common/StateSelect';

interface AccessFormValues {
  firstName?: string;
  lastName?: string;
  contactEmail?: string;
  companyName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  primaryBusinessType?: string;
  secondaryBusinessType?: string;
  companyEmail?: string;
  companyPhone?: string;
  companyURL?: string;
  numberOfEmployeesTechSales?: any;
  numberOfEmployeesMarketing?: any;
  isCustomer?: string;
  accountNumber?: string;
  unknownAccountNumber?: boolean;
  primaryDistributor?: string;
  secondaryDistributor?: string;
  shippingCountry?: string;
  shippingAddress1?: string;
  shippingAddress2?: string;
  shippingCity?: string;
  shippingState?: string;
  shippingZipCode?: string;
}

const initialFormValues = {
  firstName: '',
  lastName: '',
  contactEmail: '',
  companyName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  primaryBusinessType: '',
  secondaryBusinessType: '',
  companyEmail: '',
  companyPhone: '',
  companyURL: '',
  numberOfEmployeesTechSales: '',
  numberOfEmployeesMarketing: '',
  isCustomer: '',
  accountNumber: '',
  unknownAccountNumber: false,
  primaryDistributor: '',
  secondaryDistributor: '',
  shippingCountry: '',
  shippingAddress1: '',
  shippingAddress2: '',
  shippingCity: '',
  shippingState: '',
  shippingZipCode: '',
};

const useSearchParams = () => {
  return new URLSearchParams(useLocation().search);
};

const RequestAccessHeading = styled(Heading)`
  text-transform: uppercase;
`;

const SectionTitle = styled(Text)``;

const StyledResideoProLogo = styled(ResideoProLogo)`
  fill: #000;
  height: 28px;
`;

const StyledLink = styled(RRLink)`
  padding: ${({ theme }) => `${theme.space.small} ${theme.space.large}`};
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  &:visited {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const AccessForm: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const requestAccessBy = useSearchParams().get('by');

  const submitForm = () => {
    addToast({
      toastType: 'Success',
      message: t('common.toast.success'),
    });
    navigate(`/access/request/success?by=${requestAccessBy}`);
  };

  const validate = (values: AccessFormValues) => {
    const errors: FormikErrors<AccessFormValues> = {};

    if (values.firstName?.trim() === '')
      errors.firstName = t('accessRequest.validation.firstName');
    if (values.lastName?.trim() === '')
      errors.lastName = t('accessRequest.validation.lastName');
    if (
      values.contactEmail?.trim() === '' ||
      !checkEmail(values.contactEmail || '')
    )
      errors.contactEmail = t('accessRequest.validation.contactEmail');
    if (values.companyName?.trim() === '')
      errors.companyName = t('accessRequest.validation.companyName');
    if (values.accountNumber?.trim() === '' && !values.unknownAccountNumber)
      errors.accountNumber = t('accessRequest.validation.accountNumber');

    if (requestAccessBy === 'owner') {
      // Validation for Owner
      if (values.country?.trim() === '')
        errors.country = t('accessRequest.validation.country');
      if (values.address1?.trim() === '')
        errors.address1 = t('accessRequest.validation.address1');
      if (values.city?.trim() === '')
        errors.city = t('accessRequest.validation.city');
      if (values.state?.trim() === '')
        errors.state = t('accessRequest.validation.state');
      if (values.zipCode?.trim() === '')
        errors.zipCode = t('accessRequest.validation.zipCode');
      if (values.primaryBusinessType?.trim() === '')
        errors.primaryBusinessType = t(
          'accessRequest.validation.primaryBusinessType'
        );
      if (values.companyEmail?.trim() === '')
        errors.companyEmail = t('accessRequest.validation.companyEmail');
      if (values.companyPhone?.trim() === '')
        errors.companyPhone = t('accessRequest.validation.companyPhone');
      if (values.companyURL?.trim() === '')
        errors.companyURL = t('accessRequest.validation.companyURL');
      if (!isNumber(values.numberOfEmployeesTechSales))
        errors.numberOfEmployeesTechSales = t('accessRequest.validation.NAN');
      if (values.numberOfEmployeesTechSales?.trim() === '')
        errors.numberOfEmployeesTechSales = t(
          'accessRequest.validation.numberOfEmployeesTechSales'
        );
      if (!isNumber(values.numberOfEmployeesMarketing))
        errors.numberOfEmployeesMarketing = t('accessRequest.validation.NAN');
      if (values.numberOfEmployeesMarketing?.trim() === '')
        errors.numberOfEmployeesMarketing = t(
          'accessRequest.validation.numberOfEmployeesMarketing'
        );
      if (values.primaryDistributor?.trim() === '')
        errors.primaryDistributor = t(
          'accessRequest.validation.primaryDistributor'
        );
      if (values.secondaryDistributor?.trim() === '')
        errors.secondaryDistributor = 'Secondary Distributor is required';
    } else if (requestAccessBy === 'distributor') {
      // Validation for Distributor
      if (values.shippingCountry?.trim() === '')
        errors.shippingCountry = t('accessRequest.validation.shippingCountry');
      if (values.shippingAddress1?.trim() === '')
        errors.shippingAddress1 = t(
          'accessRequest.validation.shippingAddress1'
        );
      if (values.shippingCity?.trim() === '')
        errors.shippingCity = t('accessRequest.validation.shippingCity');
      if (values.shippingState?.trim() === '')
        errors.shippingState = t('accessRequest.validation.shippingState');
      if (values.shippingZipCode?.trim() === '')
        errors.shippingZipCode = t('accessRequest.validation.shippingZipCode');
    }

    return errors;
  };

  return (
    <Titled title={t('accessRequest.form.title')}>
      <Box height='100%'>
        <Flex
          data-test-access-request-form
          flexDirection='column'
          alignItems='center'
          backgroundColor='white'
          paddingTop='large'
          paddingBottom='xxLarge'>
          <Flex justifyContent='center'>
            <StyledResideoProLogo />
          </Flex>
          <Flex flexDirection='column'>
            <RequestAccessHeading
              textAlign='center'
              fontSize='xxxLarge'
              marginBottom='large'
              paddingTop='large'
              data-test-form-header>
              {t('accessRequest.form.title')}
            </RequestAccessHeading>
            <Flex
              flexDirection='column'
              backgroundColor='separator'
              padding='large'
              minWidth='500px'
              marginBottom='large'>
              <Formik
                enableReinitialize
                onSubmit={submitForm}
                initialValues={initialFormValues}
                validate={validate}>
                {({ values, isSubmitting }) => (
                  <Form data-test-profile>
                    <Text
                      fontStyle='italic'
                      marginBottom='large'
                      fontSize='small'>
                      {t('common.requiredFields')}
                    </Text>
                    <Flex flexDirection='column' marginBottom='xLarge'>
                      <SectionTitle
                        fontWeight='bold'
                        marginBottom='medium'
                        fontSize='xLarge'>
                        {t('accessRequest.form.contactInfo')}
                      </SectionTitle>
                      <Box marginBottom='medium' data-test-access-first-name>
                        <FloatingInputField
                          name='firstName'
                          label={t('common.name.first')}
                          required
                        />
                      </Box>
                      <Box marginBottom='medium' data-test-access-last-name>
                        <FloatingInputField
                          name='lastName'
                          label={t('common.name.last')}
                          required
                        />
                      </Box>
                      <Box>
                        <FloatingInputField
                          name='contactEmail'
                          label={t('common.email')}
                          required
                        />
                      </Box>
                    </Flex>
                    <Flex flexDirection='column'>
                      <SectionTitle
                        fontWeight='bold'
                        marginBottom='medium'
                        fontSize='xLarge'>
                        {t('accessRequest.form.companyInfo')}
                      </SectionTitle>
                      <Box marginBottom='medium'>
                        <FloatingInputField
                          name='companyName'
                          label={t('common.companyName')}
                          required
                        />
                      </Box>
                      {requestAccessBy === 'owner' && (
                        <>
                          <Box marginBottom='medium'>
                            <FloatingInputField
                              name='address1'
                              label={t('common.address.address1')}
                              required
                            />
                          </Box>
                          <Box marginBottom='medium'>
                            <FloatingInputField
                              name='address2'
                              label={t('common.address.address2')}
                            />
                          </Box>
                          <Box marginBottom='medium'>
                            <FloatingInputField
                              name='city'
                              label={t('common.address.city')}
                              required
                            />
                          </Box>
                          <Flex marginBottom='medium'>
                            <Box width={[1, 1 / 2]} marginRight={[0, 'small']}>
                              <StateSelect
                                name='state'
                                label={t('common.address.state')}
                                variant='floating'
                                country={values.country}
                                required>
                                <option value=''></option>
                              </StateSelect>
                            </Box>
                            <Box width={[1, 1 / 2]}>
                              <FloatingInputField
                                name='zip'
                                label={t('common.address.zip')}
                                required
                              />
                            </Box>
                          </Flex>
                          <Box marginBottom='medium'>
                            <FloatingSelectField
                              name='country'
                              label={t('common.address.country')}
                              required
                              data-test-access-country>
                              <option value=''></option>
                              <option value='US'>
                                {t('common.countries.US')}
                              </option>
                              <option value='CA'>
                                {t('common.countries.CA')}
                              </option>
                            </FloatingSelectField>
                          </Box>
                          <Box marginBottom='medium'>
                            <FloatingSelectField
                              name='primaryBusinessType'
                              label={t('company.profile.primaryBusiness')}
                              required>
                              <option value=''></option>
                              <option value='electrician'>
                                {t('common.proCategories.ELECTRICIAN')}
                              </option>
                              <option value='hvac'>
                                {t('common.proCategories.HVAC')}
                              </option>
                              <option value='plumber'>
                                {t('common.proCategories.PLUMBER')}
                              </option>
                              <option value='security'>
                                {t('common.proCategories.SECURITY')}
                              </option>
                              <option value='utility'>
                                {t('common.proCategories.UTILITY')}
                              </option>
                            </FloatingSelectField>
                          </Box>
                          <Box marginBottom='medium'>
                            <FloatingSelectField
                              name='secondaryBusinessType'
                              label={t('company.profile.secondaryBusiness')}>
                              <option value=''></option>
                              <option value='electrician'>
                                {t('common.proCategories.ELECTRICIAN')}
                              </option>
                              <option value='hvac'>
                                {t('common.proCategories.HVAC')}
                              </option>
                              <option value='plumber'>
                                {t('common.proCategories.PLUMBER')}
                              </option>
                              <option value='security'>
                                {t('common.proCategories.SECURITY')}
                              </option>
                              <option value='utility'>
                                {t('common.proCategories.UTILITY')}
                              </option>
                            </FloatingSelectField>
                          </Box>
                          <Box marginBottom='medium'>
                            <FloatingInputField
                              name='companyEmail'
                              label={t('common.companyEmail')}
                              required
                            />
                          </Box>
                          <Box marginBottom='medium'>
                            <FloatingInputField
                              name='companyPhone'
                              label={t('common.companyPhone')}
                              required
                            />
                          </Box>
                          <Box marginBottom='medium'>
                            <FloatingInputField
                              name='companyURL'
                              label={t('company.profile.websiteUrl')}
                              required
                            />
                          </Box>
                          <Box marginBottom='medium'>
                            <FloatingInputField
                              name='numberOfEmployeesTechSales'
                              label={t(
                                'accessRequest.form.numberOfTechnicians'
                              )}
                              required
                            />
                          </Box>
                          <Box marginBottom='medium'>
                            <FloatingInputField
                              name='numberOfEmployeesMarketing'
                              label={t('accessRequest.form.numberOfMarketing')}
                              required
                            />
                          </Box>
                        </>
                      )}
                    </Flex>

                    {requestAccessBy === 'owner' && (
                      <Flex marginBottom='medium' flexDirection='column'>
                        <Text marginBottom='small' fontWeight='bold'>
                          {t('accessRequest.form.resideoCustomer')}
                        </Text>
                        <Flex flexDirection='row'>
                          <Label>
                            <Radio name='isCustomer' value='true' />
                            <Box paddingRight='large'>{t('common.yes')}</Box>
                          </Label>

                          <Label>
                            <Radio name='isCustomer' value='false' />
                            <Box>{t('common.no')}</Box>
                          </Label>
                        </Flex>
                      </Flex>
                    )}

                    <Box marginBottom='medium'>
                      <FloatingInputField
                        name='accountNumber'
                        label={t('common.resideoAccount')}
                        help='CPRO#, PERKS#, etc'
                        disabled={values.unknownAccountNumber}
                        required
                      />
                    </Box>
                    <Flex marginBottom='medium'>
                      <Label>
                        <Checkbox name='unknownAccountNumber' />
                        <Box>{t('accessRequest.form.knowAccount')}</Box>
                      </Label>
                    </Flex>
                    {requestAccessBy === 'owner' && (
                      <>
                        <Box marginBottom='medium'>
                          <FloatingInputField
                            name='primaryDistributor'
                            label={t('accessRequest.form.primaryDistributor')}
                            help='This info will be used for...'
                            required
                          />
                        </Box>
                        <Box marginBottom='large'>
                          <FloatingInputField
                            name='secondaryDistributor'
                            label={t('accessRequest.form.secondaryDistributor')}
                          />
                        </Box>
                      </>
                    )}
                    {requestAccessBy === 'distributor' && (
                      <Flex flexDirection='column' marginBottom='large'>
                        <SectionTitle
                          fontWeight='bold'
                          marginBottom='medium'
                          fontSize='xLarge'>
                          {t('company.profile.shippingAddress')}
                        </SectionTitle>
                        <Box marginBottom='medium'>
                          <FloatingSelectField
                            name='shippingCountry'
                            label={t('common.address.country')}
                            required>
                            <option value=''></option>
                            <option value='US'>
                              {t('common.countries.US')}
                            </option>
                            <option value='CA'>
                              {t('common.countries.CA')}
                            </option>
                          </FloatingSelectField>
                        </Box>
                        <Box marginBottom='medium'>
                          <FloatingInputField
                            name='shippingAddress1'
                            label={t('common.address.address1')}
                            required
                          />
                        </Box>
                        <Box marginBottom='medium'>
                          <FloatingInputField
                            name='shippingAddress2'
                            label={t('common.address.address2')}
                          />
                        </Box>
                        <Box marginBottom='medium'>
                          <FloatingInputField
                            name='shippingCity'
                            label={t('common.address.city')}
                            required
                          />
                        </Box>
                        <Flex marginBottom='medium'>
                          <Box width={[1, 1 / 2]} marginRight={[0, 'small']}>
                            <StateSelect
                              name='shippingState'
                              label={t('common.address.state')}
                              variant='floating'
                              country={values.country}
                              required>
                              <option value=''></option>
                            </StateSelect>
                          </Box>
                          <Box width={[1, 1 / 2]}>
                            <FloatingInputField
                              name='shippingZipCode'
                              label={t('common.address.zip')}
                              required
                            />
                          </Box>
                        </Flex>
                      </Flex>
                    )}
                    <Box>
                      <RectangularButton
                        variant='primary'
                        type='submit'
                        width='100%'
                        disabled={isSubmitting}
                        onClick={() => submitForm()}
                        data-test-access-request-submit-button>
                        {t('common.submit')}
                      </RectangularButton>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Flex>
          </Flex>
          <Flex>
            <StyledLink to='/sign-out'>
              {t('accessRequest.gettingStarted.backToSignIn')}
            </StyledLink>
          </Flex>
        </Flex>
      </Box>
    </Titled>
  );
};

export default AccessForm;
