import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'utils/auth';
import { RedirectLoginOptions } from '@auth0/auth0-react';

import config from 'config';

const { connection } = config;

export const useLogin = () => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth();
  const location = useLocation();
  const returnTo = (): string => `${location.pathname}${location.search}`;
  const loginOptions: RedirectLoginOptions = {
    connection,
  };

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const opts = {
      ...loginOptions,
      appState: {
        ...loginOptions.appState,
        returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
      },
    };
    (async (): Promise<void> => {
      await loginWithRedirect(opts);
    })();
  }, [isLoading, isAuthenticated, loginWithRedirect, loginOptions, returnTo]);
};
