import React, { useState, useEffect } from 'react';
import { useRisClient } from '../../../hooks/useRisClient';
import { useCurrentUser } from '../../../context/CurrentUser';
import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import {
  Box,
  Heading,
  TableV2,
  useToastContext,
} from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  ActiveStatus,
  GetPartnerDeviceLocationModel,
  PartnerDeviceServiceType,
} from '@resideo/web-integration-services-api-client';
import { formatDateTime } from '../../../utils/common';
import { useServicesLinks } from '../../../hooks/useServicesLinks';
import { useModalContext } from '../../../context/ModalProvider';
import { DeactivateServiceModal } from '../Location/LocationModals/ServiceRequestModal/DeactivateServiceModalV2';
import { ENABLE_RESEND_CONSENT } from '../../../config/flags';
import { ActivateServiceModal } from '../Location/LocationModals/ServiceRequestModal/ActivateServiceModalV2';
import { ActivateServiceErrorModal } from '../Location/LocationModals/ServiceRequestModal/ActivateServiceErrorModal';

const ServiceHeadingContainer = styled.div`
  padding: 1.5rem 0 1rem 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-left: 10px;
  }
`;

const ServiceHeader = styled(Heading)`
  font-weight: bold;
  font-size: 21px;
`;

const ServicesContainer = styled(Box)`
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const CustomerAccountServices = location => {
  const { t } = useTranslation();
  const { servicesPath } = useServicesLinks();
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const { partnerAccountId } = useCurrentUser();
  const { openModal, closeModal } = useModalContext();
  const { addToast } = useToastContext();
  const [macId, setMacId] = useState('');
  const [serviceData, setServiceData] = useState<
    GetPartnerDeviceLocationModel | null | undefined
  >();
  const [
    isCustomerAlertsServiceActive,
    setIsCustomerAlertsServiceActive,
  ] = useState(false);

  const { refetch: fetchServiceData } = useQuery({
    queryKey: ['getPartnerDeviceLocationV2'],
    queryFn: async () => {
      const res = await client.myProAccounts.myProAccountsGetPartnerDeviceLocationV2(
        partnerAccountId || '',
        location?.locationId
      );

      return res?.data;
    },
    enabled: false,
    staleTime: 5000,
  });

  const { data: fetchSubscriptionInfo } = useSuspenseQuery({
    queryKey: ['homeOwnerAppBranding'],
    queryFn: async () =>
      (
        await client.myProAccounts.myProAccountsGetBrandingTile(
          partnerAccountId as string
        )
      ).data,
  });

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchServiceData();
      setServiceData(data);
      data?.deviceServices?.forEach(serviceInfo => {
        if (
          serviceInfo.type === 'ProAlertsPartnerDeviceService' &&
          serviceInfo.status === 'ACTIVE'
        ) {
          setIsCustomerAlertsServiceActive(true);
        }
      });
    };

    if (location?.locationId) {
      fetchData();
    }
  }, [location, fetchServiceData]);

  const activateDeviceService = useMutation({
    mutationKey: ['activateDeviceService'],
    mutationFn: async (variables: {
      partnerDeviceLocationId: string;
      deviceId: string;
    }) => {
      await client.myProAccounts.myProAccountsUpdatePartnerDeviceService(
        partnerAccountId as string,
        variables?.partnerDeviceLocationId as string,
        {
          type: PartnerDeviceServiceType.BRANDING_PARTNER_DEVICE_SERVICE,
          deviceId: variables?.deviceId,
          status: ActiveStatus.ACTIVE,
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['partnerCustomerLocations'] });
      addToast({
        toastType: 'success',
        message: t('customerDetails.reactivateService.successResponse'),
      });
      closeModal();
    },
    onError: err => {
      const parsedError = JSON.parse(JSON.stringify(err));
      const errorBody = parsedError?.body?.errors?.[0];
      if (errorBody?.reasonCode === 'ALREADY_REGISTERED') {
        openModal({
          title: t('customerDetails.activateService.title'),
          children: (
            <ActivateServiceErrorModal
              deviceId={macId}
              closeModal={closeModal}
            />
          ),
        });
      } else {
        closeModal();
        addToast({ toastType: 'error', message: t('common.toast.error') });
      }
    },
  });

  const handleActivateDeviceService = async (deviceId: string) => {
    await activateDeviceService.mutate({
      partnerDeviceLocationId: location?.locationId,
      deviceId,
    });
  };

  const deactivateDeviceService = useMutation({
    mutationKey: ['deactivateDeviceService'],
    mutationFn: async (variables: {
      partnerDeviceLocationId: string;
      deviceId: string;
    }) => {
      await client.myProAccounts.myProAccountsUpdatePartnerDeviceService(
        partnerAccountId as string,
        variables?.partnerDeviceLocationId as string,
        {
          type: PartnerDeviceServiceType.BRANDING_PARTNER_DEVICE_SERVICE,
          deviceId: variables?.deviceId,
          status: ActiveStatus.INACTIVE,
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['partnerCustomerLocations'] });
      addToast({
        toastType: 'success',
        message: t('customerDetails.deactivateService.successResponse'),
      });
      closeModal();
    },
    onError: () => {
      closeModal();
      addToast({ toastType: 'error', message: t('common.toast.error') });
    },
  });

  const handleDeactivateDeviceService = async (deviceId: string) => {
    await deactivateDeviceService.mutate({
      partnerDeviceLocationId: location?.locationId,
      deviceId,
    });
  };

  const DeviceServiceNameMap = {
    BrandingPartnerDeviceService: t(
      'customerDetails.deviceService.name.homeownerAppBranding'
    ),
    ProAlertsPartnerDeviceService: t(
      'customerDetails.deviceService.name.customerAlerts'
    ),
    InstallationPartnerDeviceService: t(
      'customerDetails.deviceService.name.installationPartnerDeviceService'
    ),
    RemoteAccess: t('customerDetails.deviceService.name.remoteAccess'),
  };

  const DeviceServiceStatusNameMap = {
    ACTIVE: t('customerDetails.deviceService.status.active'),
    INACTIVE: t('customerDetails.deviceService.status.inactive'),
  };

  const DeviceServiceConsentStatusMap = {
    PENDING: 'Pending',
    ACCEPTED: 'Approved',
    REJECTED: 'Declined',
  };

  const IsSubscriptionCancelled = () => {
    //getting a list of active Homeowner App Branding enrollments
    const activeHomeownerAppBrandingEnrollments = fetchSubscriptionInfo?.partnerUsers?.[0]?.partnerAccount?.partnerProgramEnrollments?.filter(
      enrollment =>
        enrollment?.partnerProgram?.name === 'Homeowner App Branding'
    );

    const mostRecentPurchase = activeHomeownerAppBrandingEnrollments?.sort(
      (a, b) =>
        Date.parse(b?.purchaseDate ?? '') - Date.parse(a?.purchaseDate ?? '')
    );

    // getting the most recent subscription to display in the tile
    const tileData = mostRecentPurchase?.[0];

    // checking if the status is Cancelled on the most recent enrollment
    return tileData?.status === 'CANCELLED';
  };

  const ServiceStatus = (status, consentStatus) => {
    if (status === 'ACTIVE' && consentStatus !== 'ACCEPTED') {
      return 'Pending';
    } else if (consentStatus === 'ACCEPTED' && IsSubscriptionCancelled()) {
      // if consentStatus is active and the recent subscription is cancelled
      return 'Suspended';
    } else {
      return DeviceServiceStatusNameMap[status];
    }
  };

  const RenderDeviceInfo = device => {
    let deviceDetails: { deviceType: any; macId: any } = {
      deviceType: '',
      macId: '',
    };
    serviceData?.devices?.filter(deviceInfo => {
      if (deviceInfo && deviceInfo.deviceId === device.deviceId) {
        deviceDetails = {
          deviceType: deviceInfo.globalDeviceType,
          macId: deviceInfo.deviceId,
        };
      }
    });
    return deviceDetails;
  };

  const statusAction = serviceInfo => {
    // destructive design button for mobile
    const deActivateServiceAction = [
      {
        name: t('customerDetails.deactivateService.deactivate'),
        ctaTag: t('customerDetails.deactivateService.deactivate'),
        text: t('customerDetails.deactivateService.deactivate'),
        disabled: isCustomerAlertsServiceActive,
        action: () => {
          openModal({
            title: t('customerDetails.deactivateService.title'),
            children: (
              <DeactivateServiceModal
                serviceName={DeviceServiceNameMap[serviceInfo.type]}
                deviceInfo={RenderDeviceInfo(serviceInfo.device)}
                onSubmit={async () => {
                  addToast({
                    toastType: 'Success',
                    message: t(
                      'customerDetails.deactivateService.successResponse'
                    ),
                  });
                  await handleDeactivateDeviceService(
                    serviceInfo.device?.deviceId
                  );
                  closeModal();
                }}
                onCancel={closeModal}
              />
            ),
          });
        },
      },
    ];

    const activateServiceAction = [
      {
        name: t('common.activate'),
        ctaTag: t('common.activate'),
        text: t('common.activate'),
        action: () => {
          openModal({
            title: t('customerDetails.activateService.title'),
            children: (
              <ActivateServiceModal
                serviceName={DeviceServiceNameMap[serviceInfo.type]}
                deviceInfo={RenderDeviceInfo(serviceInfo.device)}
                onSubmit={async () => {
                  setMacId(serviceInfo.device?.deviceId);
                  addToast({
                    toastType: 'Success',
                    message: t(
                      'customerDetails.activateService.successMessage'
                    ),
                  });
                  await handleActivateDeviceService(
                    serviceInfo.device?.deviceId
                  );
                  closeModal();
                }}
                onCancel={closeModal}
              />
            ),
          });
        },
      },
    ];

    const resendConsentServiceAction = [
      {
        name: t('customerDetails.resendConsent.consent'),
        ctaTag: t('customerDetails.resendConsent.consent'),
        text: t('customerDetails.resendConsent.consent'),
        action: () => {
          // Will add the Resend consent endpoint, once it's available
        },
      },
    ];

    const manageSubscriptionsAction = [
      {
        name: t('customerDetails.servicesTable.manageSubscriptions'),
        ctaTag: t('customerDetails.servicesTable.manageSubscriptions'),
        text: t('customerDetails.servicesTable.manageSubscriptions'),
        action: () => {
          window.location.href = servicesPath; // redirecting to manage Subscriptions page
        },
      },
    ];

    if (ENABLE_RESEND_CONSENT && serviceInfo.consentStatus !== 'ACCEPTED') {
      return resendConsentServiceAction;
    } else if (
      serviceInfo.consentStatus === 'ACCEPTED' &&
      IsSubscriptionCancelled()
    ) {
      // if consentStatus is active and the recent subscription is cancelled
      return manageSubscriptionsAction;
    } else if (
      serviceInfo.status === 'ACTIVE' &&
      serviceInfo.consentStatus === 'ACCEPTED'
    ) {
      return deActivateServiceAction;
    } else if (
      serviceInfo.status === 'INACTIVE' &&
      serviceInfo.consentStatus === 'ACCEPTED'
    ) {
      return activateServiceAction;
    }
    return [];
  };

  const getServiceData = () => {
    return serviceData?.deviceServices?.map(serviceInfo => {
      if (serviceInfo) {
        const { type, status, device, consentStatus, createdAt } = serviceInfo;
        const getDeviceDetails = RenderDeviceInfo(device);
        return {
          serviceType: type ? DeviceServiceNameMap[type] : '',
          associateDevice: getDeviceDetails?.deviceType || '',
          customerConsent: consentStatus
            ? `${
                DeviceServiceConsentStatusMap[consentStatus]
              } (${formatDateTime(createdAt || '', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })})`
            : '',
          serviceStatus: status ? ServiceStatus(status, consentStatus) : '',
          actions: statusAction(serviceInfo),
        };
      }
      return null;
    });
  };

  return (
    <>
      <ServiceHeadingContainer>
        <ServiceHeader>{t(`customerDetails.services`)}</ServiceHeader>
      </ServiceHeadingContainer>
      <ServicesContainer>
        <TableV2
          dataTestAttribute='data-test-services-table'
          NoDataMessage={t(
            'customerDetails.servicesTable.noServicesWithLocation'
          )}
          headers={[
            {
              displayName: t('customerDetails.servicesTable.typeHeader'),
              isSortable: false,
            },
            {
              displayName: t('customerDetails.servicesTable.associatedDevice'),
              isSortable: false,
            },
            {
              displayName: t('customerDetails.servicesTable.consentHeader'),
              isSortable: false,
              tooltipText: t(
                'customerDetails.servicesTable.consentHeaderTooltip'
              ),
            },
            {
              displayName: t('customerDetails.servicesTable.statusHeader'),
              isSortable: false,
              tooltipText: t(
                'customerDetails.servicesTable.statusHeaderTooltip'
              ),
            },
            {
              displayName: '',
              isSortable: false,
            },
          ]}
          rows={getServiceData() as any}
        />
      </ServicesContainer>
    </>
  );
};

export default CustomerAccountServices;
