// generated by i18n

export const supportedLngs = [
  'en',
  'bg',
  'cs',
  'de',
  'el',
  'es-419',
  'es',
  'fr-CA',
  'fr',
  'hr',
  'hu',
  'it',
  'nl',
  'pl',
  'pt-BR',
  'pt',
  'ro',
  'sk',
  'sl',
];
