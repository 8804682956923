import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import Error from 'routes/Error';
import Customers from './Customers';
import CustomerAccount from 'components/customers/CustomerAccount/CustomerAccount';

const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to='account-lookup' replace />,
  },
  {
    path: 'account-lookup',
    element: <Customers />,
  },
  {
    path: 'account-lookup/:id',
    element: <CustomerAccount />,
  },
  {
    path: '*',
    element: <Error />,
  },
];

export default routes;
