import React, { useEffect, useState } from 'react';

import { useAuth } from 'utils/auth';
import PageLoading from 'components/common/PageLoading';
import { useFeatureFlags } from './FeatureFlags';
import { useQuery } from '@tanstack/react-query';
import { useRisClient } from '../hooks/useRisClient';
import {
  PartnerRole,
  PartnerAccountApprovalStatus,
  PartnerAccountModel,
  CountryCode,
  InviteStatus,
} from '@resideo/web-integration-services-api-client';

type currentUserType = {
  isCurrentUserLoaded: boolean;
  error?: any;
  id?: null | string;
  firstName?: null | string;
  lastName?: null | string;
  photoUrl?: any;
  countryCode?: CountryCode;
  contactEmail?: null | string;
  isAdmin?: boolean;
  isOwner?: boolean;
  isOwnerAdmin?: boolean;
  isTechnician?: boolean;
  hasActivePartnerBrandProfile?: boolean;
  partnerAccountId?: null | string;
  partnerUserId?: null | string;
  viewerExists?: boolean;
  partnerAccount?: PartnerAccountModel | null;
  userStatus?: string | null | undefined;
  inviteStatus?: null | InviteStatus;
  partnerAccountName?: null | string;
  accountStatus?: PartnerAccountApprovalStatus | undefined;
  partnerUsersLength?: number;
  locale?: string | undefined | null;
  consentRequired?: boolean | null | undefined;
  setIsPollingViewer: (isPolling: boolean) => void;
};

const CurrentUsersContext = React.createContext<currentUserType>({
  isCurrentUserLoaded: false,
  setIsPollingViewer: () => undefined,
});

export const CurrentUserProvider = ({ children }) => {
  const auth = useAuth();
  const { REQUESTED_ACCESS_FLAG } = useFeatureFlags();
  const isAuthenticated = auth && auth.isAuthenticated && !auth.isLoading;
  const { client } = useRisClient();
  const [isPollingViewer, setIsPollingViewer] = useState<boolean>(false);

  // Set up a timer to stop polling after 10 seconds
  useEffect(() => {
    if (isPollingViewer) {
      const timer = setTimeout(() => {
        setIsPollingViewer(false);
      }, 10000); // 10 seconds

      return () => clearTimeout(timer);
    }
  }, [isPollingViewer]);

  const { data, isLoading, isFetched, error } = useQuery({
    queryKey: ['viewer'],
    queryFn: async () =>
      (await client.myProUsers?.myProUsersGetMyProUser()).data,
    enabled: isAuthenticated,
    staleTime: 5000,
    refetchInterval: isPollingViewer ? 1000 : false,
  });

  const {
    data: inviteStatusData,
    isLoading: inviteStatusDataLoading,
    isFetching: inviteStatusDataCalled,
  } = useQuery({
    queryKey: ['myRequests'],
    queryFn: async () =>
      (await client.myProUsers.myProUsersGetAddRequests()).data,
    // only enable this query for users without a company association
    enabled: !!(
      data?.id &&
      REQUESTED_ACCESS_FLAG &&
      !data?.partnerUsers?.[0]?.status
    ),
    staleTime: 5000,
  });

  let currentUser: currentUserType = {
    isCurrentUserLoaded: false,
    setIsPollingViewer,
  };

  if (
    (isAuthenticated && isLoading) ||
    (isAuthenticated && inviteStatusDataLoading && inviteStatusDataCalled)
  ) {
    return <PageLoading />;
  }

  if (isAuthenticated && isFetched) {
    const id = data?.id;
    const viewerExists = !!id;
    const firstName = data?.firstName;
    const lastName = data?.lastName;
    const photoUrl = data?.photoUrl;
    const countryCode = data?.countryCode as CountryCode;
    const contactEmail = data?.contactEmail;

    const partnerUser = data?.partnerUsers?.[0];
    const partnerUsersLength = data?.partnerUsers?.length;
    const partnerUserId = partnerUser?.id;
    const inviteStatus = inviteStatusData?.[0]?.status;
    const partnerAccountName = inviteStatusData?.[0]?.partnerAccount?.name;
    const userStatus = partnerUser?.status;
    const isAdmin =
      partnerUser?.roles?.includes('COMPANY_ADMIN' as PartnerRole) || false;
    const isOwner =
      partnerUser?.roles?.includes('OWNER' as PartnerRole) || false;
    const isOwnerAdmin = isOwner || isAdmin;

    const isTechnician =
      partnerUser?.roles?.includes('TECHNICIAN' as PartnerRole) || false;
    const partnerAccount = partnerUser?.partnerAccount;
    const hasActivePartnerBrandProfile =
      partnerAccount?.partnerProgramEnrollments?.filter(
        enrollment =>
          enrollment?.partnerProgram?.name === 'Homeowner App Branding'
      )?.[0]?.status === 'ACTIVE' || false;
    const partnerAccountId = partnerAccount?.id;
    const accountStatus = partnerAccount?.status as PartnerAccountApprovalStatus;

    currentUser = {
      isCurrentUserLoaded: true,
      error,
      id,
      firstName,
      lastName,
      photoUrl,
      countryCode,
      contactEmail,
      isAdmin,
      isOwner,
      isOwnerAdmin,
      isTechnician,
      hasActivePartnerBrandProfile,
      partnerAccountId,
      partnerAccountName,
      partnerUserId,
      viewerExists,
      partnerAccount,
      userStatus,
      inviteStatus,
      accountStatus,
      partnerUsersLength,
      locale: data?.locale,
      consentRequired: data?.consentRequired,
      setIsPollingViewer,
    };
  }

  return (
    <CurrentUsersContext.Provider value={currentUser}>
      {children}
    </CurrentUsersContext.Provider>
  );
};

export const useCurrentUser = () => React.useContext(CurrentUsersContext);
