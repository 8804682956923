import React, { FC } from 'react';
import styled from 'styled-components';
import { Flex } from '@resideo/blueprint-react';
import useTerms from './useTerms';
import { TermsInterface } from '../../interfaces/TermsInterface';

export const StyledTerms = styled.div`
  h2 {
    font-size: ${({ theme }) => theme.fontSizes.large};
    margin: 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
      /* padding: ${({ theme }) => `0 ${theme.space.medium} 0`}; */
      font-weight: ${({ theme }) => theme.fontWeights.header};
    }
  }

  ol {
    padding: ${({ theme }) => `0 ${theme.space.large} 0`};
  }
  h3 {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    margin-bottom: ${({ theme }) => theme.space.xSmall};
    margin-top: ${({ theme }) => theme.space.xSmall};
    font-weight: ${({ theme }) => theme.fontWeights.body};
  }
  li {
    margin-bottom: ${({ theme }) => theme.space.large};
  }
  p {
    &:first-child {
      margin-left: ${({ theme }) => theme.space.medium};
      margin-bottom: ${({ theme }) => theme.space.small};
    }
    word-break: break-word;
  }
  :first-of-type {
    font-weight: ${({ theme }) => theme.fontWeights.body};
    padding: ${({ theme }) => `${theme.space.medium} ${theme.space.small} 0 0`};
  }
`;

export const HeadingContainer = styled(Flex)`
  padding: ${({ theme }) =>
    `${theme.space.small} ${theme.space.large} ${theme.space.small} ${theme.space.large}`};
  background-color: #e5e5e5;
`;

export const StyledHeader = styled.h1`
  font-size: 21px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 21px;
  }
`;

export const OutsideScrollContainer = styled(Flex)`
  padding: ${({ theme }) =>
    `${theme.space.small} ${theme.space.large} 0 ${theme.space.large}`};
  background-color: #e5e5e5;
  margin-bottom: 0;
`;

export const TermsContainer = styled(Flex)`
  background-color: #e5e5e5;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: ${({ theme }) => theme.lineHeights.body};
  max-height: 700px;
  overflow-y: scroll;
  position: relative;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
  ::-webkit-scrollbar {
    appearance: none;
    background-color: rgba(0, 0, 0, 0.25);
    width: 0.5rem;
    padding-left: 4rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export interface TandCProps {
  termsDoc: TermsInterface;
}

const TermsAndConditions: FC<TandCProps> = ({ termsDoc }): JSX.Element => {
  function decodeHtml(html) {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  return (
    <StyledTerms
      data-test-terms-content
      dangerouslySetInnerHTML={{ __html: decodeHtml(termsDoc.html) }}
    />
  );
};

export default TermsAndConditions;

export { useTerms };
