import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Box } from '@resideo/blueprint-react';
const gradient = keyframes`
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
`;
const GradientBar = styled(Box)`
  height: 15px;
  background-image: linear-gradient(to right, #d9d9d9, #fcfcfc, #d9d9d9);
  background-size: 200% 100%;
  animation: ${gradient} 3s ease-in-out infinite;
  border-radius: 10px;
`;

const LoadingAnimation = () => {
  return (
    <>
      <GradientBar width='255px' marginBottom='5px' />
      <GradientBar width='255px' marginBottom='5px' />
      <GradientBar width='160px' marginBottom='10px' />
      <GradientBar width='216px' marginBottom='5px' />
      <GradientBar width='85px' marginBottom='5px' />
      <GradientBar width='85px' marginBottom='5px' />
      <GradientBar width='85px' />
    </>
  );
};
export default LoadingAnimation;
