const proSeriesTitles = t => [
  {
    title: t('proSeries.icons.titles.dataSheets'),
    type: 'data-sheets',
  },
  {
    title: t('proSeries.icons.titles.homeOwner'),
    type: 'home-owner',
  },
  {
    title: t('proSeries.icons.titles.smbOwner'),
    type: 'smb-owner',
  },
  {
    title: t('proSeries.icons.titles.infoForDealers'),
    type: 'dealer-info',
  },
  {
    title: t('proSeries.icons.titles.graphic'),
    type: 'graphic-images',
    subs: [
      {
        title: t('proSeries.icons.titles.applicationImages'),
        type: 'application-images',
      },
      {
        title: t('proSeries.icons.titles.groupImages'),
        type: 'group-images',
      },
      {
        title: t('proSeries.icons.titles.productImages'),
        type: 'product-images',
      },
    ],
  },
];

export default proSeriesTitles;
