import { useToastContext } from '@resideo/blueprint-react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'utils/auth';
import AppLayoutAnonymous from 'components/layouts/AppLayoutAnonymous';
import InviteExpired from './InviteExpired';
import PageLoading from 'components/common/PageLoading';
import PageError from 'components/common/PageError';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery, useMutation } from '@tanstack/react-query';
import { deserializeInviteToken } from 'utils/common';

const AcceptInvite = () => {
  const { addToast } = useToastContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [inviteError, setInviteError] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const inviteToken = searchParams.get('invite') || '';
  const { isAuthenticated, getAccessTokenSilently } = useAuth();
  const { inviteId = '' } = deserializeInviteToken(inviteToken);
  const { client } = useRisClient();

  const { data: inviteData } = useSuspenseQuery({
    queryKey: ['getInviteById'],
    queryFn: async () => {
      const res = await client.public.publicGetInvitation(inviteId);
      return res?.data;
    },
  });

  const existingInviteId = inviteData?.id;
  const currentExpiration = new Date(inviteData?.expiresAt || 0);
  const expired = Date.now() > currentExpiration.getTime();

  const accept = useMutation({
    mutationKey: ['acceptInvite'],
    mutationFn: async () => {
      await client.myProUsers.myProUsersAcceptEmployeeInvite(inviteId);
    },
    onSuccess: async () => {
      setInviteError(false);

      // Force a token refresh for the new partnerAccount
      await getAccessTokenSilently({ ignoreCache: true });

      navigate('/');
      navigate(0);
    },
    onError: () => {
      setInviteError(true);
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
    },
  });

  useEffect(() => {
    if (existingInviteId && isAuthenticated && !expired) {
      (async () => {
        await accept.mutate();
      })();
    }
  }, [existingInviteId, isAuthenticated, expired]);

  if (expired || !existingInviteId) {
    return (
      <AppLayoutAnonymous showHeader={false} showFooter={false}>
        <InviteExpired />
      </AppLayoutAnonymous>
    );
  }

  if (inviteError) {
    return (
      <AppLayoutAnonymous showHeader={false} showFooter={false}>
        <PageError />
      </AppLayoutAnonymous>
    );
  }

  return (
    <AppLayoutAnonymous showHeader={true}>
      <PageLoading />
    </AppLayoutAnonymous>
  );
};

export default AcceptInvite;
