import React, { useState, useEffect, useMemo } from 'react';
import { Form, Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Spinner,
  Text,
} from '@resideo/blueprint-react';
import { InputField, PasswordField } from '@resideo/blueprint-formik';

import { ENABLE_MOCKS } from 'config';
import Alert from 'components/common/Alert';
import PageLoading from 'components/common/PageLoading';
import { useAuth, AuthContextInterface } from 'utils/auth';
import { WebAuth } from 'utils/auth/WebAuth';
import { checkEmail } from 'utils/validation';

interface ErrorMessage {
  message: string;
  description: string;
}

interface LoginValues {
  email: string;
  password: string;
}

const SignIn = (): JSX.Element => {
  const webAuth = useMemo(() => new WebAuth(), []);
  const navigate = useNavigate();

  const [signInError, setSignInError] = useState<ErrorMessage>();
  const [showPassword, togglePassword] = useState(false);
  const { t } = useTranslation();

  const {
    isAuthenticated,
    setIsAuthenticated,
    isLoading,
    restoreSession,
  } = useAuth() as AuthContextInterface;

  useEffect(() => {
    if (isAuthenticated || (!ENABLE_MOCKS && !isLoading)) {
      navigate(
        `${window.location.pathname}${window.location.search}` ||
          '/mybusiness/home'
      );
    }
  }, [navigate, isAuthenticated, isLoading]);

  async function submitForm({ email: username, password }) {
    try {
      await webAuth.usernamePassword(username, password);
      restoreSession();
      setIsAuthenticated(true);
      navigate('/mybusiness/home');
    } catch (e) {
      const error = e as any;
      if (error.statusCode === 400) {
        error.message = `${t('signIn.errorMessage')} ${error.description}`;
        setSignInError({
          message: error.message,
          description: error.description,
        });
      }
      setSignInError({
        message: error.message,
        description: error.description,
      });
    }
  }

  const validate = (values: LoginValues) => {
    const errors: FormikErrors<LoginValues> = {};

    if (!values.email) {
      errors.email = t('signIn.validation.noEmail');
    } else if (!checkEmail(values.email)) {
      errors.email = t('signIn.validation.invalidEmail');
    }
    if (!values.password) {
      errors.password = t('signIn.validation.passwordRequired');
    } else if (values.password.length < 7) {
      errors.password = t('signIn.validation.passwordLength');
    }

    return errors;
  };

  if (ENABLE_MOCKS) {
    return (
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={submitForm}
        validate={validate}>
        {({ isSubmitting }) => (
          <Box
            as='main'
            marginX='auto'
            maxWidth='36rem'
            paddingX={[0, 'medium']}
            paddingY={['large', 'large', 'xLarge']}
            width={1}>
            <Heading
              as='h1'
              paddingTop='large'
              paddingBottom='medium'
              paddingX='medium'
              fontSize='xxxLarge'>
              {t('signIn.title')}
            </Heading>
            <Form data-test-sign-in-form noValidate>
              {signInError && (
                <Card marginBottom='medium'>
                  <Alert data-test-error variant='error'>
                    <Text>
                      {signInError.message || signInError.description}
                    </Text>
                  </Alert>
                </Card>
              )}
              <Card
                borderRadius={[0, 'medium']}
                paddingX={['medium', 'large']}
                paddingY='large'>
                <Box data-test-email-control>
                  <InputField
                    autoComplete='username'
                    label='Email'
                    marginBottom='medium'
                    name='email'
                  />
                </Box>
                <Box data-test-password-control>
                  <PasswordField
                    autoComplete='current-password'
                    hidePasswordLabel={t('common.forms.hidePassword')}
                    label='Password'
                    marginBottom='large'
                    name='password'
                    onToggle={() => togglePassword(!showPassword)}
                    showPassword={showPassword}
                    showPasswordLabel={t('common.forms.showPassword')}
                  />
                </Box>
                <Flex flexDirection={['column', 'column', 'row-reverse']}>
                  <Button width={[1, 'auto']} variant='primary' type='submit'>
                    {isSubmitting && !signInError && (
                      <Spinner
                        aria-label={t('signIn.inProgress')}
                        marginX='small'
                        verticalAlign='text-top'
                      />
                    )}
                    {(!isSubmitting || signInError) && t('signIn.button')}
                  </Button>
                </Flex>
              </Card>
            </Form>
          </Box>
        )}
      </Formik>
    );
  }
  return <PageLoading />;
};

export default SignIn;
