import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import DownloadCard from 'components/productDownload/DownloadCard';
import { useParams } from 'react-router-dom';
import HvacAndWaterProductsSidebar from 'components/hvacWater/HvacAndWaterProductsSidebar';
import ProSeriesSidebar from 'components/proseries/ProSeriesSideBar';
import { hvacAndWaterTitles, mobileTitle } from 'components/hvacWater';
import proSeriesTitles from 'components/proseries/proSeriesTitles';

const Container = styled(Flex)`
  padding: ${({ theme }) => theme.space.medium};
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: ${({ theme }) => theme.space.large};
  }
`;

const Main = styled.main`
  margin: 0 auto;
  padding-top: 0;
`;

const StyledHeader = styled.h1`
  display: flex;
  font-family: ${({ theme }) => theme.fonts.heading};
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: ${({ theme }) => theme.space.medium};
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: ${({ theme }) => theme.space.large};
  }
`;

const ProductDownload = () => {
  const { pageType } = useParams<{ pageType: 'proseries' | 'hvacWater' }>();
  const [showCard, setShowCard] = useState(false);
  const [cardType, getType] = useState(
    pageType === 'proseries' ? 'data-sheets' : 'all'
  );
  const { t } = useTranslation();

  const handleClick = type => {
    getType(type);
    setShowCard(showCard ? !showCard : showCard);
  };

  return (
    <Main>
      <StyledHeader>
        {pageType === 'proseries' && t('proSeries.launchKit')}
        {pageType === 'hvacWater' && t('hvacAndWater.title')}
      </StyledHeader>
      <Flex
        flexDirection={['column', 'column', 'column', 'row']}
        flexWrap='nowrap'>
        <Flex flex='1' flexDirection='column'>
          <Container>
            <Flex
              flexDirection={['column', 'column', 'column', 'row']}
              flexWrap='wrap'
              width={1}>
              {pageType === 'hvacWater' && (
                <HvacAndWaterProductsSidebar
                  cardType={cardType}
                  handleClick={handleClick}
                  titles={hvacAndWaterTitles(t)}
                  mobile={mobileTitle(t)}
                />
              )}
              {pageType === 'proseries' && (
                <ProSeriesSidebar
                  cardType={cardType}
                  handleClick={handleClick}
                  titles={proSeriesTitles(t)}
                />
              )}
            </Flex>
          </Container>
        </Flex>
        <Flex flex='3'>
          <Box width={1}>
            <DownloadCard show={showCard} type={cardType} param={pageType} />
          </Box>
        </Flex>
      </Flex>
    </Main>
  );
};

export default ProductDownload;
