import React, { useCallback, useEffect, useRef } from 'react';
import { Spinner } from '@resideo/blueprint-react';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
`;

const LoadMore = ({ loadMore, loading = false }) => {
  const sentinelRef = useRef<HTMLDivElement | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting) {
        loadMore();
      }
    },
    [loadMore]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    observer.current = new IntersectionObserver(handleObserver, option);

    if (sentinelRef.current) {
      observer.current.observe(sentinelRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [handleObserver]);

  return (
    <div>
      {loading && (
        <SpinnerContainer>
          <Spinner color='primary' size='xxLarge' />
        </SpinnerContainer>
      )}
      <div ref={sentinelRef} style={{ height: '1px' }} />
    </div>
  );
};

export default LoadMore;
