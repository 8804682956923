const parseBoolean = (str = '') => str.toLowerCase() === 'true';

// Environment
export const ENABLE_MOCKS = parseBoolean(
  import.meta.env.REACT_APP_ENABLE_MOCKS
);

export const IS_TEST = parseBoolean(import.meta.env.REACT_APP_IS_TEST);

const config = {
  //Auth0
  audience: import.meta.env.REACT_APP_AUTH0_AUDIENCE || '',
  clientId: import.meta.env.REACT_APP_AUTH0_CLIENT_ID || '',
  connection: 'Username-Password-Authentication',
  domain: import.meta.env.REACT_APP_AUTH0_DOMAIN || '',
  namespace: `${import.meta.env.REACT_APP_AUTH0_DOMAIN}/`,
  scope: 'openid profile email',
  claimsNamespace: import.meta.env.REACT_APP_AUTH0_CLAIMS_NAMESPACE,
};

export const IDLE_TIMEOUT = 30 * 60 * 1000;

// Sentry
export const SENTRY_DSN = import.meta.env.REACT_APP_SENTRY_DSN || '';
export const ENVIRONMENT =
  import.meta.env.REACT_APP_ENVIRONMENT || 'development';
export const SENTRY_SAMPLE_RATE = parseFloat(
  import.meta.env.REACT_APP_SENTRY_SAMPLE_RATE || '0.2'
);
export const SENTRY_ENABLE_LOCALHOST = parseBoolean(
  import.meta.env.REACT_APP_SENTRY_ENABLE_LOCALHOST
);

export const COUNTRY_CODE_API_KEY =
  import.meta.env.REACT_APP_COUNTRY_STATE_API_KEY || '';

// ImageKit IMK
export const imkPublicKey = import.meta.env.REACT_APP_IMK_PUBLIC_KEY || '';
export const imkUrlEndpoint = import.meta.env.REACT_APP_IMK_URL_ENDPOINT || '';
export const imkLogoFolder = import.meta.env.REACT_APP_IMK_LOGO_FOLDER || '';
export const imkUploadUrl =
  import.meta.env.REACT_APP_IMK_UPLOAD_URL ||
  'https://upload.imagekit.io/api/v1/files/upload';

export const RESIDEO_ID_URL = import.meta.env.REACT_APP_RESIDEO_ID_URL;

export const RIS_BASE_URL = import.meta.env.REACT_APP_RIS_BASE_URL || '';

export const AMS_BASE_URL = import.meta.env.REACT_APP_AMS_BASE_URL || '';

export const GOOGLE_MAPS_API_KEY =
  import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

export default config;
