import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@resideo/blueprint-react';

import SuccessIcon from 'components/icons/yes.svg?react';
import ErrorIcon from 'components/icons/alert.svg?react';
import InfoIcon from 'components/icons/info.svg?react';
import BellIcon from 'components/icons/bell.svg?react';
import { IoIosCloseCircleOutline } from 'react-icons/io';

const StatusBox = styled(Box)<{ color: string }>`
  flex-shrink: 0;
  padding: ${({ theme }) => theme.space.small};

  svg {
    fill: ${({ color, theme }) => theme.colors[color]};
    height: 3rem;
    vertical-align: middle;
    width: 3rem;
  }
`;

const IconBox: FC<{ variant: string }> = ({ variant }) => {
  const icons = {
    success: { icon: SuccessIcon, color: 'success' },
    error: { icon: ErrorIcon, color: 'danger' },
    errorWithButton: { icon: IoIosCloseCircleOutline, color: 'danger' },
    perks: { icon: BellIcon, color: 'primary' },
    info: { icon: InfoIcon, color: 'primary' },
  };

  const IconComponent = icons[variant].icon;
  const color = icons[variant].color;

  return (
    <StatusBox color={color}>
      <IconComponent />
    </StatusBox>
  );
};

const Alert: FC<{ variant?: string; children?: ReactElement }> = ({
  children,
  variant = 'info',
  ...rest
}) => {
  return (
    <Flex {...rest}>
      <IconBox {...{ variant }} />
      <Flex
        flexDirection='column'
        justifyContent='center'
        paddingLeft='medium'
        paddingRight={
          variant !== 'perks' && variant !== 'errorWithButton'
            ? 'xLarge'
            : 'large'
        }
        paddingY='medium'>
        {children}
      </Flex>
    </Flex>
  );
};

export default Alert;
