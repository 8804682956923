import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Text } from '@resideo/blueprint-react';
import CloseIcon from '../icons/circle-close.svg?react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const PageWrapper = styled(Box)`
  margin-left: 17%;
  margin-top: 8%;
  width: 30%;
  margin-bottom: 20%;
`;

const HeadingContent = styled(Flex)`
  height: 100%;
`;

const AccessDeniedText = styled(Text)`
  margin-bottom: 4%;
`;

const AccessDeniedFinalText = styled(Text)`
  margin-bottom: 7%;
`;

const StyledHeading = styled.h1`
  text-align: left;
  font: normal normal bold 36px/48px Roboto;
  letter-spacing: 0;
  color: #2f3c52;
  opacity: 1;
  padding-left: 4%;
  margin-top: -1%;
`;

export const AccessRemoved = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageWrapper>
      <HeadingContent>
        <CloseIcon data-test-close-icon />
        <StyledHeading>{t('accessDenied.pageHeader')}</StyledHeading>
      </HeadingContent>
      <AccessDeniedText data-test-text1>
        {t('accessDenied.pageText')}
      </AccessDeniedText>
      <AccessDeniedText data-test-text2>
        {t('accessDenied.pageText1')}
      </AccessDeniedText>
      <AccessDeniedFinalText data-test-text3>
        {t('accessDenied.pageText2')}
      </AccessDeniedFinalText>
      <Button
        data-test-access-denied-goback-button
        variant='primary'
        marginRight='large'
        onClick={() => {
          navigate('/sign-out');
        }}>
        {t('common.goBack')}
      </Button>
    </PageWrapper>
  );
};
