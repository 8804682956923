import React, { useState } from 'react';
import { Button, Flex, Text, useToastContext } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { useRisClient } from 'hooks/useRisClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCurrentUser } from 'context/CurrentUser';
import { PartnerAccountApprovalStatus } from '@resideo/web-integration-services-api-client';

const RestoreEmployeeModalContent = ({ closeModal, id, name, email }) => {
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { client } = useRisClient();
  const { partnerAccountId } = useCurrentUser();
  const queryClient = useQueryClient();

  const statuses = {};
  statuses[t('common.roles.TECHNICIAN')] = 'TECHNICIAN';
  statuses[t('common.roles.ADMIN')] = 'ADMIN';

  const employeeRestoreMutation = useMutation({
    mutationKey: ['employeeRestoreMutation'],
    mutationFn: async (id: string) => {
      await client.myProAccounts.myProAccountsUpdatePartnerUser(
        partnerAccountId as string,
        id,
        { status: PartnerAccountApprovalStatus.ACTIVE }
      );
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
      setSubmitting(false);
      closeModal();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['getPartnerUserById'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['getArchivedEmployees'],
      });
      addToast({
        toastType: 'Success',
        message: t('mybusiness.employees.toast.employeeRestored', {
          name,
        }),
      });
      setSubmitting(false);
      closeModal();
    },
  });

  const restoreEmployee = async id => {
    setSubmitting(true);
    employeeRestoreMutation.mutate(id);
  };

  return (
    <Flex flexDirection='column'>
      <Text marginBottom='medium'>
        {t('mybusiness.employees.restoreModal.areYouSure')}
      </Text>
      <Text
        fontWeight='bold'
        fontSize='large'
        marginBottom='small'
        justifyContent='center'>
        {name}
      </Text>
      <Text fontWeight='bold' fontSize='medium' marginBottom='large'>
        {email}
      </Text>
      <Flex justifyContent='flex-end'>
        <Button
          data-test-restore-modal-cancel-button
          onClick={() => closeModal()}
          marginRight='large'
          variant='tertiary'>
          {t('common.cancel')}
        </Button>
        <Button
          data-test-restore-modal-restore-button
          disabled={submitting}
          onClick={() => restoreEmployee(id)}
          variant='primary'>
          {t('mybusiness.employees.restoreModal.yesRestore')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default RestoreEmployeeModalContent;
