import React, { ComponentType, FC, lazy } from 'react';
import {
  Navigate,
  Outlet,
  RouteObject,
  useOutletContext,
} from 'react-router-dom';
import Error from 'routes/Error';
import AlertsDashboard from './Alerts/AlertsDashboard';
import AlertSettings from './Alerts/AlertSettings';
import { useFeatureFlags } from 'context/FeatureFlags';
import LeftNavLayout from 'components/leftNavigation/LeftNavLayout';
import { withFlaggedRoute } from 'components/common/Route';
import { isEMEA } from 'config/regions';
import PageUnavailable from 'components/common/PageUnavailable';
import AccessDenied from 'components/common/AccessDenied';
import customerAccountsRoutes from './CustomerAccounts';
import companyInfoRoutes from './CompanyInfo';
import manageBrandProfileRoutes, {
  ManageBrandProfileLayout,
} from './ManageBrandProfile';
import businessIntelligenceRoutes, {
  ServicesRoutesLayout,
} from 'routes/ServicesRoutes/BusinessIntelligenceRoutes';
import coBrandingRoutes from 'routes/ServicesRoutes/CoBrandingRoutes';
import { useCurrentUser } from 'context/CurrentUser';
import CompleteServiceSetup from './CompleteSerivceSetup';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';
import {
  COMPLETE_SERVICE_SETUP,
  ENABLE_CUSTOMIZE_ALERT_SETTINGS,
} from 'config/flags';
import AlertsServiceSettings from './Alerts/AlertsServiceSettings';

type LazyModulePromise = Promise<{ default: ComponentType<any> }>;

const Dashboard = lazy((): LazyModulePromise => import('routes/Home'));
const TrainingSupport = lazy(
  (): LazyModulePromise => import('./TrainingSupport')
);

export const MyBusinessLayout: FC = () => {
  const { IS_EMEA_OR_GB_MARKET } = useFeatureFlags();
  const { isOwner, isAdmin, accountStatus } = useCurrentUser();

  // Showing the company info, when account-status is null as well
  const isAccountActive = accountStatus == 'ACTIVE' || accountStatus === null;
  const customerAccountPermissions =
    (isOwner || isAdmin) && isAccountActive && !IS_EMEA_OR_GB_MARKET;
  const viewerHasPermissions = (isOwner || isAdmin) && isAccountActive;

  return (
    <LeftNavLayout>
      <Outlet
        context={{
          viewerHasPermissions,
          customerAccountPermissions,
        }}
      />
    </LeftNavLayout>
  );
};

const withPermissionedRoute = (
  Element: JSX.Element,
  customerAccountCheck = false
) => {
  const { viewerHasPermissions, customerAccountPermissions } = useOutletContext<
    any
  >();

  return viewerHasPermissions &&
    (!customerAccountCheck || customerAccountPermissions) ? (
    Element
  ) : (
    <AccessDenied />
  );
};

const ManageBrandProfileRoot = () => {
  const { countryCode, hasActivePartnerBrandProfile } = useCurrentUser();

  return (
    <>
      {!hasActivePartnerBrandProfile ? (
        <>
          {isEMEA(countryCode) ? (
            <Navigate to='/mybusiness/services-subscriptions' replace />
          ) : (
            <PageUnavailable />
          )}
        </>
      ) : (
        <ManageBrandProfileLayout />
      )}
    </>
  );
};

const ManageServiceSetupRoot = () => {
  const { partnerAccountId } = useCurrentUser();
  const { client } = useRisClient();

  const { data } = useSuspenseQuery({
    queryKey: ['homeOwnerAppBranding'],
    queryFn: async () =>
      (
        await client.myProAccounts.myProAccountsGetBrandingTile(
          partnerAccountId ?? ''
        )
      ).data,
  });

  const hasActiveHomeOwnerAppBranding =
    data?.partnerUsers?.[0]?.partnerAccount?.partnerProgramEnrollments?.filter(
      enrollment =>
        enrollment?.partnerProgram?.name === 'Homeowner App Branding'
    )?.[0]?.status === 'ACTIVE';

  const hasActiveProIQAdvanced =
    data?.partnerUsers?.[0]?.partnerAccount?.partnerProgramEnrollments?.filter(
      enrollment => enrollment?.partnerProgram?.name === 'Pro IQ Advanced'
    )?.[0]?.status === 'ACTIVE';

  const hasActivePartnerBrandProfile =
    data?.partnerUsers?.[0]?.partnerAccount?.partnerBrandProfile;

  if (COMPLETE_SERVICE_SETUP) {
    return (
      <>
        {hasActiveProIQAdvanced || hasActiveHomeOwnerAppBranding ? (
          <CompleteServiceSetup
            hasActiveHomeOwnerAppBranding={hasActiveHomeOwnerAppBranding}
            hasActiveProIQAdvanced={hasActiveProIQAdvanced}
            hasActivePartnerBrandProfile={hasActivePartnerBrandProfile}
          />
        ) : (
          <PageUnavailable />
        )}
      </>
    );
  } else {
    return <PageUnavailable />;
  }
};

const AlertSettingsRoot = () => {
  const { partnerAccountId } = useCurrentUser();
  const { client } = useRisClient();

  const { data } = useSuspenseQuery({
    queryKey: ['homeOwnerAppBranding'],
    queryFn: async () =>
      (
        await client.myProAccounts.myProAccountsGetBrandingTile(
          partnerAccountId ?? ''
        )
      ).data,
  });

  const hasActiveProAlerts =
    data?.partnerUsers?.[0]?.partnerAccount?.partnerProgramEnrollments?.filter(
      enrollment => enrollment?.partnerProgram?.name === 'Pro Alerts'
    )?.[0]?.status === 'ACTIVE';

  if (hasActiveProAlerts && ENABLE_CUSTOMIZE_ALERT_SETTINGS) {
    return <AlertSettings />;
  } else {
    return <PageUnavailable />;
  }
};

const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to='/mybusiness/home' replace />,
  },
  {
    path: 'dashboard',
    element: <Navigate to='/mybusiness/home' replace />,
  },
  {
    path: 'training',
    element: <TrainingSupport />,
  },
  {
    path: 'home',
    element: <Dashboard />,
  },
  {
    path: 'customers/*',
    Component: () => withPermissionedRoute(<Outlet />, true),
    children: customerAccountsRoutes,
  },
  {
    path: 'business-intelligence',
    element: <ServicesRoutesLayout />,
    children: businessIntelligenceRoutes,
  },
  {
    path: 'co-branding',
    element: <ServicesRoutesLayout />,
    children: coBrandingRoutes,
  },
  {
    path: 'company/*',
    Component: () => withPermissionedRoute(<Outlet />),
    children: companyInfoRoutes,
  },
  {
    path: 'services/brand-profile/*',
    Component: () => ManageBrandProfileRoot(),
    children: manageBrandProfileRoutes,
  },
  {
    path: 'alerts/dashboard/*',
    Component: () => withFlaggedRoute(AlertsDashboard, 'CUSTOMER_ALERTS'),
  },
  {
    path: 'alerts/alert-settings',
    Component: () => AlertSettingsRoot(),
  },
  {
    path: '*',
    element: <Error />,
  },
  {
    path: 'services/setup/customer-alerts-settings',
    Component: () =>
      withFlaggedRoute(AlertsServiceSettings, 'COMPLETE_SERVICE_SETUP'),
  },
  {
    path: 'services/setup',
    Component: () => ManageServiceSetupRoot(),
  },
];

export default routes;
