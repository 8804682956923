import { useEffect, useRef } from 'react';

interface CustomMarkerProps {
  position: any;
  map: google.maps.Map | null;
}

const GoogleMapsMarker: React.FC<CustomMarkerProps> = ({ position, map }) => {
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(
    null
  );

  useEffect(() => {
    if (window.google && map) {
      // Create the AdvancedMarkerElement
      markerRef.current = new window.google.maps.marker.AdvancedMarkerElement({
        position: position,
      });

      // Set the map for the marker
      markerRef.current.map = map;
    }
  }, [position, map]);

  return null; // This component doesn't need to render anything itself
};

export default GoogleMapsMarker;
