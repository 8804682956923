import {
  DescriptionDefinition,
  DescriptionList,
  DescriptionTerm,
  BrandProfilePreview,
  Text,
} from '@resideo/blueprint-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PartnerBrandProfile } from '../../../utils/common';
import { BRAND_PROFILE_X12_TAB } from '../../../config/flags';
import { useCurrentUser } from 'context/CurrentUser';

const PreviewSection = styled.div`
  margin-top: ${({ theme }) => theme.space.large};
  margin-bottom: 30px;
`;

const PreviewHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.space.medium};
`;

const PreviewTitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: ${({ theme }) => theme.space.small};
`;

const Status = styled(DescriptionDefinition)`
  display: inline;
  color: white;
  font-size: 12px;
`;

const Active = styled.span`
  background: green;
  border-radius: 4px;
  padding: 2px 10px 3px 10px;
`;
const Deactivated = styled.span`
  background: gray;
  border-radius: 4px;
  padding: 2px 10px 3px 10px;
`;

const DescriptionListContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(1fr, 2);
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: block;
  }
`;

const Logo = styled.img`
  margin-top: 10px;
  max-height: 100px;
  max-width: 400px;
`;

const CustomerServiceLabel = styled(DescriptionTerm)`
  margin-top: ${({ theme }) => theme.space.medium};
`;

const BrandProfileView = ({
  partnerBrandProfile,
}: {
  partnerBrandProfile: PartnerBrandProfile | undefined | null;
}) => {
  const { t } = useTranslation();
  const [isActiveStatus, setIsActiveStatus] = useState<boolean>(false);
  const { partnerAccount } = useCurrentUser();

  useEffect(() => {
    if (partnerBrandProfile) {
      setIsActiveStatus(partnerBrandProfile.status === 'ACTIVE');
    }
  }, [partnerBrandProfile]);

  return (
    <>
      <DescriptionListContainer>
        <DescriptionList>
          <DescriptionTerm marginBottom={'10px'}>
            {t('common.status')}
          </DescriptionTerm>
          {isActiveStatus && (
            <Active>
              <Status data-test-brand-profile-status>
                {t('common.statuses.ACTIVE').toLocaleUpperCase()}
              </Status>
            </Active>
          )}
          {!isActiveStatus && (
            <Deactivated>
              <Status data-test-brand-profile-status>
                {t('common.statuses.DEACTIVATED').toLocaleUpperCase()}
              </Status>
            </Deactivated>
          )}
          <CustomerServiceLabel>
            {t('mybusiness.services.brandProfile.labels.customerServiceNumber')}
          </CustomerServiceLabel>
          <DescriptionDefinition marginBottom='medium'>
            {partnerBrandProfile?.customerServiceNumber ?? '--'}
          </DescriptionDefinition>
          <DescriptionTerm>
            {t('mybusiness.services.brandProfile.labels.customerServiceEmail')}
          </DescriptionTerm>
          <DescriptionDefinition marginBottom='medium'>
            {partnerBrandProfile?.businessEmail ?? '--'}
          </DescriptionDefinition>
          <DescriptionTerm>
            {t('mybusiness.services.brandProfile.labels.customerServiceUrl')}
          </DescriptionTerm>
          <DescriptionDefinition marginBottom='medium'>
            {partnerBrandProfile?.websiteUrl ?? '--'}
          </DescriptionDefinition>
        </DescriptionList>
        <DescriptionList>
          <DescriptionTerm>Company Logo</DescriptionTerm>
          <DescriptionDefinition>
            <Logo src={partnerBrandProfile?.logo} />
          </DescriptionDefinition>
        </DescriptionList>
      </DescriptionListContainer>
      <PreviewSection>
        <PreviewHeader>
          <PreviewTitle>
            {t('mybusiness.services.brandProfile.view.preview')}
          </PreviewTitle>
        </PreviewHeader>
        <BrandProfilePreview
          logo={partnerBrandProfile?.logo ?? ''}
          phone={partnerBrandProfile?.customerServiceNumber ?? ''}
          email={partnerBrandProfile?.businessEmail ?? ''}
          url={partnerBrandProfile?.websiteUrl ?? ''}
          showX12={BRAND_PROFILE_X12_TAB}
          companyName={partnerAccount?.name ?? ''}
          translationFunction={t}
        />
      </PreviewSection>
    </>
  );
};

export default BrandProfileView;
