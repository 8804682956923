// SWR documentation - https://swr.vercel.app/
import useSWR from 'swr';

const headers = new Headers();
const apiKey = import.meta.env.REACT_APP_COUNTRY_STATE_API_KEY || '';
headers.append('X-CSCAPI-KEY', apiKey);

const requestOptions = {
  method: 'GET',
  headers: headers,
};

const fetcher = async () => {
  const res = await fetch(
    `https://api.countrystatecity.in/v1/states`,
    requestOptions
  );
  return await res.json();
};

// We can preload the data this way, but it doesn't use Suspense and
// adds to the app load time.
// Better to use suspense mode and show longer loading spinner
// where we are using it.
// To import preload -> import useSWR, { preload } from 'swr';
// preload(`https://api.countrystatecity.in/v1/states`, fetcher);

const useStateData = () => {
  const { data: stateData } = useSWR(
    `https://api.countrystatecity.in/v1/states`,
    fetcher,
    {
      suspense: true,
    }
  );

  return { stateData };
};

export default useStateData;
