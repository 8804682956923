import React, { useState, useEffect } from 'react';
import {
  useToastContext,
  useVerificationModal,
} from '@resideo/blueprint-react';
import { IAddCustomerProps, Steps } from '../types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CreatePartnerCustomerModel,
  CreatePartnerDeviceLocationModel,
} from '@resideo/web-integration-services-api-client';
import { useTranslation } from 'react-i18next';
import { useRisClient } from '../../../../hooks/useRisClient';
import { useCurrentUser } from '../../../../context/CurrentUser';
import { checkPhoneNumberForCountryCode } from '../../../../utils/validation';
import { AddressType } from '../../CustomerAccount/types';
import {
  geocodeAddress,
  parseFormattedAddress,
} from '../../../../utils/common';
import PageLoading from '../../../common/PageLoading';

const VerificationModal: React.FC<IAddCustomerProps> = ({
  setStep,
  addValues,
  data,
  closeModal,
}) => {
  const { partnerAccountId } = useCurrentUser();
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const [locationValues, setLocationValues] = useState<any>({});
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [suggestedAddress, setSuggestedAddress] = useState<AddressType>({
    address1: '',
    address2: '',
    city: '',
    country: '',
    locationName: '',
    state: '',
    zip: '',
  });
  const {
    openAddressVerificationModal,
    closeAddressVerificationModal,
    AddAddressVerificationModal,
  } = useVerificationModal();

  useEffect(() => {
    const parseAddress = async () => {
      const { address1, city, state, zip, locationName } = data || {};
      const locationAddress = `${address1} ${city} ${state} ${zip}`;
      const geoAddress = await geocodeAddress(locationAddress);
      const addressComponents = geoAddress?.results?.[0]?.address_components;
      const shortNames = parseFormattedAddress(addressComponents, locationName);
      setLocationValues(data);
      setSuggestedAddress(shortNames);
      addValues({ ...shortNames });
      if (geoAddress) {
        openAddressVerificationModal();
        setIsLoader(false);
      }
    };
    parseAddress();
    setIsLoader(true);
  }, []);

  const createCustomer = useMutation({
    mutationFn: async (cust: CreatePartnerCustomerModel) => {
      return client.myProAccounts.myProAccountsCreatePartnerCustomer(
        partnerAccountId as string,
        {
          firstName: cust.firstName,
          lastName: cust.lastName,
          email: cust.email,
          phoneNumber: cust.phoneNumber,
        }
      );
    },
  });

  const countryCodeIsTheOnlyInput =
    data?.phoneNumber &&
    `+${checkPhoneNumberForCountryCode(data?.phoneNumber).toString()}` ===
      data?.phoneNumber;

  const updateLocation = useMutation({
    mutationFn: async (vars: {
      partnerCustomerId;
      location: CreatePartnerDeviceLocationModel;
    }) => {
      return client.myProAccounts.myProAccountsCreatePartnerDeviceLocation(
        partnerAccountId as string,
        vars.partnerCustomerId,
        {
          name: vars.location.name,
          address: {
            addressLine1: vars.location?.address?.addressLine1,
            addressLine2: vars.location?.address?.addressLine2,
            city: vars.location?.address?.city,
            stateProvinceRegionCode:
              vars.location?.address?.stateProvinceRegionCode,
            zipPostalCode: vars.location?.address?.zipPostalCode,
            countryCode: vars.location?.address?.countryCode,
          },
        }
      );
    },
    onError: () => {
      closeModal();
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
    },
    onSuccess: response => {
      queryClient.invalidateQueries({ queryKey: ['partnerCustomers'] });

      if (response?.data) {
        setStep(Steps.Success);
      }
    },
  });

  const updateCustomerLocation = async (values?) => {
    const apiValues = values ? values : locationValues;

    addValues({ ...apiValues });
    try {
      const cust = await createCustomer.mutateAsync({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phoneNumber: countryCodeIsTheOnlyInput ? '' : data?.phoneNumber,
      });

      const customerId = cust?.data?.id as string;

      if (customerId) {
        addValues({ customerId });

        updateLocation.mutate({
          partnerCustomerId: customerId,
          location: {
            name: apiValues?.locationName,
            address: {
              addressLine1: apiValues?.address1,
              addressLine2: apiValues?.address2,
              city: apiValues?.city,
              stateProvinceRegionCode: apiValues?.state,
              zipPostalCode: apiValues?.zip,
              countryCode: apiValues?.country,
            },
          },
        });
      }
    } catch (e) {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
      closeModal();
    }
  };

  return isLoader ? (
    <PageLoading />
  ) : (
    <AddAddressVerificationModal
      suggestedAddress={suggestedAddress}
      originalAddress={locationValues}
      closeAddressVerificationModal={() => {
        closeAddressVerificationModal();
        closeModal();
      }}
      backButtonClicked={() => {
        addValues({ ...locationValues, isBackButtonClicked: true });
        setStep(Steps.Location);
        closeAddressVerificationModal();
      }}
      saveButtonClicked={values => {
        updateCustomerLocation(values);
      }}
      translations={{
        ModalTitle: t('customers.addCustomer'),
        ModalHeader: t('verificationModal.modalHeader'),
        ModalText: t('verificationModal.modalText'),
        ModalChooseText: t('verificationModal.modalChooseText'),
        OriginalAddressLabel: t('verificationModal.originalAddressLabel'),
        SuggestedAddressLabel: t('verificationModal.suggestedAddressLabel'),
        BackBtnLabel: t('common.back'),
        SaveBtnLabel: t('common.save'),
      }}
    />
  );
};

export default VerificationModal;
