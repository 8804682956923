const ApplicationImages = t => [
  {
    text: t('proSeries.applicationImages.dadClosing.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Application Images/Dad closing window - PROSIXMINICT.jpg?sp=rl&st=2020-11-02T20:11:24Z&se=2128-05-03T20:11:00Z&sv=2019-12-12&sr=b&sig=E9Xh9xQK%2FIeNoLEaND2BDqTthIMPs8fsdk%2ByiLOQQnQ%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.familyHome.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Application Images/Family Entering Home.jpg?sp=rl&st=2020-11-02T20:11:44Z&se=2120-06-04T20:11:00Z&sv=2019-12-12&sr=b&sig=SEAeoT%2B8tLwLIVPK1P4ibc4vH3RApjeB%2BOsn5v2OkAU%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.couch.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Application Images/Family on couch with touchscreen.jpg?sp=rl&st=2020-11-02T20:14:34Z&se=2137-09-16T20:14:00Z&sv=2019-12-12&sr=b&sig=a74GVHA%2F8O2hoODnAihIX%2FUnERC43lUVVMdcMbR8vyI%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.outside.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Application Images/Family outside - TC and Touchscreen.jpg?sp=rl&st=2020-11-02T20:14:52Z&se=2138-07-16T20:14:00Z&sv=2019-12-12&sr=b&sig=jem2fpW43fsYLevAvFSelxDDtiXd9FmQRRRv%2BCOBhzQ%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.fireman.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Application Images/Family outside with fireman.jpg?sp=rl&st=2020-11-02T20:15:11Z&se=2131-10-15T20:15:00Z&sv=2019-12-12&sr=b&sig=Upg5E5jxso6uLJSdpH1IzsQfTouteKl4W4%2BIKaa31Ic%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.bedroom.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Application Images/Girl Sleeping in bedroom.jpg?sp=rl&st=2020-11-02T20:15:30Z&se=2124-10-25T20:15:00Z&sv=2019-12-12&sr=b&sig=89FZzzXuoyyQOog0qebVpF6Rgq5unOHe4ysYU1vcIJ8%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.homeOwnerTotal.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Application Images/Homeowner - Total Connect.jpg?sp=rl&st=2020-11-02T20:16:16Z&se=2132-10-07T20:16:00Z&sv=2019-12-12&sr=b&sig=iS2VFwcz8agxaONEUEsE2ruxPFO61Dq54ms6A3XJnoI%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.camera.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Application Images/Installer - Camera.jpg?sp=rl&st=2020-11-02T20:17:06Z&se=2129-09-12T20:17:00Z&sv=2019-12-12&sr=b&sig=TvkdsmcXY31R73R%2B8Yud4ALQaN08M%2BqxbzqHAfn5XrQ%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.panel.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Application Images/Installer - Panel.jpg?sp=rl&st=2020-11-02T20:18:52Z&se=2140-10-11T20:18:00Z&sv=2019-12-12&sr=b&sig=%2BQzTkM9fY4NzMyxSWGh%2BnMZkxsN%2FO9mNi2NwEOgNcTM%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.homeOwnerInstall.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Application Images/Installer - with homeowner.jpg?sp=rl&st=2020-11-02T20:19:14Z&se=2132-06-10T20:19:00Z&sv=2019-12-12&sr=b&sig=T26hORUm3NF5MyY7HrmICRjR5PmeC5NPIOzxVO5PUmo%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.lady.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Application Images/Lady with Pendant.jpg?sp=rl&st=2020-11-02T20:19:49Z&se=2122-06-03T20:19:00Z&sv=2019-12-12&sr=b&sig=vqtrmhkckdhgfz6bC3XI4RP9zEERTQuWJcWWtUBVHtU%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.mom.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Application Images/Mom and daughter with combo above.jpg?sp=rl&st=2020-11-02T20:20:10Z&se=2129-07-06T20:20:00Z&sv=2019-12-12&sr=b&sig=p3RyVtNAP9PcuHcUetOUWOkfiLbHmxsjGkGgPiZZ69o%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.momTouchscreen.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Application Images/Mom with Touscreen.jpg?sp=rl&st=2020-11-02T20:20:30Z&se=2146-11-17T20:20:00Z&sv=2019-12-12&sr=b&sig=M%2B%2BmVqmxeYXqbBkvzEGX8V5EvMsJCfDNJhTzkcnkU%2BA%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.fatherInLivingRoomTouchscreen.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_HOUSE_LIVING_ROOM_02_025.jpg?sp=r&st=2021-07-29T14:55:09Z&se=2121-07-29T22:55:09Z&spr=https&sv=2020-08-04&sr=b&sig=D16M1QRk3%2FweQchPTt%2Fu58Z04kwSqQCjOKqOkV80QDE%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.homeOwnerTouchscreen.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_HOME_WIRELESS_TOUCHSCREEN_008.jpg?sp=r&st=2021-07-29T14:52:54Z&se=2121-07-29T22:52:54Z&spr=https&sv=2020-08-04&sr=b&sig=15%2BMfB3D493oLTcJ2APD4gDJiPn6iZxlL8zM2l3GisE%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.homeOwnerWithMobilePhoneTotalConnect.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_HOME_PHONE_011.jpg?sp=r&st=2021-07-29T14:52:30Z&se=2121-07-29T22:52:30Z&spr=https&sv=2020-08-04&sr=b&sig=rM8uPQWIq4oiLOcaGkDLBmda7gw1675vVphNJX96H7c%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.bakeryOwnerOffice.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_BAKERY_OFFICE_01_009.jpg?sp=r&st=2021-07-29T14:49:31Z&se=2121-07-29T22:49:31Z&spr=https&sv=2020-08-04&sr=b&sig=dsvZ5jIxaQJ4LQ3XylIMVbZejQSRpX7PEVdDb727Vsk%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.installerWithBakeryOwnerMobilePhoneTotalConnect.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_BAKERY_IPHONE_APP_01_075.jpg?sp=r&st=2021-07-29T14:48:20Z&se=2121-07-29T22:48:20Z&spr=https&sv=2020-08-04&sr=b&sig=CqQU%2B2SfBEFLuHezX0wzU3ln5v1%2FJ6KWGdc70jwmQVw%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.proa7plusMomDaughterInKitchen.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/HOUSE_KITCHEN_01_035%20%5BPlus%5D.jpg?sp=r&st=2021-07-29T14:42:26Z&se=2121-07-29T22:42:26Z&spr=https&sv=2020-08-04&sr=b&sig=yzu8lMLyieuRPI0f6MC44tfYAdDyDrJCtRncY%2BaTQ5Y%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.proa7plusFamilyWalkingInHouse.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/HOUSE_STAIR_01_130%20%5BPlus%5D.jpg?sp=r&st=2021-07-29T14:43:24Z&se=2111-07-29T22:43:24Z&spr=https&sv=2020-08-04&sr=b&sig=8s6DJ2T%2Befx4Nw6Qzx3%2Bsug8viN3WMJ2coYZo3jxxlU%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7plusFamilyWalkingOutOfHouse.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/HOUSE_STAIR_01_135%20%5BPlus%5D.jpg?sp=r&st=2021-07-29T14:43:46Z&se=2121-07-29T22:43:46Z&spr=https&sv=2020-08-04&sr=b&sig=LzZ%2F7TFYEUzyouAUSZqwRA0j9fV5CpTFQ141gCcx6mk%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7plusFatherDaughterBakeryCounter.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/BAKERY_COUNTER_01_042%20%5BPlus%5D.jpg?sp=r&st=2021-07-29T14:39:37Z&se=2121-07-29T22:39:37Z&spr=https&sv=2020-08-04&sr=b&sig=%2FaYo64BFEfPhWCJ4dp%2BdxdF5xzX8mReDXO2dOK4be%2Bo%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7plusMotherSonBakeryCounter.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/BAKERY_COUNTER_03_012%20%5BPlus%5D.jpg?sp=r&st=2021-07-29T14:40:14Z&se=2121-07-29T22:40:14Z&spr=https&sv=2020-08-04&sr=b&sig=kuQGDUaqYCQbTLWNOpaqt2y8IHoxP8UKmyfwBK2KpCk%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7plusFatherDaughterEnteringHouse.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/HOUSE_ENTRY_DAD_DAUGHTER_027%20%5BPlus%5D.jpg?sp=r&st=2021-07-29T14:41:01Z&se=2121-07-29T22:41:01Z&spr=https&sv=2020-08-04&sr=b&sig=IuGEz5h28rRQwG84mVaGtkSH73Sh28Wt95uLwfmnsyM%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.proa7plusBakeryOwnerByOvens.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/BAKERY_OVEN_01_031%20%5BPlus%5D.jpg?sp=r&st=2021-07-29T14:40:31Z&se=2121-07-29T22:40:31Z&spr=https&sv=2020-08-04&sr=b&sig=J4%2BaFAcUIqndzh%2BwjPj87jW1denHZM3pZ5C%2BGOblP9w%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7plusInstallerWithBakeryOwner.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/BAKERY_BAKERY_TECH_02_082%20%5BPlus%5D.jpg?sp=r&st=2021-07-29T14:39:08Z&se=2121-07-29T22:39:08Z&spr=https&sv=2020-08-04&sr=b&sig=g2ZlOq2rpPOs3nvhTRCgFss9WEDBJ4iSgHKbhiixoag%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7plusInstallerWithMaleHomeownerNumber1.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/HOUSE_FRONT_DOOR_DAD_TECH_047%20%5BPlus%5D.jpg?sp=r&st=2021-07-29T14:41:45Z&se=2121-07-29T22:41:45Z&spr=https&sv=2020-08-04&sr=b&sig=2tBfHCZHhQM7kPRTHz68FXsAYOfUzawJrPKQeKl0uX4%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7plusInstallerWithMaleHomeownerNumber2.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/HOUSE_KITCHEN_TECH_DAD_000%20%5BPlus%5D.jpg?sp=r&st=2021-07-29T14:42:53Z&se=2121-07-29T22:42:53Z&spr=https&sv=2020-08-04&sr=b&sig=nScFA5wopkR29H%2BXMCSuCNXFuqswJOpsB5sj3b23tDM%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7plusInstallerWithFemaleHomeowner.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/HOUSE_FRONT_DOOR_MOM_TECH_062%20%5BPlus%5D.jpg?sp=r&st=2021-07-29T14:42:06Z&se=2121-07-29T22:42:06Z&spr=https&sv=2020-08-04&sr=b&sig=DtmPOrtAwTitYKtrPQla4Vm10cxbJEp1ghb8Pv0z2G0%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7plusInstallerKitchenHallNumber1.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/HOUSE_TECH_INSTALL_002%20%2B%20HALL_INSTALL_018%20%5BPlus%5D.jpg?sp=r&st=2021-07-29T14:44:07Z&se=2121-07-29T22:44:07Z&spr=https&sv=2020-08-04&sr=b&sig=VifmtSMv9UWNueHr38az%2B8pdF7Ekc2lZQS55FU4NOpg%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7plusInstallerKitchenHallNumber2.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/HOUSE_TECH_INSTALL_009%20%2B%20FRONT_DOOR_TECH_037%20%5BPlus%5D.jpg?sp=r&st=2021-07-29T14:44:30Z&se=2221-07-29T22:44:30Z&spr=https&sv=2020-08-04&sr=b&sig=ovzBMSBZ%2F%2FOMpgnzADTB8vwoNCZpX8Uf0qSL0%2BooT2E%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.proa7MomDaughterInKitchen.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_HOUSE_KITCHEN_01_035.jpg?sp=r&st=2021-07-29T14:54:20Z&se=2121-07-29T22:54:20Z&spr=https&sv=2020-08-04&sr=b&sig=3WozAtPRDAsr88%2FfLj9Ic3LmertLClUpWk1ALGhKqV0%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.proa7FamilyWalkingInHouse.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_HOUSE_STAIR_01_130.jpg?sp=r&st=2021-07-29T14:55:29Z&se=2121-07-29T22:55:29Z&spr=https&sv=2020-08-04&sr=b&sig=8fcyZ1CP0dLLsvHJTyvAuHfElQKwl1xlyP11gKtTFFY%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7plusFamilyWalkingOutOfHouse.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_HOUSE_STAIR_01_135.jpg?sp=r&st=2021-07-29T14:55:57Z&se=2121-07-29T22:55:57Z&spr=https&sv=2020-08-04&sr=b&sig=3iMKkf3xQDfM30nwQBG%2F%2FjBsRMR3rVMoFT1Neu5vgrE%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7FamilyDaughterBakeryCounter.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_BAKERY_COUNTER_01_042.jpg?sp=r&st=2021-07-29T14:45:35Z&se=2121-07-29T22:45:35Z&spr=https&sv=2020-08-04&sr=b&sig=7rCKcMpbinckQj1JArPoSvGpQNR5Wi2yudIlLpL0J%2BI%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.proa7MotherSonBakeryCounter.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_BAKERY_COUNTER_03_012.jpg?sp=r&st=2021-07-29T14:46:12Z&se=2121-07-29T22:46:12Z&spr=https&sv=2020-08-04&sr=b&sig=by%2B6VZBmhh%2BFiJiCBZoAdaTFm6HR5qiprtU44cq6v8g%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7FatherDaughterEnteringHouse.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_HOUSE_ENTRY_DAD_DAUGHTER_027.jpg?sp=r&st=2021-07-29T14:53:11Z&se=2121-07-29T22:53:11Z&spr=https&sv=2020-08-04&sr=b&sig=6axcWNWkHlFCaeyPxATcBN%2FPmTzafJYb64iqHhutiB0%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7FatherSonInLivingRoomTouchscreen.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_MW_2021_02_24_HOUSE_LIVING_ROOM_037.jpg?sp=r&st=2021-07-29T14:56:53Z&se=2121-07-29T22:56:53Z&spr=https&sv=2020-08-04&sr=b&sig=jyHRIxf9Mbsxx12jnGoviTkD3IU0iehi%2FDaQpqA9TZ4%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.proa7BakeryOwnerByOvens.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_BAKERY_OVEN_01_031.jpg?sp=r&st=2021-07-29T14:49:51Z&se=2121-07-29T22:49:51Z&spr=https&sv=2020-08-04&sr=b&sig=Q3BCG4NUvSVyRxEE4a%2FgnEhBaEuYdgWjds3L0HWKt2U%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.applicationImages.proa7InstallerWithBakerOwner.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_%20BAKERY_TECH_02_082.jpg?sp=r&st=2021-07-29T14:45:03Z&se=2121-07-29T22:45:03Z&spr=https&sv=2020-08-04&sr=b&sig=4uVjPnadH9LwMBIGEF4osh7umKenHnNkQFjw7bpUrik%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7InstallerInBakeryInstallingMotionSensor.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_BAKERY_TECH_01_013.jpg?sp=r&st=2021-07-29T14:51:43Z&se=2121-07-29T22:51:43Z&spr=https&sv=2020-08-04&sr=b&sig=xwnBvwJJpuf%2BOIcxtFNSyquCHdIy%2FxPMhD6N3JM8bEM%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7InstallerInBakeryWithGlassBreakDetector.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_BAKERY_GLASS_BREAK_01_021.jpg?sp=r&st=2021-07-29T14:47:54Z&se=2121-07-29T22:47:54Z&spr=https&sv=2020-08-04&sr=b&sig=0H9ZasiUtBUFv7oQQKAnSnsOhFZKtLpLm2xlKFPLBrU%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7InstallerWithMaleHomeowner1.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_HOUSE_FRONT_DOOR_DAD_TECH_047.jpg?sp=r&st=2021-07-29T14:53:42Z&se=2121-07-29T22:53:42Z&spr=https&sv=2020-08-04&sr=b&sig=O5Hg3%2FwCbSwkITql7uTfyESV8TnUAMiDJaSfarYhxPM%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7InstallerWithMaleHomeowner2.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_HOUSE_KITCHEN_TECH_DAD_000.jpg?sp=r&st=2021-07-29T14:54:50Z&se=2121-07-29T22:54:50Z&spr=https&sv=2020-08-04&sr=b&sig=gG4gPdOA5o4Udn4LBAm2fan4iT6mAtCAeFj0nIiL2kw%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7InstallerWithFemaleHomeowner.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_HOUSE_FRONT_DOOR_MOM_TECH_062.jpg?sp=r&st=2021-07-29T14:54:02Z&se=2121-07-29T22:54:02Z&spr=https&sv=2020-08-04&sr=b&sig=4x66YM2guqUmw6JpX7G9Mt8GD2OZ%2BaDTRWaTCgoLlRU%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7InstallerKitchenHallNumber1.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_HOUSE_TECH_INSTALL_002%20%2B%20HALL_INSTALL_018.jpg?sp=r&st=2021-07-29T14:56:11Z&se=2121-07-29T22:56:11Z&spr=https&sv=2020-08-04&sr=b&sig=Mak0MVJ0Cp2crE62lvRPHjlzkidd%2FTzS2YzN5lSBEKY%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t(
      'proSeries.applicationImages.proa7InstallerKitchenHallNumber2.title'
    ),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic%20Images/Application%20Images/PROA7_HOUSE_TECH_INSTALL_009%20%2B%20FRONT_DOOR_TECH_037.jpg?sp=r&st=2021-07-29T14:56:30Z&se=2121-07-29T22:56:30Z&spr=https&sv=2020-08-04&sr=b&sig=HK4328GU7l9e%2BadmYV%2B5XOd1Bbak8TWY1MbHdktsfAc%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
];

export default ApplicationImages;
