import React, { FC } from 'react';
import { Box, Text, SelectField } from '@resideo/blueprint-react';
import styled from 'styled-components';

const CardHeading = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.heading};
  letter-spacing: 1px;
  line-height: ${({ theme }) => theme.lineHeights.heading};
  margin: 0;
  text-transform: none;
  & ~ div {
    &:nth-child(2) {
      padding-top: ${({ theme }) => theme.space.large};
    }
    padding-left: ${({ theme }) => theme.space.large};
    padding-top: ${({ theme }) => theme.space.medium};
    padding-bottom: ${({ theme }) => theme.space.small};
  }
`;

const StyledBox = styled(Box)`
  cursor: pointer;
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: block;
  }
`;

const HiddenSelectWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: none;
  }
`;

const ProSeriesSidebar: FC<{
  cardType: string;
  handleClick: (string) => void;
  titles: {
    title: string;
    type: string;
    subs?: { title: string; type: string }[];
  }[];
}> = ({ cardType, handleClick, titles }) => {
  return (
    <>
      {titles.map(({ title, type, subs }) => (
        <StyledBox key={title} paddingY='medium' width={1}>
          <CardHeading
            color={type === cardType ? 'info' : ''}
            onClick={() =>
              handleClick(
                type === 'graphic-images' ? 'application-images' : type
              )
            }>
            {title}
          </CardHeading>
          {subs
            ? subs.map(({ title, type }) => (
                <CardHeading
                  key={type}
                  color={type === cardType ? 'info' : ''}
                  onClick={() => handleClick(type)}>
                  {title}
                </CardHeading>
              ))
            : null}
        </StyledBox>
      ))}

      <HiddenSelectWrapper>
        <SelectField
          name=''
          label=''
          onChange={e => handleClick(e.target.value)}>
          {titles.map(({ title, type, subs }) =>
            type !== 'graphic-images' ? (
              <option key={title} value={type}>
                {title}
              </option>
            ) : subs !== undefined ? (
              subs.map(({ title, type }) => (
                <option key={type} value={type}>
                  {title}
                </option>
              ))
            ) : null
          )}
        </SelectField>
      </HiddenSelectWrapper>
    </>
  );
};

export default ProSeriesSidebar;
