import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button } from '@resideo/blueprint-react';
import { ButtonContainer, ModalContent } from '../../customers.style';

export const UnableToRemoveLocationModal = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <Text data-test-cannot-remove-location-text>
        {t('customerDetails.cannotRemoveLocation.text')}
      </Text>
      <ButtonContainer>
        <Button
          variant='primary'
          onClick={onSubmit}
          data-test-cannot-remove-location-close-btn>
          {t('common.close')}
        </Button>
      </ButtonContainer>
    </ModalContent>
  );
};
