import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertsTab } from './types';
import SeverityFilter, { Filter } from './components/SeverityFilter';
import RecentActivity from './components/RecentActivity';
import AlertsTable from './AlertsList/AlertsTable';
import {
  alertColumn,
  customerColumn,
  severityColumn,
  actionsColumn,
} from './AlertsList/TableColumns';
import {
  ControlsContainer,
  AlertsHeader,
} from './components/DashboardComponents';
import { useAlertCounts } from './useAlertCounts';

const activeAlertsTableColumns = [
  severityColumn,
  alertColumn(AlertsTab.Active),
  customerColumn(AlertsTab.Active),
  actionsColumn(AlertsTab.Active),
];

const Active = () => {
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState([{ id: 'severity', desc: false }]);
  const [severityFilter, setSeverityFilter] = useState<Filter>({
    urgent: true,
    warning: true,
    notice: true,
  });

  const { data } = useAlertCounts();
  const activeAlerts = data?.activeAlerts || {};
  const flaggedAlerts = data?.flaggedAlerts || {};
  const dismissedAlerts = data?.dismissedAlerts || {};
  const { urgentCount = 0, warningCount = 0, noticeCount = 0 } = activeAlerts;

  return (
    <>
      <AlertsHeader tooltip={t('alertsDashboard.activeAlertsHeaderTooltip')}>
        {t('alertsDashboard.activeAlertsHeader')}
      </AlertsHeader>
      <ControlsContainer>
        <SeverityFilter
          messagesCount={{
            urgent: urgentCount,
            warning: warningCount,
            notice: noticeCount,
          }}
          filter={severityFilter}
          onChange={setSeverityFilter}
        />
        <RecentActivity
          firstColumnCount={flaggedAlerts.last24HoursCount || 0}
          secondColumnCount={dismissedAlerts.last24HoursCount || 0}
          thirdColumnCount={dismissedAlerts.last7DaysCount || 0}
          tab={AlertsTab.Active}
        />
      </ControlsContainer>
      <AlertsTable
        columns={activeAlertsTableColumns}
        filterBy={{ status: 'Active' }}
        severityFilter={severityFilter}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        tab={AlertsTab.Active}
      />
    </>
  );
};

export default Active;
