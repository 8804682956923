const parseBoolean = (str = '') => str.toLowerCase() === 'true';

// Feature flags
export const FILEUPLOAD_FLAG = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_FILEUPLOAD
);

export const REQUESTED_ACCESS_FLAG = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_REQUESTED_ACCESS
);

export const PRO_IQ_ADVANCED = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_PRO_IQ_ADVANCED
);

export const LEGACY_COMPANY_UPDATE = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_LEGACY_COMPANY_UPDATE
);

export const INTEGRATE_AND_DEPLOY_TILE_FLAG = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_INTEGRATE_AND_DEPLOY_TILE_FLAG
);

export const ENABLE_PROIQ_SERVICES_SUBSCRIPTIONS_LINKS = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_PROIQ_SERVICES_SUBSCRIPTIONS_LINKS
);

export const TABLE_RESULTS_PER_PAGE = parseInt(
  import.meta.env.REACT_APP_FEATURE_TABLE_RESULTS_PER_PAGE ?? '15'
);

export const PROIQ_SERVICES_SUBSCRIPTIONS_LINK = import.meta.env
  .REACT_APP_PROIQ_SERVICES_SUBSCRIPTIONS_LINK as string;

export const PROIQ_ESSENTIALS_LINK = import.meta.env
  .REACT_APP_PROIQ_ESSENTIALS_LINK as string;

export const ENABLE_HIGH_RADIUS_TILE = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_ENABLE_HIGH_RADIUS_TILE
);

export const HIGH_RADIUS_TILE_REGISTER_LINK = import.meta.env
  .REACT_APP_HIGH_RADIUS_REGISTER_LINK as string;

export const HIGH_RADIUS_ACCESS_ACCOUNT_LINK = import.meta.env
  .REACT_APP_HIGH_RADIUS_ACCESS_ACCOUNT_LINK as string;

export const NEW_ACADEMY_LINK = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_NEW_ACADEMY_LINK
);

export const PRO_IQ_TILES = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_PRO_IQ_TILES
);

export const AUTOCOMPLETE_ADDRESS_FIELD = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_20240405_AUTOCOMPLETE_ADDRESS_FIELD
);

export const BUSINESS_INTELLIGENCE_DASHBOARD_UPDATES = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_BUSINESS_INTELLIGENCE_DASHBOARD_UPDATES
);

export const ENABLE_ADD_DEVICE_BUTTON = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202403_ENABLE_ADD_DEVICE_BUTTON
);

export const INVITE_ACCEPT_ASYNC = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202406_INVITE_ACCEPT_ASYNC
);

export const RESIDEO_ID_INVITES = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202407_RESIDEO_ID_INVITES
);

export const ENABLE_RESEND_INVITE = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202408_ENABLE_RESEND_INVITE
);

export const ENABLE_REDIRECTION_TO_MY_ID_SIGNUP = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202408_REDIRECT_TO_MYID_SIGNUP
);

export const CUSTOMER_DETAILS_REDESIGN = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202410_CUSTOMER_DETAILS_REDESIGN
);

export const CUSTOMER_ALERTS = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202410_CUSTOMER_ALERTS
);

export const ENABLE_VX_DEVICES = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202410_ENABLE_VX_DEVICES
);

export const AGREEMENT_CHECK = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202411_AGREEMENT_CHECK
);

export const ADD_DEVICE_BASE_URL = import.meta.env
  .REACT_APP_ADD_DEVICE_BASE_URL as string;

export const AMS_APPLICATION_ID = import.meta.env
  .REACT_APP_AMS_APPLICATION_ID as string;

export const ALERTS_CACHE = parseInt(
  import.meta.env.REACT_APP_FEATURE_202501_ALERTS_CACHE_IN_SECONDS ?? '2'
);

export const BRAND_PROFILE_X12_TAB = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202501_BRAND_PROFILE_X12_TAB
);

export const COMPLETE_SERVICE_SETUP = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202501_COMPLETE_SERVICE_SETUP
);

export const ENABLE_HVAC_DEVICES_SERVICES_DESIGN = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202502_ENABLE_HVAC_DEVICES_SERVICES_DESIGN
);

export const ENABLE_REMOTE_CONTROL_VIEW_DEVICE = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202502_ENABLE_REMOTE_CONTROL_VIEW_DEVICE
);
export const ENABLE_RESEND_CONSENT = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202501_ENABLE_RESEND_CONSENT
);

export const CUSTOMER_ALERT_DETAILS = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202503_CUSTOMER_ALERT_DETAILS
);

export const ENABLE_CUSTOMIZE_ALERT_SETTINGS = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_202502_ENABLE_CUSTOMIZE_ALERT_SETTINGS
);
