import React, { FC } from 'react';

import { DeviceAlertSeverity } from '../types';

import SeverityNoticeIcon from 'components/icons/severity-notice.svg?react';
import SeverityWarningIcon from 'components/icons/severity-warning.svg?react';
import SeverityUrgentIcon from 'components/icons/severity-urgent.svg?react';

const icons = {
  [DeviceAlertSeverity.NOTICE]: SeverityNoticeIcon,
  [DeviceAlertSeverity.WARNING]: SeverityWarningIcon,
  [DeviceAlertSeverity.URGENT]: SeverityUrgentIcon,
};

export interface SeverityIconProps {
  severity: DeviceAlertSeverity;
  className?: string;
}

const SeverityIcon: FC<SeverityIconProps> = ({
  severity,
  className,
}): JSX.Element => {
  const Icon = icons[severity];
  return <Icon className={className} />;
};

export default SeverityIcon;
