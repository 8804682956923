import React, { useEffect } from 'react';
import {
  newUseCountrySelector,
  PhoneInputField,
  InputField,
} from '@resideo/blueprint-formik';
import { Box, Button, Flex, Text } from '@resideo/blueprint-react';
import { validateAddress } from 'components/common/Address';
import AutocompleteWrapper from 'components/customers/AddCustomer/AutocompleteWrapper';
import { Form, Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  checkPhoneNumber,
  checkPhoneNumberForCountryCode,
} from 'utils/validation';

export interface CompanyProfile {
  companyName: string;

  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;

  companyPhone: any;
}

interface CustomerModalProps {
  initialValues: any;
  isLoaded?: any;
  loadError?: any;
  onSubmit: (values) => void;
  onCancel?: () => void;
}

const CityStateContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
`;

const ContactInfoModal = ({
  initialValues,
  isLoaded,
  loadError,
  onSubmit,
  onCancel,
}: CustomerModalProps) => {
  const { t } = useTranslation();
  const apiKey = import.meta.env.REACT_APP_COUNTRY_STATE_API_KEY || '';
  const {
    CountrySelectorWrapper: NewCountryField,
    StateSelectorWrapper: NewStateField,
    setCountry,
    setStateValue,
  } = newUseCountrySelector({
    apiKey,
  });
  const formikRef = React.useRef<any>(null);

  useEffect(() => {
    if (formikRef?.current?.values) {
      formikRef?.current?.validateForm();
    }
  }, [formikRef?.current?.values]);

  const validate = (values: CompanyProfile) => {
    const errors: FormikErrors<CompanyProfile> = {};
    if (!values.companyName?.trim()) {
      errors.companyName = t('company.profile.companyNameValidation');
    }

    const address = {
      address1: values?.address1,
      address2: values?.address2,
      city: values?.city,
      state: values?.state,
      zip: values?.zip,
      country: values?.country,
    };
    validateAddress(errors, t, address, '');

    const countryCodeIsNotTheOnlyInput =
      values.companyPhone &&
      `+${checkPhoneNumberForCountryCode(values.companyPhone).toString()}` !==
        values.companyPhone;
    if (
      values.companyPhone &&
      !checkPhoneNumber(values.companyPhone) &&
      countryCodeIsNotTheOnlyInput
    )
      errors.companyPhone = t('company.profile.companyPhoneValidation');
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => onSubmit(values)}
      validate={validate}
      enableReinitialize={true}
      innerRef={formikRef}>
      {({
        dirty,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        values,
        errors,
        touched,
        isValid,
      }) => {
        return (
          <Form data-test-company-info noValidate>
            <Text
              data-test-company-information-modal-header
              marginBottom='medium'
              fontSize='large'
              fontWeight='bold'>
              {t('company.profile.companyInformation')}
            </Text>
            <Text
              data-test-required-fields
              marginBottom='medium'
              fontSize='small'
              fontStyle='italic'>
              {t('common.requiredFields')}
            </Text>
            <Box data-test-company-name>
              <InputField
                version='v2'
                name={'companyName'}
                label={t('company.profile.name')}
                marginBottom='medium'
                required
              />
            </Box>
            <AutocompleteWrapper
              initialValue={initialValues?.address1}
              setCountry={setCountry}
              setStateValue={setStateValue}
              fieldName={'address1'}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              touched={touched}
              errors={errors}
              isLoaded={isLoaded}
              loadError={loadError}
            />
            <Box data-test-address-address2 marginBottom={['medium']}>
              <InputField
                version='v2'
                name={'address2'}
                label={t('company.profile.aptSuiteEtc')}
              />
            </Box>
            <Box data-test-address-zip marginBottom={['medium']}>
              <InputField
                version='v2'
                name={'zip'}
                label={t('company.profile.zip')}
                required
              />
            </Box>
            <CityStateContainer>
              <Box data-test-address-city marginBottom={['medium']}>
                <InputField
                  version='v2'
                  name={'city'}
                  label={t('company.profile.city')}
                  required
                />
              </Box>
              <Box data-test-address-state marginBottom={['medium']}>
                <NewStateField
                  version='v2'
                  name={'state'}
                  label={t('company.profile.stateProvinceRegion')}
                  variant='primary'
                  required
                  initialValue={values?.state}
                  value={values?.state}
                  blankOption={t('common.forms.select')}
                  error={touched?.state && errors?.state}
                  onStateChange={val => {
                    setFieldValue('state', val);
                  }}
                />
              </Box>
            </CityStateContainer>
            <Box data-test-address-country marginBottom={['medium']}>
              <NewCountryField
                version='v2'
                label={t('company.profile.countryCode')}
                name={'country'}
                onCountryChange={val => {
                  setFieldValue('country', val);
                }}
                blankOption={t('common.forms.select')}
                initialValue={values?.country}
                error={touched?.country && errors?.country}
                required
              />
            </Box>
            <Box marginBottom='medium' data-test-profile-primary-phone>
              <PhoneInputField
                version='v2'
                label={t('company.profile.phone')}
                name='companyPhone'
                phoneInputConfig={{
                  preferredCountries: ['us', 'ca'],
                  country: values?.country
                    ? values?.country.toLowerCase()
                    : 'us',
                  countryCodeEditable: false,
                }}
              />
            </Box>
            <Flex
              alignItems='center'
              justifyContent='flex-end'
              paddingX={['medium', 0]}
              paddingTop='large'>
              {onCancel && (
                <Button
                  data-test-edit-company-profile-cancel
                  marginX='large'
                  onClick={() => {
                    onCancel();
                  }}
                  type='button'
                  variant='secondary'>
                  {t('common.cancel')}
                </Button>
              )}
              <Button
                data-test-edit-company-profile-save
                disabled={!dirty || isSubmitting || !isValid}
                type='submit'
                variant='primary'
                width={[1, 'auto']}>
                {t('common.save')}
              </Button>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContactInfoModal;
