import PhoneNumber from 'awesome-phonenumber';
import { InviteInfo } from 'components/signup/InviteForm';
import { TFunction } from 'i18next';
import * as Sentry from '@sentry/react';

/**
 * Format date/time based on user's locale, with overriding option
 * @///<reference path="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat"/>
 * Be careful with option like 'dateStyle' or 'timeStyle' since they are only experimental phase
 * @param value
 * @param option
 */
export const formatDateTime = (
  value: number | string,
  options?: Intl.DateTimeFormatOptions,
  locale = 'en-US'
) => {
  const defaultOption: { year: 'numeric'; month: 'long'; day: '2-digit' } = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  };
  const overrideOption = { ...defaultOption, ...options };
  const date = new Date(value);
  return new Intl.DateTimeFormat(locale, overrideOption).format(date);
};

/**
 * Format phone number based on Country Code or Regional Code
 * @param phone
 * @param countryCode
 */
export const formatPhone = (phone = '', countryCode = 'US') => {
  if (!phone) return '';
  phone = phone.replace(/[^0-9]/g, '');
  return new PhoneNumber(phone, countryCode).getNumber('national');
};

export const getInternationalPhone = (phone?: string | null | undefined) => {
  if (!phone) return '';
  let pn = new PhoneNumber(phone);
  if (pn.isValid()) return phone;

  pn = new PhoneNumber(phone, 'US');
  return pn.getNumber('international');
};

export const hasAdminRole = roles => {
  const adminRoles = ['OWNER', 'COMPANY_ADMIN'];
  if (!roles) {
    return false;
  }
  return adminRoles.some(role => roles.includes(role));
};

/**
 * Deep clones of objects - can pass in arrays, objects, whatever
 * @param original
 * @returns a deep clone of original
 */
export const deepClone = original => {
  return JSON.parse(JSON.stringify(original));
};

export const getBusinessTypes = (t: TFunction) => {
  return new Map([
    ['ELECTRICIAN', t('common.proCategories.ELECTRICIAN')],
    ['HVAC', t('common.proCategories.HVAC')],
    ['PLUMBER', t('common.proCategories.PLUMBER')],
    ['SECURITY', t('common.proCategories.SECURITY')],
    ['UTILITY', t('common.proCategories.UTILITY')],
  ]);
};

export const trimFormValues = (values: Record<string, any>) => {
  const result = {};
  const keys = Object.keys(values);
  keys.forEach(key => {
    const value = values[key];
    if (typeof value === 'string') {
      result[key] = value.trim();
    } else {
      result[key] = value;
    }
  });
  return result;
};

export const deserializeInviteToken = (inviteToken): InviteInfo => {
  try {
    const [inviteId = '', email = '', company = ''] = window
      .atob(inviteToken)
      .split('|');
    return {
      inviteId,
      email,
      company,
    };
  } catch (error) {
    Sentry.addBreadcrumb({
      message: 'Invite url has been tampered with',
      level: 'debug',
      data: { error },
      timestamp: Date.now(),
    });
    Sentry.captureException(error);
    Sentry.captureMessage('Invite Error', 'error');
    throw new Error(
      'We are not able to process your request. Please try again later.'
    );
  }
};

export enum ApprovalStatus {
  ACTIVE,
  ARCHIVED,
  PENDING,
  INACTIVE,
}

export type PartnerBrandProfile = {
  __typename?: 'PartnerBrandProfile';
  aboutUs?: string;
  acceptedTermsVersion?: string;
  businessEmail?: string;
  customerServiceNumber?: string;
  fax?: string;
  id?: string;
  logo?: string;
  partnerAccount?: string;
  partnerAccountId?: string;
  status?: string;
  tagLine?: string;
  websiteUrl?: string;
};

export const geocodeAddress = async address => {
  const geocoder = new window.google.maps.Geocoder();
  return geocoder.geocode({ address }, results => {
    return results;
  });
};

export const parseGeoAddress = async values => {
  const { address1, city, state, zip, locationName, locationId } = values || {};
  const locationAddress = `${address1} ${city} ${state} ${zip}`;
  const geoAddress = await geocodeAddress(locationAddress);
  const addressComponents = geoAddress?.results?.[0]?.address_components;
  const shortNames = parseFormattedAddress(
    addressComponents,
    locationName,
    locationId
  );
  const isLocationTypeRoofTop =
    geoAddress?.results?.[0]?.geometry?.location_type === 'ROOFTOP';
  const partialMatch = geoAddress?.results?.[0]?.partial_match;
  const result = compareObjects(shortNames, values);

  return {
    geoAddress,
    isLocationTypeRoofTop,
    partialMatch,
    result,
    shortNames,
  };
};

// Function to return Geo coder address in the similar format the API accepts
export const parseFormattedAddress = (data, locationName, locationId?) => {
  const allowedTypes = {
    street_number: '',
    route: '',
    locality: '',
    administrative_area_level_1: '',
    country: '',
    postal_code: '',
    postal_code_suffix: '',
  };
  data.forEach(item => {
    item.types.forEach(type => {
      if (allowedTypes[type] !== undefined) {
        allowedTypes[type] = item.short_name; // Assigning the short_name to the corresponding type
      }
    });
  });

  // Extracting values and format the final string
  const streetNumber = allowedTypes.street_number || '';
  const route = allowedTypes.route || '';
  const city = allowedTypes.locality || '';
  const state = allowedTypes.administrative_area_level_1 || '';
  const postalCode = allowedTypes.postal_code || '';
  const postalSuffix = allowedTypes.postal_code_suffix || '';
  const country = allowedTypes.country || '';

  const formattedPostalCode =
    postalCode && postalSuffix ? `${postalCode}-${postalSuffix}` : postalCode;

  return {
    address1: `${streetNumber} ${route}`,
    address2: '',
    city: city,
    state: state,
    zip: formattedPostalCode,
    country: country,
    locationName: locationName,
    locationId: locationId,
  };
};

// Function to compare Geo coder address and User entered address
export const compareObjects = (newObj, oldObj) => {
  // List of keys to check in both objects
  const keysToCheck = ['address1', 'city', 'state', 'zip', 'country'];

  // Looping through each key in newObj and compare values case-insensitively
  for (const key of keysToCheck) {
    if (newObj[key].toLowerCase() !== oldObj[key]?.toLowerCase()) {
      return false;
    }
  }

  // If all relevant keys and values match (case-insensitively), return true
  return true;
};
