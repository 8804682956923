import styled from 'styled-components';
import { DeviceAlertSeverity } from '../types';
import { useTranslation } from 'react-i18next';
import SeverityIcon from './SeverityIcon';
import { severityTitleKeys } from '../const';
import React from 'react';

const borderColors = {
  [DeviceAlertSeverity.NOTICE]: '#aac8fd',
  [DeviceAlertSeverity.WARNING]: '#ffe0c4',
  [DeviceAlertSeverity.URGENT]: '#f9cecf',
};
const StyledSeverityBanner = styled.div<{
  $severity: DeviceAlertSeverity;
}>`
  width: 95px;
  height: 25px;
  display: flex;
  align-items: center;
  border: 1px solid ${props => borderColors[props.$severity]};
  border-radius: 10px;
  font-size: 12px;
  box-sizing: border-box;

  svg {
    margin: 0 5px 0 10px;
    height: 16px;
  }
`;

const SeverityBanner = ({ severity }: { severity: DeviceAlertSeverity }) => {
  const { t } = useTranslation();

  return (
    <StyledSeverityBanner $severity={severity}>
      <SeverityIcon severity={severity} />
      {t(severityTitleKeys[severity])}
    </StyledSeverityBanner>
  );
};

export default SeverityBanner;
