import React, { useEffect } from 'react';
import { createBrowserRouter, useNavigate } from 'react-router-dom';
import Root from 'Root';
import accessRoutes, { AccessLayout } from 'routes/Access';
import signUpRoutes, { SignUpLayout } from 'routes/SignUp';
import { withAnonymousRoute } from 'components/common/Route';
import SignOut from 'routes/SignOut';
import protectedRoutes, { ProtectedRoutesLayout } from 'routes/ProtectedRoutes';
import { ENVIRONMENT } from 'config';
import SignIn from 'routes/SignIn';
import PageNotFound from 'components/common/PageNotFound';

const SignInPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Sign in component is used only to run cypress tests, so rendering sign in component only in development environment
    if (ENVIRONMENT !== 'development') {
      navigate('/', { replace: true });
    }
  }, []);

  return withAnonymousRoute(SignIn);
};

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: 'sign-out',
        element: withAnonymousRoute(SignOut),
      },
      {
        path: 'access/*',
        element: withAnonymousRoute(AccessLayout),
        children: accessRoutes,
      },
      {
        path: 'sign-up/*',
        element: withAnonymousRoute(SignUpLayout),
        children: signUpRoutes,
      },
      {
        path: 'sign-in',
        element: <SignInPage />,
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
      {
        path: '/*',
        element: withAnonymousRoute(ProtectedRoutesLayout),
        children: protectedRoutes,
      },
    ],
  },
]);

export default router;
