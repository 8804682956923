const HomeOwnerSales = t => [
  {
    text: t('proSeries.homeOwnerSales.brochure.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/ProSeries End User Brochure.pdf?sp=rl&st=2020-11-02T20:09:18Z&se=2128-06-15T20:09:00Z&sv=2019-12-12&sr=b&sig=J8BSELW%2BrBZTWm%2BRBmbmCc1pssTEUmLtvP9Ht1Ck%2Fbs%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.homeOwnerSales.brochure.desc'),
  },
  {
    text: t('proSeries.homeOwnerSales.brochureFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home%20Owner%20Sales%20Material/ProSeries%20End-User%20Brochure%20(French).pdf?sp=r&st=2022-01-25T20:58:48Z&se=2122-01-26T04:58:48Z&sv=2020-08-04&sr=b&sig=HtVP8hrOR%2BM39YvMRg5%2FC6jce5sJ35GQi5PYvSHO56k%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.homeOwnerSales.brochureFR.desc'),
  },
  {
    text: t('proSeries.homeOwnerSales.brochureES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/L_PSEURSB_D ProSeries EndUser Consumer Res Bro_Secured_ESLA.pdf?sp=r&st=2021-10-14T18:37:04Z&se=2221-10-15T02:37:04Z&spr=https&sv=2020-08-04&sr=b&sig=Bg15RcNLZy3Ki9iJ6YeIZb4q2qbQoH60o6JvbmaAx%2Bw%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.homeOwnerSales.brochureES.desc'),
  },
  {
    text: t('proSeries.homeOwnerSales.homeSellSheet.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/ProSeries End User Sell Sheet.pdf?sp=rl&st=2020-11-02T20:09:36Z&se=2145-06-03T20:09:00Z&sv=2019-12-12&sr=b&sig=%2FUS5UQFJ3SgtHf03eOdnJoHgdAGD8ZDwhpI65glDavI%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.homeOwnerSales.homeSellSheet.desc'),
  },
  {
    text: t('proSeries.homeOwnerSales.homeSellSheetFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/L_PSEURFB_D ProSeries- End User Residential Brochure_FRCA (1).pdf?sp=rl&st=2021-02-16T22:20:30Z&se=2154-07-17T22:20:00Z&sv=2019-12-12&sr=b&sig=%2BqBzoBIy%2FeqzTKaHCLqD61eWGncdLXiPqYEvPkQgFbQ%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.homeOwnerSales.homeSellSheetFR.desc'),
  },
  {
    text: t('proSeries.homeOwnerSales.homeSellSheetES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/L_PSEU1MINSS_D ProSeries Consumer Sell Sheet_ESLA.pdf?sp=r&st=2021-10-14T18:37:40Z&se=2221-10-15T02:37:40Z&spr=https&sv=2020-08-04&sr=b&sig=qE4kBBx6f%2FyzmnJIRrW4kqbcoZOwHgMt8Q2saKFvyrQ%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.homeOwnerSales.homeSellSheetES.desc'),
  },
  {
    text: t('proSeries.homeOwnerSales.sellSheet.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/L_PSA7EURS_D ProSeries PROA7 EndUser Residential SellSheet_v2.pdf?sp=rl&st=2021-02-16T21:14:51Z&se=2215-10-10T21:14:00Z&sv=2019-12-12&sr=b&sig=B167wPEOMf4Ur29UD8SMrJdW4ddeSosJW5ZlBg2gLXg%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.homeOwnerSales.sellSheet.desc'),
  },
  {
    text: t('proSeries.homeOwnerSales.sellSheetFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/ProSeries A7 EndUser Residential Sell Sheet (French).pdf?sp=rl&st=2021-02-15T18:33:41Z&se=2146-06-07T18:33:00Z&sv=2019-12-12&sr=b&sig=H7OhRDuX8duao84gMsZ0wSGkQq10XUWn9ZnQzEL4t10%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.homeOwnerSales.sellSheetFR.desc'),
  },
  {
    text: t('proSeries.homeOwnerSales.sellSheetES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/L_PSA7EURSS_D ProSeries PROA7 EU Res SS_Secured_ESLA.pdf?sp=r&st=2021-10-14T18:38:14Z&se=2221-10-15T02:38:14Z&spr=https&sv=2020-08-04&sr=b&sig=p2w0h%2Bqj2TeOrE7CSv7cEoMrOsGtzcjIt9GiS4Z8xZ8%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.homeOwnerSales.sellSheetES.desc'),
  },
  {
    text: t('proSeries.homeOwnerSales.dieCut.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/ProSeries EndUser DieCut Mailer.pdf?sp=rl&st=2020-11-02T20:09:56Z&se=2121-11-12T20:09:00Z&sv=2019-12-12&sr=b&sig=9DEgiGEYMZr%2Bd803BTH9Um15jCDSEi2yWQjtxSzjMj0%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.homeOwnerSales.dieCut.desc'),
  },
  {
    text: t('proSeries.homeOwnerSales.dieCutFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/ProSeries End-User DieCut Mailer (French).pdf?sp=rl&st=2021-02-15T18:32:02Z&se=2169-06-13T18:32:00Z&sv=2019-12-12&sr=b&sig=Nb0my6tvaOhPsklGvtxJeHdKr7xWWzI52kPkpVws%2BeA%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.homeOwnerSales.dieCutFR.desc'),
  },
  {
    text: t('proSeries.homeOwnerSales.resBrochure.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/L_PSA7EURB_D ProSeries PROA7 EndUser Residential Bro_v4.pdf?sp=rl&st=2021-02-16T21:11:23Z&se=2132-11-05T21:11:00Z&sv=2019-12-12&sr=b&sig=HsE3Y%2BjduiNdTfQU%2FY0LfbTNzSieiHCsgPrhwZYdwOA%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.homeOwnerSales.resBrochure.desc'),
  },
  {
    text: t('proSeries.homeOwnerSales.resBrochureFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/ProSeries A7 EndUser Residential Brochure (French).pdf?sp=rl&st=2021-02-15T18:27:01Z&se=2162-10-12T18:27:00Z&sv=2019-12-12&sr=b&sig=NizMo6TFTkwhDnZ7kW5gz%2FtzmM9A0mdzvIh3OU4gPTE%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.homeOwnerSales.resBrochureFR.desc'),
  },
  {
    text: t('proSeries.homeOwnerSales.resBrochureES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/L_PSA7EURSB_D PROA7 EU Resi Bro_Secured_ESLA.pdf?sp=r&st=2021-10-14T18:38:39Z&se=2221-10-15T02:38:39Z&spr=https&sv=2020-08-04&sr=b&sig=mtoeWQSz93e8amFUGvp9w18ZWFjF6%2B6m6jvkpWTIw0s%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.homeOwnerSales.resBrochureES.desc'),
  },
];

export default HomeOwnerSales;
