import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import InfoIcon from '../components/infoIcon';
import { Text } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from 'context/CurrentUser';
import { useRisClient } from 'hooks/useRisClient';
import { useQuery } from '@tanstack/react-query';
import { ALERTS_CACHE } from 'config/flags';
import LoadingAnimation from './LoadingAnimation';
import { AlertCategory } from '@resideo/web-integration-services-api-client/dist';

const StyledTooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
    box-shadow: 0 2px 10px #0000002b;
    font-size: 14px;
    font-weight: 400;
    width: 255px;
    padding: 15px;
  }
`;

const StyledTooltipIcon = styled(InfoIcon)`
  margin-left: 12px;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
`;

const AlertDescription = styled(Text)`
  font-size: 16px;
`;

const LastRefreshText = styled(Text)`
  font-size: 12px;
  color: #767676;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const DataPoints = styled(Text)`
  font-weight: 500;
`;

const AlertTooltip = ({ id }) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { partnerAccountId = '' } = useCurrentUser();
  const { client } = useRisClient();
  const [isHovered, setIsHovered] = useState(false);
  const [lastRefresh, setLastRefresh] = useState<string | null>(null);
  const [measurementSymbol, setMeasurementSymbol] = useState('');

  const { data } = useQuery({
    queryKey: ['alertInfo', id],
    queryFn: async () => {
      const res = await client.myProAccounts.myProAccountsGetAlertInfo(
        partnerAccountId || '',
        id || ''
      );
      return res?.data;
    },
    enabled: isHovered,
    refetchInterval: ALERTS_CACHE * 1000,
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isHovered) {
      timer = setInterval(() => {
        setLastRefresh(() => {
          const date = new Date();
          const dateString = date.toLocaleDateString(i18n.language, {
            dateStyle: 'short',
          });
          const timeString = date.toLocaleTimeString(i18n.language, {
            timeStyle: 'medium',
          });

          return `${t('alertsDashboard.lastRefresh')} ${dateString} ${t(
            'common.at'
          )} ${timeString}`;
        });
      }, ALERTS_CACHE * 1000);
    }

    return () => clearInterval(timer);
  }, [isHovered, i18n.language, ALERTS_CACHE]);

  useEffect(() => {
    const dataPointMeasurementSymbol = () => {
      const degreesSymbol = [
        AlertCategory.TEMPERATURE,
        AlertCategory.STATE,
        AlertCategory.REMINDER,
      ].includes(data?.alertCategory as AlertCategory);
      const percentSymbol = AlertCategory.HUMIDITY === data?.alertCategory;

      return degreesSymbol ? '\u00B0' : percentSymbol ? '\u0025' : '';
    };

    setMeasurementSymbol(dataPointMeasurementSymbol());
  }, [data?.alertCategory]);

  const DataPoint = ({ label, value, measurementSymbol, tooltip }) => (
    <DataPoints data-test-tooltip={tooltip}>
      {label}
      {value}
      {measurementSymbol}
    </DataPoints>
  );

  const setPoint = () => {
    const { heatSetPoint, coolSetPoint } = data?.telemetry || {};

    // if both heatSetPoint and coolSetPoint are there then show both labels,
    // otherwise only show the setPoint label with the data from whichever
    // setPoint has data
    const label =
      heatSetPoint && coolSetPoint
        ? [
            {
              label: `${t('alertsDashboard.infoLabels.heatSetPoint')}`,
              value: heatSetPoint,
            },
            {
              label: `${t('alertsDashboard.infoLabels.coolSetPoint')}`,
              value: coolSetPoint,
            },
          ]
        : [
            {
              label: `${t('alertsDashboard.infoLabels.setPoint')}`,
              value: heatSetPoint || coolSetPoint,
            },
          ];

    return label.map((item, index) => (
      <DataPoint
        key={index}
        label={item.label}
        value={item.value}
        measurementSymbol={measurementSymbol}
        tooltip='set-point'
      />
    ));
  };

  const airQualityEvent = data?.alertCategory === AlertCategory.AIR_QUALITY;

  return (
    <>
      <StyledTooltipIcon
        data-test-tooltip-icon={id}
        data-tip
        data-for={`tooltip-${id}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      <StyledTooltip
        data-test-tooltip-text
        type='light'
        id={`tooltip-${id}`}
        place='bottom'
        effect='solid'
        delayHide={500}
        delayUpdate={500}>
        {data && lastRefresh ? (
          <>
            <AlertDescription data-test-tooltip-description>
              {data?.description}
            </AlertDescription>

            <LastRefreshText data-test-tooltip-last-refresh>
              {lastRefresh}
            </LastRefreshText>
            {/* air quality events are not implemented yet on the backend 
            so unsure if these data points will be accurate  */}
            {airQualityEvent ? (
              <>
                <DataPoint
                  label={t('alertsDashboard.infoLabels.indoorHumidity')}
                  value={data?.telemetry?.indoorTemp}
                  measurementSymbol={measurementSymbol}
                  tooltip='indoor-humidity'
                />
                <DataPoint
                  label={t('alertsDashboard.infoLabels.voc')}
                  value={data?.telemetry?.heatSetPoint}
                  measurementSymbol={measurementSymbol}
                  tooltip='voc'
                />
                <DataPoint
                  label={t('alertsDashboard.infoLabels.co2')}
                  value={data?.telemetry?.outdoorTemp}
                  measurementSymbol={measurementSymbol}
                  tooltip='co2'
                />
              </>
            ) : (
              <>
                <DataPoint
                  label={t('alertsDashboard.infoLabels.indoor')}
                  value={data?.telemetry?.indoorTemp}
                  measurementSymbol={measurementSymbol}
                  tooltip='indoor-temp'
                />
                {setPoint()}
                <DataPoint
                  label={t('alertsDashboard.infoLabels.outdoor')}
                  value={data?.telemetry?.outdoorTemp}
                  measurementSymbol={measurementSymbol}
                  tooltip='outdoor-temp'
                />
              </>
            )}
          </>
        ) : (
          <LoadingAnimation />
        )}
      </StyledTooltip>
    </>
  );
};

export default AlertTooltip;
