import React from 'react';
import { Helmet } from 'react-helmet';

export const Titled = ({
  children,
  title,
}: {
  children?: any;
  title: string;
}) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </>
);
