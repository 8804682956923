import { light } from '@resideo/blueprint-theme';

const colors = {
  ...light.colors,
  welcomeBanner: '#f8f8f8',
  button: {
    danger: {
      hover: '#b52e15',
      hoverText: '#ececec',
    },
  },
  header: {
    icons: '#ffffff',
    primaryBackground: '#032a3c',
    primaryLink: '#FFFFFF',
    primaryLinkActive: '#ffffff',
    secondaryBackground: '#ffffff',
    secondaryLink: '#217eae',
    secondaryLinkActive: '#000000',
  },
  home: {
    icons: '#012a3a',
  },
  list: {
    hover: '#d8e7ef',
  },
  secondaryBackground: '#f8f8f8',
  sidebar: {
    circle: '#add8e6',
    background: '#f5f5f5',
    text: '#ffffff',
    selected: '#f0f8ff',
    block: '#ffffff',
    hover: '#e6e6fa',
    active: '#fffff0',
  },
  sideNav: {
    active: '#e0e0e0',
    arrow: '#767676',
    arrowHovered: '#217eae',
  },
  table: {
    oddRowBackgroundColor: '#f2f2f2',
    rowBorderColor: '#e0e0e0',
    header: '#797979',
  },
  tag: {
    viewer: '#fff',
  },
  badge: {
    primary: '#166a96',
    active: '#3dca5b',
    normal: '#ededed',
  },
};

export default {
  ...light,
  colors,
};
