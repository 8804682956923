import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import Error from 'routes/Error';
import ContactInfo from './ContactInfo';
import EmployeeView from './Employees/EmployeeView';
import ManageEmployees from './Employees/ManageEmployees';

const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to='contact' replace />,
  },
  {
    path: 'contact',
    element: <ContactInfo />,
  },
  {
    path: 'employees/:id/view',
    element: <EmployeeView />,
  },
  {
    path: 'employees/*',
    element: <ManageEmployees />,
  },
  {
    path: '*',
    element: <Error />,
  },
];

export default routes;
