const WaterProducts = t => [
  {
    text: t('hvacAndWater.waterProducts.am1'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/AM1 - Resideo.zip?sp=r&st=2021-10-14T14:59:48Z&se=2221-10-14T22:59:48Z&spr=https&sv=2020-08-04&sr=b&sig=CFATe4FGVrR3tN5ZJ28Uqnue%2BrDMVeWpAdmTM93ya3I%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.waterProducts.descriptions.mixing'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.waterProducts.am300'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/AMX300 - Resideo.zip?sp=r&st=2021-10-14T15:01:48Z&se=2221-10-14T23:01:48Z&spr=https&sv=2020-08-04&sr=b&sig=mPsC%2Fea3TIgkVSIpAeqYzUAM2K0OE91%2FFoA02Q8QTtA%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.waterProducts.descriptions.amx'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.waterProducts.ds06'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/DS06 - Resideo.zip?sp=r&st=2021-10-14T15:09:17Z&se=2221-10-14T23:09:17Z&spr=https&sv=2020-08-04&sr=b&sig=qWR4jPi3xPuMT6U6mtU%2BkeyuAab5h%2FSil8gRNKKcU%2Bs%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.waterProducts.descriptions.ds06'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.waterProducts.fm911.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/FM911 - Resideo.zip?sp=r&st=2021-10-14T15:10:32Z&se=2221-10-14T23:10:32Z&spr=https&sv=2020-08-04&sr=b&sig=If3wi7LbI3GIGIx2msbiIXys%2FClAepgNLqqrc%2FFgvJQ%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.waterProducts.fm911.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.waterProducts.pv100'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/PV100%20%26%20PV100S - Resideo.zip?sp=r&st=2021-10-14T15:22:20Z&se=2221-10-14T23:22:20Z&spr=https&sv=2020-08-04&sr=b&sig=zJMV%2FE4IL5eC5YQwkaiSNCy5PWpZxiT%2Blk%2FJ37YmGGA%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.waterProducts.descriptions.npt'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.waterProducts.pv125s'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/PV125 - PV125S -- Resideo.zip?sp=rl&st=2021-03-04T20:40:27Z&se=2221-03-14T20:40:00Z&sv=2020-02-10&sr=b&sig=QbZePWtjWt5pGgDPg9XHp%2F0hjYa4gl8gTuxQSTGS55c%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.waterProducts.descriptions.sweat'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.waterProducts.tk30.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TK30PV125FM - Resideo.zip?sp=r&st=2021-10-14T15:41:34Z&se=2221-10-14T23:41:34Z&spr=https&sv=2020-08-04&sr=b&sig=YuSxMvUK2NsIgFeDAD%2BBUc8KHjSvtp%2FppnnWtdyZE2s%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.waterProducts.tk30.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.waterProducts.v8043.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/V8043 - Honeywell Home.zip?sp=r&st=2021-10-14T15:44:34Z&se=2221-10-14T23:44:34Z&spr=https&sv=2020-08-04&sr=b&sig=JWhptmISLjrAgp7SQ9KZ%2FAmSm5vk3bal2IGM1tqsHtE%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.waterProducts.v8043.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.waterProducts.hydroFlanged.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HYDROSEP Flanged - Resideo.zip?sp=r&st=2021-10-14T15:14:07Z&se=2221-10-14T23:14:07Z&spr=https&sv=2020-08-04&sr=b&sig=ek7ohd%2FYX2U0rJz66LRp75EqJqDTnLYA7hQyF3j%2BrA0%3D',
    ext: t('proSeries.ext.zip'),
    desc: t('hvacAndWater.waterProducts.hydroFlanged.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.waterProducts.hydroUnion.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HYDROSEP Union - Resideo.zip?sp=r&st=2021-10-14T15:15:55Z&se=2221-10-14T23:15:55Z&spr=https&sv=2020-08-04&sr=b&sig=uXx3qGJ8NwAVSecz7RI0sBeC9lYe4npRq%2FKtAsX6iqA%3D',
    ext: t('proSeries.ext.zip'),
    desc: t('hvacAndWater.waterProducts.hydroUnion.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.waterProducts.pcvf.ECM2020.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/PCVF-ECM2020 - Resideo.zip?sp=r&st=2021-10-14T15:20:19Z&se=2221-10-14T23:20:19Z&spr=https&sv=2020-08-04&sr=b&sig=8eGAzK5gebSbF9MQRZUL5BpO%2FTt9QEGQv1lEHhp7Whw%3D',
    ext: t('proSeries.ext.zip'),
    desc: t('hvacAndWater.waterProducts.pcvf.ECM2020.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.waterProducts.pcvf.ECM2020LF.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/PCVF-ECM2020-LF - Resideo.zip?sp=r&st=2021-10-14T15:21:13Z&se=2821-10-14T23:21:13Z&spr=https&sv=2020-08-04&sr=b&sig=e96BlYwJXH0orpvWDJbLeMJIkupbofof%2FYzsOZ41zMg%3D',
    ext: t('proSeries.ext.zip'),
    desc: t('hvacAndWater.waterProducts.pcvf.ECM2020LF.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.waterProducts.pv100p.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/PV100P%20%26%20PV125P%20-%20Resideo.zip?sp=r&st=2021-10-14T15:24:51Z&se=2221-10-14T23:24:51Z&spr=https&sv=2020-08-04&sr=b&sig=AW%2BVzOwIh%2Bn3eO%2BseP7bDel5k6PRfF4adEGwQNLbqmM%3D',
    ext: t('proSeries.ext.zip'),
    desc: t('hvacAndWater.waterProducts.pv100p.desc'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.waterProducts.tk30p100'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TK30PV100PNKP%20%26%20TK30PV125PNKP - Resideo.zip?sp=r&st=2021-10-14T15:43:34Z&se=2221-10-14T23:43:34Z&spr=https&sv=2020-08-04&sr=b&sig=EAQP2VKsW0JG24BNFeFMY%2FAe3EXDJ379scd0bcWVc%2B0%3D',
    ext: t('proSeries.ext.zip'),
    desc: t('hvacAndWater.waterProducts.descriptions.trim'),
    brand: t('hvacAndWater.brands.resideo'),
  },
  {
    text: t('hvacAndWater.waterProducts.tk30p125'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/TK30PV100PNKP%20%26%20TK30PV125PNKP - Resideo.zip?sp=r&st=2021-10-14T15:43:34Z&se=2221-10-14T23:43:34Z&spr=https&sv=2020-08-04&sr=b&sig=EAQP2VKsW0JG24BNFeFMY%2FAe3EXDJ379scd0bcWVc%2B0%3D',
    ext: t('proSeries.ext.zip'),
    desc: t('hvacAndWater.waterProducts.descriptions.trim'),
    brand: t('hvacAndWater.brands.resideo'),
  },
];

export default WaterProducts;
