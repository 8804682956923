import React, { FC } from 'react';
import { Box, SelectField, Text } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledTitles = styled(Text)`
  cursor: pointer;
`;

const CardHeading = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.heading};
  letter-spacing: 1px;
  line-height: ${({ theme }) => theme.lineHeights.heading};
  margin: 0;
  text-transform: none;
  & ~ div {
    &:nth-child(2) {
      padding-top: ${({ theme }) => theme.space.large};
    }
    padding-left: ${({ theme }) => theme.space.large};
    padding-top: ${({ theme }) => theme.space.medium};
    padding-bottom: ${({ theme }) => theme.space.small};
  }
`;

const StyledBox = styled(Box)`
  cursor: pointer;
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: block;
  }
`;

const HiddenSelectWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: none;
  }
`;

const HvacAndWaterProductsSidebar: FC<{
  cardType: string;
  handleClick: (string) => void;
  titles: { title: string; type: string }[];
  mobile: { title: string; type: string }[];
}> = ({ cardType, handleClick, titles, mobile }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <StyledBox>
        <StyledTitles
          paddingBottom='medium'
          color={cardType === 'all' ? 'info' : ''}
          onClick={() => handleClick('all')}>
          {t('hvacAndWater.all')}
        </StyledTitles>
        <Text>{t('hvacAndWater.categories')}</Text>
      </StyledBox>
      {titles.map(({ title, type }) => (
        <StyledBox key={title} paddingY='medium' width={1} paddingLeft='medium'>
          <CardHeading
            color={type === cardType ? 'info' : ''}
            onClick={() => handleClick(type)}>
            {title}
          </CardHeading>
        </StyledBox>
      ))}

      <HiddenSelectWrapper>
        <SelectField
          name=''
          label=''
          onChange={e => handleClick(e.target.value)}>
          {mobile.map(({ title, type }) => (
            <option key={title} value={type}>
              {title}
            </option>
          ))}
        </SelectField>
      </HiddenSelectWrapper>
    </>
  );
};

export default HvacAndWaterProductsSidebar;
