import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableV2, useToastContext } from '@resideo/blueprint-react';
import {
  ADD_DEVICE_BASE_URL,
  ENABLE_RESEND_INVITE,
  ENABLE_HVAC_DEVICES_SERVICES_DESIGN,
  ENABLE_REMOTE_CONTROL_VIEW_DEVICE,
} from 'config/flags';
import RemoveDeviceModal from 'components/customers/Location/LocationModals/RemoveDeviceModal';
import { IModalProps, useModalContext } from 'context/ModalProvider';
import { useCurrentUser } from '../../../context/CurrentUser';
import {
  useMutation as useMutationRIS,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';
import { useLanguageCode } from 'hooks/useLanguageCode';
import {
  GetPartnerDeviceLocationModel,
  ConsentStatus,
} from '@resideo/web-integration-services-api-client';
import Battery from './Battery';
import Signal from './Signal';

interface DeviceRowType {
  typeName: string;
  deviceName?: string;
  deviceId: string | null | undefined;
  status?: string;
  data?: string;
  signal?: string | React.ReactElement;
  battery?: string | React.ReactElement;
  actions: any[];
}

interface AccessoryType {
  id: string;
  deviceId: string;
  deviceType: string;
  deviceName: string;
  data?: {
    temperature?: string;
    humidity?: string;
  };
  signal?: string;
  battery?: string;
}

export type CustomerAccountCustomerDataType = {
  firstName: string;
  lastName: string;
  email: string;
};

interface AccountDevicesType {
  locationData?: any;
  customerData: CustomerAccountCustomerDataType;
}

const CustomerAccountDevices: React.FC<AccountDevicesType> = ({
  locationData,
  customerData,
}) => {
  const { t } = useTranslation();
  const [languageCode] = useLanguageCode();
  const { addToast } = useToastContext();
  const { openModal, closeModal } = useModalContext();
  const { partnerAccountId } = useCurrentUser();
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const [deviceLocationData, setDeviceLocationData] = useState<
    GetPartnerDeviceLocationModel | null | undefined
  >();
  const [hasRevokedConsent, setHasRevokedConsent] = useState(false);

  useEffect(() => {
    if (deviceLocationData?.deviceServices) {
      const activeServices = deviceLocationData.deviceServices
        .filter(
          service =>
            service.type === 'ProAlertsPartnerDeviceService' &&
            service.status === 'ACTIVE'
        )
        .sort((a, b) => {
          const dateA = a.updatedAt ? new Date(a.updatedAt).getTime() : 0;
          const dateB = b.updatedAt ? new Date(b.updatedAt).getTime() : 0;
          return dateB - dateA;
        });

      const mostRecentService = activeServices.length
        ? activeServices[0]
        : null;

      setHasRevokedConsent(
        mostRecentService?.consentStatus !== ConsentStatus.ACCEPTED
      );
    }
  }, [deviceLocationData, setHasRevokedConsent]);

  const deleteDeviceFromLocation = useMutationRIS({
    mutationKey: ['deleteDeviceFromLocation'],
    mutationFn: async (variables: {
      partnerDeviceLocationId: string;
      deviceId: string;
    }) => {
      await client.myProAccounts.myProAccountsDeleteDeviceFromPartnerDeviceLocation(
        partnerAccountId as string,
        variables?.partnerDeviceLocationId as string,
        variables?.deviceId as string
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['partnerCustomerLocations'],
      });
      addToast({
        toastType: 'Success',
        message: t('customerDetails.removeDevice.success'),
      });
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('customerDetails.removeDevice.tryAgain'),
        linkHref:
          'mailTo:proIQsupport@resideo.com?subject=Unable to Remove Device from Location',
        linkText: t('customerDetails.removeDevice.contactSupport'),
        target: '_blank',
      });
    },
  });

  const handleRemoveDevice = (deviceId, locationId) => {
    deleteDeviceFromLocation.mutate({
      partnerDeviceLocationId: locationId,
      deviceId: deviceId || '',
    });
  };

  const deviceNewInput = useMutationRIS({
    mutationFn: async (vars: { macId }) => {
      return client.myProAccounts.myProAccountsPerformDeviceHandoff({
        deviceId: vars.macId,
        firstName: customerData?.firstName,
        lastName: customerData?.lastName,
        sendToEmail: customerData?.email,
        templateLanguage: languageCode,
        baseUrl: ADD_DEVICE_BASE_URL,
        partnerAccountId: partnerAccountId,
        partnerDeviceLocationId: locationData.id,
      });
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('company.user.show.toast.error'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['partnerCustomerLocations'],
      });
      addToast({
        toastType: 'Success',
        message: t('company.user.show.inviteSent'),
      });
    },
  });

  const resendInvite = async (deviceId: string) => {
    deviceNewInput.mutate({ macId: deviceId });
  };

  const DeviceTypes = [
    {
      deviceTypeDisplayName: t('L1 Wifi Water Leak & Freeze Detector'),
      deviceSku: 'CHW3610W8001/U',
      deviceType: 'homes.d.l1smartwatersensor',
    },
    {
      deviceTypeDisplayName: t('L2 Wifi Water Sensor & Switch'),
      deviceSku: 'RWLD3001-001/U',
      deviceType: 'homes.d.l1smartwatersensor',
    },
    {
      deviceTypeDisplayName: t('L5 Leak Protection Valve'),
      deviceSku: 'WS0100',
      deviceType: 'homes.d.l5leakprotectionvalve',
    },
    {
      deviceTypeDisplayName: t('T5/T6 Thermostat'),
      deviceSku: 'RCHT8612WF2005',
      deviceType: 'homes.d.t6trade',
    },
    {
      deviceTypeDisplayName: t('T9/T10 or T10+Thermostat'),
      deviceSku: 'RCHT9610WFW2004',
      deviceType: 'homes.d.t10',
    },
    {
      deviceTypeDisplayName: t('VX1 Video Doorbell'),
      deviceSku: 'CAMW-WDB',
    },
    {
      deviceTypeDisplayName: t('VX3 HD Outdoor Camera'),
      deviceSku: 'CAMWE-WO',
    },
  ];

  // Getting device-type based on sku from response
  const getDeviceType = deviceType => {
    if (deviceType !== 'homes.d.l1smartwatersensor') {
      // As both L1 & L2 devices have same device type, we cannot distinguish between those 2 device names to display so for now displaying this default value
      return t('customers.connectedDevice');
    }
    const deviceTypeObject: any = DeviceTypes.filter(device => {
      return device.deviceType === deviceType;
    });

    if (deviceTypeObject.length > 0) {
      return deviceTypeObject[0].deviceTypeDisplayName;
    }
    return t('customers.connectedDevice');
  };

  const { refetch: fetchPartnerDeviceLocationData } = useQuery({
    queryKey: ['getPartnerDeviceLocationV2'],
    queryFn: async () => {
      const res = await client.myProAccounts.myProAccountsGetPartnerDeviceLocationV2(
        partnerAccountId || '',
        locationData?.id || ''
      );

      return res?.data;
    },
    // Disabled, only execute this query manually
    enabled: false,
    staleTime: 5000,
  });

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchPartnerDeviceLocationData();
      setDeviceLocationData(data as GetPartnerDeviceLocationModel);
    };

    if (locationData?.id && ENABLE_HVAC_DEVICES_SERVICES_DESIGN) {
      fetchData();
    }
  }, [
    locationData,
    setDeviceLocationData,
    ENABLE_HVAC_DEVICES_SERVICES_DESIGN,
    fetchPartnerDeviceLocationData,
  ]);

  const getOriginalRows = () => {
    const resendInviteLabel = t('customerDetails.resendInvite.label');
    const rows = locationData?.devices?.map(item => {
      if (item) {
        const { deviceId, id, deviceType } = item;
        // TODO - hardcoding as Lyric connected device until device type is added
        let typeName = getDeviceType(deviceType);
        let status = '';
        const actions: any = [];
        if (ENABLE_RESEND_INVITE) {
          actions.push({
            name: resendInviteLabel,
            ctaTag: resendInviteLabel,
            text: resendInviteLabel,
            action: () => {
              resendInvite(deviceId);
            },
          });
        }
        actions.push({
          name: t('customerDetails.remove'),
          ctaTag: t('customerDetails.remove'),
          text: t('customerDetails.remove'),
          action: () => {
            openModal({
              title: t('customerDetails.removeDevice.title'),
              children: (
                <RemoveDeviceModal
                  onCancel={closeModal}
                  onSubmit={() => {
                    closeModal();
                    // TODO: id = deviceId - think this needs to be the base64 version of the id to remove it
                    handleRemoveDevice(id, locationData?.id);
                  }}
                  locationData={locationData}
                  device={{ id, deviceId, typeName }}
                />
              ),
            } as IModalProps);
          },
        });
        if (deviceId === null || deviceId === 'NULL') {
          typeName = t('customers.deviceUnknown');
          status = t('customers.devicePending');
        } else {
          status = t('customers.deviceConfirmed');
        }
        return {
          typeName,
          deviceId,
          status,
          actions,
        };
      }
    });

    return rows?.filter(row => !!row);
  };

  const getDeviceActions = (
    deviceId: string | null | undefined,
    id: string,
    typeName: string
  ) => {
    const actions: {
      name: string;
      ctaTag: string;
      text: string;
      action: () => void;
    }[] = [];

    if (ENABLE_REMOTE_CONTROL_VIEW_DEVICE && !hasRevokedConsent) {
      actions.push({
        name: t('customers.remoteControl'),
        ctaTag: t('customers.remoteControl'),
        text: t('customers.remoteControl'),
        action: () => console.log('Remote Control'),
      });
    }

    if (ENABLE_RESEND_INVITE) {
      actions.push({
        name: t('customerDetails.resendInvite.label'),
        ctaTag: t('customerDetails.resendInvite.label'),
        text: t('customerDetails.resendInvite.label'),
        action: () => resendInvite(deviceId as string),
      });
    }

    actions.push({
      name: t('customerDetails.remove'),
      ctaTag: t('customerDetails.remove'),
      text: t('customerDetails.remove'),
      action: () => {
        openModal({
          title: t('customerDetails.removeDevice.title'),
          children: (
            <RemoveDeviceModal
              onCancel={closeModal}
              onSubmit={() => {
                closeModal();
                handleRemoveDevice(id, locationData?.id);
              }}
              locationData={locationData}
              device={{ id, deviceId, typeName }}
            />
          ),
        } as IModalProps);
      },
    });

    if (ENABLE_REMOTE_CONTROL_VIEW_DEVICE && !hasRevokedConsent) {
      actions.push({
        name: t('customers.viewDevice'),
        ctaTag: t('customers.viewDevice'),
        text: t('customers.viewDevice'),
        action: () => console.log('View Device'),
      });
    }

    return actions;
  };

  const formatDeviceData = (data?: {
    temperature?: string;
    humidity?: string;
  }) => {
    if (!data) return '';
    return [
      data.temperature && `${data.temperature}°`,
      data.humidity && `${data.humidity}%`,
    ]
      .filter(Boolean)
      .join(' | ');
  };

  const getDeviceTypeName = (type?: string, prefix = 'deviceTypes') => {
    if (!type) return '';
    const translatedText = t(`${prefix}.${type}`);
    return translatedText.includes(`${prefix}.`) ? '' : translatedText;
  };

  const createDeviceRow = (device: any): DeviceRowType => {
    const { deviceId, id, globalDeviceType, deviceName, data } = device;
    const typeName = getDeviceTypeName(globalDeviceType);
    const actions = getDeviceActions(deviceId, id, typeName);
    const status =
      !deviceId || deviceId === 'NULL'
        ? t('customers.devicePending')
        : t('customers.deviceRegistered');

    if (hasRevokedConsent) {
      return { typeName, deviceId, status, actions };
    }

    return {
      typeName,
      deviceName: deviceName || '',
      deviceId,
      status,
      data: formatDeviceData(data),
      signal: '',
      battery: '',
      actions,
    };
  };

  const createAccessoryRow = (accessory: AccessoryType): DeviceRowType => {
    const typeName = getDeviceTypeName(
      accessory.deviceType,
      'accessoryDeviceTypes'
    );

    return {
      typeName: `   ${typeName}`,
      deviceName: accessory.deviceName || '',
      deviceId: accessory.deviceId || '',
      status: accessory.deviceId
        ? t('customers.accessoryEnrolled')
        : t('customers.accessoryDisconnected'),
      data: formatDeviceData(accessory.data),
      signal: <Signal status={accessory.signal} />,
      battery: <Battery status={accessory.battery} />,
      actions: [],
    };
  };

  const getUpdatedRows = () => {
    if (!deviceLocationData?.devices) return [];

    return deviceLocationData.devices.flatMap(device => {
      if (!device) return [];

      const rows: DeviceRowType[] = [createDeviceRow(device)];

      if (device.accessories?.length && !hasRevokedConsent) {
        const accessoryRows = device.accessories
          .filter(accessory => accessory)
          .map(accessory => createAccessoryRow(accessory as AccessoryType));
        rows.push(...accessoryRows);
      }

      return rows;
    });
  };

  const getTableHeaders = (isUpdated: boolean) => {
    const baseHeaders = [
      {
        displayName: t('customers.deviceType'),
        isSortable: false,
      },
      {
        displayName: isUpdated ? t('customers.macId') : t('customers.id'),
        isSortable: false,
      },
      {
        displayName: t('customers.devicesStatus'),
        isSortable: false,
      },
    ];

    if (isUpdated) {
      // Add the other columns
      if (!hasRevokedConsent) {
        // Insert deviceName after deviceType
        baseHeaders.splice(1, 0, {
          displayName: t('customers.deviceName'),
          isSortable: false,
        });
        baseHeaders.push(
          {
            displayName: t('customers.deviceData'),
            isSortable: false,
          },
          {
            displayName: t('customers.deviceSignal'),
            isSortable: false,
          },
          {
            displayName: t('customers.deviceBattery'),
            isSortable: false,
          }
        );
      }
    }

    // Add action column
    baseHeaders.push({ displayName: '', isSortable: false });

    return baseHeaders;
  };

  return (
    <div data-test-customers-list>
      <TableV2
        headers={getTableHeaders(ENABLE_HVAC_DEVICES_SERVICES_DESIGN)}
        rows={
          (ENABLE_HVAC_DEVICES_SERVICES_DESIGN
            ? getUpdatedRows()
            : getOriginalRows()) as any[]
        }
      />
    </div>
  );
};

export default CustomerAccountDevices;
