import React, { FC, Suspense, useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Heading,
  Select as BlueprintSelect,
  Text,
  Link,
  useToastContext,
  useVerificationModal,
} from '@resideo/blueprint-react';
import { MdAddCircleOutline } from 'react-icons/md';
import {
  WarningContainer,
  StyledWarningIcon,
  ButtonContainer,
} from '../customers.style';
import styled from 'styled-components';
import { AddressType, CustomerLocationsProps } from './types';
import { useTranslation } from 'react-i18next';
import CustomerLocationForm from '../AddCustomer/CustomerLocationForm';
import { useRisClient } from 'hooks/useRisClient';
import {
  useSuspenseQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useCurrentUser } from 'context/CurrentUser';
import TableLoading from 'components/common/TableLoading';
import { useModalContext } from 'context/ModalProvider';
import { UpdatePartnerDeviceLocationModel } from '@resideo/web-integration-services-api-client';
import { convertSchemaAddress } from 'components/common/Address';
import { REZI_COUNTRIES } from 'constants/countries';
import Select, { components } from 'react-select';
import { FaCheck } from 'react-icons/fa';
import { iQSupportEmail } from 'config/emailAddresses';
import { UnableToRemoveLocationModal } from '../Location/LocationModals/UnableToRemoveLocationModal';
import RemoveLocationModal from '../Location/LocationModals/RemoveLocationModal';
import DeleteIcon from 'components/icons/delete.svg?react';
import EditIcon from 'components/icons/edit.svg?react';
import GoogleMaps from './GoogleMap';
import CustomerLocation from './CustomerLocation';
import { CustomerAccountCustomerDataType } from './CustomerAccountDevices';
import { CreatePartnerDeviceLocationModel } from '@resideo/web-integration-services-api-client';
import { useFeatureFlags } from 'context/FeatureFlags';
import { parseGeoAddress } from '../../../utils/common';

const OldHeadingComponent = styled(Flex)`
  display: -webkit-box;
  margin-left: 15px;
  margin-right: 15px;
  min-width: 0;
  padding: 30px 15px 8px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 14px;
    padding: 0 0 15px 0;
  }
`;

const HeadingComponent = styled(Flex)`
  display: -webkit-box;
  margin-left: 15px;
  margin-right: 15px;
  min-width: 0;
  padding: 0px 15px 8px 15px;
  margin-top: 110px;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 14px;
    padding: 0 0 5px 0;
  }
`;

const AddLocationContainer = styled.div`
  box-sizing: border-box;
  width: max-content;
  margin-left: auto;
  padding-top: 8px;
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: none;
    position: relative;
    padding-left: 4%;
    padding-top: 2%;
  }
`;

const AddMobileLocationContainer = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
    margin-left: auto;
    position: relative;
    padding-left: 4%;
    padding-top: 20px;
  }
`;

const OldLocationDesktopSelectContainer = styled.div`
  margin-left: 2%;
  width: 215px;
  padding-top: 8px;
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: none;
    padding: 0;
  }
`;

const LocationDesktopSelectContainer = styled.div`
  position: absolute;
  z-index: 1;
  margin-top: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
    padding: 0;
  }
`;

const DesktopContainer = styled.div`
  width: 200px;
  &:hover .hovering {
    background-color: rgba(246, 246, 246, 0.87);
    display: block;
  }
`;

const OneLocationName = styled.div`
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid rgba(244, 244, 244, 1);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 0.5625rem 0.75rem;
  line-height: 1.5;
  background-color: rgb(255, 255, 255);
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  display: block;
  appearance: none;
  border-style: solid;
  color: #153755;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    border: none;
    padding-left: 0px;
    box-shadow: unset;
  }
`;

const LocationBox = styled(Flex)`
  background-color: #f6f6f6;
  width: 200px;
  opacity: 80%;
  justify-content: space-between;
  margin-top: 10px;
  flex-direction: row;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
`;

const AddressLine1 = styled.div`
  padding-top: 10px;
  padding-left: 5px;
  font-size: 14px;
`;

const AddressLine2 = styled.div`
  padding-left: 5px;
  font-size: 14px;
  width: 200px;
`;

const AddressLine3 = styled.div`
  padding-left: 5px;
  font-size: 14px;
  padding-bottom: 10px;
`;

const GoogleMapsContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`;

const OldLocationMobileSelectContainer = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

// Icons
const AddIcon = styled(MdAddCircleOutline)`
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
  color: #1c6fb9;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 0.875rem;
  }
`;

const LocationMobileSelectContainer = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 30%;
  }
`;

const MobileContainer = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const MobileLocationBox = styled(Flex)`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const IconContainer = styled(Flex)`
  height: fit-content;
  width: fit-content;
  &:hover .editBackground {
    background-color: rgba(250, 250, 250, 1);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  }

  &:active .editBackground {
    background-color: rgba(237, 237, 237, 1);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    &:active .editBackground {
      background-color: rgba(237, 237, 237, 1);
    }
  }
`;

const BackgroundForIcons = styled(Flex)`
  background-color: rgba(247, 247, 247, 0.87);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-left: 10px;

  &:hover .showEditIcon {
    fill: #1c6fb9;
    stroke: #1c6fb9;
  }

  &:hover .showDeleteIcon {
    fill: red;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    background-color: white;
    box-shadow: unset;
  }
`;

const Edit = styled(EditIcon)`
  fill: #153755;
  stroke: #153755;
  height: 20px;
  width: 20px;
  display: inline-block;
  padding-left: 8px;
  padding-top: 8px;
`;

const Delete = styled(DeleteIcon)`
  fill: #153755;
  height: 20px;
  width: 20px;
  display: inline-block;
  padding-left: 8px;
  padding-top: 8px;
`;

const MobileButtonContainer = styled(Flex)`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-right: 10px;
  }
`;

const CheckIcon = styled(FaCheck)`
  color: #1c6fb9;
`;

const OptionDropdownStyles = styled(Flex)`
  justify-content: space-between;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
  &:hover {
    background-color: #ededed;
    height: 25px;
  }
`;

// Customizing Styling for the Select Dropdown
const Option = props => {
  return (
    <components.Option {...props}>
      <OptionDropdownStyles data-test-dropdown>
        <label>{props.label}</label>
        {props.isSelected && <CheckIcon />}
      </OptionDropdownStyles>
    </components.Option>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontWeight: 'bold',
    color: '#153755',
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.05)',
    borderColor: state.menuIsOpen ? '#257DE4F4' : '#f4f4f4',
    backgroundColor: state.menuIsOpen
      ? 'rgba(221, 235, 255)'
      : 'rgb(255, 255, 255)',
    '&:hover': {
      backgroundColor: state.menuIsOpen
        ? 'rgba(221, 235, 255)'
        : 'rgba(246, 246, 246)',
      borderColor: state.menuIsOpen ? '#257DE4F4' : '#f4f4f4',
      '@media (max-width: 48rem)': {
        backgroundColor: 'white',
        border: '0',
      },
    },
    borderRadius: '10px',
    borderWidth: '2px',
    height: '40px',
    '@media (max-width: 48rem)': {
      boxShadow: 'none',
      border: '0',
      backgroundColor: 'white',
    },
  }),
  singleValue: provided => ({
    ...provided,
    color: '#153755',
    fontSize: '1rem',
  }),
  option: provided => ({
    ...provided,
    fontSize: 14,
    color: '#303030',
    backgroundColor: 'white',
    height: '35px',
    paddingLeft: '5px',
    paddingRight: '5px',
    '&:active': {
      backgroundColor: 'white',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#153755',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    '&:hover': {
      color: '#153755',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: provided => ({
    ...provided,
    color: 'transparent',
  }),
  menu: provided => ({
    ...provided,
    marginTop: 0,
    borderRadius: 0,
    boxShadow: 'none',
  }),
};

const CustomerLocations: FC<CustomerLocationsProps> = ({
  customerId,
  selectedLocation,
  setSelectedLocation,
  isLoaded,
  loadError,
  customer,
}) => {
  const { t } = useTranslation();
  const { partnerAccountId } = useCurrentUser();
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const { openModal, closeModal } = useModalContext();
  const { addToast } = useToastContext();
  const { CUSTOMER_DETAILS_REDESIGN } = useFeatureFlags();
  const [suggestedAddress, setSuggestedAddress] = useState<AddressType>({
    address1: '',
    address2: '',
    city: '',
    country: '',
    locationName: '',
    state: '',
    zip: '',
  });
  const [locationValues, setLocationValues] = useState<any>({});
  const {
    openAddressVerificationModal,
    closeAddressVerificationModal,
    AddAddressVerificationModal,
  } = useVerificationModal();

  const { data } = useSuspenseQuery({
    queryKey: ['partnerCustomerLocations'],
    queryFn: async () => {
      const res = await client.myProAccounts.myProAccountsSearchPartnerCustomerLocations(
        partnerAccountId || '',
        customerId || ''
      );
      return res?.data;
    },
  });

  const [deviceLocationEdges, setDeviceLocationEdges] = useState<any>(null);
  const [locationNames, setLocationNames] = useState<any>(null);

  useEffect(() => {
    if (data) {
      setDeviceLocationEdges(data);
      setLocationNames(data?.map((location: any) => location?.name ?? ''));
    }
  }, [data]);

  const [dropdownOptions, setDropdownOptions] = useState<any>([]);
  const [dropdownValue, setDropdownValue] = useState<any>();

  useEffect(() => {
    if (!deviceLocationEdges) {
      return;
    }

    const hasSelectedLocation = deviceLocationEdges?.find(
      location => location?.id === selectedLocation?.id
    );

    setSelectedLocation(
      hasSelectedLocation ? hasSelectedLocation : deviceLocationEdges[0]
    );

    setDropdownOptions(
      deviceLocationEdges?.map(location => ({
        value: location.name,
        label: location.name,
      }))
    );

    setDropdownValue(
      hasSelectedLocation
        ? {
            value: hasSelectedLocation?.name,
            label: hasSelectedLocation?.name,
          }
        : {
            value: deviceLocationEdges[0]?.name,
            label: deviceLocationEdges[0]?.name,
          }
    );
  }, [deviceLocationEdges]);

  const updateLocationDetails = locationName => {
    deviceLocationEdges?.forEach(location => {
      const locationNameInDropdown = CUSTOMER_DETAILS_REDESIGN
        ? locationName?.label
        : locationName;
      if (location?.name === locationNameInDropdown) {
        setSelectedLocation(location);
        setDropdownValue({
          value: locationNameInDropdown,
          label: locationNameInDropdown,
        });
      }
    });
  };

  const editCustomerLocation = useMutation({
    mutationKey: ['editCustomerLocation'],
    mutationFn: async (vars: {
      partnerCustomerId: string;
      partnerDeviceLocationId: string;
      loc: UpdatePartnerDeviceLocationModel;
    }) => {
      await client.myProAccounts.myProAccountsUpdatePartnerDeviceLocation(
        partnerAccountId as string,
        vars?.partnerCustomerId as string,
        vars?.partnerDeviceLocationId as string,
        {
          name: vars?.loc?.name,
          address: {
            addressLine1: vars?.loc?.address?.addressLine1,
            addressLine2: vars?.loc?.address?.addressLine2,
            city: vars?.loc?.address?.city,
            stateProvinceRegionCode:
              vars?.loc?.address?.stateProvinceRegionCode,
            zipPostalCode: vars?.loc?.address?.zipPostalCode,
            countryCode: vars?.loc?.address?.countryCode,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['partnerCustomerLocations'],
      });
      addToast({
        toastType: 'Success',
        message: t('customerDetails.updateLocation.success'),
      });
      closeModal();
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
    },
  });

  const updateCustomerLocation = (values?, isSaveButtonClicked = false) => {
    // parse these values and pass it to service
    const apiValues = isSaveButtonClicked ? values : locationValues;

    editCustomerLocation.mutate({
      partnerCustomerId: customerId ?? '',
      partnerDeviceLocationId: selectedLocation?.id ?? '',
      loc: {
        name: apiValues.locationName,
        address: {
          addressLine1: apiValues.address1,
          addressLine2: apiValues.address2 || '',
          city: apiValues.city,
          stateProvinceRegionCode: apiValues.state,
          zipPostalCode: apiValues.zip,
          countryCode: apiValues.country,
        },
      },
    });
  };

  const handleUpdateLocation = async values => {
    const {
      geoAddress,
      result,
      isLocationTypeRoofTop,
      partialMatch,
      shortNames,
    } = await parseGeoAddress(values);

    // check the response structure for geoAddress
    if ((result && isLocationTypeRoofTop && !partialMatch) || !geoAddress) {
      updateCustomerLocation();
    } else {
      closeModal();
      // updating the formatted address similar to original address so that we can use the same to make the API call
      setSuggestedAddress(shortNames);
      openAddressVerificationModal();
    }
  };

  // Add location API call
  const addCustomerDeviceLocation = useMutation({
    mutationKey: ['addCustomerDeviceLocation'],
    mutationFn: async (variables: {
      partnerCustomerId: string;
      loc: CreatePartnerDeviceLocationModel;
    }) => {
      await client.myProAccounts.myProAccountsCreatePartnerDeviceLocation(
        partnerAccountId as string,
        variables?.partnerCustomerId as string,
        {
          name: variables?.loc?.name,
          address: {
            addressLine1: variables?.loc?.address?.addressLine1,
            addressLine2: variables?.loc?.address?.addressLine2,
            city: variables?.loc?.address?.city,
            stateProvinceRegionCode:
              variables?.loc?.address?.stateProvinceRegionCode,
            zipPostalCode: variables?.loc?.address?.zipPostalCode,
            countryCode: variables?.loc?.address?.countryCode,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['partnerCustomerLocations'],
      });
      closeModal();
      addToast({
        toastType: 'Success',
        message: t('customerDetails.addLocationToast.success'),
      });
    },
    onError: () => {
      openModal({
        title: t('customers.addCustomer'),
        children: (
          <Flex flexDirection='column' alignItems='left' width={[1]}>
            <WarningContainer>
              <StyledWarningIcon />
              <Text fontSize='large' fontWeight='bold'>
                {t('customers.addLocationErrors.title')}
              </Text>
            </WarningContainer>
            <p> {t('customers.addLocationErrors.error')}</p>
            <p>
              {t('customers.addLocationErrors.contactSupport')}{' '}
              <StyledLink href='mailto:proIQsupport@resideo.com'>
                proIQsupport@resideo.com
              </StyledLink>
              {'.'}
            </p>
            <ButtonContainer>
              <Button variant='primary' onClick={() => closeModal()}>
                {t('common.ok')}
              </Button>
            </ButtonContainer>
          </Flex>
        ),
      });
    },
  });

  const handleCreateDeviceLocation = values => {
    addCustomerDeviceLocation.mutate({
      partnerCustomerId: customerId || '',
      loc: {
        name: values?.locationName,
        address: {
          addressLine1: values?.address1,
          addressLine2: values?.address2,
          city: values?.city,
          stateProvinceRegionCode: values?.state,
          zipPostalCode: values?.zip,
          countryCode: values?.country,
        },
      },
    });
  };

  const removeCustomerLocation = useMutation({
    mutationKey: ['removeCustomerLocation'],
    mutationFn: async (variables: { partnerDeviceLocationId: string }) => {
      await client.myProAccounts.myProAccountsDeletePartnerDeviceLocation(
        partnerAccountId as string,
        variables?.partnerDeviceLocationId as string
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['partnerCustomerLocations'],
      });
      addToast({
        toastType: 'Success',
        message: t('customerDetails.removeLocation.successMessage'),
      });
      setDropdownOptions(prevOptions =>
        prevOptions.filter(opt => opt.value !== dropdownValue.value)
      );
      closeModal();
    },
    onError: () => {
      closeModal();
      addToast({
        toastType: 'Error',
        message: t('customerDetails.removeLocation.errorMessage'),
        linkText: t('customerDetails.removeLocation.contactCustomerSupport'),
        linkHref: `mailto:${iQSupportEmail}?subject=Unable to Remove Location`,
      });
    },
  });

  const handleRemoveLocation = () => {
    removeCustomerLocation.mutate({
      partnerDeviceLocationId: selectedLocation?.id ?? '',
    });
  };

  const EditLocationModal = (backButtonAddress?, isBackBtnClicked = false) => {
    const address = isBackBtnClicked
      ? backButtonAddress
      : convertSchemaAddress(selectedLocation?.address);
    return openModal({
      title: t('locations.editLocation'),
      children: (
        <CustomerLocationForm
          initialValues={{
            locationName: selectedLocation?.name,
            locationId: selectedLocation?.id,
            ...address,
          }}
          onSubmit={(values, autoCompleteSelected) => {
            if (autoCompleteSelected) {
              updateCustomerLocation(values, true);
            } else {
              setLocationValues(values);
              handleUpdateLocation(values);
            }
          }}
          onCancel={() => closeModal()}
          isLoaded={isLoaded}
          loadError={loadError}
          locations={deviceLocationEdges}
          isBackBtnClickedFromVerificationModal={isBackBtnClicked}
        />
      ),
    });
  };

  return (
    <>
      {CUSTOMER_DETAILS_REDESIGN ? (
        <>
          <HeadingComponent data-test-locations-heading>
            {deviceLocationEdges && (
              <>
                <LocationDesktopSelectContainer>
                  {deviceLocationEdges?.length > 1 && (
                    <DesktopContainer data-test-location-names>
                      <Select
                        options={dropdownOptions}
                        components={{ Option }}
                        styles={customStyles}
                        value={dropdownValue}
                        onChange={updateLocationDetails}
                        blurInputOnSelect
                      />
                    </DesktopContainer>
                  )}

                  {deviceLocationEdges?.length === 1 && (
                    <DesktopContainer>
                      <OneLocationName
                        data-test-location-name
                        className='hovering'>
                        {selectedLocation?.name}
                      </OneLocationName>
                    </DesktopContainer>
                  )}
                  {deviceLocationEdges?.length >= 1 && (
                    <Flex style={{ flexDirection: 'row' }}>
                      <LocationBox>
                        <Flex style={{ flexDirection: 'column' }}>
                          <AddressLine1 data-test-street-address>
                            {selectedLocation?.address?.addressLine1}{' '}
                            {selectedLocation?.address?.addressLine2}
                          </AddressLine1>
                          <AddressLine2 data-test-city-state-zip>
                            {selectedLocation?.address?.city},{' '}
                            {selectedLocation?.address?.stateProvinceRegionCode}{' '}
                            {selectedLocation?.address?.zipPostalCode}
                          </AddressLine2>
                          <AddressLine3 data-test-country>
                            {`${
                              REZI_COUNTRIES[
                                selectedLocation?.address?.countryCode
                              ]
                            }`}
                          </AddressLine3>
                        </Flex>
                      </LocationBox>
                      <Flex style={{ flexDirection: 'row', paddingTop: '5px' }}>
                        <Flex
                          data-test-edit-icon
                          onClick={() => {
                            EditLocationModal();
                          }}>
                          <IconContainer>
                            <BackgroundForIcons className='editBackground'>
                              <Edit className='showEditIcon' />
                            </BackgroundForIcons>
                          </IconContainer>
                        </Flex>
                        <Flex
                          data-test-delete-icon
                          onClick={() => {
                            setDropdownValue({
                              value: selectedLocation?.name,
                              label: selectedLocation?.name,
                            });
                            openModal({
                              title: selectedLocation?.devices?.length
                                ? t(
                                    'customerDetails.cannotRemoveLocation.title'
                                  )
                                : t('customerDetails.removeLocation.title'),
                              children: selectedLocation?.devices?.length ? (
                                <UnableToRemoveLocationModal
                                  onSubmit={closeModal}
                                />
                              ) : (
                                <RemoveLocationModal
                                  onCancel={closeModal}
                                  onSubmit={() => {
                                    handleRemoveLocation();
                                  }}
                                />
                              ),
                            });
                          }}>
                          <IconContainer>
                            <BackgroundForIcons className='editBackground'>
                              <Delete className='showDeleteIcon' />
                            </BackgroundForIcons>
                          </IconContainer>
                        </Flex>
                      </Flex>
                    </Flex>
                  )}
                </LocationDesktopSelectContainer>
              </>
            )}
          </HeadingComponent>
          {deviceLocationEdges?.length > 1 && (
            <LocationMobileSelectContainer>
              <Select
                components={{ Option }}
                styles={customStyles}
                value={dropdownValue}
                onChange={updateLocationDetails}
                options={dropdownOptions}
                blurInputOnSelect
              />
            </LocationMobileSelectContainer>
          )}
          {deviceLocationEdges?.length === 1 && (
            <MobileContainer>
              <OneLocationName className='hovering'>
                {selectedLocation?.name}
              </OneLocationName>
            </MobileContainer>
          )}
          {deviceLocationEdges?.length >= 1 && (
            <Flex
              style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <MobileLocationBox className='showEditIcon'>
                <Flex style={{ flexDirection: 'column' }}>
                  <AddressLine1
                    style={{
                      paddingTop: '10px',
                      paddingLeft: '5px',
                      fontSize: '14px',
                    }}>
                    {selectedLocation?.address?.addressLine1}{' '}
                    {selectedLocation?.address?.addressLine2}
                  </AddressLine1>
                  <AddressLine2>
                    {selectedLocation?.address?.city},{' '}
                    {selectedLocation?.address?.stateProvinceRegionCode}{' '}
                    {selectedLocation?.address?.zipPostalCode}
                  </AddressLine2>
                  <AddressLine3>
                    {`${
                      REZI_COUNTRIES[selectedLocation?.address?.countryCode]
                    }`}
                  </AddressLine3>
                </Flex>
              </MobileLocationBox>

              <MobileButtonContainer>
                <Flex
                  onClick={() => {
                    EditLocationModal();
                  }}>
                  <IconContainer>
                    <BackgroundForIcons className='editBackground'>
                      <Edit className='showEditIcon' />
                    </BackgroundForIcons>
                  </IconContainer>
                </Flex>
                <Flex
                  onClick={() => {
                    openModal({
                      title: selectedLocation?.devices?.length
                        ? t('customerDetails.cannotRemoveLocation.title')
                        : t('customerDetails.removeLocation.title'),
                      children: selectedLocation?.devices?.length ? (
                        <UnableToRemoveLocationModal onSubmit={closeModal} />
                      ) : (
                        <RemoveLocationModal
                          onCancel={closeModal}
                          onSubmit={() => {
                            handleRemoveLocation();
                          }}
                        />
                      ),
                    });
                  }}>
                  <IconContainer>
                    <BackgroundForIcons className='editBackground'>
                      <Delete className='showDeleteIcon' />
                    </BackgroundForIcons>
                  </IconContainer>
                </Flex>
              </MobileButtonContainer>
            </Flex>
          )}
          {selectedLocation && (
            <Suspense fallback={<TableLoading />}>
              <GoogleMapsContainer data-test-google-map>
                <GoogleMaps
                  isLoaded={isLoaded}
                  location={selectedLocation}
                  loadError={loadError}
                  customerId={customerId}
                  locations={deviceLocationEdges}
                />
              </GoogleMapsContainer>
            </Suspense>
          )}
          <CustomerLocation
            customerData={
              {
                firstName: customer?.firstName || '',
                lastName: customer?.lastName || '',
                email: customer?.email || '',
              } as CustomerAccountCustomerDataType
            }
            location={selectedLocation}
            customerId={customerId}
            isLoaded={isLoaded}
            loadError={loadError}
            locations={deviceLocationEdges}
          />
          <AddAddressVerificationModal
            suggestedAddress={suggestedAddress}
            originalAddress={locationValues}
            closeAddressVerificationModal={closeAddressVerificationModal}
            backButtonClicked={address => {
              EditLocationModal(address, true);
              closeAddressVerificationModal();
            }}
            saveButtonClicked={values => {
              updateCustomerLocation(values, true);
              closeAddressVerificationModal();
            }}
            translations={{
              ModalTitle: t('locations.editLocation'),
              ModalHeader: t('verificationModal.modalHeader'),
              ModalText: t('verificationModal.modalText'),
              ModalChooseText: t('verificationModal.modalChooseText'),
              OriginalAddressLabel: t('verificationModal.originalAddressLabel'),
              SuggestedAddressLabel: t(
                'verificationModal.suggestedAddressLabel'
              ),
              BackBtnLabel: t('common.back'),
              SaveBtnLabel: t('common.save'),
            }}
          />
        </>
      ) : (
        <>
          <OldHeadingComponent data-test-locations-heading>
            <Heading as='h3' fontSize='large' paddingTop='medium'>
              {t('customers.locations')}
            </Heading>
            {deviceLocationEdges?.length > 1 && (
              <OldLocationDesktopSelectContainer>
                <BlueprintSelect
                  version='v2'
                  value={selectedLocation?.name}
                  name={'Location'}
                  onChange={e => updateLocationDetails(e.target.value)}>
                  {locationNames?.map((locationName, id) => {
                    return (
                      <option key={id} value={locationName}>
                        {locationName}
                      </option>
                    );
                  })}
                </BlueprintSelect>
              </OldLocationDesktopSelectContainer>
            )}
            <>
              <AddLocationContainer>
                <Button
                  variant={'secondary'}
                  width='max-content'
                  onClick={() => {
                    openModal({
                      title: t('locations.addLocation'),
                      children: (
                        <CustomerLocationForm
                          initialValues={{
                            locationName: '',
                            address1: '',
                            address2: '',
                            city: '',
                            state: '',
                            zip: '',
                            country: '',
                          }}
                          onSubmit={values =>
                            handleCreateDeviceLocation(values)
                          }
                          onCancel={() => closeModal()}
                          isLoaded={isLoaded}
                          loadError={loadError}
                          locations={deviceLocationEdges}
                        />
                      ),
                    });
                  }}>
                  {t('locations.addLocation')}
                </Button>
              </AddLocationContainer>
              <AddMobileLocationContainer
                onClick={() => {
                  openModal({
                    title: t('locations.addLocation'),
                    children: (
                      <CustomerLocationForm
                        initialValues={{
                          locationName: '',
                          address1: '',
                          address2: '',
                          city: '',
                          state: '',
                          zip: '',
                          country: '',
                        }}
                        onSubmit={values => handleCreateDeviceLocation(values)}
                        onCancel={() => closeModal()}
                        isLoaded={isLoaded}
                        loadError={loadError}
                        locations={deviceLocationEdges}
                      />
                    ),
                  });
                }}>
                <AddIcon />
                <Button size='large' variant='tertiary' tertiaryLink>
                  {t('common.add')}
                </Button>
              </AddMobileLocationContainer>
            </>
          </OldHeadingComponent>
          {deviceLocationEdges?.length > 1 && (
            <OldLocationMobileSelectContainer>
              <BlueprintSelect
                version='v2'
                value={selectedLocation?.node?.name}
                name={'Location'}
                onChange={e => updateLocationDetails(e.target.value)}>
                {locationNames?.map((locationName, id) => {
                  return (
                    <option key={id} value={locationName}>
                      {locationName}
                    </option>
                  );
                })}
              </BlueprintSelect>
            </OldLocationMobileSelectContainer>
          )}
          {selectedLocation && (
            <Suspense fallback={<TableLoading />}>
              <CustomerLocation
                customerData={
                  {
                    firstName: customer?.firstName || '',
                    lastName: customer?.lastName || '',
                    email: customer?.email || '',
                  } as CustomerAccountCustomerDataType
                }
                location={selectedLocation}
                customerId={customerId}
                isLoaded={isLoaded}
                loadError={loadError}
                locations={deviceLocationEdges}
              />
            </Suspense>
          )}
        </>
      )}
    </>
  );
};

export default CustomerLocations;
