import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Link,
  Text,
} from '@resideo/blueprint-react';
import Page from 'components/common/Page';

const PageNotFound: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Page as='main' data-test-page-not-found>
      <Card borderRadius={[0, 'medium']} padding='medium'>
        <Heading as='h1' fontSize='xxLarge' marginBottom='medium'>
          {t('404.header')}
        </Heading>
        <Text fontSize='medium' data-test-page-not-found-desc>
          {t('404.description')}
        </Text>
        <Flex paddingTop='large'>
          {window.history.length > 1 && (
            <Box paddingRight='medium'>
              <Button variant='secondary' onClick={() => navigate(-1)}>
                {t('common.back')}
              </Button>
            </Box>
          )}
          <Box>
            <Link href='/'>
              <Button variant='primary'>{t('404.goHome')}</Button>
            </Link>
          </Box>
        </Flex>
      </Card>
    </Page>
  );
};

export default PageNotFound;
