import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Flex, useToastContext } from '@resideo/blueprint-react';
import { convertSchemaAddress } from 'components/common/Address';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'context/ModalProvider';
import CustomerLocationForm from '../AddCustomer/CustomerLocationForm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdatePartnerDeviceLocationModel } from '@resideo/web-integration-services-api-client/dist';
import { useCurrentUser } from 'context/CurrentUser';
import { useRisClient } from 'hooks/useRisClient';
import { GoogleMap } from '@react-google-maps/api';
import GoogleMapsMarker from './GoogleMapsMarker';

interface Location {
  lat: number;
  lng: number;
}

const InvalidAddressContainer = styled(Flex)`
  padding-top: 65px;
  height: 275px;
  flex-direction: column;
  background-color: #e4e4e4;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }
`;

const InvalidAddress = styled(Flex)`
  font-weight: bold;
  padding-bottom: 15px;
  justify-content: center;
`;

const InvalidAddressText = styled(Flex)`
  justify-content: center;
  padding-bottom: 15px;
  text-align: center;
`;

interface Props {
  location: any;
  isLoaded: any;
  loadError: any;
  customerId: any;
  locations?: any;
}

const GoogleMaps: React.FC<Props> = ({
  location,
  isLoaded,
  loadError,
  customerId,
  locations,
}) => {
  const address = location?.address;
  const { t } = useTranslation();
  const { openModal, closeModal } = useModalContext();
  const { partnerAccountId } = useCurrentUser();
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const { addToast } = useToastContext();

  const { addressLine1, city, stateProvinceRegionCode, zipPostalCode } =
    address || {};
  const locationAddress = `${addressLine1} ${city} ${stateProvinceRegionCode} ${zipPostalCode}`;

  const [coordinates, setCoordinates] = useState<Location | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);

  const [invalidAddress, setInvalidAddress] = useState(false);

  useEffect(() => {
    const geocodeAddress = async () => {
      if (!address || !isLoaded) return;

      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: locationAddress }, (results, status) => {
        if (
          status === 'OK' &&
          // this location_type will be true if a valid address has been entered
          results?.[0]?.geometry?.location_type === 'ROOFTOP'
        ) {
          const { lat, lng } = results?.[0].geometry.location;
          setCoordinates({ lat: lat(), lng: lng() });
          setInvalidAddress(false);
        } else {
          console.error(
            'Geocode was not successful for the following reason: ' + status
          );
          setInvalidAddress(true);
        }
      });
    };
    geocodeAddress();
  }, [address, isLoaded]);

  const mapContainerStyle = {
    width: '100%',
    height: '250px',
  };

  const center = coordinates || { lat: -34.397, lng: 150.644 }; // Default center

  const editCustomerLocation = useMutation({
    mutationKey: ['editCustomerLocation'],
    mutationFn: async (vars: {
      partnerCustomerId: string;
      partnerDeviceLocationId: string;
      loc: UpdatePartnerDeviceLocationModel;
    }) => {
      await client.myProAccounts.myProAccountsUpdatePartnerDeviceLocation(
        partnerAccountId as string,
        vars?.partnerCustomerId as string,
        vars?.partnerDeviceLocationId as string,
        {
          name: vars?.loc?.name,
          address: {
            addressLine1: vars?.loc?.address?.addressLine1,
            addressLine2: vars?.loc?.address?.addressLine2,
            city: vars?.loc?.address?.city,
            stateProvinceRegionCode:
              vars?.loc?.address?.stateProvinceRegionCode,
            zipPostalCode: vars?.loc?.address?.zipPostalCode,
            countryCode: vars?.loc?.address?.countryCode,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['partnerCustomerLocations'],
      });
      addToast({
        toastType: 'Success',
        message: t('customerDetails.updateLocation.success'),
      });
      closeModal();
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
    },
  });

  const handleUpdateLocation = values => {
    editCustomerLocation.mutate({
      partnerCustomerId: customerId ?? '',
      partnerDeviceLocationId: location?.id ?? '',
      loc: {
        name: values.locationName,
        address: {
          addressLine1: values.address1,
          addressLine2: values.address2,
          city: values.city,
          stateProvinceRegionCode: values.state,
          zipPostalCode: values.zip,
          countryCode: values.country,
        },
      },
    });
  };

  return (
    <div style={{ width: '100%', overflow: 'hidden', height: '250px' }}>
      {!invalidAddress && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={15}
          onLoad={map => {
            mapRef.current = map;
          }}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            // mapId comes from the google account
            mapId: 'cf89b52af1a124cb',
          }}>
          <GoogleMapsMarker position={center} map={mapRef.current} />
        </GoogleMap>
      )}
      {invalidAddress && (
        <InvalidAddressContainer>
          <InvalidAddress data-test-invalid-location-title>
            {t('customerDetails.invalidCustomerLocation.title')}
          </InvalidAddress>
          <InvalidAddressText data-test-invalid-location-text>
            {t('customerDetails.invalidCustomerLocation.text')} <br />
            {t('customerDetails.invalidCustomerLocation.text2')}
          </InvalidAddressText>
          <Flex style={{ justifyContent: 'center' }}>
            <Button
              data-test-change-address-btn
              style={{ color: '#333333' }}
              variant='secondary'
              onClick={() => {
                const editAddress = convertSchemaAddress(address);
                openModal({
                  title: t('locations.editLocation'),
                  children: (
                    <CustomerLocationForm
                      initialValues={{
                        locationName: location?.name,
                        locationId: location?.id,
                        ...editAddress,
                      }}
                      onSubmit={values => handleUpdateLocation(values)}
                      onCancel={() => closeModal()}
                      isLoaded={isLoaded}
                      loadError={loadError}
                      locations={locations}
                    />
                  ),
                });
              }}>
              {t('customerDetails.invalidCustomerLocation.button')}
            </Button>
          </Flex>
        </InvalidAddressContainer>
      )}
    </div>
  );
};

export default GoogleMaps;
