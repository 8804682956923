const IMAGEKIT_UPLOAD_URL = 'https://upload.imagekit.io/api/v1/files/upload';

export const respond = (isError, response, callback) => {
  if (typeof callback == 'function') {
    if (isError) {
      callback(response, null);
    } else {
      callback(null, response);
    }
  }
};

export const uploadFile = (formData, callback) => {
  const uploadFileXHR = new XMLHttpRequest();
  uploadFileXHR.open('POST', IMAGEKIT_UPLOAD_URL);

  uploadFileXHR.onerror = function() {
    respond(true, 'Upload Network Error', callback);
    return;
  };

  uploadFileXHR.onload = function() {
    if (uploadFileXHR.status === 200) {
      if (typeof callback != 'function') return;
      const uploadResponse = JSON.parse(uploadFileXHR.responseText);
      callback(null, uploadResponse);
    } else if (uploadFileXHR.status !== 200) {
      if (typeof callback != 'function') return;
      callback(JSON.parse(uploadFileXHR.responseText));
    }
  };

  uploadFileXHR.send(formData);
  return;
};

export const upload = (uploadOptions, callback) => {
  if (!uploadOptions) {
    respond(true, 'Missing required options', callback);
    return;
  }
  ['publicKey', 'file', 'fileName', 'signature', 'expire', 'token'].forEach(
    option => {
      if (!uploadOptions[option]) {
        respond(true, `Missing ${option}`, callback);
        return;
      }
    }
  );

  const formData = new FormData();
  for (const i in uploadOptions) {
    formData.append(i, uploadOptions[i]);
  }

  uploadFile(formData, callback);
};
