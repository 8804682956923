import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { DeviceAlertSeverity } from '../types';
import SeverityIcon from './SeverityIcon';
import { severityTitleKeys } from '../const';

export interface AlertFilterButtonProps {
  severity: DeviceAlertSeverity;
  messagesCount: number;
  active?: boolean;
  onChange: (state?: boolean, severity?: DeviceAlertSeverity) => any;
  className?: string;
}

interface IBtn {
  $active: boolean;
}

const Button = styled.button<IBtn>`
  cursor: pointer;
  width: 172px;
  height: 100px;
  box-sizing: border-box;
  padding: 0 12px;
  text-align: left;
  background: #ffffff;
  color: #303030;
  border-radius: 10px;
  border: 3px solid transparent;
  transition: all 0.1s ease-out;
  box-shadow: 0 2px 10px #00000012;
  ${({ $active }) =>
    $active &&
    `
      background: #ddebff;
      border: 3px solid #257de4;
    `}

  &:hover {
    background: ${props => (props.$active ? '#257de4' : '#f4f4f4')};
    color: ${props => (props.$active ? '#fff' : '#303030')};
  }

  &:active {
    color: #303030;
    background: #ddebff;
    border: 3px solid transparent;
  }
`;

const Severity = styled.div`
  font: normal normal normal 15px/20px Roboto;
  letter-spacing: 1px;
  display: flex;
  margin-bottom: 10px;

  svg {
    margin-right: 10px;
  }
`;

const MessagesCount = styled.div`
  font: normal normal bold 30px Roboto;
  margin-bottom: 12px;
`;

const formatNumber = (n: number) =>
  Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: n > 1000000 ? 2 : 1,
  }).format(n);

const AlertFilterButton: FC<AlertFilterButtonProps> = ({
  severity,
  messagesCount,
  active,
  onChange,
  className,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Button
      className={className}
      $active={!!active}
      onClick={() => {
        onChange(!active, severity);
      }}>
      <MessagesCount>{formatNumber(messagesCount)}</MessagesCount>
      <Severity>
        <SeverityIcon severity={severity} />
        {t(severityTitleKeys[severity])}
      </Severity>
    </Button>
  );
};

export default AlertFilterButton;
