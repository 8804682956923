import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Text,
  Link as BPLink,
  TableV2,
  Option,
} from '@resideo/blueprint-react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { useIsMounted } from 'hooks/useIsMounted';
import { useFeatureFlags } from '../../context/FeatureFlags';
import useStateData from 'hooks/useStateData';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useCurrentUser } from 'context/CurrentUser';
import { useNavigate } from 'react-router-dom';

const StyledTooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
    box-shadow: 0px 2px 10px #0000004d;
  }
`;

const SERVICES = {
  BrandingPartnerDeviceService: 'Homeowner App Branding',
  ProAlertsPartnerDeviceService: 'Pro Alerts',
};

interface LocationsWithUnregisteredDevicesProps {
  filter?: string | undefined;
  filterBy?: Option | undefined;
}

const LocationsWithUnregisteredDevices = ({
  filter,
  filterBy,
}: LocationsWithUnregisteredDevicesProps) => {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState<any>({ createdAt: 'DESC' });
  const isMounted = useIsMounted();
  const { TABLE_RESULTS_PER_PAGE } = useFeatureFlags();
  const rowsPerPage = TABLE_RESULTS_PER_PAGE;
  const { stateData } = useStateData();
  const { client } = useRisClient();
  const { partnerAccountId } = useCurrentUser();

  const { data, error } = useSuspenseQuery({
    queryKey: ['new', { filter, sortBy }],
    queryFn: async () => {
      const customerFilter = JSON.stringify([
        { 'customer.firstName': filter },
        { 'customer.lastName': filter },
        { 'address.addressline1': filter },
        { 'address.city': filter },
        { 'address.zippostalcode': filter },
      ]);

      const result = await client.myProAccounts.myProAccountsGetUnregisteredDeviceLocations(
        partnerAccountId ?? '',
        customerFilter,
        JSON.stringify(sortBy)
      );
      return result?.data;
    },
  });

  useEffect(() => {
    setPage(0);
  }, [sortBy, filter, filterBy]);

  const getLocationCell = (id, addressField) => {
    if (addressField) {
      const {
        addressLine1,
        addressLine2,
        city,
        stateProvinceRegionCode,
        zipPostalCode,
        countryCode,
      } = addressField;
      const stateName =
        stateData?.find(
          state =>
            state.country_code === countryCode &&
            state.iso2 === stateProvinceRegionCode
        )?.name ?? '';

      return (
        <>
          <Text fontSize='medium'>
            {addressLine1} {addressLine2}
          </Text>
          <Text fontSize='medium'>{`${city} ${stateName} ${zipPostalCode}`}</Text>
        </>
      );
    }
  };

  const getDeviceCell = (id, devices) => {
    const deviceList = [] as { loc: any; id: string }[];
    devices?.forEach(device => {
      const deviceId = device.deviceId;
      deviceList.push(deviceId);
    });

    return (
      <>
        {/* Display first three device IDs */}
        {deviceList.slice(0, 3).map(device => {
          return <Text key={device.id}>{device}</Text>;
        })}
        {/* More than three devices, show tooltip */}
        {deviceList.length > 3 && (
          <>
            <BPLink data-tip data-for={'devices-' + id}>{`+${deviceList.length -
              3} More`}</BPLink>
            <StyledTooltip
              type='light'
              id={'devices-' + id}
              place='bottom'
              effect='solid'
              delayHide={500}
              delayUpdate={500}>
              {deviceList.slice(3).map(device => (
                <div key={device?.id}>
                  <Text fontSize='medium'>{device}</Text>
                </div>
              ))}
            </StyledTooltip>
          </>
        )}
      </>
    );
  };

  const partnerCustomersCount = data?.length || 0;

  const pageInfo = {
    hasNextPage: page * rowsPerPage + rowsPerPage < partnerCustomersCount,
    hasPreviousPage: page !== 0,
  };

  const getRows = () => {
    return (
      data
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(node => {
          return {
            id: node?.partnerCustomerId,
            location: getLocationCell(node?.id, node?.address),
            name: `${node?.lastName}, ${node?.firstName}`,
            devices: getDeviceCell(node?.id, node?.devices),
            services:
              SERVICES[
                node.deviceServices?.[0]?.type || 'BrandingPartnerDeviceService'
              ],
          };
        }) || []
    );
  };

  return (
    <TableV2
      headers={[
        {
          displayName: 'id',
          isSortable: false,
          isHiddenColumn: true,
        },
        {
          displayName: t('common.location'),
          onSortColumnName: 'address.addressline1',
          isSortable: true,
        },
        {
          displayName: t('common.customer'),
          isSortable: true,
          onSortColumnName: 'name',
          useEllipses: true,
          onColumnCellClick: row => {
            navigate(`/mybusiness/customers/account-lookup/${row.id}`);
          },
        },
        {
          displayName: t('common.unregisteredDevices'),
          isSortable: false,
          notBlueprintEllipses: true,
        },
        {
          displayName: t('common.pendingServices'),
          isSortable: false,
        },
      ]}
      isError={
        error
          ? {
              errorHeader: t('common.tableErrors.header'),
              errorMessage: t('common.tableErrors.text'),
            }
          : undefined
      }
      rows={getRows()}
      onSort={(sortColumn, sortDirection) => {
        if (sortColumn && sortDirection && isMounted()) {
          setPage(0);
          setSortBy({
            [sortColumn]: sortDirection,
          });
        }
      }}
      NoDataMessage={t('customers.noCustomersFound')}
      initialSortDirection={'DESC'}
      hasPagination
      hasNext={pageInfo?.hasNextPage}
      hasPrevious={pageInfo?.hasPreviousPage}
      nextButtonLabel={t('common.next')}
      previousButtonLabel={t('common.previous')}
      onPaginationNextClick={() => {
        setPage(prev => prev + 1);
      }}
      onPaginationPrevClick={() => {
        setPage(prev => prev - 1);
      }}
    />
  );
};

export default LocationsWithUnregisteredDevices;
