import { Flex, Box } from '@resideo/blueprint-react';
import { AnonymousHeader } from 'components/header/AnonymousHeader';
import Header from 'components/header/Header';
import { LayoutProvider } from 'context/Layout';
import React, { FC, ReactNode } from 'react';
import { useAuth } from 'utils/auth';
import Footer from '../footer/Footer';
import { AccessRemoved } from '../common/AccessRemoved';
import { useCurrentUser } from 'context/CurrentUser';
import PageLoading from '../common/PageLoading';
import ServiceStatusBanner from '../common/ServiceStatusBanner';
import { COMPLETE_SERVICE_SETUP } from '../../config/flags';

const AppLayout: FC<{ showHeader?: boolean; children?: ReactNode }> = ({
  showHeader = true,
  children,
}): JSX.Element => {
  const auth = useAuth();
  const { isCurrentUserLoaded, userStatus } = useCurrentUser();

  return (
    <LayoutProvider>
      {COMPLETE_SERVICE_SETUP && auth?.isAuthenticated && (
        <ServiceStatusBanner />
      )}
      <Flex flexDirection='column' minHeight='100vh'>
        {auth?.isAuthenticated &&
        !auth?.isLoading &&
        isCurrentUserLoaded &&
        showHeader ? (
          <Header userStatus={userStatus || ''} />
        ) : (
          <AnonymousHeader />
        )}
        {!auth?.isLoading && isCurrentUserLoaded ? (
          <>
            {userStatus !== 'INACTIVE' && userStatus !== 'ARCHIVED' ? (
              <Flex flex='1'>
                <Box flex='1'>{children}</Box>
              </Flex>
            ) : (
              <AccessRemoved />
            )}
            <Footer />
          </>
        ) : (
          <PageLoading />
        )}
      </Flex>
    </LayoutProvider>
  );
};

export default AppLayout;
