import React, { useState } from 'react';
import { Button, Flex, Text, useToastContext } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { useRisClient } from 'hooks/useRisClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCurrentUser } from 'context/CurrentUser';
import {
  PartnerAccountApprovalStatus,
  UpdatePartnerUserModel,
} from '@resideo/web-integration-services-api-client';

const ArchiveEmployeeModalContent = ({ closeModal, id, name }) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const { addToast } = useToastContext();
  const { client } = useRisClient();
  const { partnerAccountId } = useCurrentUser();
  const queryClient = useQueryClient();

  const archiveEmployee = useMutation({
    mutationKey: ['archiveEmployee'],
    mutationFn: async (id: string) => {
      await client.myProAccounts.myProAccountsUpdatePartnerUser(
        partnerAccountId as string,
        id,
        {
          status: PartnerAccountApprovalStatus.ARCHIVED,
        } as UpdatePartnerUserModel
      );
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
      setSubmitting(false);
      closeModal();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['getPartnerUserById'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['getArchivedEmployees'],
      });
      addToast({
        toastType: 'Success',
        message: t('mybusiness.employees.toast.employeeArchived', { name }),
      });
      setSubmitting(false);
      closeModal();
    },
  });

  return (
    <Flex data-test-modal-container flexDirection='column'>
      <Text marginBottom='medium'>
        {t('mybusiness.employees.archived.archiveModal.pleaseConfirm')}
      </Text>
      <Text marginBottom='medium' fontWeight='bold' fontSize='large'>
        {name}
      </Text>
      <Text marginBottom='large'>
        {t('mybusiness.employees.archived.archiveModal.body')}
      </Text>
      <Flex justifyContent='flex-end'>
        <Button
          data-test-archive-modal-cancel-button
          onClick={() => closeModal()}
          marginRight='large'
          variant='tertiary'>
          {t('common.cancel')}
        </Button>
        <Button
          data-test-archive-modal-confirm-button
          disabled={submitting}
          onClick={() => {
            setSubmitting(true);
            archiveEmployee.mutate(id);
          }}
          variant='primary'>
          {t('common.confirm')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default ArchiveEmployeeModalContent;
