import React from 'react';
import styled from 'styled-components';
import { ActionButton } from './DashboardComponents';

const StyledFlagButton = styled(ActionButton)<{
  $active?: boolean;
}>`
  .flag-button,
  .flagpole {
    stroke: #153755;
  }

  .flag-button {
    fill: ${props => (props.$active ? '#153755' : 'white')};
  }

  &:hover {
    .flag-button,
    .flagpole {
      stroke: ${props => (props.$active ? '#1C6FB9' : '#153755')};
    }

    .flag-button {
      fill: ${props => (props.$active ? '#1C6FB9' : '#bfdcff')};
    }
  }
`;

const FlagButton = ({
  onClick,
  active,
  children,
}: {
  onClick: () => void;
  active?: boolean;
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <StyledFlagButton onClick={onClick} $active={active}>
      <svg
        width='23'
        height='22'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          className='flag-button'
          d='M6 13.161s.782-.782 3.127-.782c2.345 0 3.908 1.563 6.253 1.563s3.127-.782 3.127-.782V3.782s-.782.782-3.127.782C13.035 4.564 11.472 3 9.127 3S6 3.782 6 3.782v9.379Z'
        />
        <path className='flagpole' d='M6 18.633V13.16' />
      </svg>
      {children}
    </StyledFlagButton>
  );
};

FlagButton.displayName = 'FlagButton';

export default FlagButton;
