import React, { useRef, useState, RefObject, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import ActiveNotificationBell from 'components/icons/active-notifications-bell.svg?react';
import NotificationsBell from 'components/icons/notifications-bell.svg?react';
import { Text } from '@resideo/blueprint-react';
import styled from 'styled-components';
import useOutsideClick from 'hooks/useOutsideClick';

const NotificationsContainer = styled.div`
  display: flex;
  position: relative;
  padding: 0 5px;
`;

const NotificationsButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
`;

const NotificationsListContainer = styled.div`
  position: absolute;
  top: 50px;
  width: 260px;
  right: 0;
  z-index: 99999;
  background: #f7f7f7;
  box-shadow: 0 3px 6px #00000029;
  font-size: 14px;
  font-weight: normal;
  color: #303030;

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: -10px;
    right: 15px;
    border: 10px solid transparent;
    border-top: none;
    border-bottom-color: #f7f7f7;
    height: 0;
    width: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: auto;
    position: fixed;
    top: 60px;
    right: 15px;
    left: 15px;
  }
`;

const NotificationsList = styled.div`
  max-height: calc(100vh - 150px);
  overflow: auto;
  line-height: normal;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    max-height: calc(100vh - 100px);
  }
`;

export const NotificationContainer = styled.div`
  padding: 10px 16px;
  border-bottom: 1px solid #ededed;
`;

export const NotificationsContext: React.Context<{
  closeNotifications?: () => void;
}> = createContext({});

const Notifications = ({
  notifications = [],
  onOpen,
}: {
  notifications?: React.ReactElement[];
  onOpen?: () => void;
}) => {
  const { t } = useTranslation();
  const ref: RefObject<any> = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  const context = {
    closeNotifications: () => setIsOpen(false),
  };
  return (
    <NotificationsContext.Provider value={context}>
      <NotificationsContainer ref={ref} data-test-notifications-menu>
        <NotificationsButton
          onClick={() => {
            if (!isOpen && onOpen) onOpen();
            setIsOpen(!isOpen);
          }}>
          {notifications.length > 0 ? (
            <ActiveNotificationBell data-test-active-notifications-bell />
          ) : (
            <NotificationsBell data-test-notifications-bell />
          )}
        </NotificationsButton>
        {isOpen && (
          <NotificationsListContainer data-test-notifications-list-container>
            {notifications.length > 0 ? (
              <NotificationsList>
                {notifications.map((component, index) =>
                  React.cloneElement(component, { key: index })
                )}
              </NotificationsList>
            ) : (
              <Text padding='medium' data-test-no-notifications>
                {t('header.notifications.emptyState')}
              </Text>
            )}
          </NotificationsListContainer>
        )}
      </NotificationsContainer>
    </NotificationsContext.Provider>
  );
};

export default Notifications;
