import React, { useState, useEffect } from 'react';
import { StyledTerms } from '.';
import { getRegion } from 'config/regions';

const useTerms = (country?: string, language?: string) => {
  const version = getRegion(country);
  const [importedDoc, setImportedDoc] = useState({
    html: '',
    title: '',
    lastUpdated: '',
    region: '',
  });

  const isEMEA = version === 'EMEA';

  //EMEA
  const termsEMEATranslations = {
    ES: 'termsEMEA_ES',
    FR: 'termsEMEA_FR',
    IT: 'termsEMEA_IT',
    NL: 'termsEMEA_NL',
    PT: 'termsEMEA_PT',
    DE: 'termsEMEA_DE',
    BG: 'termsEMEA_BG',
    CZ: 'termsEMEA_CZ',
    GR: 'termsEMEA_GR',
    HR: 'termsEMEA_HR',
    HU: 'termsEMEA_HU',
    PL: 'termsEMEA_PL',
    SK: 'termsEMEA_SK',
    SI: 'termsEMEA_SI',
    RO: 'termsEMEA_RO',
    EN: 'termsEMEA',
  };

  //North America
  const termsNATranslations = {
    EN: 'termsNA',
    'FR-C': 'termsNA_FC',
    'ES-A': 'termsNA_ES',
    'PT-B': 'termsNA_PT',
  };

  const termsVersions = {
    NA: 'termsNA',
    EMEA: 'termsEMEA',
  };

  let terms = termsVersions[version];

  if (language) {
    if (isEMEA && termsEMEATranslations[language.toUpperCase()]) {
      terms = termsEMEATranslations[language.toUpperCase()];
    }

    if (!isEMEA && termsNATranslations[language.toUpperCase()]) {
      terms = termsNATranslations[language.toUpperCase()];
    }
  }

  const decodeHtml = (html: string) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  useEffect(() => {
    const importComponent = async () => {
      const module = await import(`./doc/${terms}.ts`);
      setImportedDoc(module.default);
    };

    importComponent();
  }, [terms]);

  const TermsContent = (
    <StyledTerms
      dangerouslySetInnerHTML={{
        __html: decodeHtml(importedDoc.html),
      }}
    />
  );

  return {
    title: importedDoc.title,
    lastUpdated: importedDoc.lastUpdated,
    region: importedDoc.region,
    content: TermsContent,
    currentVersion: version, // to save which terms were accepted
    isEMEA,
  };
};

export default useTerms;
