import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AlertsTab } from '../types';

const StyledCustomerCell = styled.div`
  font-size: 12px;
  color: #303030;

  a {
    display: block;
    text-decoration: none;
    font-size: 16px;
    color: #1c6fb9;
  }
`;

const CustomerCell = ({ customer, tab }: { customer: any; tab: AlertsTab }) => {
  const { id, firstName, lastName, address } = customer || {};
  const { addressLine1, city, stateProvinceRegionCode, zipPostalCode } =
    address || {};

  const [prevPage, setPrevPage] = useState<string | undefined>();

  useEffect(() => {
    if (tab !== undefined) {
      switch (tab) {
        case AlertsTab.Flagged:
          setPrevPage('flagged');
          break;
        case AlertsTab.Dismissed:
          setPrevPage('dismissed');
          break;
        default:
          setPrevPage('alerts');
      }
    }
  }, [setPrevPage, tab]);

  return (
    <StyledCustomerCell>
      <Link
        to={`/mybusiness/customers/account-lookup/${id}`}
        state={{
          prevPage,
        }}>{`${firstName} ${lastName}`}</Link>
      {address && addressLine1} <br />
      {address && `${city}, ${stateProvinceRegionCode} ${zipPostalCode}`}
    </StyledCustomerCell>
  );
};

export default CustomerCell;
