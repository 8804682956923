import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

const MenuLink = styled(Link)`
  cursor: pointer;
  display: block;
  color: #4cb5f5;
  text-decoration: initial;
  padding: 0.5rem 2rem;
  letter-spacing: 1px;
  outline: none;

  &:focus,
  &:hover {
    color: #fff;
    background: transparent;
  }
`;

const ProfileMenuOptions: FC<{ isOwner: boolean; isAdmin: boolean }> = ({
  isOwner,
  isAdmin,
}) => {
  const { t } = useTranslation();
  const viewerHasPermissions = isOwner || isAdmin;
  return (
    <>
      <MenuLink to='/profile' data-test-profile-link>
        {t('header.profile')}
      </MenuLink>
      {viewerHasPermissions && (
        <MenuLink to='/mybusiness/company/contact' data-test-company-link>
          {t('header.company')}
        </MenuLink>
      )}
      <MenuLink to='/sign-out' data-test-signout-link>
        {t('header.signout')}
      </MenuLink>
    </>
  );
};

export default ProfileMenuOptions;
