import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import OkayBattery from 'assets/CustomerAccountDevices/Battery/Okay.svg';
import LowBattery from 'assets/CustomerAccountDevices/Battery/Low.svg';
import CriticalBattery from 'assets/CustomerAccountDevices/Battery/Critical.svg';

const StyledTooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
    box-shadow: 0px 2px 10px #0000004d;
    z-index: 1000000;
  }
`;

export type BatteryStatus = 'ok' | 'low' | 'critical';

interface BatteryProps {
  status?: BatteryStatus | string;
  className?: string;
}

const Battery: React.FC<BatteryProps> = ({ status, className }) => {
  const { t } = useTranslation();

  if (!status) return null;

  let batterySrc: string | undefined;
  let tooltipText: string | undefined;

  switch (status.toLowerCase()) {
    case 'ok':
      batterySrc = OkayBattery;
      tooltipText = t('customers.batteryOkay');
      break;
    case 'low':
      batterySrc = LowBattery;
      tooltipText = t('customers.batteryLow');
      break;
    case 'critical':
      batterySrc = CriticalBattery;
      tooltipText = t('customers.batteryCritical');
      break;
    default:
      return null;
  }

  return (
    <>
      <StyledTooltip
        id={`battery-${status}`}
        place='bottom'
        type='light'
        effect='solid'>
        {tooltipText}
      </StyledTooltip>
      <img
        src={batterySrc}
        alt={status || t('customers.batteryCritical')}
        data-for={`battery-${status}`}
        data-tip
        className={className}
      />
    </>
  );
};

export default Battery;
