import { Box, Flex } from '@resideo/blueprint-react';
import { AnonymousHeader } from 'components/header/AnonymousHeader';
import React, { FC, ReactNode } from 'react';
import Footer from '../footer/Footer';

const AppLayoutAnonymous: FC<{
  showHeader?: boolean;
  showFooter?: boolean;
  children?: ReactNode;
}> = ({ showHeader = true, showFooter = true, children }): JSX.Element => {
  return (
    <Flex flexDirection='column' minHeight='100vh'>
      {showHeader && <AnonymousHeader />}
      <Flex flex='1'>
        <Box flex='1'>{children}</Box>
      </Flex>
      {showFooter && <Footer />}
    </Flex>
  );
};

export default AppLayoutAnonymous;
