import React, { FC } from 'react';
import CustomerInfoForm from './CustomerInfoForm';
import { IAddCustomerProps, Steps } from '../types';

const CreateCustomerForm: FC<IAddCustomerProps> = ({
  closeModal,
  addValues,
  setStep,
  data,
}) => {
  const onSubmit = async values => {
    if (!values?.email) return;

    addValues({ ...values });
    setStep(Steps.Location);
  };

  return (
    <CustomerInfoForm
      {...{
        onSubmit,
        closeModal,
        addValues,
        data,
      }}
    />
  );
};

export default CreateCustomerForm;
