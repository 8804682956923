import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from 'routes';
import PageLoading from 'components/common/PageLoading';
import { GlobalStyle } from '@resideo/blueprint-react';
import ThemePreferencesProvider from 'components/global/ThemePreferencesProvider';
import ReactQueryProvider from 'utils/ReactQueryProvider';

import 'styles/fonts.css';
import 'styles/app.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const App = () => {
  return (
    <ThemePreferencesProvider>
      <GlobalStyle />
      <ReactQueryProvider>
        <Suspense fallback={<PageLoading />}>
          <RouterProvider router={router} />
        </Suspense>
        <ReactQueryDevtools initialIsOpen={false} />
      </ReactQueryProvider>
    </ThemePreferencesProvider>
  );
};

export default App;
