import { Box } from '@resideo/blueprint-react';
import useStateData from 'hooks/useStateData';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Autocomplete } from '@react-google-maps/api';

type LocationAddress = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
};

type AddressPart = {
  long_name: string;
  short_name: string;
  types: string[];
};

const addressLookupMap = {
  streetNumber: ['street_number', 'long_name'],
  street: ['route', 'long_name'],
  address2: ['subpremise', 'long_name'],
  city: ['locality', 'long_name'],
  state: ['administrative_area_level_1', 'short_name'],
  country: ['country', 'short_name'],
  zip: ['postal_code', 'short_name'],
};

const AutocompleteContainer = styled(Box)`
  display: grid;
`;

const AutocompleteStyles = styled.input`
  height: 44px;
  padding: 0 12px;
  width: 100%;
`;

const AutocompleteLabelStyles = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 8px;
`;

const AutocompleteError = styled(Box)`
  color: #d22730;
  font-size: 0.75rem;
  padding-top: 0.25rem;
`;

const AutocompleteStyle = styled(Autocomplete)`
  display: flex;
`;

const AutocompleteComponent = ({
  initialValue,
  setCountry,
  setStateValue,
  fieldName,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  setAutoCompleteSelected,
}) => {
  const { t } = useTranslation();
  const { stateData } = useStateData();
  const inputRef = useRef<any>();

  const [
    autocomplete,
    setAutocomplete,
  ] = useState<google.maps.places.Autocomplete | null>(null);
  const [inputValue, setInputValue] = useState(initialValue || '');

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const findAddressPart = (address: AddressPart[], lookup: string) => {
    const addressLookup = addressLookupMap[lookup];

    const addressPart = address?.find(
      part => part?.types?.findIndex(type => type === addressLookup[0]) > -1
    );

    return addressPart ? (addressPart[addressLookup[1]] as string) : null;
  };

  const formatAddress = (address: AddressPart[] | null) => {
    if (!address) return null;

    const city = findAddressPart(address, 'city') || '';
    const country = findAddressPart(address, 'country') || '';
    const streetNumber = findAddressPart(address, 'streetNumber') || '';
    const street = findAddressPart(address, 'street') || '';
    const address2 = findAddressPart(address, 'address2') || '';
    const zip = findAddressPart(address, 'zip') || '';

    let state = findAddressPart(address, 'state') || '';

    const foundState = stateData?.find(
      s =>
        s.name.replace(/[\s-]+/g, '') === state.replace(/[\s-]+/g, '') ||
        s.iso2 === state.replace(/[\s-]+/g, '')
    );

    state = foundState?.iso2 || null;

    const address1 = (streetNumber + ' ' + street).trim();

    return {
      address1,
      address2,
      city,
      state,
      country,
      zip,
    } as LocationAddress;
  };

  const onPlaceChanged = () => {
    setAutoCompleteSelected(true);
    if (autocomplete) {
      const place = autocomplete.getPlace();

      const addressComponents = place?.address_components?.length
        ? (place?.address_components as AddressPart[])
        : null;
      const formattedAddress = formatAddress(addressComponents);

      if (formattedAddress) {
        const fieldValues = Object.keys(formattedAddress);

        fieldValues.forEach(fieldValue => {
          setFieldValue(fieldValue, formattedAddress[fieldValue]);
        });

        setInputValue(formattedAddress?.address1);
        setCountry(formattedAddress?.country);
        setStateValue(formattedAddress?.state);
      }
    }
  };

  return (
    <AutocompleteContainer data-test-address-address1 marginBottom={['medium']}>
      <AutocompleteLabelStyles htmlFor={fieldName}>
        {t(`customerLocationForm.${fieldName}`)} *
      </AutocompleteLabelStyles>
      <AutocompleteStyle onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <AutocompleteStyles
          data-test-address-line-1
          style={
            touched[fieldName] && errors[fieldName]
              ? { borderColor: '#d22730', borderWidth: '1px' }
              : undefined
          }
          ref={inputRef}
          id={fieldName}
          name={fieldName}
          value={inputValue}
          onChange={e => {
            setInputValue(e.target.value);
            setFieldValue(fieldName, e.target.value);
          }}
          onBlur={() => {
            setFieldTouched(fieldName, true, true);
          }}
          type='text'
          placeholder=''
          required
        />
      </AutocompleteStyle>
      {touched.address1 && errors.address1 && (
        <AutocompleteError role='alert'>
          {t(`customerLocationForm.${fieldName}`) +
            t('customerLocationForm.isRequiredError')}
        </AutocompleteError>
      )}
    </AutocompleteContainer>
  );
};

export default AutocompleteComponent;
