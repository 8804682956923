import React, { useState } from 'react';
import {
  Button,
  Flex,
  Text,
  useToastContext,
  SelectField,
} from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from 'context/CurrentUser';
import { useRisClient } from 'hooks/useRisClient';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PartnerRole } from '@resideo/web-integration-services-api-client';

const RequestedAccessModalContent = ({
  closeModal,
  id,
  firstName,
  lastName,
  contactEmail,
  templateLanguage,
}) => {
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const [submitting, setSubmitting] = useState(false);
  const [role, setRole] = useState('');
  const { isOwner, partnerAccountId } = useCurrentUser();
  const { client } = useRisClient();
  const queryClient = useQueryClient();

  const { refetch: getEmployee } = useQuery({
    queryKey: ['getEmployees'],
    queryFn: async () => {
      const res = await client.myProAccounts.myProAccountsSearchPartnerUser(
        partnerAccountId as string,
        JSON.stringify([{ 'user.contactEmail': contactEmail }])
      );
      return res?.data;
    },
    enabled: false,
    staleTime: 5000,
  });

  const acceptPartnerUserInvite = useMutation({
    mutationKey: ['acceptPartnerUserInvite'],
    mutationFn: async (variables: { inviteId: string; baseUrl: string }) => {
      await client.myProAccounts.myProAccountsAcceptAddRequest(
        partnerAccountId as string,
        variables?.inviteId || '',
        {
          baseUrl: variables?.baseUrl,
          roles: [role as PartnerRole],
          templateLanguage,
        }
      );
    },
    onSuccess: async () => {
      const { data: existingEmployeeData } = await getEmployee();
      const [existingEmployee] = existingEmployeeData || [];

      addToast({
        toastType: 'Success',
        message: `${t(
          'mybusiness.requested.approveAccessModalAccessGrantedTo'
        )}`,
        linkHref: `/mybusiness/company/employees/${existingEmployee?.id ||
          ''}/view`,
        linkText: `${lastName}, ${firstName}`,
        hideToast: () => ({}),
      });
      queryClient.invalidateQueries({ queryKey: ['getRequested'] });
      setSubmitting(false);
      closeModal();
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
      setSubmitting(false);
      closeModal();
    },
  });

  const acceptInvite = () => {
    setSubmitting(true);

    acceptPartnerUserInvite.mutate({
      inviteId: id,
      baseUrl: window.location.origin,
    });
  };

  return (
    <Flex data-test-requested-access-modal-container flexDirection='column'>
      <Text marginBottom='small' fontWeight='bold'>
        {t('mybusiness.employees.employeeName')}
      </Text>
      <Text marginBottom='large'>{`${firstName} ${lastName}`}</Text>

      <SelectField
        label={t('mybusiness.employees.role')}
        name='role'
        value={role}
        onChange={e => {
          setRole(e.target.value);
        }}
        version='v2'
        marginBottom='large'>
        <option value='' disabled>
          {t('common.forms.select')}
        </option>
        {isOwner && <option value='OWNER'>{t('common.roles.OWNER')}</option>}
        <option value='COMPANY_ADMIN'>{t('common.roles.COMPANY_ADMIN')}</option>
        <option value='TECHNICIAN'>{t('common.roles.TECHNICIAN')}</option>
      </SelectField>

      <Flex justifyContent='flex-end'>
        <Button
          data-test-requested-access-modal-cancel-button
          onClick={() => closeModal()}
          marginRight='large'
          variant='secondary'>
          {t('common.cancel')}
        </Button>
        <Button
          data-test-requested-access-modal-confirm-button
          disabled={submitting}
          onClick={() => acceptInvite()}
          variant='primary'>
          {t('common.confirm')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default RequestedAccessModalContent;
