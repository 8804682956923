import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Button,
  useDeviceModal,
  useToastContext,
} from '@resideo/blueprint-react';
import { MdAddCircleOutline } from 'react-icons/md';
import { useCurrentUser } from 'context/CurrentUser';
import { CustomerAccountCustomerDataType } from 'components/customers/CustomerAccount/CustomerAccountDevices';
import { useMutation as Mutation } from '@tanstack/react-query';
import { ADD_DEVICE_BASE_URL, ENABLE_VX_DEVICES } from 'config/flags';
import { useQueryClient } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';
import styled from 'styled-components';
import { useLanguageCode } from 'hooks/useLanguageCode';

const AddIcon = styled(MdAddCircleOutline)`
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
  color: #1c6fb9;
  padding-top: 12px;
`;

const AddDeviceButtonContainer = styled.div`
  box-sizing: border-box;
  width: max-content;
  margin-left: auto;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
    position: relative;
    padding-left: 4%;
    padding-top: 2%;
  }
`;

const AddMobileDeviceContainer = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: flex;
    button {
      align-self: center;
    }
  }
`;

interface DeviceLookupProps {
  deviceLocationId?: string;
  customerData: CustomerAccountCustomerDataType;
}

enum AddDeviceSteps {
  'AddDeviceSpinner',
  'AddDeviceType',
  'AddDevice',
  'UnableToAddDeviceServiceError',
  'DeviceAlreadyAddedError',
  'BrandingDeviceServiceError',
  'UFCDeviceMatchError',
  'OtherError',
  'Success',
}

const DeviceLookup: React.FC<DeviceLookupProps> = ({
  deviceLocationId,
  customerData,
}) => {
  const { t } = useTranslation();
  const [languageCode] = useLanguageCode();
  const { partnerAccountId } = useCurrentUser();
  const {
    openDeviceModal,
    AddDeviceModal,
    closeDeviceModal,
  } = useDeviceModal();
  const [deviceId, setDeviceId] = useState<string>('');
  const { addToast } = useToastContext();
  const [step, setStep] = useState<AddDeviceSteps>(AddDeviceSteps.AddDevice);
  const [deviceServiceName, setDeviceServiceName] = useState<string>(
    `${t('customers.deviceServiceErrors.brandingPartnerDeviceService')}`
  );
  const [searchError, setSearchError] = useState('');
  const [deviceInfo, setDeviceInfo] = useState({
    deviceTypeDisplayName: '',
    deviceSku: '',
    deviceName: '',
    deviceVariant: '',
    deviceType: '',
  });
  const { client } = useRisClient();
  const queryClient = useQueryClient();

  const deviceNewInput = Mutation({
    mutationFn: async (vars: { macId }) => {
      return client.myProAccounts.myProAccountsPerformDeviceHandoff({
        deviceId: vars.macId,
        deviceName: deviceInfo.deviceName,
        deviceType: deviceInfo.deviceType,
        deviceVariant: deviceInfo.deviceVariant,
        firstName: customerData?.firstName,
        lastName: customerData?.lastName,
        sendToEmail: customerData?.email,
        sku: deviceInfo.deviceSku,
        templateLanguage: languageCode,
        baseUrl: ADD_DEVICE_BASE_URL,
        partnerAccountId: partnerAccountId,
        partnerDeviceLocationId: deviceLocationId,
      });
    },
    onError: err => {
      setSearchError('');
      const parsedError = JSON.parse(JSON.stringify(err));
      const errorBody = parsedError?.body?.errors?.[0];
      if (
        parsedError.status === 409 &&
        errorBody?.code === 'ENTITY_CONFLICT' &&
        (errorBody?.reasonCode === 'ALREADY_REGISTERED' ||
          errorBody?.reasonCode === 'EXISTING_INSTALLATION_SERVICE')
      ) {
        setStep(AddDeviceSteps.DeviceAlreadyAddedError);
      } else if (
        parsedError.status === 404 &&
        errorBody?.code === 'ENTITY_NOT_FOUND' &&
        errorBody?.message === 'No device exists for the specified ID'
      ) {
        setSearchError(t('Invalid MAC ID'));
        setStep(AddDeviceSteps.AddDevice);
      } else if (
        parsedError.status === 409 &&
        errorBody?.code === 'emailDeviceIdMatch' &&
        errorBody?.reasonCode === 'ALREADY_REGISTERED'
      ) {
        setStep(AddDeviceSteps.UFCDeviceMatchError);
      } else {
        setStep(AddDeviceSteps.OtherError);
      }
    },
    onSuccess: response => {
      const brandingDeviceServiceSkip = response?.data?.deviceServices?.filter(
        service =>
          service?.type === 'BrandingPartnerDeviceService' &&
          service?.skipReason === 'APPLIED_BY_ANOTHER_PARTNER'
      );
      if (brandingDeviceServiceSkip?.length) {
        setDeviceServiceName(
          `${t('customers.deviceServiceErrors.brandingPartnerDeviceService')}`
        );
        setStep(AddDeviceSteps.UnableToAddDeviceServiceError);
      } else {
        setSearchError('');
        addToast({
          toastType: 'Success',
          message: `${t('common.new')} ${deviceInfo.deviceTypeDisplayName} ${t(
            'common.added'
          )}`,
        });
        closeDeviceModal();
      }
      queryClient.invalidateQueries({
        queryKey: ['partnerCustomerLocations'],
      });
    },
  });

  const addDeviceRest = async macId => {
    setDeviceId(macId);
    setStep(AddDeviceSteps.AddDeviceSpinner);
    deviceNewInput.mutate({ macId: macId });
  };

  const openAddDeviceModal = () => {
    openDeviceModal();
    setStep(AddDeviceSteps.AddDeviceType);
  };

  return (
    <>
      <div>
        <Flex className='DevicesHeader'>
          <AddDeviceButtonContainer>
            <Button
              data-test-add-device
              className='AddLocation'
              variant='secondary'
              onClick={openAddDeviceModal}>
              {t('customers.addADevice')}
            </Button>
          </AddDeviceButtonContainer>
          <AddMobileDeviceContainer onClick={openAddDeviceModal}>
            <AddIcon />
            <Button size='large' variant='tertiary' tertiaryLink>
              {t('common.add')}
            </Button>
          </AddMobileDeviceContainer>
        </Flex>
      </div>
      <AddDeviceModal
        updateDeviceStep={(deviceStep, deviceDetails: any = {}, clearError) => {
          setStep(deviceStep);
          // When clicking on the back button, we are losing deviceDetails in blueprint so not replacing it when value is empty.
          if (deviceDetails.deviceSku && deviceDetails.deviceTypeDisplayName) {
            setDeviceInfo(deviceDetails);
          }
          if (clearError) {
            setSearchError('');
            setDeviceId('');
          }
        }}
        addDeviceResponse={step}
        inValidDeviceError={searchError}
        translationFunction={t}
        deviceId={deviceId}
        addDevice={addDeviceRest}
        closeDeviceModal={closeDeviceModal}
        enableVXDevices={ENABLE_VX_DEVICES}
        deviceServiceName={deviceServiceName}
      />
    </>
  );
};

export default DeviceLookup;
