import { Flex, SearchField, Breadcrumbs, Box } from '@resideo/blueprint-react';
import Page from 'components/common/Page';
import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Titled } from 'components/common/Titled';
import styled from 'styled-components';
import LocationsWithUnregisteredDevices from './LocationsWithUnregisteredDevices';
import TableLoading from 'components/common/TableLoading';
import { Link } from 'react-router-dom';

const Container = styled(Flex)`
  margin-top: ${({ theme }) => theme.space.large};
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0;
    overflow: auto;
  }
`;

const SubHeadingContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Border = styled(Box)`
  box-shadow: 0 4px 2px -1px #f4f4f4;
  overflow: auto;
  margin-left: -2rem;
  margin-bottom: 32px;
  padding-bottom: 12px;
  margin-right: -2rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-right: -0.5rem;
  }
`;

const SearchAndFilterContainer = styled(Flex)`
  align-items: center;
  gap: 10px;

  & > div {
    width: 300px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
    margin-top: 10px;

    & > div {
      width: 100%;
    }
  }
`;

const LocationsWithUnregisteredDevicesList = (): JSX.Element => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');

  return (
    <Titled title={t('customers.title')}>
      <Container>
        <Page
          as='main'
          paddingTop='0'
          paddingY='medium'
          paddingX='0'
          marginLeft={['small', 'large']}
          marginRight={['small', 'large']}
          maxWidth='100%'
          width={1}>
          <Breadcrumbs>
            <Link to='/mybusiness/business-intelligence'>
              {t('leftNav.businessIntelligence')}
            </Link>
          </Breadcrumbs>
          <SubHeadingContainer>
            <h1>{t('cobrandingDashboard.locationsUnregisteredDevices')}</h1>
            <SearchAndFilterContainer>
              <SearchField
                name='search'
                type='text'
                value={search}
                placeholder={t('common.search')}
                onChange={e => {
                  e.preventDefault();
                  setSearch(e.target.value);
                }}
                onSearchClick={search => {
                  setSearch(search);
                }}
                onClearClick={() => {
                  setSearch('');
                }}
              />
            </SearchAndFilterContainer>
          </SubHeadingContainer>
          <Border />
          <Suspense fallback={<TableLoading />}>
            <LocationsWithUnregisteredDevices filter={search} />
          </Suspense>
        </Page>
      </Container>
    </Titled>
  );
};

export default LocationsWithUnregisteredDevicesList;
