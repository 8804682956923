import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import ExcellentSignal from 'assets/CustomerAccountDevices/Signal/Excellent.svg';
import GoodSignal from 'assets/CustomerAccountDevices/Signal/Good.svg';
import LowSignal from 'assets/CustomerAccountDevices/Signal/Low.svg';
import NoSignal from 'assets/CustomerAccountDevices/Signal/NoSignal.svg';
import VeryGoodSignal from 'assets/CustomerAccountDevices/Signal/VeryGood.svg';
import VeryLowSignal from 'assets/CustomerAccountDevices/Signal/VeryLow.svg';

const StyledTooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
    box-shadow: 0px 2px 10px #0000004d;
    z-index: 1000000;
  }
`;

export type SignalStatus =
  | 'excellent'
  | 'good'
  | 'fair'
  | 'weak'
  | 'veryweak'
  | 'none';

interface SignalProps {
  status?: SignalStatus | string;
  className?: string;
}

const Signal: React.FC<SignalProps> = ({ status, className }) => {
  const { t } = useTranslation();

  if (!status) return null;

  let signalSrc: string | undefined;
  let tooltipText: string | undefined;

  switch (status.toLowerCase()) {
    case 'excellent':
      signalSrc = ExcellentSignal;
      tooltipText = t('customers.signalExcellent');
      break;
    case 'good':
      signalSrc = VeryGoodSignal;
      tooltipText = t('customers.signalVeryGood');
      break;
    case 'fair':
      signalSrc = GoodSignal;
      tooltipText = t('customers.signalGood');
      break;
    case 'weak':
      signalSrc = LowSignal;
      tooltipText = t('customers.signalLow');
      break;
    case 'veryweak':
      signalSrc = VeryLowSignal;
      tooltipText = t('customers.signalVeryLow');
      break;
    case 'none':
      signalSrc = NoSignal;
      tooltipText = t('customers.signalNone');
      break;
    default:
      return null;
  }

  return (
    <>
      <StyledTooltip
        id={`signal-${status}`}
        place='bottom'
        type='light'
        effect='solid'>
        {tooltipText}
      </StyledTooltip>
      <img
        src={signalSrc}
        alt={status || t('customers.noSignal')}
        data-for={`signal-${status}`}
        data-tip
        className={className}
      />
    </>
  );
};

export default Signal;
