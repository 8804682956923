import WaterProducts from './WaterProducts';
import ThermalSolutions from './ThermalSolutions';
import ThermostatProducts from './ThermostatProducts';
import ZoningProducts from './ZoningProducts';
import HumidificationProducts from './HumidificationProducts';
import DehumidificationProducts from './Dehumidification';
import FiltrationProducts from './FiltrationProducts';
import DuctlessProducts from './DuctlessProducts';
import ForceAirZoning from './ForceAirZoning';
import { productsDownloadList } from './downloadAll';

const hvacAndWaterTitles = t => [
  {
    title: t('hvacAndWater.titles.dehumidification'),
    type: 'dehumidification',
  },
  {
    title: t('hvacAndWater.titles.ductless'),
    type: 'ductless',
  },
  {
    title: t('hvacAndWater.titles.filtration'),
    type: 'filtration',
  },
  {
    title: t('hvacAndWater.titles.humidification'),
    type: 'humidification',
  },
  {
    title: t('hvacAndWater.titles.thermal'),
    type: 'thermal',
  },
  {
    title: t('hvacAndWater.titles.thermostats'),
    type: 'thermostats',
  },
  {
    title: t('hvacAndWater.titles.water'),
    type: 'water',
  },
  {
    title: t('hvacAndWater.titles.zoning'),
    type: 'zoning',
  },
  {
    title: t('hvacAndWater.titles.force'),
    type: 'force',
  },
];

const mobileTitle = t => [
  {
    title: t('hvacAndWater.all'),
    type: 'all',
  },
  ...hvacAndWaterTitles(t),
];

export {
  productsDownloadList,
  WaterProducts,
  ThermalSolutions,
  ThermostatProducts,
  ZoningProducts,
  HumidificationProducts,
  DehumidificationProducts,
  FiltrationProducts,
  DuctlessProducts,
  hvacAndWaterTitles,
  mobileTitle,
  ForceAirZoning,
};
