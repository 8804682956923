import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  Box,
  Heading,
  TableV2,
  useToastContext,
} from '@resideo/blueprint-react';
import { useFeatureFlags } from 'context/FeatureFlags';
import { useCurrentUser } from 'context/CurrentUser';
import { formatDateTime } from 'utils/common';
import { CustomerLocationServicesProps } from './types';
import { DeactivateServiceModal } from '../Location/LocationModals/ServiceRequestModal';
import { useModalContext } from 'context/ModalProvider';
import { useRisClient } from 'hooks/useRisClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ActiveStatus,
  PartnerDeviceServiceType,
} from '@resideo/web-integration-services-api-client';

const ServicesContainer = styled(Box)`
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ServiceHeadingContainer = styled.div`
  padding: 1.5rem 0 1rem 2rem;
  h3 {
    font-size: 1.125rem;
  }
`;

const CustomerLocationServices: FC<CustomerLocationServicesProps> = ({
  deviceServices,
  location,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { PRO_IQ_ADVANCED } = useFeatureFlags();
  const { hasActivePartnerBrandProfile, partnerAccountId } = useCurrentUser();
  const { addToast } = useToastContext();
  const { openModal, closeModal } = useModalContext();
  const { client } = useRisClient();
  const queryClient = useQueryClient();

  const activateDeviceService = useMutation({
    mutationKey: ['activateDeviceService'],
    mutationFn: async (variables: {
      partnerDeviceLocationId: string;
      deviceId: string;
    }) => {
      await client.myProAccounts.myProAccountsUpdatePartnerDeviceService(
        partnerAccountId as string,
        variables?.partnerDeviceLocationId as string,
        {
          type: PartnerDeviceServiceType.BRANDING_PARTNER_DEVICE_SERVICE,
          status: ActiveStatus.ACTIVE,
          deviceId: variables?.deviceId,
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['partnerCustomerLocations'] });
      closeModal();
      addToast({
        toastType: 'success',
        message: t('customerDetails.reactivateService.successResponse'),
      });
    },
    onError: () => {
      closeModal();
      addToast({ toastType: 'error', message: t('common.toasts.error') });
    },
  });

  const deactivateDeviceService = useMutation({
    mutationKey: ['deactivateDeviceService'],
    mutationFn: async (variables: {
      partnerDeviceLocationId: string;
      deviceId: string;
    }) => {
      await client.myProAccounts.myProAccountsUpdatePartnerDeviceService(
        partnerAccountId as string,
        variables?.partnerDeviceLocationId as string,
        {
          type: PartnerDeviceServiceType.BRANDING_PARTNER_DEVICE_SERVICE,
          status: ActiveStatus.INACTIVE,
          deviceId: variables?.deviceId,
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['partnerCustomerLocations'] });
      closeModal();
      addToast({
        toastType: 'success',
        message: t('customerDetails.deactivateService.successResponse'),
      });
    },
    onError: () => {
      closeModal();
      addToast({ toastType: 'error', message: t('common.toasts.error') });
    },
  });

  const DeviceServiceStatusNameMap = {
    ACTIVE: t('customerDetails.deviceService.status.active'),
    INACTIVE: t('customerDetails.deviceService.status.inactive'),
    undefined: t('customerDetails.deviceService.status.active'), // TODO: remove with status
  };

  const DeviceServiceNameMap = {
    BrandingPartnerDeviceService: t(
      'customerDetails.deviceService.name.homeownerAppBranding'
    ),
  };

  const aggregateAppBrandingDeviceServices = () => {
    if (!hasActivePartnerBrandProfile) return null;

    const brandingDeviceServices = deviceServices?.filter(
      service => service?.type === 'BrandingPartnerDeviceService'
    );

    if (!brandingDeviceServices?.length) return null;

    const activeBrandingDeviceServices = brandingDeviceServices
      .filter(service => service?.status === 'ACTIVE')
      .sort((a, b) => {
        return (
          new Date(a?.installedAt).getTime() -
          new Date(b?.installedAt).getTime()
        );
      });

    const inactiveBrandingDeviceServices = brandingDeviceServices
      .filter(service => service?.status === 'INACTIVE')
      .sort((a, b) => {
        return (
          new Date(a?.installedAt).getTime() -
          new Date(b?.installedAt).getTime()
        );
      });

    if (activeBrandingDeviceServices?.length) {
      return activeBrandingDeviceServices[0];
    } else if (inactiveBrandingDeviceServices?.length) {
      return inactiveBrandingDeviceServices[0];
    } else {
      return null;
    }
  };

  const handleReactivateDeviceService = async (deviceId: string) => {
    await activateDeviceService.mutate({
      partnerDeviceLocationId: location?.locationId,
      deviceId,
    });
  };

  const handleDeactivateDeviceService = async (deviceId: string) => {
    await deactivateDeviceService.mutate({
      partnerDeviceLocationId: location?.locationId,
      deviceId,
    });
  };

  const getDeviceServiceRows = () => {
    const appBrandingDeviceService = aggregateAppBrandingDeviceServices();

    if (!appBrandingDeviceService) return [];

    const { type, status, installedAt, device } = appBrandingDeviceService;

    const appBrandingDeviceServiceDisplay = {
      serviceType: DeviceServiceNameMap[type],
      serviceStatus: `${DeviceServiceStatusNameMap[status]} (${
        status === 'ACTIVE'
          ? t('customerDetails.servicesTable.activationDate')
          : t('customerDetails.servicesTable.deactivationDate')
      }: ${formatDateTime(installedAt, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })})`,
    };

    if (status === 'ACTIVE' && PRO_IQ_ADVANCED) {
      appBrandingDeviceServiceDisplay['actions'] = [
        {
          name: t('customerDetails.deactivateService.deactivate'),
          ctaTag: t('customerDetails.deactivateService.deactivate'),
          text: t('customerDetails.deactivateService.deactivate'),
          action: () => {
            openModal({
              title: t('customerDetails.deactivateService.title'),
              children: (
                <DeactivateServiceModal
                  serviceName={DeviceServiceNameMap[type]}
                  location={location}
                  onSubmit={async () => {
                    addToast({
                      toastType: 'Success',
                      message: t(
                        'customerDetails.deactivateService.successResponse'
                      ),
                    });
                    await handleDeactivateDeviceService(device?.id);
                    closeModal();
                  }}
                  onCancel={closeModal}
                />
              ),
            });
          },
        },
      ];
    }

    if (status === 'INACTIVE' && PRO_IQ_ADVANCED) {
      appBrandingDeviceServiceDisplay['actions'] = [
        {
          name: t('common.activate'),
          ctaTag: t('common.activate'),
          text: t('common.activate'),
          action: async () => {
            await handleReactivateDeviceService(device?.id);
          },
        },
      ];
    }

    return [appBrandingDeviceServiceDisplay];
  };

  return (
    <>
      <ServiceHeadingContainer>
        <Heading as='h3'>{t(`customerDetails.services`)}</Heading>
      </ServiceHeadingContainer>
      <ServicesContainer>
        <TableV2
          dataTestAttribute='data-test-services-table'
          headers={[
            {
              displayName: t('customerDetails.servicesTable.typeHeader'),
              isSortable: false,
              onColumnCellClick: row => {
                if (row?.serviceType === 'Homeowner App Branding') {
                  navigate('/mybusiness/business-intelligence');
                }
              },
            },
            {
              displayName: t('customerDetails.servicesTable.statusHeader'),
              isSortable: false,
              tooltipText: t(
                'customerDetails.servicesTable.statusHeaderTooltip'
              ),
            },
            {
              displayName: '',
              isHiddenColumn: !PRO_IQ_ADVANCED,
              isSortable: false,
            },
          ]}
          rows={getDeviceServiceRows()}
        />
      </ServicesContainer>
    </>
  );
};

export default CustomerLocationServices;
