import React, { useState, useEffect } from 'react';
import { Button, Flex } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import TimePeriodFilter from './TimePeriodFilter';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from 'context/CurrentUser';
import { format, isAfter, isBefore, subDays } from 'date-fns';
import { Titled } from 'components/common/Titled';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';

const DashboardContainer = styled(Flex)`
  flex-direction: column;
  padding: 2rem;
`;

const DashboardHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: ${({ theme }) => theme.space.large};
`;

const DashboardSubheading = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.separator};
  margin-bottom: ${({ theme }) => theme.space.medium};
  padding-bottom: ${({ theme }) => theme.space.medium};
  padding-top: ${({ theme }) => theme.space.medium};
`;

const StyledHeading = styled.h1`
  font-size: 40px;
  margin: 0;
`;

const DateBlock = styled.div`
  p {
    margin: 0;
  }
`;

const DashboardContent = styled.div``;

const DashboardFilters = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const DashboardFiltersLeft = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-right: 10px;
`;

const MoreInfoIcon = styled(BsFillQuestionCircleFill)`
  font-size: 20px;
  padding-left: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`;

const ToolTip = styled.div`
  visibility: hidden;
  position: absolute;
  line-height: 1.75;
  bottom: 32px;
  right: calc(100% - 175px);
  font-size: ${({ theme }) => theme.fontSizes.xSmall};
  border: 1px solid #bfc2c8;
  border-radius: 0.3rem;
  padding: 12px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  width: 250px;

  &:after {
    top: 100%;
    left: 41%;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: 5px solid transparent;
    border-top-color: #fff;
  }
`;

const ToolTipWrapper = styled(Flex)`
  .tooltipContent {
    width: 290px;
    &:after {
      left: 49%;
    }
  }
  &:hover .tooltipContent {
    visibility: visible;
  }
`;

const DashboardSubheader = styled(Flex)`
  h1 {
    margin: 0;
    font-size: 21px;
  }
  align-items: center;
`;

const TimePeriod = styled.div`
  p {
    margin: 0;
    font-size: 14px;
    color: #575757;
  }
`;

const Cards = styled.div`
  display: grid;
  grid-gap: 10px;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    grid-template-columns: 1fr 1fr minmax(400px, auto);
  }
`;

const Card = styled.div`
  display: inline-block;
  border: 1px #dadada solid;
  max-width: 500px;
  height: 390px;
  h3 {
    background: #00000008;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    padding: 1rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    :nth-child(2) {
      margin: 0 1rem;
    }
  }
`;

const CustomTooltipWrapper = styled.div`
  line-height: 1.75;
  bottom: 32px;
  font-size: ${({ theme }) => theme.fontSizes.xSmall};
  color: #fff;
  background: #414141;
  width: 50px;
  padding: 12px;
  border: none !important;
  border-radius: 0 !important;
  margin: 0 !important;
`;

const CardContent = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 60px;
    margin: 6rem 0 2rem 0;
  }
  p {
    color: #575757;
    font-size: 14px;
    margin: 0;
  }
  > p:last-child {
    font-size: 21px;
  }
`;

const BusinessIntelligence = () => {
  const { t } = useTranslation();
  const { partnerAccountId } = useCurrentUser();
  const [timeFilterValue, setTimeFilterValue] = useState('Last 7 Days');
  const [activeBarIndex, setActiveBarIndex] = useState(0);
  const [toolTipYPosition, setToolTipYPosition] = useState(0);
  const [displayData, setDisplayData] = useState<any>();

  const { client } = useRisClient();

  const { data } = useSuspenseQuery({
    queryKey: ['getPartnerAccount'],
    queryFn: async () =>
      (
        await client.myProAccounts.myProAccountsGetCoBrandingDashboard(
          partnerAccountId as string
        )
      ).data,
  });

  useEffect(() => {
    const dataToParse =
      data?.historicalBrandingPartnerDeviceServicesCountByDay?.data;

    if (dataToParse) {
      const dataToDisplay = dataToParse
        .sort(
          (a, b) =>
            Date.parse(a?.defaultLabel ?? '') - Date.parse(b.defaultLabel ?? '')
        )
        .slice(timeFilterValue === 'Last 7 Days' ? -7 : -30);

      setDisplayData(dataToDisplay);
    }
  }, [data, timeFilterValue]);

  useEffect(() => {
    const barCharts = document.querySelectorAll('.recharts-bar-rectangle');
    if (!barCharts || !activeBarIndex) return;
    const barChart = barCharts[activeBarIndex];
    setToolTipYPosition(barChart?.getBoundingClientRect()?.height);
  }, [activeBarIndex]);

  const CustomToolTip = ({ payload }: any) => {
    return (
      <div>
        <CustomTooltipWrapper>
          Total: {payload?.[0]?.payload?.total}
        </CustomTooltipWrapper>
      </div>
    );
  };

  const renderCustomXAxisTick = ({ x, y, payload }) => {
    const date = Date.parse(payload.value);
    const today = Date.now();
    const [weekDay, calendarDate] = format(date, 'eee MM/dd').split(' ');
    const isToday = format(date, 'MM/dd/yyyy') === format(today, 'MM/dd/yyyy');

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor='middle' fill='#666'>
          {timeFilterValue === 'Last 7 Days' ? (
            <>
              <tspan x='0' dy='1.2em' fontSize='14px'>
                {isToday ? 'Today' : weekDay}
              </tspan>
              <tspan x='0' dy='1.2em' fontSize='12px'>
                {calendarDate}
              </tspan>
            </>
          ) : (
            <tspan x='0' dy='1.2em' fontSize='12px'>
              {isToday ? 'Today' : calendarDate}
            </tspan>
          )}
        </text>
      </g>
    );
  };

  let currentSevenDays = 0;
  let previousSevenDays = 0;
  let currentThirtyDays = 0;
  let previousThirtyDays = 0;

  const today = new Date();
  const sevenDaysAgo = subDays(new Date(), 6);
  const fourteenDaysAgo = subDays(new Date(), 13);
  const thirtyDaysAgo = subDays(new Date(), 29);
  const sixtyDaysAgo = subDays(new Date(), 59);

  data?.deviceLocationsWithHomeownerAppBranding?.filter(element => {
    let chartDataDate;
    if (element?.createdAt) {
      chartDataDate = Date.parse(element?.createdAt);
    }
    //current 7 days
    if (
      isAfter(chartDataDate, sevenDaysAgo) &&
      isBefore(chartDataDate, today)
    ) {
      currentSevenDays++;
    }

    //previous 7 days
    if (
      isAfter(chartDataDate, fourteenDaysAgo) &&
      isBefore(chartDataDate, sevenDaysAgo)
    ) {
      previousSevenDays++;
    }
    //current 30 days
    if (
      isAfter(chartDataDate, thirtyDaysAgo) &&
      isBefore(chartDataDate, today)
    ) {
      currentThirtyDays++;
    }

    //previous 60 days
    if (
      isAfter(chartDataDate, sixtyDaysAgo) &&
      isBefore(chartDataDate, thirtyDaysAgo)
    ) {
      previousThirtyDays++;
    }
  });

  return (
    <DashboardContainer>
      <Titled title={t('cobrandingDashboard.businessIntelligence')} />
      <DashboardHeader>
        <StyledHeading data-test-brand-profile-header>
          {t('cobrandingDashboard.businessIntelligence')}
        </StyledHeading>
        <Link
          to='/mybusiness/services/brand-profile/view'
          state={{ prevPage: 'business-intelligence' }}>
          <Button>{t('cobrandingDashboard.viewBrandProfile')}</Button>
        </Link>
      </DashboardHeader>
      <DashboardSubheading>
        <DashboardSubheader data-test-dashboard-title>
          <h1>{t('cobrandingDashboard.brandingInsights')}</h1>
        </DashboardSubheader>
        <DateBlock data-test-date-block>
          <p style={{ fontSize: '14px', color: '#575757' }}>
            {t('cobrandingDashboard.date')}
          </p>
          <p style={{ fontWeight: 500 }}>{format(new Date(), 'MMMM d, y')}</p>
        </DateBlock>
      </DashboardSubheading>
      <DashboardContent>
        <DashboardFilters>
          <DashboardFiltersLeft>
            <DashboardSubheader data-test-dashboard-title>
              <h1>{t('cobrandingDashboard.brandedLocationSummary')}</h1>
            </DashboardSubheader>
            <ToolTipWrapper data-test-tooltip>
              <MoreInfoIcon />
              <ToolTip className='tooltipContent'>
                <b>{t('cobrandingDashboard.tooltipContent1')}</b>
                {t('cobrandingDashboard.tooltipContent2')}
              </ToolTip>
            </ToolTipWrapper>
          </DashboardFiltersLeft>
          <TimePeriod data-test-time-period>
            <p>{t('cobrandingDashboard.timePeriod')}</p>
            <TimePeriodFilter
              getTimeFilterValue={value => {
                setTimeFilterValue(value);
              }}
              data-test-time-period-filter></TimePeriodFilter>
          </TimePeriod>
        </DashboardFilters>
        <Cards>
          <Card data-test-total-locations>
            <h3>{t('cobrandingDashboard.totalLocations')}</h3>
            <CardContent data-test-total-locations-number>
              <h1>{data?.brandingPartnerDeviceServiceLocationCount || 0}</h1>
            </CardContent>
          </Card>
          <Card data-test-added-recent-days>
            <h3>
              {timeFilterValue === 'Last 7 Days'
                ? t('cobrandingDashboard.added7')
                : t('cobrandingDashboard.added30')}
            </h3>
            <CardContent data-test-added-recent-days-number>
              <h1>
                {timeFilterValue === 'Last 7 Days'
                  ? currentSevenDays
                  : currentThirtyDays}
              </h1>
              <p>
                {timeFilterValue === 'Last 7 Days'
                  ? t('cobrandingDashboard.previous7')
                  : t('cobrandingDashboard.previous30')}
              </p>
              <p>
                {timeFilterValue === 'Last 7 Days'
                  ? previousSevenDays
                  : previousThirtyDays}
              </p>
            </CardContent>
          </Card>
          <Card data-test-device-type-label>
            <h3>{t('cobrandingDashboard.devicesRegistered')}</h3>
            <ResponsiveContainer
              width={'100%'}
              height={330}
              data-test-device-type-graph>
              {displayData ? (
                <BarChart
                  data={displayData}
                  margin={{
                    bottom: 0,
                    left: 0,
                    right: 40,
                    top: 30,
                  }}
                  onMouseMove={e => {
                    if (e && e.activeTooltipIndex) {
                      setActiveBarIndex(e.activeTooltipIndex);
                    }
                  }}>
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey={'defaultLabel'}
                    height={50}
                    tick={renderCustomXAxisTick}
                    interval={timeFilterValue === 'Last 7 Days' ? 0 : 3}
                  />
                  <YAxis fontSize={14} allowDecimals={false} />
                  <Tooltip
                    content={<CustomToolTip />}
                    cursor={{ fill: 'transparent' }}
                    wrapperStyle={{
                      paddingLeft: '0',
                      paddingRight: '0',
                      border: '1px solid #414141',
                      borderRadius: '0',
                    }}
                    position={{ y: 230 - toolTipYPosition }}
                    offset={-60}
                    allowEscapeViewBox={{ x: true, y: true }}
                  />
                  <Bar
                    dataKey={'total'}
                    stackId='a'
                    fill='#29A9E0'
                    type='monotone'
                    fillOpacity='0.5'
                  />
                </BarChart>
              ) : (
                <></>
              )}
            </ResponsiveContainer>
          </Card>
        </Cards>
      </DashboardContent>
    </DashboardContainer>
  );
};
export default BusinessIntelligence;
