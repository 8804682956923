import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Titled } from 'components/common/Titled';
import { Flex, useToastContext } from '@resideo/blueprint-react';
import NoticeLayout from 'components/common/NoticeLayout';
import NoticeGrayBox from 'components/access/NoticeGrayBox';
import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';

const SuccessText = styled.span`
  color: ${({ theme }) => theme.colors.success};
`;

enum EmailStatus {
  SENT,
  UNSENT,
  LOADING,
}

const EmailVerify: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const [emailState, setEmailState] = useState<EmailStatus>(EmailStatus.UNSENT);
  const { client } = useRisClient();

  const resendEmailMutation = useMutation({
    mutationFn: async () => {
      return client.myProUsers.myProUsersSendVerificationEmail();
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.verification.resendError'),
      });
      setEmailState(EmailStatus.UNSENT);
    },
    onSuccess: () => {
      addToast({
        toastType: 'Success',
        message: t('signUp.success.resendEmailSuccessToast'),
      });
      setEmailState(EmailStatus.SENT);
    },
  });

  const handleSendEmail = () => {
    setEmailState(EmailStatus.LOADING);
    resendEmailMutation.mutate();
  };

  const getVariableContent = (version: EmailStatus) => {
    const versions = {
      [EmailStatus.SENT]: {
        description2: <SuccessText>Email Sent</SuccessText>,
      },
      [EmailStatus.LOADING]: {
        description2: t('common.verification.loading'),
      },
      [EmailStatus.UNSENT]: {
        linkFunction: handleSendEmail,
      },
    };

    return versions[version];
  };

  const grayboxContent = {
    title: t('common.verification.notVerifiedTitle'),
    description: t('common.verification.notVerifiedDescription'),
    linkText: t('common.verification.notVerifiedLink').toUpperCase(),
    ...getVariableContent(emailState),
  };

  return (
    <Titled title={t('accessRequest.gettingStarted.title')}>
      <NoticeLayout data-test-successful-request-screen logoVersion='nologo'>
        <Flex marginBottom='2rem'>
          <NoticeGrayBox data-test-success-notice {...grayboxContent} />
        </Flex>
      </NoticeLayout>
    </Titled>
  );
};

export default EmailVerify;
