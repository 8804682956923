import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Heading, Link } from '@resideo/blueprint-react';
import Page from 'components/common/Page';

export type RecordNotFoundProps = {
  description?: string;
};

const RecordNotFound: FC<RecordNotFoundProps> = ({
  description,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Page as='main' data-test-record-not-found>
      <Card borderRadius={[0, 'medium']} padding='large'>
        <Heading as='h1' fontSize='xLarge' marginBottom='medium'>
          {t('recordNotFound.header')}
        </Heading>
        <p>{description || t('recordNotFound.defaultContent')}</p>
        <Link href='/'>{t('404.link')}</Link>
      </Card>
    </Page>
  );
};

export default RecordNotFound;
