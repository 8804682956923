import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useToastContext,
} from '@resideo/blueprint-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CustomerLocationProps } from './types';
import CustomerAccountDevices, {
  CustomerAccountCustomerDataType,
} from './CustomerAccountDevices';
import { MdEdit } from 'react-icons/md';
import CustomerLocationServices from './CustomerLocationServices';
import { convertSchemaAddress } from 'components/common/Address';
import DeviceLookup from 'components/mybusiness/customers/DeviceLookup';
import { useCurrentUser } from 'context/CurrentUser';
import CustomerLocationForm from '../AddCustomer/CustomerLocationForm';
import {
  CUSTOMER_ALERT_DETAILS,
  ENABLE_ADD_DEVICE_BUTTON,
  ENABLE_HVAC_DEVICES_SERVICES_DESIGN,
} from '../../../config/flags';
import useStateData from 'hooks/useStateData';
import { useModalContext } from 'context/ModalProvider';
import { useRisClient } from 'hooks/useRisClient';
import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import {
  ConsentStatus,
  GetPartnerDeviceLocationModel,
  UpdatePartnerDeviceLocationModel,
} from '@resideo/web-integration-services-api-client';
import { useFeatureFlags } from 'context/FeatureFlags';
import { IoMdRemoveCircleOutline } from 'react-icons/io';
import RemoveLocationModal from '../Location/LocationModals/RemoveLocationModal';
import { iQSupportEmail } from 'config/emailAddresses';
import { UnableToRemoveLocationModal } from '../Location/LocationModals/UnableToRemoveLocationModal';
import CustomerAccountServices from './CustomerAccountServices';
import { useServicesLinks } from 'hooks/useServicesLinks';

const LocationDetailsContainer = styled.div`
  display: flex;
  padding-left: 30px;
  padding-right: 15px;
  padding-top: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: block;
    margin-bottom: 24px;
    padding-left: 15px;
    padding-top: 15px;
  }
`;

const LocationActions = styled(Box)`
  box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  margin-left: auto;
  height: fit-content;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-left: 0;
    padding-top: 5%;
  }
`;

const AlertsContainer = styled(Box)`
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const AlertsHeadingContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem 1rem 2rem;
  margin-top: 14px;
  h2 {
    align-self: center;
    font-size: 21px;
  }
`;

const AlertsHeading = styled(Heading)`
  color: #303030;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-left: 0;
  }
`;

const AlertsConditionalContainer = styled(Flex)`
  flex-direction: column;
  background-color: #f7f7f7;

  align-items: center;
  padding: 30px;
`;

const AlertsSectionTitle = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 15px;
  color: #303030;
`;

const AlertsSectionDesc = styled(Text)`
  color: #767676;
  text-align: center;
  font-size: 14px;
`;

const DevicesContainer = styled(Box)`
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const DeviceHeading = styled(Heading)`
  margin-left: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-left: 0;
  }
`;

const DeviceHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem 1rem 2rem;
  margin-top: 14px;
  h3 {
    align-self: center;
    font-size: 1.125rem;
  }
`;

const EditIcon = styled(MdEdit)`
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
  color: #1c6fb9;
`;

const RemoveButtonDiv = styled.div`
  border-right: 3px solid rgba(0, 0, 0, 0.1);
`;

const RemoveIcon = styled(IoMdRemoveCircleOutline)`
  font-size: 14px;
  margin-right: 8px;
  cursor: pointer;
  color: #1c6fb9;
`;

const CustomerLocation: FC<CustomerLocationProps> = ({
  customerData,
  location,
  customerId,
  isLoaded,
  loadError,
  locations,
}) => {
  const { t } = useTranslation();
  const { partnerAccountId } = useCurrentUser();
  const { stateData } = useStateData();
  const { openModal, closeModal } = useModalContext();
  const { addToast } = useToastContext();
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const { CUSTOMER_DETAILS_REDESIGN } = useFeatureFlags();
  const { servicesPath } = useServicesLinks();
  const [deviceLocationData, setDeviceLocationData] = useState<
    GetPartnerDeviceLocationModel | null | undefined
  >();
  const [consentPending, setConsentPending] = useState(false);
  const [alertsServiceApplied, setAlertServiceApplied] = useState(false);
  const { refetch: fetchPartnerDeviceLocationData } = useQuery({
    queryKey: ['getPartnerDeviceLocationV2'],
    queryFn: async () => {
      const res = await client.myProAccounts.myProAccountsGetPartnerDeviceLocationV2(
        partnerAccountId || '',
        location?.id || ''
      );

      return res?.data;
    },
    enabled: false,
    staleTime: 5000,
  });

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchPartnerDeviceLocationData();
      setDeviceLocationData(data as GetPartnerDeviceLocationModel);
    };

    if (location?.id) {
      fetchData();
    }
  }, [location, setDeviceLocationData, fetchPartnerDeviceLocationData]);

  useEffect(() => {
    if (deviceLocationData?.deviceServices) {
      const activeServices = deviceLocationData.deviceServices
        .filter(
          service =>
            service.type === 'ProAlertsPartnerDeviceService' &&
            service.status === 'ACTIVE'
        )
        .sort((a, b) => {
          const dateA = a.updatedAt ? new Date(a.updatedAt).getTime() : 0;
          const dateB = b.updatedAt ? new Date(b.updatedAt).getTime() : 0;
          return dateB - dateA;
        });

      const mostRecentService = activeServices.length
        ? activeServices[0]
        : null;

      setConsentPending(
        mostRecentService?.consentStatus === ConsentStatus.PENDING
      );
      setAlertServiceApplied(mostRecentService?.status === 'ACTIVE');
    }
  }, [deviceLocationData, setConsentPending, setAlertServiceApplied]);

  const { data } = useSuspenseQuery({
    queryKey: ['homeOwnerAppBranding'],
    queryFn: async () =>
      (
        await client.myProAccounts.myProAccountsGetBrandingTile(
          partnerAccountId ?? ''
        )
      ).data,
  });

  const hasProAlertsService =
    data?.partnerUsers?.[0]?.partnerAccount?.partnerProgramEnrollments?.filter(
      enrollment => enrollment?.partnerProgram?.name === 'Pro Alerts'
    )?.[0]?.status === 'ACTIVE';

  const editCustomerLocation = useMutation({
    mutationKey: ['editCustomerLocation'],
    mutationFn: async (vars: {
      partnerCustomerId: string;
      partnerDeviceLocationId: string;
      loc: UpdatePartnerDeviceLocationModel;
    }) => {
      await client.myProAccounts.myProAccountsUpdatePartnerDeviceLocation(
        partnerAccountId as string,
        vars?.partnerCustomerId as string,
        vars?.partnerDeviceLocationId as string,
        {
          name: vars?.loc?.name,
          address: {
            addressLine1: vars?.loc?.address?.addressLine1,
            addressLine2: vars?.loc?.address?.addressLine2,
            city: vars?.loc?.address?.city,
            stateProvinceRegionCode:
              vars?.loc?.address?.stateProvinceRegionCode,
            zipPostalCode: vars?.loc?.address?.zipPostalCode,
            countryCode: vars?.loc?.address?.countryCode,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['partnerCustomerLocations'],
      });
      addToast({
        toastType: 'Success',
        message: t('customerDetails.updateLocation.success'),
      });
      closeModal();
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
    },
  });

  const removeCustomerLocation = useMutation({
    mutationKey: ['removeCustomerLocation'],
    mutationFn: async (variables: { partnerDeviceLocationId: string }) => {
      await client.myProAccounts.myProAccountsDeletePartnerDeviceLocation(
        partnerAccountId as string,
        variables?.partnerDeviceLocationId as string
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['partnerCustomerLocations'],
      });
      addToast({
        toastType: 'Success',
        message: t('customerDetails.removeLocation.successMessage'),
      });
      closeModal();
    },
    onError: () => {
      closeModal();
      addToast({
        toastType: 'Error',
        message: t('customerDetails.removeLocation.errorMessage'),
        linkText: t('customerDetails.removeLocation.contactCustomerSupport'),
        linkHref: `mailto:${iQSupportEmail}?subject=Unable to Remove Location`,
      });
    },
  });

  const handleUpdateLocation = values => {
    editCustomerLocation.mutate({
      partnerCustomerId: customerId ?? '',
      partnerDeviceLocationId: location?.id ?? '',
      loc: {
        name: values.locationName,
        address: {
          addressLine1: values.address1,
          addressLine2: values.address2,
          city: values.city,
          stateProvinceRegionCode: values.state,
          zipPostalCode: values.zip,
          countryCode: values.country,
        },
      },
    });
  };

  const handleRemoveLocation = () => {
    removeCustomerLocation.mutate({
      partnerDeviceLocationId: location?.id ?? '',
    });
  };

  const stateName =
    stateData?.find(
      state =>
        state.country_code === location?.address?.countryCode &&
        state.iso2 === location?.address?.stateProvinceRegionCode
    )?.name ?? '';

  return (
    <>
      {CUSTOMER_DETAILS_REDESIGN ? null : (
        <LocationDetailsContainer data-test-locations-details>
          <Box>
            <Heading as='h2' fontSize='medium' paddingBottom='1rem'>
              {location?.name}
            </Heading>
            <Text>{`${location?.address?.addressLine1} ${location?.address
              ?.addressLine2 || ''}`}</Text>
            <Text>{`${location?.address?.city}
        ${stateName}
        ${location?.address?.zipPostalCode}`}</Text>
            <Text>{`${location?.address?.countryCode}`}</Text>
          </Box>
          <LocationActions>
            {CUSTOMER_DETAILS_REDESIGN ? null : (
              <RemoveButtonDiv
                data-test-remove-customer-location
                onClick={() => {
                  openModal({
                    title: location?.devices?.length
                      ? t('customerDetails.cannotRemoveLocation.title')
                      : t('customerDetails.removeLocation.title'),
                    children: location?.devices?.length ? (
                      <UnableToRemoveLocationModal onSubmit={closeModal} />
                    ) : (
                      <RemoveLocationModal
                        onCancel={closeModal}
                        onSubmit={() => {
                          handleRemoveLocation();
                        }}
                      />
                    ),
                  });
                }}>
                <RemoveIcon />
                <Button marginRight='medium' variant='tertiary' tertiaryLink>
                  {t('customerDetails.remove')}
                </Button>
              </RemoveButtonDiv>
            )}
            <Button
              data-test-edit-customer-location
              marginRight='medium'
              marginLeft='medium'
              onClick={() => {
                const address = convertSchemaAddress(location?.address);
                openModal({
                  title: t('locations.editLocation'),
                  children: (
                    <CustomerLocationForm
                      initialValues={{
                        locationName: location?.name,
                        locationId: location?.id,
                        ...address,
                      }}
                      onSubmit={values => handleUpdateLocation(values)}
                      onCancel={() => closeModal()}
                      isLoaded={isLoaded}
                      loadError={loadError}
                      locations={locations}
                    />
                  ),
                });
              }}
              variant='tertiary'
              tertiaryLink>
              <EditIcon />
              {t('common.edit')}
            </Button>
          </LocationActions>
        </LocationDetailsContainer>
      )}
      {CUSTOMER_ALERT_DETAILS && (
        <>
          <AlertsHeadingContainer>
            <AlertsHeading as='h2'>
              {t('customerDetails.alertsSection.title')}
            </AlertsHeading>
          </AlertsHeadingContainer>
          <AlertsContainer>
            {!hasProAlertsService && (
              <AlertsConditionalContainer>
                <AlertsSectionTitle data-test-alert-section-title>
                  {t('customerDetails.alertsSection.serviceNotActivated')}
                </AlertsSectionTitle>
                <AlertsSectionDesc
                  style={{ paddingBottom: '15px' }}
                  data-test-alert-section-desc>
                  {t('customerDetails.alertsSection.serviceNotActivatedDesc')}
                </AlertsSectionDesc>
                <Button
                  data-test-alert-section-btn
                  variant={'secondary'}
                  onClick={() => {
                    window.location.href = servicesPath;
                  }}>
                  {t('customerDetails.alertsSection.subscribeToServiceBtn')}
                </Button>
              </AlertsConditionalContainer>
            )}
            {hasProAlertsService && consentPending && (
              <AlertsConditionalContainer>
                <AlertsSectionTitle data-test-alert-section-title>
                  {t('customerDetails.alertsSection.consentPending')}
                </AlertsSectionTitle>
                <AlertsSectionDesc data-test-alert-section-desc>
                  {t('customerDetails.alertsSection.consentPendingDesc')}
                </AlertsSectionDesc>
              </AlertsConditionalContainer>
            )}
            {hasProAlertsService && !alertsServiceApplied && (
              <AlertsConditionalContainer>
                <AlertsSectionTitle data-test-alert-section-title>
                  {t('customerDetails.alertsSection.serviceNotApplied')}
                </AlertsSectionTitle>
                <AlertsSectionDesc data-test-alert-section-desc>
                  {t('customerDetails.alertsSection.serviceNotAppliedDesc')}
                </AlertsSectionDesc>
              </AlertsConditionalContainer>
            )}
          </AlertsContainer>
        </>
      )}

      <DeviceHeadingContainer>
        <DeviceHeading as='h3'>{t(`customerDetails.devices`)}</DeviceHeading>
        {ENABLE_ADD_DEVICE_BUTTON && (
          <>
            <DeviceLookup
              deviceLocationId={location?.id}
              customerData={
                {
                  firstName: customerData?.firstName,
                  lastName: customerData?.lastName,
                  email: customerData?.email,
                } as CustomerAccountCustomerDataType
              }
            />
          </>
        )}
      </DeviceHeadingContainer>
      <DevicesContainer>
        <CustomerAccountDevices
          customerData={
            {
              firstName: customerData?.firstName,
              lastName: customerData?.lastName,
              email: customerData?.email,
            } as CustomerAccountCustomerDataType
          }
          locationData={{ ...location }}
        />
      </DevicesContainer>
      {ENABLE_HVAC_DEVICES_SERVICES_DESIGN ? (
        <CustomerAccountServices locationId={location?.id} />
      ) : (
        <CustomerLocationServices
          deviceServices={location?.deviceServices}
          location={{
            locationName: location?.name,
            locationId: location?.id,
            state: stateName,
            ...location?.address,
          }}
        />
      )}
    </>
  );
};

export default CustomerLocation;
