import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams, NavLink } from 'react-router-dom';
import { Titled } from 'components/common/Titled';
import UpdateEmployeeRoleModalContent from './UpdateEmployeeRoleModalContent';
import { useCurrentUser } from 'context/CurrentUser';
import { Link, Button, Heading, Breadcrumbs } from '@resideo/blueprint-react';
import { IModalProps, useModalContext } from 'context/ModalProvider';
import ArchiveEmployeeModalContent from './ArchiveEmployeeModalContent';
import RestoreEmployeeModalContent from './RestoreEmployeeModalContent';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';

const Main = styled.main`
  margin: 0 auto;
  padding-top: 0;
  width: 100%;
`;

const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.space.medium};
  box-shadow: 0 1px 7px -5px;
  padding: ${({ theme }) => theme.space.medium}
    ${({ theme }) => theme.space.large};
`;

const ToolTipContainer = styled.div`
  display: flex;
  min-height: 26px;
`;

const SubHeader = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.space.medium};
`;

const Content = styled.div`
  padding: ${({ theme }) => theme.space.medium}
    ${({ theme }) => theme.space.large};
`;

const NotFound = styled.p`
  margin-top: ${({ theme }) => theme.space.large};
  margin-left: ${({ theme }) => theme.space.large};
`;

const EmailLink = styled(Link)`
  text-decoration: none;
`;

const DescriptionList = styled.dl`
  div {
    display: flex;
    margin-bottom: ${({ theme }) => theme.space.medium};
  }
  dt {
    font-weight: 500;
    width: 135px;
  }
`;

const EmployeesView = (): JSX.Element => {
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();
  const {
    partnerAccountId,
    partnerUserId,
    isOwner,
    isAdmin,
  } = useCurrentUser();
  const { openModal, closeModal } = useModalContext();
  const { client } = useRisClient();

  const { data } = useSuspenseQuery({
    queryKey: ['getPartnerUserById'],
    queryFn: async () => {
      const res = await client.myProAccounts.myProAccountsGetEmployee(
        partnerAccountId as string,
        id
      );
      return res?.data;
    },
  });

  const role = data?.roles?.[0] || '';
  const status = data?.status;
  const user = data?.user;

  if (!user) {
    return (
      <Titled title={t('mybusiness.employees.employeeDetails')}>
        <Main data-test-edit-employee>
          <NotFound>{t('mybusiness.employees.doesNotExist')}</NotFound>
        </Main>
      </Titled>
    );
  }

  const name = `${user?.lastName}, ${user?.firstName}`;
  const isOwn = id === partnerUserId;
  const isArchived = status === 'ARCHIVED';
  const canRestore = isArchived && role === 'TECHNICIAN';
  const canUpdate =
    !isOwn &&
    !isArchived &&
    (isOwner || (isAdmin && role !== 'OWNER')) &&
    role === 'TECHNICIAN'; // we can update only technicians until implemented https://resideo.atlassian.net/browse/GSF-330

  return (
    <Titled title={t('mybusiness.employees.employeeDetails')}>
      <Main data-test-edit-employee>
        <Header>
          <Breadcrumbs>
            {isArchived ? (
              <NavLink to='/mybusiness/company/employees/archived'>
                {t('leftNav.archivedEmployees')}
              </NavLink>
            ) : (
              <NavLink to='/mybusiness/company/employees'>
                {t('leftNav.employees')}
              </NavLink>
            )}
          </Breadcrumbs>
          <Heading
            as='h1'
            fontSize='xLarge'
            paddingTop='medium'
            paddingBottom='small'>{`${user?.lastName}, ${user?.firstName}`}</Heading>
          <ToolTipContainer>
            {/*A tooltip is out of scope for this ticket*/}
          </ToolTipContainer>
        </Header>
        <Content>
          <SubHeader>
            <Heading as='h2' fontSize='large'>
              {t('mybusiness.employees.employeeDetails')}
            </Heading>
            <div>
              {isArchived ? (
                <Button
                  variant='primary'
                  onClick={() => {
                    openModal({
                      title: t('mybusiness.employees.restoreModal.title'),
                      testAttribute: { 'data-test-restore-modal': true },
                      children: (
                        <RestoreEmployeeModalContent
                          closeModal={closeModal}
                          id={id}
                          name={name}
                          email={user?.contactEmail}
                        />
                      ),
                    } as IModalProps);
                  }}
                  disabled={!canRestore}>
                  {t('common.restore')}
                </Button>
              ) : (
                <>
                  <Button
                    variant='destructiveSecondary'
                    marginRight='medium'
                    onClick={() => {
                      openModal({
                        title: t(
                          'mybusiness.employees.archived.archiveModal.title'
                        ),
                        testAttribute: { 'data-test-archive-modal': true },
                        children: (
                          <ArchiveEmployeeModalContent
                            closeModal={closeModal}
                            id={id}
                            name={name}
                          />
                        ),
                      } as IModalProps);
                    }}
                    disabled={!canUpdate}>
                    {t('common.archive')}
                  </Button>
                  <Button
                    variant='primary'
                    onClick={() => {
                      openModal({
                        title: t('mybusiness.employees.updateEmployeeRole'),
                        testAttribute: { 'data-test-archive-modal': true },
                        children: (
                          <UpdateEmployeeRoleModalContent
                            closeModal={closeModal}
                            id={id}
                            role={role}
                            name={name}
                          />
                        ),
                      } as IModalProps);
                    }}
                    disabled={!canUpdate}>
                    {t('mybusiness.employees.updateRole')}
                  </Button>
                </>
              )}
            </div>
          </SubHeader>

          <DescriptionList>
            <div>
              <dt>{t('mybusiness.employees.name')}:</dt>
              <dd>{name}</dd>
            </div>
            <div>
              <dt>{t('mybusiness.employees.country')}:</dt>
              <dd>{user?.countryCode}</dd>
            </div>
            <div>
              <dt>{t('common.emailShort')}:</dt>
              <dd>
                <EmailLink href={`mailto:${user?.contactEmail}`}>
                  {user?.contactEmail}
                </EmailLink>
              </dd>
            </div>
            <div>
              <dt>{t('mybusiness.employees.role')}:</dt>
              <dd>{t(`common.roles.${role}`)}</dd>
            </div>
            <div>
              <dt>{t('common.status')}:</dt>
              <dd>{t(`common.statuses.${status}`)}</dd>
            </div>
          </DescriptionList>
        </Content>
      </Main>
    </Titled>
  );
};

export default EmployeesView;
