import React, { ComponentType, lazy } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { withFlaggedRoute, withRoute } from 'components/common/Route';
import AppLayout from 'components/layouts/AppLayout';
import ProductDownload from './ProductDownload/ProductDownload';
import Terms from './Terms';
import { useLogin } from 'hooks/useLogin';
import companyRoutes, { CompanyLayout } from './Company';
import myBusinessRoutes, { MyBusinessLayout } from './MyBusiness';
import EmailVerify from './EmailVerify';
import AgreementCheck from './AgreementCheck';

type LazyModulePromise = Promise<{ default: ComponentType<any> }>;

const Profile = lazy((): LazyModulePromise => import('./Profile'));

export const ProtectedRoutesLayout = () => {
  useLogin();

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

const routes: RouteObject[] = [
  {
    path: 'terms',
    Component: () => withRoute(Terms),
  },
  {
    path: 'email-verify',
    Component: () => withRoute(EmailVerify),
  },
  {
    path: 'agreement-check',
    Component: () => withFlaggedRoute(AgreementCheck, 'AGREEMENT_CHECK'),
  },
  {
    index: true,
    Component: () =>
      withRoute(() => <Navigate to='/mybusiness/home' replace />),
  },
  {
    path: 'onboarding',
    Component: () => withRoute(MyBusinessLayout),
    children: myBusinessRoutes,
  },
  {
    path: 'mybusiness/*',
    Component: () => withRoute(MyBusinessLayout),
    children: myBusinessRoutes,
  },
  {
    path: 'profile/*',
    Component: () => withRoute(Profile),
  },
  {
    path: 'company/*',
    Component: () => withRoute(CompanyLayout),
    children: companyRoutes,
  },
  {
    path: 'products/downloads/:pageType',
    Component: () => withRoute(ProductDownload),
  },
];

export default routes;
