export const productsDownloadList = t => ({
  all: {
    file:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/All Assets.zip?sp=r&st=2021-10-14T18:33:37Z&se=2221-10-15T02:33:37Z&spr=https&sv=2020-08-04&sr=b&sig=%2FUKBrlMbyHfUMbre%2B07hnH2e%2FG%2BUMcBxOfGNBJGuBZ0%3D',
    size: t('hvacAndWater.downloadAll.all'),
  },
  dehumidification: {
    file:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/All Dehumidification.zip?sp=r&st=2021-10-14T15:56:11Z&se=2221-10-14T23:56:11Z&spr=https&sv=2020-08-04&sr=b&sig=N3LYn5hF4%2FeVfQ2pUtKSUKB0uqyMtAOaVpFJ891DjPY%3D',
    size: t('hvacAndWater.downloadAll.dehumidification'),
  },
  ductless: {
    file:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/All Ductless.zip?sp=rl&st=2021-03-10T15:34:08Z&se=2129-06-11T15:34:00Z&sv=2020-02-10&sr=b&sig=weR6MFBTU760X7iM6%2FU%2BOaL0OtU8ApQHvOvf964Of%2F0%3D',
    size: t('hvacAndWater.downloadAll.ductless'),
  },
  filtration: {
    file:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/All Filtration.zip?sp=rl&st=2021-03-10T15:38:20Z&se=2163-07-11T15:38:00Z&sv=2020-02-10&sr=b&sig=9kjmCglnfM3BgvfOSoO3qhC9nvdYqojcCqpHxTS%2BNy0%3D',
    size: t('hvacAndWater.downloadAll.filtration'),
  },
  humidification: {
    file:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/All Humidification.zip?sp=rl&st=2021-03-10T15:45:10Z&se=2173-06-11T15:45:00Z&sv=2020-02-10&sr=b&sig=FzSljqhMnvnWuwhe3kc6V6%2BdhcG38wJCpCk2MIJ5zoY%3D',
    size: t('hvacAndWater.downloadAll.humidification'),
  },
  thermal: {
    file:
      ' https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/All Thermal Solutions.zip?sp=r&st=2021-10-14T16:02:40Z&se=2221-10-15T00:02:40Z&spr=https&sv=2020-08-04&sr=b&sig=pxILxuDjtH1%2FxZYx8nnMTg2dcAnmspyDaydqs5ZdxXM%3D',
    size: t('hvacAndWater.downloadAll.thermal'),
  },
  thermostats: {
    file:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/All Thermostats.zip?sp=r&st=2021-10-14T16:22:46Z&se=2221-10-15T00:22:46Z&spr=https&sv=2020-08-04&sr=b&sig=zTHVA6OaIiK5R1%2BvDJAoLi6I%2B%2BNEowcDb9mKxxTYT4o%3D',
    size: t('hvacAndWater.downloadAll.thermostats'),
  },
  water: {
    file:
      ' https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/All Water.zip?sp=r&st=2021-10-14T15:52:09Z&se=2221-10-14T23:52:09Z&spr=https&sv=2020-08-04&sr=b&sig=s%2BNS5ktjeZdMAfNywOLVLy0LqdwlqKg2KdrupnW0gyw%3D',
    size: t('hvacAndWater.downloadAll.water'),
  },
  zoning: {
    file:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/All Zoning.zip?sp=r&st=2021-10-14T15:54:02Z&se=2221-10-14T23:54:02Z&spr=https&sv=2020-08-04&sr=b&sig=lpYndSojBV4rDKDK7Z9S3mDf28RyQl2pytNVPj553wU%3D',
    size: t('hvacAndWater.downloadAll.zoning'),
  },
  force: {
    file:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/All Forced Air Zoning.zip?sp=r&st=2021-10-14T16:02:13Z&se=2221-10-15T00:02:13Z&spr=https&sv=2020-08-04&sr=b&sig=opGgF%2BVt%2FzfHalTGCy%2FzPVbSmQSbVaJzpnZShbzzlsc%3D',
    size: t('hvacAndWater.downloadAll.force'),
  },
});
