import React, { FC, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumbs,
  Button,
  Box,
  SecondaryNavigation,
  Text,
  SegmentedControl,
  MultiSelectCheckboxFilter,
  Flex,
  useToastContext,
  Link as BPLink,
} from '@resideo/blueprint-react';
import {
  Link,
  useLocation,
  useNavigate,
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';
import styled from 'styled-components';
import Page from 'components/common/Page';
import RightPanelHeader from 'components/common/RightPanelHeader';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';
import { useCurrentUser } from 'context/CurrentUser';
import {
  CreateProAlertSettingModel,
  ProAlertSettingModel,
  UpdateProAlertSettingModel,
} from '@resideo/web-integration-services-api-client';
import { AlertsHeader } from './components/DashboardComponents';
import { PrevPageInfo } from '../ManageBrandProfile';
import { useModalContext } from 'context/ModalProvider';
import { Titled } from 'components/common/Titled';
import AlertSettingsTable from './AlertSettingsTable';

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    & > * {
      width: 100%;
    }
  }
`;

const Header = styled.div`
  margin-left: 2rem;
  padding-top: 5px;
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
    margin-left: 1rem;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  & > *:only-child {
    grid-column: 2;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 100%;
  }
`;

const ContentBox = styled(Box)`
  width: 100%;
`;

const StyledSecondaryNavContainer = styled.div`
  margin-bottom: 1.5rem;
  margin-top: -1.2rem;

  ul {
    box-shadow: 0 10px 6px -6px ${({ theme }) => theme.baseColors.lightGray};
  }

  li {
    padding-left: 40px;
  }
`;

const StyledLink = styled(BPLink)`
  text-decoration: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 0.875rem;
  }
`;

const AlertSettings: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { client } = useRisClient();
  const { partnerAccountId } = useCurrentUser();
  const [editableAlertSettings, setEditableAlertSettings] = useState<
    ProAlertSettingModel[]
  >();
  const [options, setOptions] = useState<any[]>();
  const [filteredSettings, setFilteredSettings] = useState<any[]>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [prevPage, setPrevPage] = useState<PrevPageInfo | undefined>();
  const queryClient = useQueryClient();
  const blocker = useBlocker(isEditMode && hasChanges);
  const { openModal, closeModal } = useModalContext();
  const { addToast } = useToastContext();

  const tabs = [
    {
      text: t('alertSettings.all'),
      value: 'All',
    },
    {
      text: t('alertSettings.urgent'),
      value: 'Urgent',
    },
    {
      text: t('alertSettings.warning'),
      value: 'Warning',
    },
    {
      text: t('alertSettings.notice'),
      value: 'Notice',
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  // Helper function to filter alert settings based on currentTab and filteredSettings
  const filterAlertSettings = (sourceData: ProAlertSettingModel[]) => {
    let filtered = sourceData.filter(alert => {
      if (currentTab === 'All') {
        return true;
      } else {
        return alert?.severity === currentTab;
      }
    });

    if (filteredSettings?.length) {
      filtered = filtered.filter(alert => {
        return filteredSettings.some(item => item.value === alert.alertName);
      });
    }

    return filtered;
  };

  const { data } = useSuspenseQuery({
    queryKey: ['getAlertSettings'],
    queryFn: async () => {
      if (!partnerAccountId) return null;

      const res = await client.myProAccounts.myProAccountsGetProAlertSettings(
        partnerAccountId
      );
      return res.data;
    },
  });

  const updateAlertSettings = useMutation({
    mutationKey: ['updateAlertSettings'],
    mutationFn: async (settingsToUpdate: UpdateProAlertSettingModel[]) => {
      await client.myProAccounts.myProAccountsUpdateProAlertSettings(
        partnerAccountId as string,
        settingsToUpdate
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAlertSettings'] });

      addToast({
        toastType: 'Success',
        message: t('alertSettings.settingsSaved'),
      });
    },
    onError: () => {
      openModal({
        title: t('alertSettings.unableToSaveChangesModal.title'),
        children: (
          <>
            <Text data-test-unable-to-save-changes-text-1 marginBottom='small'>
              {t('alertSettings.unableToSaveChangesModal.textLine1')}
            </Text>
            <br />
            <Text data-test-unable-to-save-changes-text-2 marginBottom='small'>
              {t('alertSettings.unableToSaveChangesModal.textLine2')}
              <StyledLink href='mailto:proIQsupport@resideo.com'>
                proIQsupport@resideo.com
              </StyledLink>
            </Text>
            <Flex
              flexDirection='row'
              justifyContent='flex-end'
              paddingTop='30px'>
              <Button
                data-test-unable-to-save-changes-cancel-button
                variant='secondary'
                onClick={() => {
                  closeModal();
                }}
                marginRight='large'>
                {t('alertSettings.unableToSaveChangesModal.cancel')}
              </Button>
              <Button
                data-test-unable-to-save-changes-try-again-button
                variant='primary'
                onClick={async () => {
                  if (editableAlertSettings) {
                    await updateAlertSettings.mutateAsync(
                      editableAlertSettings as UpdateProAlertSettingModel[]
                    );
                  }
                  closeModal();
                }}>
                {t('alertSettings.unableToSaveChangesModal.tryAgain')}
              </Button>
            </Flex>
          </>
        ),
      });
    },
  });

  const createAlertSettings = useMutation({
    mutationKey: ['createAlertSettings'],
    mutationFn: async (settingsToCreate: CreateProAlertSettingModel[]) => {
      await client.myProAccounts.myProAccountsCreateProAlertSettings(
        partnerAccountId as string,
        'en',
        settingsToCreate
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAlertSettings'] });
      addToast({
        toastType: 'Success',
        message: t('alertSettings.settingsCreated'),
      });
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('alertSettings.unableToCreateSettings'),
      });
    },
  });

  // Initialize editableAlertSettings with a deep copy of the data when it changes
  useEffect(() => {
    if (data) {
      const dataCopy = JSON.parse(JSON.stringify(data));
      setEditableAlertSettings(dataCopy);
    }
  }, [data]);

  useEffect(() => {
    if (data && editableAlertSettings) {
      const hasSettingsChanged =
        JSON.stringify(data) !== JSON.stringify(editableAlertSettings);
      setHasChanges(hasSettingsChanged);
    }
  }, [data, editableAlertSettings, setHasChanges]);

  const displaySettings = useMemo(() => {
    const sourceData = isEditMode ? editableAlertSettings : data;
    if (!sourceData) return undefined;

    return filterAlertSettings(sourceData as ProAlertSettingModel[]);
  }, [isEditMode, editableAlertSettings, data, currentTab, filteredSettings]);

  useEffect(() => {
    if (data) {
      const settings =
        [...(data as any)]
          ?.sort((a, b) =>
            (a?.alertName || '').localeCompare(b?.alertName || '')
          )
          .map(alert => ({
            text: alert.title || '',
            value: alert.alertName || '',
          })) || [];
      setOptions(settings);
    }
  }, [data, setOptions]);

  useEffect(() => {
    if (blocker?.state === 'blocked') {
      openModal({
        title: t('alertSettings.unsavedChangesModal.title'),
        children: (
          <>
            <Text data-test-unsaved-changes-text marginBottom='small'>
              {t('alertSettings.unsavedChangesModal.text')}
            </Text>
            <Flex
              flexDirection='row'
              justifyContent='flex-end'
              paddingTop='30px'>
              <Button
                data-test-unsaved-changes-back-button
                variant='secondary'
                onClick={() => {
                  closeModal();
                }}
                marginRight='large'>
                {t('alertSettings.unsavedChangesModal.back')}
              </Button>
              <Button
                data-test-unsaved-changes-discard-button
                variant='destructivePrimary'
                onClick={() => {
                  blocker.reset?.();
                  closeModal();
                  navigate(blocker?.location?.pathname ?? '');
                }}>
                {t('alertSettings.unsavedChangesModal.discardChanges')}
              </Button>
            </Flex>
          </>
        ),
      });
    }
  }, [blocker]);

  useEffect(() => {
    if (location?.state?.prevPage) {
      switch (location?.state?.prevPage) {
        case 'alerts-dashboard':
          setPrevPage({
            url: '/mybusiness/alerts/dashboard',
            displayName: t('alertsDashboard.title'),
          });
          break;
        case 'complete-service-setup':
          setPrevPage({
            url: '/mybusiness/services/setup',
            displayName: t(
              'mybusiness.services.brandProfile.header.completeServiceSetup'
            ),
          });
          break;
        default:
          setPrevPage(undefined);
      }
    }
  }, [setPrevPage, location, t]);

  useEffect(() => {
    const callCreateAlertSettings = async () => {
      if (prevPage && prevPage.url === '/mybusiness/services/setup') {
        const hasEmptyGuids = data?.every(
          (setting: ProAlertSettingModel) =>
            setting.id && setting.id === '00000000-0000-0000-0000-000000000000'
        );
        if (hasEmptyGuids) {
          await createAlertSettings.mutateAsync(
            data as CreateProAlertSettingModel[]
          );
        }
      }
    };

    callCreateAlertSettings();
  }, [prevPage, data]);

  const handleToggleAlert = (alert: ProAlertSettingModel) => {
    if (editableAlertSettings) {
      const updatedSettings = editableAlertSettings.map(setting => {
        if (setting.alertName === alert.alertName) {
          return {
            ...setting,
            isEnabled: !setting.isEnabled,
          };
        }
        return setting;
      });

      setEditableAlertSettings(updatedSettings);
    }
  };

  return (
    <Titled title={t('alertSettings.title')}>
      <ContentBox>
        <HeaderContainer>
          <Header>
            <Breadcrumbs>
              {prevPage ? (
                <Link to={prevPage.url}>{prevPage.displayName}</Link>
              ) : (
                <Link to='/mybusiness/alerts/dashboard'>
                  {t('alertsDashboard.title')}
                </Link>
              )}
              <Link to='#'>{t('alertSettings.title')}</Link>
            </Breadcrumbs>
          </Header>
          <RightPanelHeader
            hasNoBorder
            cta={
              <ButtonContainer>
                {!isEditMode ? (
                  <>
                    <Button
                      variant='secondary'
                      onClick={() =>
                        prevPage
                          ? navigate(prevPage.url)
                          : navigate('/mybusiness/alerts/dashboard')
                      }>
                      {t('common.back')}
                    </Button>
                    <Button
                      variant='primary'
                      onClick={() => setIsEditMode(true)}>
                      {t('alertSettings.editSettings')}
                    </Button>
                  </>
                ) : hasChanges ? (
                  <>
                    <Button
                      variant='secondary'
                      onClick={() => {
                        setEditableAlertSettings(
                          JSON.parse(JSON.stringify(data))
                        );
                        setIsEditMode(false);
                      }}>
                      {t('common.cancel')}
                    </Button>
                    <Button
                      variant='primary'
                      onClick={async () => {
                        if (editableAlertSettings) {
                          await updateAlertSettings.mutateAsync(
                            editableAlertSettings as UpdateProAlertSettingModel[]
                          );
                          setIsEditMode(false);
                        }
                      }}>
                      {t('common.save')}
                    </Button>
                  </>
                ) : (
                  <Button
                    variant='primary'
                    onClick={() => setIsEditMode(false)}>
                    {t('common.done')}
                  </Button>
                )}
              </ButtonContainer>
            }>
            <div>{t('alertSettings.title')}</div>
          </RightPanelHeader>
          <StyledSecondaryNavContainer>
            <SecondaryNavigation
              navItems={[
                {
                  text: t('alertSettings.thermostats'),
                  path: '#',
                  ref: 'thermostats',
                },
              ]}
              displayedRef='thermostats'
            />
          </StyledSecondaryNavContainer>
        </HeaderContainer>

        <Page
          as='main'
          paddingY='none'
          paddingX={['large', 'small']}
          marginLeft={[0, 'large']}
          marginRight={['small', '2rem']}
          maxWidth='1000rem'>
          <SegmentedControl
            tabs={tabs}
            value={currentTab}
            onChange={setCurrentTab}
          />
          <FilterContainer>
            <AlertsHeader>
              {t('alertSettings.thermostatAlertSettingsTitle')}
            </AlertsHeader>
            <MultiSelectCheckboxFilter
              name='alertTypeFilter'
              data-testid='alertTypeFilter'
              label={null}
              options={options}
              isRightAlignedMenu
              onChange={values => {
                setFilteredSettings(values);
              }}
            />
          </FilterContainer>
          <AlertSettingsTable
            data={displaySettings}
            isEditMode={isEditMode}
            onToggleAlert={handleToggleAlert}
          />
        </Page>
      </ContentBox>
    </Titled>
  );
};

export default AlertSettings;
