import React, { useState } from 'react';
import { Button, Flex, Text, useToastContext } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCurrentUser } from 'context/CurrentUser';
import { useRisClient } from 'hooks/useRisClient';
import { EmailTemplateModel } from '@resideo/web-integration-services-api-client';

const DeclineAccessModalContent = ({ closeModal, id, name }) => {
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const [submitting, setSubmitting] = useState(false);
  const { client } = useRisClient();
  const { partnerAccountId } = useCurrentUser();
  const queryClient = useQueryClient();

  const rejectPartnerUserInvite = useMutation({
    mutationKey: ['rejectPartnerUserInvite'],
    mutationFn: async (id: string) => {
      await client.myProAccounts.myProAccountsRejectAddRequest(
        partnerAccountId as string,
        id,
        {
          templateLanguage: 'en',
          baseUrl: window.location.origin,
        } as EmailTemplateModel
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['partnerCustomer', 'getRequested'],
      });
      addToast({
        toastType: 'Error',
        message: `${t(
          'mybusiness.requested.declineAccessModalAccessDeclinedFor'
        )}${name}`,
      });
      setSubmitting(false);
      closeModal();
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('common.toast.error'),
      });
      setSubmitting(false);
      closeModal();
    },
  });

  const rejectInvite = () => {
    setSubmitting(true);
    rejectPartnerUserInvite.mutate(id);
  };

  return (
    <Flex data-test-decline-access-modal-container flexDirection='column'>
      <Text marginBottom='large'>
        {name}
        {t('mybusiness.requested.declineAccessModalWillNotBeAssociated')}
      </Text>

      <Flex justifyContent='flex-end'>
        <Button
          data-test-decline-access-modal-cancel-button
          onClick={() => closeModal()}
          marginRight='large'
          variant='secondary'>
          {t('common.cancel')}
        </Button>
        <Button
          data-test-decline-access-modal-decline-button
          disabled={submitting}
          onClick={() => rejectInvite()}
          variant='destructivePrimary'>
          {t('common.decline')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default DeclineAccessModalContent;
