import React from 'react';
import { Button, Text, Toggle } from '@resideo/blueprint-react';
import {
  DeviceAlertSeverity,
  ProAlertSettingModel,
} from '@resideo/web-integration-services-api-client';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SeverityBanner from './components/SeverityBanner';
import Pencil from '../../../assets/AlertSettings/pencil.svg?react';

const AlertTableContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
`;

const AlertRow = styled.div`
  display: flex;
  padding: 0.2rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.baseColors.lightGray};
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.75rem 0 1rem;
    gap: 0.75rem;
    width: 100%;
    margin-left: -2rem;
    margin-right: -2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    position: relative;
    border-bottom: none;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: ${({ theme }) => theme.baseColors.lightGray};
      width: 100vw;
      margin-left: calc(-50vw + 50%);
    }
  }
`;

const SeverityCell = styled.div`
  width: 120px;
  padding-right: 1rem;
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: auto;
    padding-right: 0;
  }
`;

const TitleCell = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex: 1;
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
  }
`;

const ActionCell = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-left: auto; /* Push to the right edge of the container */
  min-width: 140px; /* Ensure enough space for both elements */

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 100%;
    min-width: unset;
    margin-left: 0; /* Reset for mobile */
    justify-content: center;
  }
`;

// Container for title and toggle on mobile view
const MobileNameToggleRow = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
`;

// Desktop-only elements
const DesktopElement = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: none;
  }
`;

const EditButton = styled(Button)<{ isEditMode?: boolean }>`
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: ${({ isEditMode, theme }) =>
    !isEditMode ? theme.baseColors.lightGray : undefined};
  font-style: ${({ isEditMode }) => (!isEditMode ? 'italic' : 'normal')};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 0.25rem;
    font-size: 0.875rem;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.baseColors.lightGray};
    border-radius: 8px;
  }
`;

const PenIcon = styled(Pencil)<{ $isEditMode?: boolean }>`
  margin-right: 10px;

  .background {
    fill: white;
  }

  .outline {
    fill: ${({ $isEditMode }) => (!$isEditMode ? '#767676' : '#1C6FB9')};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-right: 5px;
    transform: scale(0.9);
  }
`;

interface AlertSettingsTableProps {
  data: ProAlertSettingModel[] | undefined | null;
  isEditMode: boolean;
  onToggleAlert?: (alert: ProAlertSettingModel) => void;
}

const AlertSettingsTable = ({
  data,
  isEditMode,
  onToggleAlert,
}: AlertSettingsTableProps) => {
  const { t } = useTranslation();

  if (!data) {
    return (
      <AlertTableContainer>
        <Text variant='body' textAlign={'center'}>
          <b>{t('alertSettings.emptyTable')}</b>
        </Text>
      </AlertTableContainer>
    );
  }

  const severityOrder = {
    [DeviceAlertSeverity.URGENT]: 1,
    [DeviceAlertSeverity.WARNING]: 2,
    [DeviceAlertSeverity.NOTICE]: 3,
  };

  return (
    <AlertTableContainer>
      {data.length === 0 && (
        <Text variant='body' textAlign={'center'}>
          <b>{t('alertSettings.emptyTable')}</b>
        </Text>
      )}
      {data
        ?.sort((a, b) => {
          const severityA = a?.severity
            ? DeviceAlertSeverity[a.severity.toUpperCase()]
            : DeviceAlertSeverity.NOTICE;
          const severityB = b?.severity
            ? DeviceAlertSeverity[b.severity.toUpperCase()]
            : DeviceAlertSeverity.NOTICE;

          // First sort by severity order
          const severityCompare =
            severityOrder[severityA] - severityOrder[severityB];

          // If severity is the same, sort alphabetically by title
          if (severityCompare === 0) {
            return (a?.title || '').localeCompare(b?.title || '');
          }

          return severityCompare;
        })
        .map((alert, i) => {
          return (
            <AlertRow key={alert?.alertName || `alert-${i}`}>
              <SeverityCell>
                <SeverityBanner
                  severity={
                    alert?.severity
                      ? DeviceAlertSeverity[alert.severity.toUpperCase()]
                      : DeviceAlertSeverity.NOTICE
                  }
                />
              </SeverityCell>
              {/* Desktop view */}
              <DesktopElement>
                <TitleCell>
                  <Text variant='body'>{alert?.title}</Text>
                </TitleCell>
              </DesktopElement>

              {/* Mobile view - name and toggle in one row */}
              <MobileNameToggleRow>
                <TitleCell>
                  <Text variant='body'>{alert?.title}</Text>
                </TitleCell>
                <Toggle
                  name={`toggle-${alert?.alertName}`}
                  disabled={!isEditMode}
                  checked={alert?.isEnabled}
                  onChange={() => {
                    if (isEditMode && alert && onToggleAlert) {
                      onToggleAlert(alert);
                    }
                  }}
                />
              </MobileNameToggleRow>

              <ActionCell>
                <EditButton
                  variant='tertiary'
                  isEditMode={isEditMode}
                  disabled={!isEditMode}
                  onClick={() => {
                    // Implement edit functionality in upcoming task
                  }}>
                  <PenIcon $isEditMode={isEditMode} />
                  {t('common.edit')}
                </EditButton>

                {/* Toggle only shows on desktop */}
                <DesktopElement>
                  <Toggle
                    name={`toggle-${alert?.alertName}`}
                    disabled={!isEditMode}
                    checked={alert?.isEnabled}
                    onChange={() => {
                      if (isEditMode && alert && onToggleAlert) {
                        onToggleAlert(alert);
                      }
                    }}
                  />
                </DesktopElement>
              </ActionCell>
            </AlertRow>
          );
        })}
    </AlertTableContainer>
  );
};

export default AlertSettingsTable;
