import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useCurrentUser } from 'context/CurrentUser';

export const useAlertCounts = () => {
  const { partnerAccountId = '' } = useCurrentUser();
  const { client } = useRisClient();

  return useSuspenseQuery({
    queryKey: ['alertsInsights', partnerAccountId],
    queryFn: async () => {
      const res = await client.myProAccounts.myProAccountsGetAlertCounts(
        partnerAccountId as string
      );
      return res?.data || {};
    },
  });
};
