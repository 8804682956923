import { useModal } from '@resideo/blueprint-react';
import React, { createContext, useEffect, useState } from 'react';

export interface IModalProps {
  title: string;
  children: any;
  style?: any;
  testAttribute?: any;
  onModalClose?: any;
  outsideClick?: boolean;
}

interface IModalContext {
  closeModal: () => void;
  openModal: (modalProps: any) => void;
  setModalProps: (modalProps: any) => void;
}

const ModalContext = createContext<IModalContext>({
  closeModal: () => ({}),
  openModal: () => ({}),
  setModalProps: () => ({}),
});

export const ModalProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState<IModalProps | null>(null);
  const [modalClosed, setModalClosed] = useState<boolean>(true);

  useEffect(() => {
    if (modalClosed && modalProps?.onModalClose) {
      modalProps?.onModalClose();
    }
  }, [modalClosed, modalProps]);

  const { Modal, openModal: _openModal, closeModal } = useModal({
    newDesign: true,
    onModalClose: () => {
      setModalClosed(true);
    },
    allowOutsideClick: modalProps?.outsideClick,
  });

  const openModal = (modalProps: IModalProps) => {
    setModalProps(modalProps);
    setModalClosed(false);
    _openModal();
  };

  return (
    <ModalContext.Provider value={{ closeModal, openModal, setModalProps }}>
      {children}
      {modalProps && (
        <Modal
          title={modalProps.title}
          style={{ ...modalProps.style }}
          {...modalProps.testAttribute}>
          {modalProps.children}
        </Modal>
      )}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => React.useContext(ModalContext);
