import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button } from '@resideo/blueprint-react';
import { ButtonContainer, ModalContent } from '../../../customers.style';
import styled from 'styled-components';

const StrongText = styled(Text)`
  font-weight: bold;
`;

const ModalText = styled(Text)`
  font-size: 14px;
`;

export const DeactivateServiceModal = ({
  onCancel,
  onSubmit,
  serviceName,
  deviceInfo,
}) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <ModalText data-test-deactivate-service-text1 marginBottom='small'>
        {t('customerDetails.deactivateService.successRemoveMsg')}
      </ModalText>
      <StrongText data-test-deactivate-service-servicename marginBottom='small'>
        {serviceName}
      </StrongText>
      <ModalText data-test-deactivate-service-text2 marginBottom='small'>
        {t('customerDetails.deactivateService.text2')}
      </ModalText>
      <StrongText data-test-deactivate-service-location marginBottom='small'>
        {deviceInfo?.deviceType}
      </StrongText>
      <ModalText data-test-deactivate-macid>{deviceInfo?.macId}</ModalText>
      <ButtonContainer>
        <Button
          data-test-deactivate-service-cancel-btn
          variant='secondary'
          onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button
          data-test-deactivate-service-deactivate-btn
          variant='destructivePrimary'
          onClick={onSubmit}>
          {t('customerDetails.deactivateService.deactivate')}
        </Button>
      </ButtonContainer>
    </ModalContent>
  );
};
