import React, { useState } from 'react';
import { Button, StatusBanner } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { session } from '../../utils/storage';

const ServiceStatusBanner = () => {
  const { t } = useTranslation();
  // Storing the value in session storage, as we should not display the status banner once it's closed.
  const [closeBanner, setCloseBanner] = useState(() => {
    return session.getItem('isStatusBannerClosed') === 'true';
  });

  const CloseBanner = () => {
    const newState = !closeBanner;
    session.setItem('isStatusBannerClosed', String(newState));
    setCloseBanner(newState);
  };

  const closeIconClicked = () => {
    // Adding set timeout to provide extra time for banner to slide down
    setTimeout(() => {
      CloseBanner();
    }, 1000);
  };

  const renderStatusBanner = () => {
    // Accessing the service steps from local storage
    const serviceSetUpStepNumber = Number(localStorage.getItem('currentStep'));
    if (
      serviceSetUpStepNumber &&
      serviceSetUpStepNumber >= 1 &&
      serviceSetUpStepNumber <= 5
    ) {
      return (
        <StatusBanner
          iconStatus={'Info'}
          statusHeader={t('home.manage.homeOwner.bannerStatusHeader')}
          top={'58px'}
          statusText={t('home.manage.homeOwner.bannerStatusText')}
          actionButtons={
            <>
              <Button
                onClick={() => {
                  CloseBanner();
                  window.location.href = '/mybusiness/services/setup';
                }}>
                {t('home.manage.homeOwner.bannerContinueBtn')}
              </Button>
              <Button variant='secondary' onClick={() => CloseBanner()}>
                {t('home.manage.homeOwner.notNow')}
              </Button>
            </>
          }
          isCloseIconEnabled={true}
          closeIconClicked={closeIconClicked}
          isBannerClosed={closeBanner}
        />
      );
    }
  };

  return renderStatusBanner();
};

export default ServiceStatusBanner;
