import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Route, Routes } from 'react-router-dom';
import { Titled } from 'components/common/Titled';
import {
  Button,
  Flex,
  SecondaryNavigation,
  SecondaryNavItem,
} from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useFeatureFlags } from 'context/FeatureFlags';
import Add from 'components/icons/add.svg?react';
import Page from 'components/common/Page';
import TableLoading from 'components/common/TableLoading';
import EmployeesList from './EmployeesList';
import Error from 'routes/Error';
import InvitedEmployeesList from './InvitedEmployeesList';
import ArchivedEmployeesList from './ArchivedEmployeesList';
import RequestedList from './RequestedList';
import { IModalProps, useModalContext } from 'context/ModalProvider';
import InviteEmployeeModalContent from './InviteEmployeeModalContent';

const Container = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    overflow: auto;
  }
`;

const AddIcon = styled(Add)`
  margin-right: 0.25rem;
`;

const HeadingContainer = styled(Flex)<{ hasNoBorder?: boolean }>`
  border-bottom: ${({ hasNoBorder }) =>
    hasNoBorder ? 'none' : '1px solid rgba(0, 0, 0, 0.1)'};
  min-width: 0;
  margin-bottom: ${({ theme }) => theme.space.small};
`;

const HeadingContent = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: ${({ theme }) => theme.space.large};
    flex-direction: column;
    align-items: stretch;
  }
`;

const InviteEmployeesButton = styled(Button)`
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
  }
`;

const ManageEmployees = () => {
  const { t } = useTranslation();
  const { REQUESTED_ACCESS_FLAG } = useFeatureFlags();
  const { openModal, closeModal } = useModalContext();

  const secondaryNavigation: SecondaryNavItem[] = [
    {
      text: t('mybusiness.employees.active'),
      path: `/mybusiness/company/employees`,
      ref: 'employees',
    },
    {
      text: t('mybusiness.employees.invited.label'),
      path: `/mybusiness/company/employees/invited`,
      ref: 'invited',
    },
    {
      text: t('mybusiness.employees.archived.label'),
      path: `/mybusiness/company/employees/archived`,
      ref: 'archived',
    },
    {
      ...(REQUESTED_ACCESS_FLAG && {
        text: t('mybusiness.requested.header'),
        path: `/mybusiness/company/employees/requested`,
        ref: 'requested',
      }),
    } as SecondaryNavItem,
  ];

  const EmployeesRoutes = () => {
    return (
      <Routes>
        <Route path='/' element={<EmployeesList />} />
        <Route
          path='/invited'
          element={
            <div data-test-employees-invited>
              <Titled
                title={`${t('mybusiness.employees.pageTitle')} - ${t(
                  'mybusiness.employees.invited.label'
                )}`}
              />
              <InvitedEmployeesList />
            </div>
          }
        />
        <Route
          path='/archived'
          element={
            <div data-test-employees-archived>
              <Titled
                title={`${t('mybusiness.employees.pageTitle')} - ${t(
                  'mybusiness.employees.archived.label'
                )}`}
              />
              <ArchivedEmployeesList />
            </div>
          }></Route>
        {REQUESTED_ACCESS_FLAG && (
          <Route
            path='/requested'
            element={
              <div data-test-employees-requested>
                <Titled
                  title={`${t('mybusiness.employees.pageTitle')} - ${t(
                    'mybusiness.requested.header'
                  )}`}
                />
                <RequestedList />
              </div>
            }
          />
        )}
        <Route path='*' element={<Error />} />
      </Routes>
    );
  };

  return (
    <Titled title={t('mybusiness.employees.pageTitle')}>
      <HeadingContainer
        flexDirection='column'
        justifyContent='space-between'
        paddingX={['medium', 'medium', 'medium', 'large']}
        data-test-right-panel-header>
        <HeadingContent
          alignItems='center'
          justifyContent='space-between'
          paddingTop='medium'
          paddingRight={[0, 0, 0, 0]}>
          <Flex flexDirection='column'>
            <h1>{t('mybusiness.employees.pageTitle')}</h1>
          </Flex>
          <Flex>
            <InviteEmployeesButton
              data-test-employees-invite
              onClick={() => {
                openModal({
                  title: t('mybusiness.employees.invite'),
                  children: (
                    <InviteEmployeeModalContent closeModal={closeModal} />
                  ),
                  testAttribute: { 'data-test-employee-invite-modal': true },
                } as IModalProps);
              }}
              variant='primary'
              width={[1, 'auto']}>
              <Flex alignItems='center' justifyContent='center'>
                <AddIcon />
                {t('mybusiness.employees.invite')}
              </Flex>
            </InviteEmployeesButton>
          </Flex>
        </HeadingContent>
        <SecondaryNavigation
          navItems={secondaryNavigation}
          linkRenderer={({ text, path }) => {
            return (
              <NavLink to={path} end>
                {text}
              </NavLink>
            );
          }}
          displayedRef={window.location.pathname.split('/').pop()}
        />
      </HeadingContainer>
      <Container>
        <Page
          as='main'
          paddingY='medium'
          paddingX={[0, 0, 'medium', 'large']}
          maxWidth='1'
          width={1}
          data-test-employees>
          <Suspense fallback={<TableLoading />}>
            <EmployeesRoutes />
          </Suspense>
        </Page>
      </Container>
    </Titled>
  );
};

export default ManageEmployees;
