import React, { FC } from 'react';
import {
  Box,
  Flex,
  Heading,
  Label,
  SolidCheckbox,
  RectangularButton,
} from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { Titled } from 'components/common/Titled';
import styled from 'styled-components';

import ResideoProLogo from 'components/logo/resideo-pro-logo.svg?react';
import TermsAndConditions from '../../components/terms';
import { TermsInterface } from '../../interfaces/TermsInterface';

const StyledResideoProLogo = styled(ResideoProLogo)`
  fill: 'black';
  height: 28px;
`;

const TermsContainer = styled(Flex)`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: ${({ theme }) => theme.lineHeights.body};
  margin-bottom: ${({ theme }) => theme.space.large};
  margin-left: ${({ theme }) => theme.space.xxxLarge};
  margin-right: ${({ theme }) => theme.space.xxxLarge};
  overflow-y: scroll;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
  ::-webkit-scrollbar {
    appearance: none;
    background-color: rgba(0, 0, 0, 0.25);
    width: 0.5rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const TermsHeading = styled(Heading)`
  text-transform: uppercase;
`;

export interface TermsProps {
  termsDoc: TermsInterface;
}

const Terms: FC<TermsProps> = ({ termsDoc }): JSX.Element => {
  const { t } = useTranslation();

  const acceptTerms = () => ({});
  const declineTerms = () => ({});

  return (
    <Titled title={t('accessRequest.terms.title')}>
      <Box height='100%' data-test-access-terms>
        <Flex
          flexDirection='column'
          alignItems='center'
          backgroundColor='white'
          paddingTop='large'
          paddingBottom='xxLarge'>
          <Flex justifyContent='center' data-test-access-terms-logo>
            <StyledResideoProLogo />
          </Flex>
          <Flex flexDirection='column'>
            <TermsHeading
              textAlign='center'
              fontSize='xxxLarge'
              marginBottom='large'
              paddingTop='large'
              data-test-access-terms-header>
              {t('accessRequest.terms.heading')}
            </TermsHeading>
            <TermsContainer flexDirection='column' backgroundColor='separator'>
              <TermsAndConditions termsDoc={termsDoc} />

              <Flex
                flexDirection='column'
                paddingX='large'
                marginBottom='xLarge'>
                <Flex marginBottom='medium'>
                  <Label>
                    <SolidCheckbox name='readAndAccept' />
                    <Box fontStyle='italic' data-test-access-terms-checkbox>
                      {t('accessRequest.terms.checkbox')}
                    </Box>
                  </Label>
                </Flex>
                <Flex flexDirection='row' justifyContent='space-between'>
                  <RectangularButton
                    type='submit'
                    width={[1, 1 / 4]}
                    onClick={() => {
                      declineTerms();
                    }}
                    data-test-access-terms-decline-button>
                    {t('common.decline')}
                  </RectangularButton>
                  <RectangularButton
                    variant='primary'
                    type='submit'
                    width={[1, 1 / 4]}
                    onClick={() => {
                      acceptTerms();
                    }}
                    data-test-access-terms-agree-button>
                    {t('common.agree')}
                  </RectangularButton>
                </Flex>
              </Flex>
            </TermsContainer>
          </Flex>
        </Flex>
      </Box>
    </Titled>
  );
};

export default Terms;
