const ZoningProducts = t => [
  {
    text: t('hvacAndWater.zoningProducts.ard'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/ARD - Honeywell Home.zip?sp=r&st=2021-10-14T15:03:54Z&se=2221-10-14T23:03:54Z&spr=https&sv=2020-08-04&sr=b&sig=CUeofBzcdUMCFewDsg6vYbebdMJDq6ZpuvAAC97zHrg%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.zoningProducts.descriptions.auto'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.zoningProducts.cprd'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/CPRD - Honeywell Home.zip?sp=r&st=2021-10-14T15:05:23Z&se=2221-10-14T23:05:23Z&spr=https&sv=2020-08-04&sr=b&sig=f80RYllyLWZ9sKH2zLHub7bbnUl1Lrx%2FXLSwj3FuP8s%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.zoningProducts.descriptions.bypass'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.zoningProducts.hz221.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HZ221 - Honeywell Home.zip?sp=rl&st=2021-03-04T20:54:34Z&se=2129-07-13T20:54:00Z&sv=2020-02-10&sr=b&sig=hirglRnEO1k%2FSF2nqIANRfF%2B%2FNLheG6BlsdSgO9hjrs%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.zoningProducts.hz221.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.zoningProducts.hz311.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HZ311 - Honeywell Home.zip?sp=rl&st=2021-03-04T20:54:57Z&se=2138-10-08T20:54:00Z&sv=2020-02-10&sr=b&sig=I44TTD8Jx0pd9IkpOOPU0gCzOaleZfxSFsLC9MHc4B4%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.zoningProducts.hz311.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.zoningProducts.hz322.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HZ322 - Honeywell Home.zip?sp=rl&st=2021-03-04T20:55:14Z&se=2139-10-14T20:55:00Z&sv=2020-02-10&sr=b&sig=eQ%2Bc8gxvV4oM0epSl1Zkxw75BHqYSgAAgwOQWUwPWPg%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.zoningProducts.hz322.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.zoningProducts.hz432.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HZ432 - Honeywell Home.zip?sp=rl&st=2021-03-04T20:55:34Z&se=2243-06-14T20:55:00Z&sv=2020-02-10&sr=b&sig=sJOxVwvDuSDfrpcSUf1PFP23AC1xCnTW8z4%2FAAEqyqs%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.zoningProducts.hz432.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
];

export default ZoningProducts;
