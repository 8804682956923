import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Card, Flex, Heading, Box } from '@resideo/blueprint-react';
import ResideoProLogo from 'components/logo/resideo-pro-logo.svg?react';
import ResideoLogo from 'components/logo/resideo-logo.svg?react';
import NoticeLayoutFooter, {
  CustomBlocksType,
  SharedNoticeLayoutFooterProps as FooterProps,
} from './NoticeLayoutFooter';

type LogoVersions = 'resideo' | 'resideopro' | 'nologo';
export interface NoticeLayoutProps extends FooterProps {
  title?: string;
  logoVersion?: LogoVersions;
  customFooterBlocks?: CustomBlocksType;
  children?: any;
}

const StyledLogo = css`
  height: ${props => props.theme.fontSizes.xxLarge};
  margin-bottom: ${props => props.theme.space.large};
`;

const StyledResideoProLogo = styled(ResideoProLogo)`
  ${StyledLogo}
`;

const StyledResideoLogo = styled(ResideoLogo)`
  ${StyledLogo}
`;

const StyledTitle = styled(Heading)`
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.xxxLarge};
  margin-bottom: ${props => props.theme.space.medium};
  white-space: pre-line;
  text-align: center;
`;

const NoticeLayout: FC<NoticeLayoutProps> = ({
  title,
  logoVersion = 'resideo',
  customFooterBlocks,
  children,
  ...rest
}): JSX.Element => {
  const Logos = {
    resideo: StyledResideoLogo,
    resideopro: StyledResideoProLogo,
    nologo: null,
  };

  const LogoComponent = Logos[logoVersion];
  return (
    <Card height='100%' padding={['medium', 'large']} {...rest}>
      <Flex
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        marginBottom='medium'>
        {LogoComponent && <LogoComponent />}
        {title && (
          <Box marginBottom='medium'>
            <StyledTitle>{title}</StyledTitle>
          </Box>
        )}
        {children}
      </Flex>
      {customFooterBlocks && (
        <NoticeLayoutFooter
          {...(customFooterBlocks
            ? { customFooterBlocks: customFooterBlocks }
            : {})}
        />
      )}
    </Card>
  );
};

export default NoticeLayout;
