const ForceAirZoning = t => [
  {
    text: t('hvacAndWater.forceAirZoning.hz221.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HZ221 - Honeywell Home.zip?sp=r&st=2021-10-14T15:16:58Z&se=2221-10-14T23:16:58Z&spr=https&sv=2020-08-04&sr=b&sig=k%2BH0HwRedwYP0d6tUhFKdAC1B%2BQjnx79wPIZjneDPs8%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.forceAirZoning.hz221.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.forceAirZoning.hz311.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HZ311 - Honeywell Home.zip?sp=rl&st=2021-03-04T20:54:57Z&se=2138-10-08T20:54:00Z&sv=2020-02-10&sr=b&sig=I44TTD8Jx0pd9IkpOOPU0gCzOaleZfxSFsLC9MHc4B4%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.forceAirZoning.hz311.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.forceAirZoning.hz322.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HZ322 - Honeywell Home.zip?sp=rl&st=2021-03-04T20:55:14Z&se=2139-10-14T20:55:00Z&sv=2020-02-10&sr=b&sig=eQ%2Bc8gxvV4oM0epSl1Zkxw75BHqYSgAAgwOQWUwPWPg%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.forceAirZoning.hz322.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
  {
    text: t('hvacAndWater.forceAirZoning.hz432.text'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/RTS Product Asset Library/HZ432 - Honeywell Home.zip?sp=rl&st=2021-03-04T20:55:34Z&se=2243-06-14T20:55:00Z&sv=2020-02-10&sr=b&sig=sJOxVwvDuSDfrpcSUf1PFP23AC1xCnTW8z4%2FAAEqyqs%3D',
    ext: t('proseries.ext.zip'),
    desc: t('hvacAndWater.forceAirZoning.hz432.desc'),
    brand: t('hvacAndWater.brands.honeywell'),
  },
];

export default ForceAirZoning;
