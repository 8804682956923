import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  Text,
  Label,
  SolidCheckbox,
  useToastContext,
  DragandDrop,
  Breadcrumbs,
  Box,
  BrandProfilePreview,
} from '@resideo/blueprint-react';
import TermsAndConditions from 'components/terms';
import TermsAPPCOBRANDING from 'components/terms/doc/termsAPPCOBRANDING';
import { Formik, Form, FormikErrors } from 'formik';
import { imkLogoFolder, imkPublicKey, imkUrlEndpoint } from '../../../config';
import { upload } from 'components/common/ImageUpload';
import { BrandApprovalFormValues } from '../interfaces';
import CustomerServiceInformationFields, {
  validateCustomerServiceInformationFields,
} from './CustomerServiceInformationFormFields';
import { IModalProps, useModalContext } from 'context/ModalProvider';
import {
  useNavigate,
  unstable_useBlocker as useBlocker,
  Link,
} from 'react-router-dom';
import { useRisClient } from 'hooks/useRisClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PartnerBrandProfile } from '../../../utils/common';
import { useBrandProfileContext } from 'routes/MyBusiness/ManageBrandProfile';
import { UpdatePartnerBrandProfileModel } from '@resideo/web-integration-services-api-client/dist';
import { urlEnsureHttps } from 'utils/validation';
import { useCurrentUser } from 'context/CurrentUser';
import { BRAND_PROFILE_X12_TAB } from 'config/flags';

const TextWrapper = styled.div`
  margin: 10px 0 0 0;
`;

enum ModalStates {
  'terms',
  'declined',
  'help',
  'accepted',
}

const sectionTitleStyles = {
  fontWeight: 'bold',
  fontSize: 'large',
  marginBottom: 'medium',
};

const DragAndDropComponent = styled.div`
  height: 328px;
`;

const TermsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.card};
  font-size: ${({ theme }) => theme.fontSizes.small};
  height: 35vh;
  line-height: ${({ theme }) => theme.lineHeights.body};
  overflow-y: scroll;
  padding-bottom: ${({ theme }) => theme.space.large};
  padding-left: ${({ theme }) => theme.space.medium};
  padding-right: ${({ theme }) => theme.space.large};
  padding-top: ${({ theme }) => theme.space.small};
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
  ::-webkit-scrollbar {
    appearance: none;
    background-color: rgba(0, 0, 0, 0.25);
    width: 0.5rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const SubmitPreviewButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
`;

const StyledBrandProfilePreview = styled(BrandProfilePreview)<{
  $showHeader: boolean;
}>`
  ul {
    box-shadow: ${props =>
      props.$showHeader ? '0 10px 6px -6px #ccc' : 'none'};
  }

  li {
    margin-left: ${props => (props.$showHeader ? '30px' : 'none')};
  }

  svg {
    margin-left: ${props => (props.$showHeader ? '30px' : 'none')};
  }
`;

const Header = styled.div`
  padding-top: 5px;
  margin-left: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
    margin-left: 1rem;
  }
`;

const HeaderSection = styled(Flex)`
  margin-left: 30px;
  margin-right: 30px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-top: 1rem;
    display: block;
    margin-left: 1rem;
  }
`;

const TitleText = styled.h1`
  margin-bottom: 0;
`;

const HeaderButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const BrandProfileForm = ({
  partnerAccountId,
  partnerBrandProfile,
  setLoading,
  loading,
}: {
  partnerAccountId: string | undefined | null;
  partnerBrandProfile: PartnerBrandProfile | undefined | null;
  setLoading: (boolean) => any;
  loading: boolean;
}) => {
  const { t } = useTranslation();

  const { addToast } = useToastContext();
  const [modalState, setModalState] = useState<ModalStates>(ModalStates.terms);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [error, setError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [modalClosed, setModalClosed] = useState(false);
  const { openModal, closeModal, setModalProps } = useModalContext();
  const queryClient = useQueryClient();
  const [touchedLogoField, setTouchedLogoField] = useState(false);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const { client } = useRisClient();
  const { partnerAccount } = useCurrentUser();

  const [initialValues, setInitialValues] = useState({
    logo: '',
    customerServiceNumber: '',
    businessEmail: '',
    websiteUrl: '',
    fullName: '',
    contactPhone: '',
    email: '',
  });
  const navigate = useNavigate();

  const DragandDropTranslations = {
    draganddropText: t(
      'mybusiness.services.coBranding.DragandDrop.draganddropText'
    ),
    fileRecommendations: t(
      'mybusiness.services.coBranding.DragandDrop.fileRecommendations'
    ),
    supportedFiles: t(
      'mybusiness.services.coBranding.DragandDrop.supportedFiles'
    ),
    logoInfo: t('mybusiness.services.coBranding.DragandDrop.logoInfo'),
    browseFiles: t('mybusiness.services.coBranding.DragandDrop.browseFiles'),
    downloadLogo: t('mybusiness.services.coBranding.DragandDrop.downloadLogo'),
    removeLogo: t('mybusiness.services.coBranding.DragandDrop.removeLogo'),
    uploadFailed: t('mybusiness.services.coBranding.DragandDrop.uploadFailed'),
    fileRestrictions: t(
      'mybusiness.services.coBranding.DragandDrop.fileRestrictions'
    ),
    clear: t('mybusiness.services.coBranding.DragandDrop.clear'),
    invalidFile: t('mybusiness.services.coBranding.DragandDrop.invalidFile'),
    companyLogoRequired: t(
      'mybusiness.services.coBranding.DragandDrop.companyLogoRequired'
    ),
  };

  // re-route to Services & Subscriptions dashboard if terms aren't accepted & modal is closed
  const handleModalCloseReroute = () => {
    window.location.href =
      'https://www.resideo.com/us/en/pro/services-subscriptions';
  };

  useEffect(() => {
    if (modalClosed && modalState !== ModalStates.accepted) {
      handleModalCloseReroute();
    }
  }, [modalState, modalClosed]);

  useEffect(() => {
    // Need to re-render the terms modal when toggling the accept checkbox
    // so that the button gets enabled/disabled properly
    setModalProps(modalProps(ModalStates.terms));
  }, [termsAccepted, setModalProps]);

  useEffect(() => {
    if (partnerBrandProfile) {
      setInitialValues(prev => ({
        ...prev,
        customerServiceNumber: partnerBrandProfile.customerServiceNumber ?? '',
        businessEmail: partnerBrandProfile.businessEmail ?? '',
        websiteUrl: partnerBrandProfile.websiteUrl ?? '',
      }));
      setLogoUrl(partnerBrandProfile.logo ?? '');
      setModalState(ModalStates.accepted);
    } else {
      openModal(modalProps(ModalStates.terms));
    }
  }, [partnerBrandProfile]);

  const [isNavBlocked, setIsNavigationBlocked] = useState(false);
  const blocker = useBlocker(isNavBlocked);
  const [previewMode, setPreviewMode] = useState(false);
  const {
    setShowHeader: headerVisible,
    prevPage: prevPage,
  } = useBrandProfileContext();

  useEffect(() => {
    if (headerVisible) {
      headerVisible(!previewMode);
    }
  }, [previewMode]);

  useEffect(() => {
    if (blocker?.state === 'blocked') {
      openModal({
        title: t('mybusiness.services.coBranding.leaveModal.title'),
        children: (
          <>
            <Text data-test-leave-page-modal-text marginBottom='small'>
              {t('mybusiness.services.coBranding.leaveModal.text')}
            </Text>
            <Flex
              flexDirection='row'
              justifyContent='flex-end'
              paddingTop='30px'>
              <Button
                data-test-leave-page-modal-no-button
                variant='secondary'
                onClick={() => {
                  closeModal();
                }}
                marginRight='large'>
                {t('common.no')}
              </Button>
              <Button
                data-test-leave-page-modal-yes-button
                variant='primary'
                onClick={() => {
                  blocker.reset?.();
                  closeModal();
                  navigate(blocker?.location?.pathname ?? '');
                }}>
                {t('common.yes')}
              </Button>
            </Flex>
          </>
        ),
      });
    }
  }, [blocker]);

  const modalProps = version => {
    const states = {
      [ModalStates.terms]: {
        title: t('terms.pageTitle'),
        onModalClose: () => {
          setModalClosed(true);
        },
        children: (
          <>
            <Flex marginBottom='large'>
              <TermsContainer>
                <TermsAndConditions termsDoc={TermsAPPCOBRANDING} />
              </TermsContainer>
            </Flex>
            <Flex flexDirection='column'>
              <Flex marginBottom='large'>
                <Label data-test-brand-profile-terms-modal-accept-checkbox>
                  <SolidCheckbox
                    type='checkbox'
                    name='readAndAccept'
                    onChange={() => {
                      setTermsAccepted(prev => !prev);
                    }}
                    checked={termsAccepted}
                  />
                  <Text fontStyle='italic' fontSize='small'>
                    {t('terms.homeOwnerApp.checkbox')}
                  </Text>
                </Label>
              </Flex>
              <Flex flexDirection='row' justifyContent='flex-end'>
                <Button
                  data-test-brand-profile-terms-modal-decline-btn
                  onClick={() => {
                    setModalProps(modalProps(ModalStates.declined));
                  }}
                  marginRight='large'>
                  {t('common.decline')}
                </Button>
                <Button
                  disabled={!termsAccepted}
                  variant='primary'
                  data-test-brand-profile-terms-modal-accept-btn
                  onClick={() => {
                    setModalState(ModalStates.accepted);
                    closeModal();
                  }}>
                  {t('common.accept')}
                </Button>
              </Flex>
            </Flex>
          </>
        ),
      } as IModalProps,
      [ModalStates.declined]: {
        title: 'Declined',
        onModalClose: () => {
          setModalClosed(true);
        },
        children: (
          <>
            <Text data-test-brand-profile-terms-modal-decline-warning>
              {t('terms.homeOwnerApp.declineMsg')}
            </Text>
            <Flex
              flexDirection='row'
              justifyContent='flex-end'
              paddingTop='large'>
              <Button
                data-test-brand-profile-terms-modal-decline-back-btn
                onClick={() => setModalProps(modalProps(ModalStates.terms))}>
                {t('common.back')}
              </Button>
            </Flex>
          </>
        ),
      },
    };
    return states[version];
  };

  const onError = (msg?: string) => {
    addToast({
      toastType: 'Error',
      message: msg,
    });
  };

  const onUploadSuccess = uploadResult => {
    setIsUploading(false);
    setLogoUrl(uploadResult.url);
  };

  const uploadImageMutation = useMutation({
    mutationFn: async () => {
      return client.myProUsers.myProUsersGetImagekitToken();
    },
    onError: () => {
      setError(true);
      setIsUploading(false);
    },
    onSuccess: response => {
      if (response?.data) {
        const { token, signature, expire } = response?.data || {};
        const options = {
          file,
          fileName: fileName,
          publicKey: imkPublicKey,
          urlEndpoint: imkUrlEndpoint,
          token,
          signature,
          expire: expire,
          useUniqueFileName: true,
          tags: [],
          folder: imkLogoFolder,
          isPrivateFile: false,
          customCoordinates: '',
          responseFields: [],
        };
        if (file && fileName) {
          upload(options, (err, result) => {
            setIsUploading(false);
            if (err) {
              setError(true);
              addToast({
                toastType: 'Error',
                message: t('common.toast.error'),
              });
            } else {
              onUploadSuccess(result);
            }
          });
        } else {
          setError(true);
          setIsUploading(false);
        }
      }
    },
  });

  const uploadLogo = acceptedFiles => {
    if (acceptedFiles) {
      setFile(acceptedFiles[0]);
      setFileName(acceptedFiles[0]?.name);
      setError(false);
      setIsUploading(true);
      uploadImageMutation.mutateAsync();
    }
  };

  const brandProfileApplicationCreate = useMutation({
    mutationFn: async (vars: {
      partnerAccountId;
      websiteUrl;
      logo;
      customerServiceNumber;
      businessEmail;
    }) => {
      return client.myProAccounts.myProAccountsCreatePartnerBrandProfile(
        partnerAccountId as string,
        {
          websiteUrl: vars.websiteUrl,
          logo: vars.logo,
          customerServiceNumber: vars.customerServiceNumber,
          businessEmail: vars.businessEmail,
        }
      );
    },
    onError: error => {
      onError(error?.message);
    },
    onSuccess: async () => {
      setPreviewMode(false);

      await queryClient.invalidateQueries({ queryKey: ['viewer'] });
      setLoading(false);
      addToast({
        toastType: 'Success',
        message: t('mybusiness.services.brandProfile.toast.success'),
      });
      navigate('/mybusiness/services/brand-profile/view');
    },
  });

  const brandProfileApplicationUpdate = useMutation({
    mutationFn: async (vars: {
      partnerBrandProfile: UpdatePartnerBrandProfileModel;
      partnerAccountId;
    }) => {
      return client.myProAccounts.myProAccountsUpdateMyPartnerAccount(
        partnerAccountId as string,
        {
          partnerBrandProfile: {
            websiteUrl: vars.partnerBrandProfile.websiteUrl,
            logo: vars.partnerBrandProfile.logo,
            customerServiceNumber:
              vars.partnerBrandProfile.customerServiceNumber,
            businessEmail: vars.partnerBrandProfile.businessEmail,
          },
        }
      );
    },
    onError: error => {
      onError(error?.message);
    },
    onSuccess: () => {
      setPreviewMode(false);

      setLoading(false);
      addToast({
        toastType: 'Success',
        message: t('mybusiness.services.brandProfile.toast.success'),
      });
      setPreviewMode(false);

      navigate('/mybusiness/services/brand-profile/view');
      queryClient.invalidateQueries({ queryKey: ['viewer'] });
    },
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    const { websiteUrl, customerServiceNumber, businessEmail } = values;
    if (!partnerBrandProfile) {
      brandProfileApplicationCreate.mutate({
        websiteUrl: websiteUrl ? urlEnsureHttps(websiteUrl) : '',
        logo: logoUrl,
        customerServiceNumber: customerServiceNumber,
        businessEmail: businessEmail,

        partnerAccountId: partnerAccountId,
      });
    } else {
      brandProfileApplicationUpdate.mutate({
        partnerBrandProfile: {
          websiteUrl: websiteUrl ? urlEnsureHttps(websiteUrl) : '',
          logo: logoUrl,
          customerServiceNumber: customerServiceNumber,
          businessEmail: businessEmail,
        },
        partnerAccountId: partnerAccountId,
      });
    }
  };

  const validate = (values: BrandApprovalFormValues) => {
    const errors: FormikErrors<BrandApprovalFormValues> = {};

    if (!logoUrl)
      errors.logo = t('mybusiness.services.coBranding.validation.logo');

    const customerServiceInfoErrors: FormikErrors<BrandApprovalFormValues> = validateCustomerServiceInformationFields(
      values,
      t
    );
    const compiledErrors = { ...errors, ...customerServiceInfoErrors };

    return compiledErrors;
  };

  return (
    <>
      {modalState === ModalStates.accepted && (
        <Formik
          enableReinitialize={true}
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validate}>
          {({ isValid, dirty, values }) => {
            // if you don't have a brand profile set up show the leaving page modal
            // if you have filled out any of the form or uploaded in a logo
            if (!partnerBrandProfile?.status) {
              setIsNavigationBlocked(dirty || logoUrl !== null);
            } else {
              setIsNavigationBlocked(dirty || !logoUrl);
            }

            return (
              <Form noValidate>
                {previewMode ? (
                  <>
                    <Box>
                      <Header>
                        <Breadcrumbs>
                          <>
                            {prevPage ? (
                              <Link
                                data-test-dynamic-breadcrumb-link
                                to={prevPage.url}>
                                {prevPage.displayName}
                              </Link>
                            ) : (
                              <Link
                                data-test-breadcrumbs-business-intelligence
                                to='/mybusiness/business-intelligence'>
                                {t(
                                  'mybusiness.services.brandProfile.header.businessIntelligence'
                                )}
                              </Link>
                            )}
                            <Link
                              data-test-breadcrumbs-view-brand-profile
                              to='#'>
                              {t(
                                'mybusiness.services.brandProfile.header.viewBrandProfile'
                              )}
                            </Link>
                          </>
                        </Breadcrumbs>
                        <TitleText>
                          {t('mybusiness.services.coBranding.previewTitle')}
                        </TitleText>
                      </Header>
                      <HeaderSection>
                        <Text
                          style={{ marginTop: '-10px' }}
                          data-test-brand-profile-subtitle>
                          {t(
                            'mybusiness.services.brandProfile.header.subtitlePreview'
                          )}
                        </Text>
                        <HeaderButtonContainer>
                          <Button
                            data-test-brand-profile-back-btn
                            variant='secondary'
                            type='button'
                            onClick={() => {
                              setPreviewMode(false);
                            }}>
                            {t('common.back')}
                          </Button>
                          <Button
                            data-test-brand-profile-publish-btn
                            variant='primary'
                            type='submit'>
                            {t(
                              'mybusiness.services.brandProfile.buttons.publish'
                            )}
                          </Button>
                        </HeaderButtonContainer>
                      </HeaderSection>
                      <StyledBrandProfilePreview
                        $showHeader={previewMode}
                        logo={logoUrl}
                        companyName={partnerAccount?.name ?? ''}
                        phone={values.customerServiceNumber}
                        email={values.businessEmail}
                        url={values.websiteUrl}
                        translationFunction={t}
                        showX12={BRAND_PROFILE_X12_TAB}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Text
                      data-test-brand-profile-step1-header
                      {...sectionTitleStyles}>
                      {t('mybusiness.services.brandProfile.titles.step1')}
                    </Text>
                    <DragAndDropComponent
                      data-test-brand-profile-step1-logoupload
                      id='NoLogoContainer'>
                      <DragandDrop
                        uploadImage={file => {
                          setFile(file);
                          uploadLogo(file);
                          setTouchedLogoField(true);
                        }}
                        image={logoUrl}
                        setImage={() => {
                          setLogoUrl('');
                        }}
                        error={error}
                        isUploading={isUploading}
                        clearError={() => setError(false)}
                        translations={DragandDropTranslations}
                      />
                    </DragAndDropComponent>
                    <TextWrapper>
                      <Text
                        data-test-brand-profile-step2-header
                        {...sectionTitleStyles}>
                        {t('mybusiness.services.brandProfile.titles.step2')}
                      </Text>
                    </TextWrapper>
                    <Text fontStyle='italic' marginBottom='medium'>
                      {t('mybusiness.services.coBranding.required')}
                    </Text>
                    <CustomerServiceInformationFields />
                    <ButtonContainer>
                      <SubmitPreviewButtons>
                        <Button
                          data-test-brand-profile-preview-btn
                          type='button'
                          variant={'secondary'}
                          disabled={
                            logoUrl
                              ? (!dirty && !touchedLogoField) || !isValid
                              : true
                          }
                          onClick={() => {
                            setPreviewMode(true);
                          }}>
                          {t('common.preview')}
                        </Button>
                        <Button
                          data-test-brand-profile-submit-btn
                          type='submit'
                          disabled={
                            logoUrl
                              ? (!dirty && !touchedLogoField) ||
                                !isValid ||
                                loading
                              : true
                          }>
                          {t(
                            'mybusiness.services.brandProfile.buttons.publish'
                          )}
                        </Button>
                      </SubmitPreviewButtons>
                    </ButtonContainer>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default BrandProfileForm;
