import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MockAuth0Provider } from '../utils/auth/MockAuth0Provider';
import { useAuth0, Auth0Provider } from '@auth0/auth0-react';
import config, { ENABLE_MOCKS, RESIDEO_ID_URL, IDLE_TIMEOUT } from '../config';
import { useIdleTimer } from 'hooks/useIdleTimer';

const IdleLogout = ({ children }) => {
  const { logout } = useAuth0();

  useIdleTimer(IDLE_TIMEOUT, () => {
    logout({ returnTo: window.location.origin });
  });
  return children;
};

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const onRedirectCallback = appState => {
    navigate(appState?.returnTo || window.location.pathname, { replace: true });
  };

  if (ENABLE_MOCKS) {
    return <MockAuth0Provider>{children}</MockAuth0Provider>;
  } else {
    return (
      <Auth0Provider
        audience={config.audience}
        domain={config.domain}
        clientId={config.clientId}
        onRedirectCallback={onRedirectCallback}
        redirectUri={window.location.origin}
        scope={config.scope}
        useRefreshTokens={true}
        signUpUrl={`${RESIDEO_ID_URL}/sign-up?userType=partner`}>
        <IdleLogout>{children}</IdleLogout>
      </Auth0Provider>
    );
  }
};

export default AuthProvider;
