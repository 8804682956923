import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button, Link } from '@resideo/blueprint-react';
import { iQSupportEmail } from 'config/emailAddresses';
import { ButtonContainer, ModalContent } from '../../../customers.style';

export const ReactivateServiceModal = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <Text marginBottom='large'>
        {t('customerDetails.reactivateService.text')}
        <Link
          href='#'
          onClick={e => {
            e.preventDefault();
            window.location.href = `mailto:${iQSupportEmail}`;
          }}>
          {iQSupportEmail}
        </Link>
        {t('customerDetails.serviceRequestText')}
      </Text>
      <ButtonContainer>
        <Button variant='secondary' onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button variant='primary' onClick={onSubmit}>
          {t('customerDetails.emailSupport')}
        </Button>
      </ButtonContainer>
    </ModalContent>
  );
};
