const GroupImages = t => [
  {
    text: t('proSeries.groupImages.consumerLandscapeFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/L_PSPSCLFI_D ProSeries_Consumer Graphic_landscape (French).pdf?sp=rl&st=2020-11-02T20:27:38Z&se=2140-10-10T20:27:00Z&sv=2019-12-12&sr=b&sig=SZtToK5mOT4z2Px5JBH0FctiqH5HoPhtb60aHmXx6K0%3D',
    ext: t('proSeries.ext.pdf'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.consumerLandscapeEN.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/L_PSPSCLI_D ProSeries_Consumer Graphic_landscape.pdf?sp=rl&st=2020-11-02T20:28:01Z&se=2142-06-12T20:28:00Z&sv=2019-12-12&sr=b&sig=8sv9WhQXkJ%2BiSBLgLLym%2B3xQMSxwTFANfjL46gnrMUc%3D',
    ext: t('proSeries.ext.pdf'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.consumerLandscapeES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/L_PSPSCLSI_D ProSeries Product Spread Consumer Landscape ESLA_.pdf?sp=r&st=2021-10-14T18:58:14Z&se=2221-10-15T02:58:14Z&spr=https&sv=2020-08-04&sr=b&sig=mpbddoge2aWN1iUVPdu6N3axE%2BWZNYmy2mB%2FIGrH9fM%3D',
    ext: t('proSeries.ext.pdf'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.consumerPortraitFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/L_PSPSCPFI_D ProSeries_Consumer Graphic_portrait (French).pdf?sp=rl&st=2020-11-02T20:28:26Z&se=2137-07-17T20:28:00Z&sv=2019-12-12&sr=b&sig=TwEQTZJq5nPUWrJq1l2EN%2F%2F2huMK1pOVttTK5uKbS88%3D',
    ext: t('proSeries.ext.pdf'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.consumerPortraitEN.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/L_PSPSCPI_D ProSeries_Consumer Graphic_portrait.pdf?sp=rl&st=2020-11-02T20:28:43Z&se=2144-07-03T20:28:00Z&sv=2019-12-12&sr=b&sig=3z5kfGTxrhRDeiEbSyIhpT6GViEkuQRz3Rt%2BwB5IwTk%3D',
    ext: t('proSeries.ext.pdf'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.consumerPortraitES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/L_PSPSCPSI_D ProSeries_ProductSpread_Consumer_Portrait_ESLA.pdf?sp=r&st=2021-10-14T18:58:36Z&se=2221-10-15T02:58:36Z&spr=https&sv=2020-08-04&sr=b&sig=oN0PmjdRyW5dOjqFAA2UF4W670nYYDcJE%2B5a%2BCc7%2FFg%3D',
    ext: t('proSeries.ext.pdf'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.dealerLandscapeFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/L_PSPSDLFI_D ProSeries_ Dealer Graphic _landscape (French).pdf?sp=rl&st=2020-11-02T20:29:06Z&se=2149-06-18T20:29:00Z&sv=2019-12-12&sr=b&sig=v5F32%2BGnXwE%2BOpQRATj1sFOYfi7Ws9PKhZM9xIpuxtw%3D',
    ext: t('proSeries.ext.pdf'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.dealerLandscapeEN.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/L_PSPSDLI_D ProSeries_Dealer Graphic_landscape.pdf?sp=rl&st=2020-11-02T20:29:24Z&se=2137-10-21T20:29:00Z&sv=2019-12-12&sr=b&sig=%2Bwmx6VpR2Ci2KhKRn6OLvmkGG5piTa8bMId7fVJVMvk%3D',
    ext: t('proSeries.ext.pdf'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.dealerLandscapeES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/L_PSPSDLSI_D ProSeries Product Spread Dealer Landscape Image.png?sp=r&st=2021-10-14T18:59:06Z&se=2221-10-15T02:59:06Z&spr=https&sv=2020-08-04&sr=b&sig=b67YE43jziEszXLTJEkq0FjK4o1BbLjFxjfN6n2Q1DQ%3D',
    ext: t('proSeries.ext.pdf'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.dealerPortraitFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/L_PSPSDPFI_D ProSeries Dealer Graphic_portrait (French).pdf?sp=rl&st=2020-11-02T20:29:44Z&se=2137-10-15T20:29:00Z&sv=2019-12-12&sr=b&sig=nezTtmwPFBrCXGtgkLPmwXhRqio8AWnbtaExzIA1Z%2FM%3D',
    ext: t('proSeries.ext.pdf'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.dealerPortraitEN.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/L_PSPSDPI_D ProSeries_Dealer Graphic_portrait.pdf?sp=rl&st=2020-11-02T20:30:29Z&se=2137-06-03T20:30:00Z&sv=2019-12-12&sr=b&sig=71bgHzX5e3UF5q8i4t6vHrmVQ8kq8vXPerOedpePbSA%3D',
    ext: t('proSeries.ext.pdf'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.dealerPortraitES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/L_PSPSDPSI_D ProSeries Product Spread Dealer Portrait.png?sp=r&st=2021-10-14T18:59:29Z&se=2221-10-15T02:59:29Z&spr=https&sv=2020-08-04&sr=b&sig=6EQFxT9eOKAxpW3sj4vpwu9%2BOWEaZ4LlhkF%2F0JZiQJQ%3D',
    ext: t('proSeries.ext.pdf'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.withPhoneFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/ProSeries_HH_Collage_wPhone (French).jpg?sp=rl&st=2020-11-02T20:30:52Z&se=2139-10-03T20:30:00Z&sv=2019-12-12&sr=b&sig=NLE722zGbpcOSzg%2BbqpUx5%2FRchmsuRGptKa25b8oR8w%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.withPhoneES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/ProSeries_PROA7PLUS Collage_wPhone_HH_ESLA.png?sp=r&st=2021-10-14T19:00:37Z&se=2221-10-15T03:00:37Z&spr=https&sv=2020-08-04&sr=b&sig=aaE1f6YVHByEI2mux8S6CqlVrc3NbwuIQW5YMTK3Hbk%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.withPhoneCN.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/ProSeries_HH_Collage_wPhone _CN.jpg?sp=rl&st=2020-11-02T20:31:15Z&se=2129-10-11T20:31:00Z&sv=2019-12-12&sr=b&sig=9W%2FE5X5spKbcE9jFFVaiM7%2FGFML%2B3KszFffQd4pBTWw%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.withPhone.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/ProSeries_HH_Collage_wPhone.jpg?sp=rl&st=2020-11-02T20:31:38Z&se=2136-02-03T20:31:00Z&sv=2019-12-12&sr=b&sig=NxXTjKWYYWayuUgnbW10aqgNHsmzBqTdMrswPbpiNHU%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.phoneES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/ProSeries_PROA7PLUS Collage_HH_ESLA.png?sp=r&st=2021-10-14T18:59:59Z&se=2221-10-15T02:59:59Z&spr=https&sv=2020-08-04&sr=b&sig=tj8q57ojYanaNsX4MjrlrKNiNP36eSkINAfEgMy6y1w%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.collageES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/ProSeries_PROA7PLUS Collage_HH_ESLA.png?sp=r&st=2021-10-14T18:59:59Z&se=2221-10-15T02:59:59Z&spr=https&sv=2020-08-04&sr=b&sig=tj8q57ojYanaNsX4MjrlrKNiNP36eSkINAfEgMy6y1w%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.resideoCollageES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/ProSeries_PROA7PLUS_Collage_wPhone_RESIDEO_ESLA.png?sp=r&st=2021-10-14T19:00:59Z&se=2221-10-15T03:00:59Z&spr=https&sv=2020-08-04&sr=b&sig=mjFtCI4ddFMwArlExq0xzs%2FJXEIE7XvG%2BeP8uf30ONk%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
  {
    text: t('proSeries.groupImages.a7CollageES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Graphic Images/Group Images/ProSeries PROA7 Family Product Image RESIDEO_ESLA.png?sp=r&st=2021-10-14T19:01:31Z&se=2221-10-15T03:01:31Z&spr=https&sv=2020-08-04&sr=b&sig=N8%2BmCPOOFMA7SQgO4bdsTCt1zlTQRcDhL34D5DHuUsc%3D',
    ext: t('proSeries.ext.jpg'),
    desc: '',
  },
];

export default GroupImages;
