const InfoForDealers = t => [
  {
    text: t('proSeries.infoForDealers.resBrochure.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/ProSeries_Dealer_Brochure.pdf',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.infoForDealers.brochure.desc'),
  },
  {
    text: t('proSeries.infoForDealers.resBrochureFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/L_PSDRFB_D_ProSeries_Dealer_Residential_Brochure_FRCA.pdf',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.infoForDealers.brochureFR.desc'),
  },
  {
    text: t('proSeries.infoForDealers.proSellSheet.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/ProSeries_Dealer_SellSheet.pdf',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.infoForDealers.proSellSheet.desc'),
  },
  {
    text: t('proSeries.infoForDealers.sellSheetFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/L_PSD1MINFS_D_ProSeries_Dealer_Sell_Sheet_R4_FRCA.pdf',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.infoForDealers.sellSheetFR.desc'),
  },
  {
    text: t('proSeries.infoForDealers.sellSheetES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/L_PSD1MINSS_D ProSeries Dealer Sell Sheet_ESLA.pdf?sp=r&st=2021-10-14T18:43:06Z&se=2221-10-15T02:43:06Z&spr=https&sv=2020-08-04&sr=b&sig=vBrgiFwZqPm0rCVwdavXLhYSmL77H9TA2v9AK9FaSmw%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.infoForDealers.sellSheetES.desc'),
  },
  {
    text: t('proSeries.infoForDealers.zWave.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/L_PSZWVCCHT_D ProSeries ZWave Compatibility Flyer_v4.pdf?sp=rl&st=2021-02-01T20:32:28Z&se=2135-11-16T20:32:00Z&sv=2019-12-12&sr=b&sig=DOy5mfTRvGn6mQneVtzVTRfp9W5zzf70zRtDPGHO5i0%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.infoForDealers.zWave.desc'),
  },
  {
    text: t('proSeries.infoForDealers.zWaveES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/L_PSZWVCSCHT_D ProSeries ZWave Compatibility Flyer_ESAR.pdf?sp=r&st=2021-10-14T18:44:15Z&se=2221-10-15T02:44:15Z&spr=https&sv=2020-08-04&sr=b&sig=7994GnLT%2FIUKQ6Ldg4Y8EyjmG4cILXRpjArPpbE40WY%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.infoForDealers.zWaveES.desc'),
  },
  {
    text: t('proSeries.infoForDealers.security.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/ProSeries At-A-Glance Datasheet.pdf?sp=rl&st=2021-02-16T21:02:22Z&se=2148-06-19T21:02:00Z&sv=2019-12-12&sr=b&sig=E0daVu9kFC0Gu9nV1F7KkMgslE%2Fp%2F6Z4AebhAnmzqW8%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.infoForDealers.security.desc'),
  },
  {
    text: t('proSeries.infoForDealers.securityES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/L_PSAAGSB_D ProSeries At_A_G Brochure_ESLA.pdf?sp=r&st=2021-10-14T18:48:35Z&se=2221-10-15T02:48:35Z&spr=https&sv=2020-08-04&sr=b&sig=PZAKknDLOpgPfv4TerPFoglay6Zj0M2fXuftJNOzodw%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.infoForDealers.securityES.desc'),
  },
  {
    text: t('proSeries.infoForDealers.combined.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/L_PSDCB_D ProSeries Dealer Commercial Brochure R3_v4Final.pdf?sp=rl&st=2021-06-28T18:19:25Z&se=2024-10-16T18:19:00Z&sv=2020-02-10&sr=b&sig=7AZVBiaj8UMcAoUKLfmzHWdk4uDZsNJdtkqi1hgTHh8%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.infoForDealers.combined.desc'),
  },
  {
    text: t('proSeries.infoForDealers.smbBrochureFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/ProSeries Dealer SMB Brochure (French).pdf?sp=r&st=2021-10-14T14:36:58Z&se=2221-10-14T22:36:58Z&spr=https&sv=2020-08-04&sr=b&sig=8j%2FnMQY%2B%2BD5nycLAb4OwHjedp5o%2FFOH7CsUY2OK6UhI%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.infoForDealers.smbBrochureFR.desc'),
  },
  {
    text: t('proSeries.infoForDealers.smbBrochureES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/L_PSDCSB_D ProSeries Combined Dealer Commercial Bro_ESLA.pdf?sp=r&st=2021-10-14T18:45:55Z&se=2221-10-15T02:45:55Z&spr=https&sv=2020-08-04&sr=b&sig=DPNfzXAsRuIOCKrcgSB7by4Q247ZFwY8qUwuggbSLs8%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.infoForDealers.smbBrochureES.desc'),
  },
  {
    text: t('proSeries.infoForDealers.resBrochureES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/L_PSDRSB_D ProSeries_Dealer Residential Brochure_ESLA.pdf?sp=r&st=2021-10-14T18:46:30Z&se=2221-10-15T02:46:30Z&spr=https&sv=2020-08-04&sr=b&sig=84gJcZvA1T5Ih1dI7bw3POkMNxctVjL%2F7crxc0nBPgc%3D ',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.infoForDealers.resBrochureES.desc'),
  },
  {
    text: t('proSeries.dataSheets.glance.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Data Sheets/L_PSAAGB_D ProSeries At_A_Glance Brochure R2_v3 FINAL 12.22.pdf?sp=rl&st=2021-02-16T21:48:26Z&se=2158-06-14T21:48:00Z&sv=2019-12-12&sr=b&sig=2GTwPaxH%2BTwP%2FlzeLhrG5GkQLTXwFppSuRWNm1WgvZU%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.dataSheets.glance.desc'),
  },
  {
    text: t('proSeries.infoForDealers.atGlanceFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/ProSeries At-A-Glance Brochure (French).pdf?sp=r&st=2021-10-14T14:32:32Z&se=2221-10-14T22:32:32Z&spr=https&sv=2020-08-04&sr=b&sig=4Ip9B9y7GHuwYfyd%2F0YU%2FVPjK0jHtf2L%2Bb5OJ4I8J%2Fk%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.infoForDealers.atGlanceFR.desc'),
  },
];

export default InfoForDealers;
