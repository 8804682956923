import React from 'react';
import { useTranslation } from 'react-i18next';
import SuccessIcon from 'components/icons/yes.svg?react';
import styled from 'styled-components';
import { Flex, Text, Button } from '@resideo/blueprint-react';
import { IAddCustomerProps } from '../types';
import ButtonLink from '../../../common/ButtonLinkComponent';

const StyledSuccessIcon = styled(SuccessIcon)`
  fill: #258039;
  height: 78px;
`;

const SuccessModal: React.FC<IAddCustomerProps> = ({ data, closeModal }) => {
  const { t } = useTranslation();
  const customerId = data?.customerId;

  return (
    <Flex flexDirection='column' justifyContent='center' alignItems='center'>
      <StyledSuccessIcon />
      <Text
        data-test-success-title
        marginBottom='medium'
        as='h2'
        fontSize='xLarge'>
        {t('mybusiness.customers.addCustomer.success')}
      </Text>
      <Text data-test-success-message marginBottom='medium'>
        {t('mybusiness.customers.addCustomer.successMessage')}
      </Text>
      <ButtonLink
        data-test-success-link
        onClick={() =>
          (window.location.href = `/mybusiness/customers/account-lookup/${customerId}`)
        }>
        {t('mybusiness.customers.addCustomer.viewDetails')}
      </ButtonLink>
      <Button
        data-test-success-done-btn
        variant='primary'
        onClick={() => closeModal()}>
        {t('mybusiness.customers.addCustomer.done')}
      </Button>
    </Flex>
  );
};

export default SuccessModal;
