import React, { useEffect, useState } from 'react';
import { FieldArray, Form, Formik, FormikErrors } from 'formik';
import { InputField, SelectField } from '@resideo/blueprint-formik';
import { Button, useToastContext } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { FiLoader, FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { checkEmail } from 'utils/validation';
import { RESIDEO_ID_URL } from 'config';
import { useCurrentUser } from 'context/CurrentUser';
import { useLanguageCode } from 'hooks/useLanguageCode';
import { useFeatureFlags } from 'context/FeatureFlags';
import useMedia from 'hooks/useMedia';
import { useMutation } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';
import { PartnerRole } from '@resideo/web-integration-services-api-client';

const AddIcon = styled(FiPlusCircle)`
  margin-right: 12px;
  font-size: 24px;
`;

const RemoveIcon = styled(FiTrash2)`
  cursor: pointer;
  font-size: 24px;
`;

type InvitationType = {
  contactEmail?: string;
  role?: string;
  perksRole?: string;
};

type FormValuesType = {
  invites: InvitationType[];
};

enum StatusEnum {
  UNSENT = 'UNSENT',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  LOADING = 'LOADING',
}

const InviteEmployeeModalContent = ({ closeModal }) => {
  const [invitesLength, setInvitesLength] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [recordStatuses, setRecordStatuses] = useState<StatusEnum[]>([
    StatusEnum.UNSENT,
  ]);
  const { partnerAccountId, isOwner } = useCurrentUser();
  const [languageCode] = useLanguageCode();
  const { RESIDEO_ID_INVITES } = useFeatureFlags();
  const isMobile = useMedia('(max-width: 37rem)');
  const { addToast } = useToastContext();
  const { client } = useRisClient();
  const { t } = useTranslation();

  const partnerUserCreateInvitation = useMutation({
    mutationKey: ['partnerUserCreateInvitation'],
    mutationFn: async (variables: { role: string; sendToEmail: string }) => {
      return await client.myProAccounts.myProAccountsCreateEmployeeInvite(
        partnerAccountId as string,
        {
          roles: [variables?.role as PartnerRole],
          sendToEmail: variables?.sendToEmail,
        }
      );
    },
  });

  const partnerUserSendInvitation = useMutation({
    mutationKey: ['partnerUserSendInvitation'],
    mutationFn: async (variables: {
      templateLanguage: string;
      baseUrl: string;
      expiresAt: string;
      inviteId: string;
    }) => {
      return await client.myProAccounts.myProAccountsSendEmployeeInvite(
        variables?.inviteId || '',
        partnerAccountId as string,
        {
          templateLanguage: variables?.templateLanguage || '',
          baseUrl: variables?.baseUrl || '',
          expiresAt: variables?.expiresAt || '',
        }
      );
    },
  });

  useEffect(() => {
    document
      .getElementById(`invites.${invitesLength - 1}.contactEmail`)
      ?.focus();
  }, [invitesLength]);

  const roleOptions = [
    {
      value: '',
      text: t('common.forms.choose'),
    },
    {
      value: 'COMPANY_ADMIN',
      text: t('common.roles.COMPANY_ADMIN'),
    },
    {
      value: 'TECHNICIAN',
      text: t('common.roles.TECHNICIAN'),
    },
  ];

  if (isOwner) {
    roleOptions.splice(1, 0, { value: 'OWNER', text: t('common.roles.OWNER') });
  }

  const validate = values => {
    const { invites } = values;
    const errors: FormikErrors<InvitationType>[] = [];
    invites.forEach((item, idx) => {
      const inviteErrors: FormikErrors<InvitationType> = {};
      const msgPrefix = 'signUp.validation.';
      if (!item.contactEmail) {
        inviteErrors.contactEmail = t(msgPrefix + 'email');
      } else if (!checkEmail(item.contactEmail)) {
        inviteErrors.contactEmail = t(msgPrefix + 'emailInvalid');
      }
      if (!item.role) {
        inviteErrors.role = t('mybusiness.employees.roleRequired');
      }

      if (Object.keys(inviteErrors).length) errors[idx] = inviteErrors;
    });

    if (errors.length > 0) return { invites: errors };

    return {};
  };

  const handleAlerts = (currentState: StatusEnum[]) => {
    if (
      currentState.some(
        i => i === StatusEnum.UNSENT || i === StatusEnum.LOADING
      )
    ) {
      return;
    }

    if (currentState.every(i => i === StatusEnum.ERROR)) {
      addToast({
        toastType: 'Error',
        message: t('Failed to send invites. Please try again.'),
      });
      return;
    }

    if (currentState.some(i => i === StatusEnum.ERROR)) {
      addToast({
        toastType: 'Error',
        message: t('Some invites failed to send. Please try again.'),
      });
      return;
    }

    if (currentState.every(i => i === StatusEnum.SUCCESS)) {
      addToast({ toastType: 'Success', message: t('Invites sent.') });
      closeModal();
    }
  };

  useEffect(() => {
    handleAlerts(recordStatuses);
  }, [recordStatuses]);

  const setStatus = (idx: number, status: StatusEnum) => {
    setRecordStatuses(oldState => {
      const newState = [...oldState];
      newState[idx] = status;

      return newState;
    });
  };

  const inviteCreateHandle = (values: InvitationType) =>
    new Promise((resolve, reject) => {
      partnerUserCreateInvitation.mutate(
        {
          sendToEmail: values?.contactEmail as string,
          role: values?.role as string,
        },
        {
          onError: error => {
            reject(`InviteCreateMutation: ${error}`);
          },
          onSuccess: response => {
            resolve(response?.data?.id);
          },
        }
      );
    });

  const inviteSendHandle = inviteId =>
    new Promise((resolve, reject) => {
      const expiresAt = new Date();
      expiresAt.setDate(expiresAt.getDate() + 7);

      partnerUserSendInvitation.mutate(
        {
          templateLanguage: languageCode as string,
          baseUrl: `${
            RESIDEO_ID_INVITES ? RESIDEO_ID_URL : window.location.origin
          }/sign-up`,
          expiresAt: expiresAt.toISOString(),
          inviteId,
        },
        {
          onError: error => {
            reject(`InviteSendMutation: ${error}`);
          },
          onSuccess: response => {
            if (response?.errors?.length) {
              reject('Error: InviteSendMutation error');
            }

            resolve(response?.data);
          },
        }
      );
    });

  const handleMutation = async (values: InvitationType) => {
    try {
      const inviteId = await inviteCreateHandle(values);
      const inviteResult = await inviteSendHandle(inviteId);

      if (inviteResult) {
        return new Promise<boolean>(resolve => resolve(true));
      }
    } catch (err) {
      // handling error
    }
  };

  const submitRecord = async (data: InvitationType) => {
    const result = await handleMutation(data);
    return result ? StatusEnum.SUCCESS : StatusEnum.ERROR;
  };

  const onSubmit = async (values: FormValuesType) => {
    setLoading(true);

    const { invites } = values;

    let idx = 0;
    for (const invite of invites) {
      if (
        recordStatuses[idx] === StatusEnum.UNSENT ||
        recordStatuses[idx] === StatusEnum.ERROR
      ) {
        setStatus(idx, StatusEnum.LOADING);
        const inviteTrimmed = {
          ...invite,
          contactEmail: invite?.contactEmail?.trim() || '',
        };
        const result = await submitRecord(inviteTrimmed);
        setStatus(idx, result);
      }
      idx++;
    }
    setLoading(false);
  };

  return (
    <Formik
      initialValues={{ invites: [{ contactEmail: '', role: '' }] }}
      validate={validate}
      onSubmit={onSubmit}>
      {({ values, isValid, dirty, setFieldTouched }) => (
        <Form>
          <p style={{ marginTop: 0 }}>* {t('Required Fields')}</p>
          <FieldArray name='invites'>
            {arrayHelpers => (
              <div>
                <div style={{ maxHeight: '485px', overflow: 'auto' }}>
                  {values.invites &&
                    values.invites.map((invite, index) => (
                      <div
                        key={index}
                        style={
                          isMobile
                            ? {
                                border: '1px solid #DADADA',
                                marginBottom: '10px',
                                padding: '10px',
                              }
                            : { display: 'flex', gap: '15px', height: '97px' }
                        }>
                        <div style={{ flexGrow: 5 }}>
                          <InputField
                            id={`invites.${index}.contactEmail`}
                            version='v2'
                            label={t('common.emailShort')}
                            name={`invites.${index}.contactEmail`}
                            required
                          />
                        </div>

                        <div style={{ display: 'flex', flexGrow: 3 }}>
                          <div style={{ flexGrow: 3 }}>
                            <SelectField
                              id={`invites.${index}.role`}
                              version='v2'
                              label={t('common.roles.label')}
                              name={`invites.${index}.role`}
                              required>
                              {roleOptions.map(i => (
                                <option key={i.value} value={i.value}>
                                  {i.text}
                                </option>
                              ))}
                            </SelectField>
                          </div>

                          <div
                            style={
                              isMobile
                                ? {
                                    flexGrow: 0,
                                    display: 'flex',
                                    marginTop: '25px',
                                    height: '44px',
                                  }
                                : {
                                    flexGrow: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }
                            }>
                            <Button
                              className='removeButton'
                              type='button'
                              data-test-remove-btn
                              variant='tertiary'
                              onClick={() => {
                                arrayHelpers.remove(index);
                                const newRecordStatuses = [...recordStatuses];
                                newRecordStatuses.splice(index, 1);
                                setRecordStatuses(newRecordStatuses);
                              }}
                              disabled={values.invites.length <= 1}
                              style={{ minWidth: 0 }}>
                              <RemoveIcon style={{ paddingTop: '8px' }} />
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <Button
                  type='button'
                  variant='tertiary'
                  data-test-add-another
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '0',
                  }}
                  onClick={() => {
                    setFieldTouched(
                      `invites.${values.invites.length - 1}.contactEmail`,
                      true
                    );
                    setFieldTouched(
                      `invites.${values.invites.length - 1}.role`,
                      true
                    );
                    arrayHelpers.push({ contactEmail: '', role: '' });
                    setRecordStatuses([...recordStatuses, StatusEnum.UNSENT]);
                    setInvitesLength(values.invites.length + 1);
                  }}>
                  <AddIcon /> {t('mybusiness.employees.addAnother')}
                </Button>
              </div>
            )}
          </FieldArray>

          <div
            style={
              isMobile
                ? { display: 'flex' }
                : { display: 'flex', justifyContent: 'right' }
            }>
            <Button
              variant='secondary'
              type='button'
              style={
                isMobile
                  ? { width: '50%', marginRight: '25px' }
                  : { marginRight: '25px' }
              }
              onClick={closeModal}>
              {t('common.cancel')}
            </Button>

            <Button
              variant='primary'
              type='submit'
              data-test-send-invite
              disabled={!dirty || !isValid || loading}
              style={isMobile ? { width: '50%' } : {}}>
              {loading && (
                <FiLoader
                  className='icon-spin'
                  style={{ marginLeft: 4, marginTop: 2 }}
                />
              )}
              {!loading && t('common.send')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InviteEmployeeModalContent;
