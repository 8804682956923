import {
  OpenAPIConfig,
  AMSClient,
} from '@resideo/agreement-management-service-web-client';
import { useAuth } from 'utils/auth';
import { AMS_BASE_URL } from 'config';

export const useAMSClient = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth();

  const config: Partial<OpenAPIConfig> = {
    BASE: AMS_BASE_URL,
    TOKEN: async () => {
      return isAuthenticated ? await getAccessTokenSilently() : '';
    },
  };

  return { client: new AMSClient(config) };
};
