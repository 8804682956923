import React, { FC } from 'react';
import { Box, Button, Text } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';

import { productsDownloadList } from 'components/hvacWater';

const getCurrentDownloadAll = (type, t) => ({
  file: productsDownloadList(t)[type].file,
  size: productsDownloadList(t)[type].size,
});

const DownloadAllButton: FC<{
  type: string;
  display?: string[];
  alignItems: string;
}> = ({ type, display, alignItems }) => {
  const { t } = useTranslation();
  return (
    <Box
      display={display}
      flexBasis='15%'
      flexDirection='column'
      alignItems={alignItems}>
      <Box textAlign='center'>
        <a
          href={getCurrentDownloadAll(type, t).file}
          download
          target='_blank'
          rel='noopener noreferrer'>
          <Button>{t('hvacAndWater.downloadAll.download')}</Button>
        </a>
        <Text paddingTop='small'>
          {t('hvacAndWater.downloadAll.fileSize')}{' '}
          {getCurrentDownloadAll(type, t).size}
        </Text>
      </Box>
    </Box>
  );
};

export default DownloadAllButton;
