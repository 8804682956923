import React from 'react';
import styled from 'styled-components';
import { useLayout } from 'context/Layout';

interface StyledMobileMenuToggle {
  open: boolean;
}

const StyledMobileMenuToggle = styled.button<StyledMobileMenuToggle>`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  width: 27px;
  height: 20px;
  position: relative;
  flex: 0 0 auto;

  div,
  div:before,
  div:after {
    display: block;
    content: '';
    position: relative;
    height: 2px;
    width: 100%;
    background: ${({ theme }) => theme.colors.header.primaryLink};
    opacity: 1;
  }

  div {
    background: ${({ open, theme }) =>
      open ? 'transparent' : theme.colors.header.primaryLink};
    &:before {
      top: ${({ open }) => (open ? '0' : '-9px')};
      transform: ${({ open }) => (open ? 'rotate(135deg)' : 'rotate(0deg)')};
    }

    &:after {
      top: ${({ open }) => (open ? '-2px' : '7px')};
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0deg)')};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.mLarge}) {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mLarge}) {
    margin-right: 15px;
  }
`;

export const MobileMenuToggle = () => {
  const { mobileMenuOpen, setMobileMenuOpen } = useLayout();
  return (
    <StyledMobileMenuToggle
      open={mobileMenuOpen}
      onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
      role='button'
      tabIndex={0}>
      <div></div>
    </StyledMobileMenuToggle>
  );
};
