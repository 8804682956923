const SmbOwnerSales = t => [
  {
    text: t('proSeries.smbOwnerSales.smbBrochure.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/ProSeriesA7 EndUser SMB Brochure.pdf?sp=rl&st=2021-02-16T20:54:58Z&se=2126-03-13T20:54:00Z&sv=2019-12-12&sr=b&sig=WgFjNjDwSTi3I%2FpUyqZHdb70ZkRjSGIKaykgxlpuGAg%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.smbOwnerSales.smbBrochure.desc'),
  },
  {
    text: t('proSeries.smbOwnerSales.smbBrochureFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/ProSeries A7 EndUser SMB Brochure (French).pdf?sp=rl&st=2021-02-16T20:44:24Z&se=2140-06-17T20:44:00Z&sv=2019-12-12&sr=b&sig=mFyI%2BFkKseoiIadCPH13kdMkKwZ3UCvypH9fOduun6Q%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.smbOwnerSales.smbBrochureFR.desc'),
  },
  {
    text: t('proSeries.smbOwnerSales.smbBrochureES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/L_PSA7EUCSB_D PROA7 EU Comm Bro_ESLA.pdf?sp=r&st=2021-10-14T18:40:40Z&se=2221-10-15T02:40:40Z&spr=https&sv=2020-08-04&sr=b&sig=05RsokeDds%2BftFDMjVewL0iVdtg0FZ9qIXu6kk%2B3G1Q%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.smbOwnerSales.smbBrochureES.desc'),
  },
  {
    text: t('proSeries.smbOwnerSales.smbSellSheet.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/ProSeriesA7 EndUser SMB SellSheet.pdf?sp=rl&st=2021-02-16T20:58:04Z&se=2146-07-17T20:58:00Z&sv=2019-12-12&sr=b&sig=7BUi0eJ%2F0Nv%2FRdY6d11h%2FaDr4pVxb1YOS5ih4ux1TJQ%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.smbOwnerSales.smbSellSheet.desc'),
  },
  {
    text: t('proSeries.smbOwnerSales.smbSellSheetFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/ProSeries A7 EndUser SMB Sell Sheet (French).pdf?sp=rl&st=2021-02-15T18:28:40Z&se=2210-06-05T18:28:00Z&sv=2019-12-12&sr=b&sig=8o5uXf8A8mPJtHoRKJXi9dbIEjMjDx5x%2FYA%2F3ybrsHU%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.smbOwnerSales.smbSellSheetFR.desc'),
  },
  {
    text: t('proSeries.smbOwnerSales.smbSellSheetES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/L_PSA7EUCSS_D ProSeries PROA7 EU Com SS_Secured_ESLA.PDF?sp=r&st=2021-10-14T18:41:10Z&se=2221-10-15T02:41:10Z&spr=https&sv=2020-08-04&sr=b&sig=R4y7x1zfBn8i9ugQTNd%2BRx8V%2BrlFlhMvO9h10uGbL4k%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.smbOwnerSales.smbSellSheetES.desc'),
  },
  {
    text: t('proSeries.smbOwnerSales.commEN.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/ProSeries EndUser Resideo Commercial Brochure.pdf?sp=rl&st=2021-02-16T22:37:11Z&se=2144-07-07T22:37:00Z&sv=2019-12-12&sr=b&sig=E6EV6eEZrWECFvt%2Fua8EtMjwh64rXlpcRZ9b4FMXJ3E%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.smbOwnerSales.commEN.desc'),
  },
  {
    text: t('proSeries.smbOwnerSales.commercialFR.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Home Owner Sales Material/ProSeries EndUser Resideo Commercial Brochure (French).pdf?sp=rl&st=2021-02-15T18:25:37Z&se=2144-02-11T18:25:00Z&sv=2019-12-12&sr=b&sig=QKL4nQyEygtZWN4Ezculkwf8n6PgAubd6RX%2FTMPFCO0%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.smbOwnerSales.commercialFR.desc'),
  },
  {
    text: t('proSeries.smbOwnerSales.commercialES.title'),
    download:
      'https://prdproseriesfiles.blob.core.windows.net/$web/Information For Dealers/L_PSEUCSB_D ProSeries EU Comm Bro_Secured_ESLA.pdf?sp=r&st=2021-10-14T18:41:42Z&se=2221-10-15T02:41:42Z&spr=https&sv=2020-08-04&sr=b&sig=DNd76sAnNYIuEaoDzA58Xi2do6RjgUty%2Firz79IJ4J8%3D',
    ext: t('proSeries.ext.pdf'),
    desc: t('proSeries.smbOwnerSales.commercialES.desc'),
  },
];

export default SmbOwnerSales;
