import React, { FC, useEffect } from 'react';
import { useAuth } from 'utils/auth';
import PageLoading from 'components/common/PageLoading';
import { session } from '../utils/storage';

const SignOut: FC = (): JSX.Element => {
  const { isLoading, logout } = useAuth();
  useEffect(() => {
    if (!isLoading) {
      logout({ returnTo: window.location.origin });
      // Removing this key from session storage so that we will display the status banner once the user logs back in
      session.removeItem('isStatusBannerClosed');
    }
  }, [logout, isLoading]);

  return <PageLoading />;
};

export default SignOut;
