import React, { useState } from 'react';
import { Form, Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, Button } from '@resideo/blueprint-react';
import {
  checkName,
  checkPhoneNumber,
  checkEmail,
  checkPhoneNumberForCountryCode,
} from 'utils/validation';
import { InputField, PhoneInputField } from '@resideo/blueprint-formik';
import { ICustomerInfoProps } from '../types';
import styled from 'styled-components';
import { useCurrentUser } from 'context/CurrentUser';
import { useRisClient } from 'hooks/useRisClient';
import { useQuery } from '@tanstack/react-query';

export interface CustomerValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
}

const emptyState = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  countryCode: '',
};

const ErrorDiv = styled(Flex)`
  align-items: center;
  gap: 5px;
  color: #d22730;
  font-size: 0.75rem;
  line-height: 1.5;
  padding-top: 0.25rem;
  margin: 0;

  a {
    text-decoration: none;
  }
`;

const CustomerInfoForm: React.FC<ICustomerInfoProps> = ({
  initialValues = emptyState,
  isEditMode = false,
  closeModal,
  onSubmit,
  data,
}) => {
  const { t } = useTranslation();
  const { partnerAccountId } = useCurrentUser();
  const { client } = useRisClient();
  const [email, setEmail] = useState('');

  const { refetch: checkExistingCustomerFetch } = useQuery({
    queryKey: ['partnerCustomersCheckExisting'],
    queryFn: async () =>
      (
        await client.myProAccounts.myProAccountsSearchPartnerCustomers(
          partnerAccountId as string,
          JSON.stringify([{ email: email }])
        )
      ).data,
    // Disabled, only execute this query manually
    enabled: false,
    staleTime: 5000,
  });

  const validate = async (values: CustomerValues) => {
    let existingCustomerId;

    // Only check if email exists if the email value is valid
    const isValidEmail =
      values?.email && values?.email?.trim() !== '' && checkEmail(values.email);

    if (isValidEmail) {
      const { data } = await checkExistingCustomerFetch();

      const existingCustomers = data?.filter(
        node => node.email === values.email
      );

      existingCustomerId =
        existingCustomers?.length && existingCustomers[0]?.id;
    }

    const errors: FormikErrors<CustomerValues> = {};
    if (!values.firstName || values.firstName.trim() === '')
      errors['firstName'] = t('user.profile.validation.firstName');

    if (values?.firstName && !checkName(values?.firstName)) {
      errors['firstName'] = `${t('common.validation.unallowedCharacters')} ${t(
        'common.name.first'
      )} ${t('common.field')}`;
    }

    if (!values.lastName || values.lastName.trim() === '')
      errors['lastName'] = t('user.profile.validation.lastName');

    if (values?.lastName && !checkName(values?.lastName)) {
      errors['lastName'] = `${t('common.validation.unallowedCharacters')} ${t(
        'common.name.last'
      )} ${t('common.field')}`;
    }

    if (!values.email || values.email.trim() === '') {
      errors.email = t('user.profile.validation.email');
    } else if (!checkEmail(values.email)) {
      errors.email = t('user.profile.validation.emailInvalid');
    } else if (existingCustomerId && existingCustomerId !== initialValues?.id) {
      errors.email = `${t(
        'user.profile.validation.emailInUse'
      )} <a href="/mybusiness/customers/account-lookup/${existingCustomerId}" target="_blank">${t(
        'user.profile.validation.viewCustomer'
      )}</a>`;
    }

    const countryCodeIsNotTheOnlyInput =
      values.phoneNumber &&
      `+${checkPhoneNumberForCountryCode(values.phoneNumber).toString()}` !==
        values.phoneNumber;

    if (
      values.phoneNumber &&
      !checkPhoneNumber(values.phoneNumber) &&
      countryCodeIsNotTheOnlyInput
    ) {
      errors.phoneNumber = t('user.profile.validation.phone');
    }

    return errors;
  };

  return (
    <>
      <Text marginBottom='medium' as='h2' fontSize='xLarge'>
        {t('customers.customerInfo')}
      </Text>
      <Formik
        initialValues={{ ...initialValues, ...data }}
        onSubmit={onSubmit}
        validate={validate}>
        {({ dirty, resetForm, errors, isValid }) => (
          <Form data-test-customer-info noValidate>
            <Text
              data-test-required-fields
              marginBottom='medium'
              fontSize='small'
              fontStyle='italic'>
              {t('common.requiredFields')}
            </Text>
            <Flex flexDirection={['column', 'row']}>
              <Box
                data-test-customer-first-name
                marginBottom='medium'
                width={[1, 1, 1, 1]}>
                <InputField
                  version='v2'
                  name='firstName'
                  label={t('common.name.first')}
                  required={true}
                  disabled={false}
                />
              </Box>
            </Flex>
            <Flex flexDirection={['column', 'row']}>
              <Box
                data-test-customer-last-name
                marginBottom='medium'
                width={[1, 1, 1, 1]}>
                <InputField
                  version='v2'
                  name='lastName'
                  label={t('common.name.last')}
                  required={true}
                  disabled={false}
                />
              </Box>
            </Flex>
            <Flex flexDirection={['column', 'row']}>
              <Box
                data-test-customer-email
                marginBottom='medium'
                width={[1, 1, 1, 1]}>
                <InputField
                  error={undefined}
                  borderColor={errors?.email ? 'red' : undefined}
                  version='v2'
                  name='email'
                  label={'Contact Email'}
                  required={true}
                  disabled={false}
                  onBlur={e => {
                    setEmail(e.target.value);
                  }}
                />
                {errors?.email && (
                  <ErrorDiv
                    data-test-add-customer-email-error
                    dangerouslySetInnerHTML={{
                      __html: errors?.email,
                    }}></ErrorDiv>
                )}
              </Box>
            </Flex>
            <Flex flexDirection={['column', 'row']}>
              <Box
                data-test-customer-phone
                marginBottom='medium'
                width={[1, 1, 1, 1]}>
                <PhoneInputField
                  version='v2'
                  label={t('common.phone')}
                  name='phoneNumber'
                  disabled={false}
                  phoneInputConfig={{
                    preferredCountries: ['us', 'ca'],
                    country: 'us',
                    countryCodeEditable: false,
                  }}
                />
              </Box>
            </Flex>
            <Flex
              alignItems='center'
              justifyContent='flex-end'
              paddingX={['medium', 0]}
              paddingTop='large'>
              <Button
                data-test-add-customer-cancel-btn
                marginX='large'
                onClick={() => {
                  resetForm();
                  closeModal();
                }}
                variant='secondary'>
                {t('common.cancel')}
              </Button>
              <Button
                data-test-add-customer-next-btn
                disabled={
                  !dirty && data && Object.keys(data).length === 0
                    ? !dirty
                    : !isValid
                }
                type='submit'
                variant='primary'
                width={[1, 'auto']}>
                {t(`common.${isEditMode ? 'save' : 'next'}`)}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CustomerInfoForm;
