import auth0, { DbSignUpOptions } from 'auth0-js';
import config, { ENABLE_MOCKS } from 'config';
import { local } from 'utils/storage';

const SESSION_KEY = 'session';

interface Auth0SignUpResponse {
  Id: string;
  email: string;
  emailVerified: boolean;
  userMetadata: unknown;
}

export interface Auth0Config {
  [key: string]: string | undefined;
}

export class WebAuth {
  private auth0: auth0.WebAuth;
  private config: Auth0Config;

  constructor() {
    this.config = config;
    this.auth0 = new auth0.WebAuth({
      domain: config.domain,
      clientID: config.clientId,
      scope: config.scope,
      audience: config.audience,
      responseType: 'token id_token',
    });
  }

  signUp = ({
    email,
    password,
    name,
    metadata = {},
  }): Promise<Auth0SignUpResponse> => {
    return new Promise((resolve, reject) => {
      this.auth0.signup(
        {
          connection: 'Username-Password-Authentication',
          email,
          password,
          userMetadata: metadata,
          name, // Typings don't support name (incorrectly)
        } as DbSignUpOptions,
        (err, signUpResult) => {
          if (err) {
            return reject(err);
          }
          return resolve(signUpResult);
        }
      );
    });
  };

  // only use for mirage log in
  usernamePassword = (username: string, password: string) => {
    return new Promise((resolve, reject) => {
      this.auth0.client.login(
        { realm: this.config.connection || '', username, password },
        (err, authResult) => {
          if (err) {
            return reject(err);
          }
          resolve(authResult);
          if (ENABLE_MOCKS) {
            local.setItem(SESSION_KEY, JSON.stringify(authResult));
          }
        }
      );
    });
  };
}
