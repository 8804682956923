import React, { FC, Suspense } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, Route, Routes } from 'react-router-dom';
import AlertSettingsIcon from 'components/icons/alert-settings.svg?react';

import {
  Breadcrumbs,
  Flex,
  SecondaryNavigation,
  SecondaryNavItem,
} from '@resideo/blueprint-react';
import { Titled } from 'components/common/Titled';
import Active from './Active';
import Error from 'routes/Error';
import Flagged from './Flagged';
import Dismissed from './Dismissed';
import TableLoading from 'components/common/TableLoading';
import Page from 'components/common/Page';

const HeadingContainer = styled(Flex)`
  margin: 15px 30px 10px 30px;
`;

const AlertsPageTitle = styled.h1`
  margin: 20px 0;
`;

const AlertsContainer = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0 1rem;
    overflow: auto;
  }
`;

const SecondaryNavigationContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 30px;
`;

const TopNavContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
`;

const BusinessIntelligenceLink = styled(Link)`
  width: 164px;
  height: 44px;
  align-items: center;
  justify-content: center;
  background-color: #3c3c3c;
  color: white;
  display: flex;
  font-size: 13px;
  border: 1px solid #3c3c3c;
  text-decoration: none;
`;

const AlertSettingsLink = styled(Link)`
  width: 140px;
  height: 44px;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #3c3c3c;
  display: flex;
  font-size: 13px;
  border: 1px solid #3c3c3c;
  text-decoration: none;
`;

const LinkContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px;
`;

const AlertsDashboard: FC = () => {
  const { t } = useTranslation();

  const secondaryNavigation: SecondaryNavItem[] = [
    {
      text: `${t('alertsDashboard.active')}`,
      path: `/mybusiness/alerts/dashboard`,
      ref: 'dashboard',
    },
    {
      text: `${t('alertsDashboard.flagged')}`,
      path: `/mybusiness/alerts/dashboard/flagged`,
      ref: 'flagged',
    },
    {
      text: `${t('alertsDashboard.dismissed')}`,
      path: `/mybusiness/alerts/dashboard/dismissed`,
      ref: 'dismissed',
    },
  ];

  const AlertsRoutes = () => {
    return (
      <Routes>
        <Route
          path='/'
          element={
            <div>
              <Titled
                title={`${t('alertsDashboard.title')} - ${t(
                  'alertsDashboard.active'
                )}`}
              />
              <Active />
            </div>
          }
        />
        <Route
          path='/flagged'
          element={
            <div>
              <Titled
                title={`${t('alertsDashboard.title')} - ${t(
                  'alertsDashboard.flagged'
                )}`}
              />
              <Flagged />
            </div>
          }
        />
        <Route
          path='/dismissed'
          element={
            <div>
              <Titled
                title={`${t('alertsDashboard.title')} - ${t(
                  'alertsDashboard.dismissed'
                )}`}
              />
              <Dismissed />
            </div>
          }
        />

        <Route path='*' element={<Error />} />
      </Routes>
    );
  };

  return (
    <>
      <TopNavContainer>
        <HeadingContainer flexDirection='column' justifyContent='space-between'>
          <Breadcrumbs>
            <Link to='/mybusiness/customers/account-lookup'>
              {t('customerDetails.customerLookup')}
            </Link>
            <Link to='#'>{t('alertsDashboard.title')}</Link>
          </Breadcrumbs>
          <AlertsPageTitle>{t('alertsDashboard.title')}</AlertsPageTitle>
        </HeadingContainer>
        <LinkContainer>
          <AlertSettingsLink
            to='/mybusiness/alerts/alert-settings'
            state={{ prevPage: 'alerts-dashboard' }}>
            <AlertSettingsIcon />
            &nbsp;{t('alertsDashboard.alertSettings')}
          </AlertSettingsLink>
          <BusinessIntelligenceLink to='/mybusiness/business-intelligence'>
            {t('alertsDashboard.businessIntelligence')}
          </BusinessIntelligenceLink>
        </LinkContainer>
      </TopNavContainer>
      <SecondaryNavigationContainer>
        <SecondaryNavigation
          navItems={secondaryNavigation}
          linkRenderer={({ text, path }) => {
            return (
              <NavLink to={path} end>
                {text}
              </NavLink>
            );
          }}
          displayedRef={window.location.pathname.split('/').pop()}
        />
      </SecondaryNavigationContainer>
      <AlertsContainer>
        <Page
          as='main'
          paddingY='medium'
          paddingX='0'
          marginLeft={['0', 'large']}
          marginRight={['small', 'large']}
          maxWidth='1'
          width={1}>
          <Suspense fallback={<TableLoading />}>
            <AlertsRoutes />
          </Suspense>
        </Page>
      </AlertsContainer>
    </>
  );
};

export default AlertsDashboard;
