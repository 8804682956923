import { useState, useEffect } from 'react';

function throttle(func, delay) {
  let lastCall = 0;
  return function(...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return func(...args);
  };
}

export const useIdleTimer = (timeoutDuration, onIdle) => {
  const [lastActivity, setLastActivity] = useState(
    Number(localStorage.getItem('lastActivity')) || Date.now()
  );

  const syncActivityAcrossTabs = event => {
    if (event.key === 'lastActivity') {
      setLastActivity(Number(event.newValue));
    }
  };

  const handleActivity = throttle(() => {
    const currentTime = Date.now();
    setLastActivity(currentTime);
    localStorage.setItem('lastActivity', currentTime.toString());
  }, 1000);

  const checkIdleTimeout = () => {
    if (Date.now() - lastActivity >= timeoutDuration) {
      localStorage.removeItem('lastActivity');
      onIdle();
    }
  };

  useEffect(() => {
    const interval = setInterval(checkIdleTimeout, 1000);
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('click', handleActivity);
    window.addEventListener('storage', syncActivityAcrossTabs);
    window.addEventListener('blur', checkIdleTimeout);
    window.addEventListener('focus', checkIdleTimeout);

    return () => {
      clearInterval(interval);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('storage', syncActivityAcrossTabs);
      window.removeEventListener('blur', checkIdleTimeout);
      window.removeEventListener('focus', checkIdleTimeout);
    };
  }, [timeoutDuration, onIdle]);

  return lastActivity;
};
