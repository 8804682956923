import { useContext } from 'react';
import AuthenticationContext, { AuthenticationState } from './context';
import { useAuth0, Auth0ContextInterface } from '@auth0/auth0-react';
import { ENABLE_MOCKS } from 'config';

export interface AuthContextInterface extends Auth0ContextInterface {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  restoreSession: () => void;
}

const useAuth = ENABLE_MOCKS
  ? (): AuthenticationState => useContext(AuthenticationContext)
  : useAuth0;

export default useAuth;
