import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button } from '@resideo/blueprint-react';
import { ButtonContainer, ModalContent } from '../../../customers.style';

export const ActivateServiceModal = ({ onCancel, onSubmit, serviceName }) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <Text>
        {t('customerDetails.activateService.subject')} {serviceName}{' '}
        {t('customerDetails.activateService.subject1')}
      </Text>
      <ButtonContainer>
        <Button variant='secondary' onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button variant='primary' onClick={onSubmit}>
          {t('common.confirm')}
        </Button>
      </ButtonContainer>
    </ModalContent>
  );
};
