import React from 'react';
import styled from 'styled-components';
import { ActionButton } from './DashboardComponents';

const StyledDismissButton = styled(ActionButton)`
  path {
    stroke: #153755;
  }

  &:hover,
  &:active {
    path {
      stroke: #1c6fb9;
    }
  }
`;

const DismissButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <StyledDismissButton onClick={onClick}>
      <svg
        width='18'
        height='18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='m1 1 16.5 16.5M17.5 1 1 17.5' />
      </svg>
      {children}
    </StyledDismissButton>
  );
};

DismissButton.displayName = 'DismissButton';

export default DismissButton;
