export enum Steps {
  UserInfo,
  Location,
  Success,
  Info,
  Verification,
}

type AddCustomerDataShape = {
  customerId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: number;
  locationName?: any;
  address1?: any;
  address2?: any;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
};

export interface IModalProps {
  closeModal: () => void;
  data?: { [x: string]: any };
}

export interface IAddCustomerProps extends IModalProps {
  addValues: (values: AddCustomerDataShape) => any;
  setStep: (step: Steps) => void;
  customerData?: { [x: string]: any };
}

export interface ICustomerInfoProps extends IModalProps {
  initialValues?: any;
  onSubmit?: any;
  isEditMode?: boolean;
  customerData?: { [x: string]: any };
}
