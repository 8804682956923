import React, { FC } from 'react';
import styled from 'styled-components';
import { Avatar, Box, Flex, Text } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';

const AvatarBox = styled(Box)`
  border-radius: ${({ theme }) => theme.radii.circle};
  background-color: ${({ theme }) => theme.colors.input};
  min-width: ${({ variant }) => (variant === 'small' ? '20px' : '65px')};
`;

const StyledText = styled(Text)`
  font-size: ${({ variant }) => (variant === 'small' ? '1rem' : '1.5rem')};
  color: ${({ theme }) => theme.colors.nav.text};
`;

type ProfileAvatarProps = {
  firstName?: string;
  lastName?: string;
  photoUrl?: string;
  size?: number;
  variant?: string;
};

const ProfileAvatar: FC<ProfileAvatarProps> = ({
  firstName,
  lastName,
  photoUrl,
  variant,
}): JSX.Element => {
  const { t } = useTranslation();
  const firstInitial = firstName?.[0] || '';
  const lastInitial = lastName?.[0] || '';

  return (
    <Flex data-test-profile-avatar>
      {photoUrl || (!photoUrl && !firstName && !lastName) ? (
        <Avatar
          data-test-user-image-avatar
          aria-label={
            firstName && lastName
              ? `${firstName} ${lastName}`
              : t('header.primary.settings')
          }
          size={variant === 'small' ? '36px' : '65px'}
          src={photoUrl ? photoUrl : undefined}
        />
      ) : (
        <AvatarBox
          data-test-user-initials-avatar
          size={variant === 'small' ? '36px' : '65px'}
          variant={variant}>
          <Flex
            flexDirection='row'
            justifyContent='center'
            alignItems='center'
            height='100%'>
            <StyledText variant={variant}>{firstInitial}</StyledText>
            <StyledText variant={variant}>{lastInitial}</StyledText>
          </Flex>
        </AvatarBox>
      )}
    </Flex>
  );
};

export default ProfileAvatar;
