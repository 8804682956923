import React, { FC, useEffect, useState } from 'react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { Titled } from 'components/common/Titled';
import InviteExpired from './InviteExpired';
import { Text, Flex, RectangularButton } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import AppLayoutAnonymous from 'components/layouts/AppLayoutAnonymous';
import NoticeLayout from 'components/common/NoticeLayout';
import GrayPanel from 'components/access/GrayPanel';
import { useRisClient } from 'hooks/useRisClient';
import { useQuery } from '@tanstack/react-query';
import { RESIDEO_ID_URL } from 'config';
import PageLoading from 'components/common/PageLoading';
import InviteError from './InviteError';
import { useAuth } from 'utils/auth';

export type InviteInfo = {
  inviteId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  country?: string;
  company?: string;
  inviteToken?: string;
};

const InviteForm: FC<InviteInfo> = ({
  inviteId = '',
  company,
  email,
  inviteToken,
}: InviteInfo): JSX.Element => {
  const [showSignup, setShowSignUp] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const { client } = useRisClient();

  const { data: inviteData, error } = useQuery({
    queryKey: ['getInviteById', { inviteId }],
    queryFn: async () => {
      const res = await client.public.publicGetInvitation(inviteId);
      return res?.data;
    },
    enabled: true,
  });

  useEffect(() => {
    if (showSignup) {
      window.location.href = `${RESIDEO_ID_URL}/sign-up?invite=${inviteToken}`;
    }
  }, [showSignup]);

  const { loginWithRedirect } = useAuth();

  const invitedUserLink = () => {
    loginWithRedirect({
      isNewUser: true,
      email: email ?? '',
      appState: {
        returnTo: `/sign-up/accept-invite${location.search}`,
      },
    });
  };

  if (!inviteData || error) {
    return (
      <AppLayoutAnonymous showHeader={false} showFooter={false}>
        <InviteError />
      </AppLayoutAnonymous>
    );
  }

  const existingInviteId = inviteData?.id;
  const currentExpiration = new Date(inviteData?.expiresAt || 0);
  const expired = Date.now() > currentExpiration.getTime();

  if (!inviteId || !email || !existingInviteId) {
    return (
      <AppLayoutAnonymous showHeader={false} showFooter={false}>
        <InviteExpired />
      </AppLayoutAnonymous>
    );
  }
  if (expired) {
    return (
      <AppLayoutAnonymous showHeader={false} showFooter={false}>
        <InviteExpired />
      </AppLayoutAnonymous>
    );
  }

  return (
    <Titled title={t('common.invite')}>
      <AppLayoutAnonymous showHeader={false}>
        <NoticeLayout data-test-invite-form>
          {!showSignup && (
            <>
              <GrayPanel
                width='380px'
                marginBottom='large'
                alignItems='baseline'
                data-test-invite-form-create-account>
                <Text
                  fontSize='24px'
                  marginBottom='22px'
                  fontWeight='bold'
                  lineHeight='2rem'
                  data-test-invite-text-company>
                  {t('user.signUp.inviteForm.inviteTo')} {company}
                </Text>
                <Text
                  marginBottom='large'
                  fontSize='18px'
                  data-test-invite-text2>
                  {t('user.signUp.inviteForm.inviteText')}
                </Text>
                <RectangularButton
                  variant='primary'
                  onClick={() => setShowSignUp(!showSignup)}
                  data-test-invite-form-sign-up-btn>
                  {t('user.signUp.inviteForm.signUpBtn')}
                </RectangularButton>
              </GrayPanel>
              <Flex>
                <Text
                  fontSize='18px'
                  marginRight='small'
                  data-test-invite-sign-in-text>
                  {t('user.signUp.inviteForm.signInText')}
                </Text>
                <ReactRouterLink
                  to={`/sign-up/accept-invite${location.search}`}
                  onClick={invitedUserLink}
                  data-test-invite-sign-in-link>
                  {t('signIn.button')}
                </ReactRouterLink>
              </Flex>
            </>
          )}
          {showSignup && <PageLoading />}
        </NoticeLayout>
      </AppLayoutAnonymous>
    </Titled>
  );
};
export { InviteForm };
