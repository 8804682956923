import React, { FC } from 'react';
import { Titled } from 'components/common/Titled';
import { useTranslation } from 'react-i18next';
import { Flex } from '@resideo/blueprint-react';
import NoticeGrayBox from 'components/access/NoticeGrayBox';
import { useLocation } from 'react-router-dom';
import PageError from 'components/common/PageError';
import NoticeLayout from 'components/common/NoticeLayout';

const Success: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const useSearchParams = () => {
    return new URLSearchParams(useLocation().search);
  };

  const partiesLinks = {
    distributor: 'https://www.resideo.com/us/en/pro/resources/distributors/',
    owner: 'https://www.resideo.com/us/en/pro/',
  };

  const parties = Object.keys(partiesLinks);

  const party = useSearchParams().get('by');
  if (!party || !parties.includes(party)) return <PageError />;

  const grayBoxContent = {
    title: t('accessRequest.success.title'),
    description: t('accessRequest.success.description'),
    linkText: t(`accessRequest.success.${party}Link`),
    linkTo: partiesLinks[party],
  };

  return (
    <Titled title={t('accessRequest.gettingStarted.title')}>
      <NoticeLayout data-test-successful-request-screen>
        <Flex marginBottom='2rem'>
          <NoticeGrayBox
            data-test-success-notice
            type='success'
            {...grayBoxContent}
          />
        </Flex>
      </NoticeLayout>
    </Titled>
  );
};

export default Success;
