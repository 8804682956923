import React from 'react';
import { Titled } from 'components/common/Titled';
import { Outlet, RouteObject } from 'react-router-dom';
import Error from 'routes/Error';
import GettingStarted from './GettingStarted';
import AccessForm from './AccessForm';
import Success from './Success';
import Terms from './Terms';
import TermsNA from 'components/terms/doc/termsNA';
import { useTranslation } from 'react-i18next';

export const AccessLayout = () => {
  const { t } = useTranslation();
  return (
    <Titled title={t('accessRequest.requestAccess')}>
      <Outlet />
    </Titled>
  );
};

const routes: RouteObject[] = [
  {
    index: true,
    element: <GettingStarted />,
  },
  {
    path: 'request',
    element: <GettingStarted />,
  },
  {
    path: 'request/form',
    element: <AccessForm />,
  },
  {
    path: 'request/success',
    element: <Success />,
  },
  {
    path: 'request/terms',
    element: <Terms termsDoc={TermsNA} />,
  },
  {
    path: '*',
    element: <Error />,
  },
];

export default routes;
