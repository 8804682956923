import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableV2 } from '@resideo/blueprint-react';
import { Link, useNavigate } from 'react-router-dom';
import { hasAdminRole } from 'utils/common';
import { useCurrentUser } from 'context/CurrentUser';
import { useIsMounted } from 'hooks/useIsMounted';
import { useFeatureFlags } from '../../../context/FeatureFlags';
import ArchiveEmployeeModalContent from './ArchiveEmployeeModalContent';
import { IModalProps, useModalContext } from 'context/ModalProvider';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';

const EmployeesList = () => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { openModal, closeModal } = useModalContext();
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState<any>(null);
  const { partnerAccountId } = useCurrentUser();
  const { TABLE_RESULTS_PER_PAGE } = useFeatureFlags();
  const [partnerUsers, setPartnerUsers] = useState<any>();
  const rowsPerPage = TABLE_RESULTS_PER_PAGE;
  const { client } = useRisClient();
  const [pageInfo, setPageInfo] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    first: rowsPerPage,
    last: undefined,
    before: undefined,
    after: undefined,
  });

  const getOrderBy = (sortColumn: string, sortDirection: string) => {
    if (sortColumn === 'Name') {
      return {
        'user.lastName': sortDirection,
        'user.firstName': sortDirection,
      };
    }

    return { [sortColumn]: sortDirection };
  };

  const goToPageZero = () => {
    setPagination({
      first: rowsPerPage,
      last: undefined,
      before: undefined,
      after: undefined,
    });
  };

  const { data: RisData, error } = useSuspenseQuery({
    queryKey: ['getEmployees', { orderBy, pagination }],
    queryFn: async () => {
      const res = await client.myProAccounts.myProAccountsSearchPartnerUser(
        partnerAccountId as string,
        JSON.stringify([{ status: 'ACTIVE' }]),
        orderBy
          ? JSON.stringify(orderBy)
          : JSON.stringify({
              'user.lastName': 'ASC',
              'user.firstName': 'ASC',
            }),
        pagination.first,
        pagination.last,
        pagination.before,
        pagination.after
      );
      return res;
    },
  });

  useEffect(() => {
    const tempPartnerUsers =
      RisData?.data?.filter(partnerUser => {
        return (
          partnerUser?.user?.firstName &&
          partnerUser?.user?.lastName &&
          partnerUser?.user?.contactEmail
        );
      }) || [];

    setPartnerUsers(tempPartnerUsers);

    if ((RisData as any)?.pageInfo) {
      setPageInfo((RisData as any)?.pageInfo);
    }
  }, [RisData]);

  const linkRenderer = ({ href, children, className }) => {
    return (
      <Link to={href} className={className}>
        {children}
      </Link>
    );
  };

  const getRows = () =>
    partnerUsers.map(partnerUser => {
      const user = partnerUser?.user;
      // Only when user details are available display user information in the table
      if (user && user.firstName && user.lastName && user.contactEmail) {
        return {
          id: partnerUser?.id,
          name: `${user?.lastName}, ${user?.firstName}`,
          email: user?.contactEmail,
          role: partnerUser?.roles?.length
            ? t(`common.roles.${partnerUser?.roles}`)
            : '',
          status: t(`common.statuses.${partnerUser?.status}`),
          actions: [
            {
              name: t('mybusiness.employees.view'),
              ctaTag: 'edit',
              text: t('mybusiness.employees.view'),
              href: `/mybusiness/company/employees/${partnerUser?.id}/view`,
              linkRenderer,
            },
            {
              name: t('mybusiness.employees.archived.button'),
              ctaTag: 'archive',
              text: t('mybusiness.employees.archived.button'),
              disabled: hasAdminRole(partnerUser?.roles),
              action: () => {
                openModal({
                  title: t('mybusiness.employees.archived.archiveModal.title'),
                  testAttribute: { 'data-test-archive-modal': true },
                  children: (
                    <ArchiveEmployeeModalContent
                      closeModal={closeModal}
                      id={partnerUser?.id}
                      name={`${user?.lastName}, ${user?.firstName}`}
                    />
                  ),
                } as IModalProps);
              },
            },
          ],
        };
      }
    });

  return (
    <div data-test-employees-list>
      {partnerUsers && (
        <TableV2
          hasPagination
          NoDataMessage={t('mybusiness.employees.noEmployees')}
          onSort={(sortColumn, sortDirection) => {
            if (sortColumn && sortDirection && isMounted()) {
              setOrderBy(getOrderBy(sortColumn, sortDirection));
              goToPageZero();
            }
          }}
          hasNext={pageInfo?.hasNextPage}
          hasPrevious={pageInfo?.hasPreviousPage}
          nextButtonLabel={t('common.next')}
          previousButtonLabel={t('common.previous')}
          onPaginationNextClick={() => {
            setPagination({
              first: rowsPerPage,
              last: undefined,
              before: undefined,
              after: pageInfo?.endCursor,
            });
          }}
          onPaginationPrevClick={() => {
            setPagination({
              first: undefined,
              last: rowsPerPage,
              before: pageInfo?.startCursor,
              after: undefined,
            });
          }}
          headers={[
            { isHiddenColumn: true, displayName: 'id', isSortable: false },
            {
              displayName: t('mybusiness.employees.name'),
              isSortable: true,
              useEllipses: true,
              onSortColumnName: 'Name',
              onColumnCellClick: row => {
                navigate(`/mybusiness/company/employees/${row.id}/view`);
              },
            },
            {
              displayName: t('common.emailShort'),
              isSortable: true,
              onSortColumnName: 'user.contactEmail',
              useEllipses: true,
            },
            {
              displayName: t('common.roles.label'),
              isSortable: true,
              onSortColumnName: 'roles',
              notBlueprintEllipses: true,
            },
            {
              displayName: 'Status',
              isSortable: false,
              isHiddenColumn: false,
              notBlueprintEllipses: true,
            },
            { displayName: '', isSortable: false },
          ]}
          isError={
            error
              ? {
                  errorHeader: t('common.tableErrors.header'),
                  errorMessage: t('common.tableErrors.text'),
                }
              : undefined
          }
          rows={getRows()}
        />
      )}
    </div>
  );
};

export default EmployeesList;
