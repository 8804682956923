import {
  Button,
  Flex,
  Heading,
  Link,
  Option,
  ScopedSearch,
  Box,
} from '@resideo/blueprint-react';
import RightPanelHeader from 'components/common/RightPanelHeader';
import AddCustomer from 'components/customers/AddCustomer';
import { iQSupportEmail } from 'config/emailAddresses';
import { iQSupportPhone } from 'config/phoneNumbers';
import { useCurrentUser } from 'context/CurrentUser';
import React, { Suspense, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Titled } from 'components/common/Titled';
import styled from 'styled-components';
import CustomersList from '../../components/customers/CustomersList';
import TableLoading from 'components/common/TableLoading';
import { IModalProps, useModalContext } from 'context/ModalProvider';

const Container = styled(Flex)`
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0;
    overflow: auto;
  }
`;

const SubHeadingContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space.medium};

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SearchAndFilterContainer = styled(Flex)`
  align-items: center;
  gap: 10px;

  & > div {
    width: 700px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
    margin-top: 10px;

    & > div {
      width: 100%;
    }
  }
`;

enum UserDetailSearchType {
  EMAIL = 'EMAIL',
  MAC_ADDRESS = 'MAC_ADDRESS',
  NAME = 'NAME',
  LOCATION = 'LOCATION',
}

const Customers = (): JSX.Element => {
  const { t } = useTranslation();
  const { partnerAccountId } = useCurrentUser();
  const { openModal, closeModal } = useModalContext();
  const searchOptions = [
    { key: UserDetailSearchType.EMAIL, value: 'Email', label: t('By Email') },
    { key: UserDetailSearchType.NAME, value: 'Name', label: t('By Name') },
    {
      key: UserDetailSearchType.MAC_ADDRESS,
      value: 'MAC/Device ID',
      label: t('By MAC/Device ID'),
    },
    {
      key: UserDetailSearchType.LOCATION,
      value: 'Location',
      label: t('By Location'),
    },
  ];
  const [filter, setFilter] = useState<string | undefined>();
  const [filterBy, setFilterBy] = useState<Option | undefined>();

  useEffect(() => {
    return () => {
      setFilter(undefined);
      setFilterBy(undefined);
    };
  }, []);

  return (
    <Titled title={t('customers.title')}>
      <RightPanelHeader
        cta={
          <Button
            disabled={false}
            onClick={() => {
              openModal({
                title: t('customers.addCustomer'),
                children: <AddCustomer closeModal={closeModal} />,
              } as IModalProps);
            }}
            variant='primary'
            width={[1, 'auto']}>
            {t('customers.addCustomer')}
          </Button>
        }
        subtitle={
          <div>
            <Trans
              i18nKey='customers.subtitle'
              components={[
                <Link
                  key='0'
                  href='#'
                  onClick={e => {
                    e.preventDefault();
                    window.location.href = `mailto:${iQSupportEmail}?subject=${t(
                      'customers.supportEmailSubject'
                    )}`;
                  }}
                />,
                <Link key='1' href={`tel:${iQSupportPhone}`} />,
              ]}
              values={{
                email: iQSupportEmail,
                phone: iQSupportPhone,
              }}
            />
          </div>
        }>
        {t('customers.title')}
      </RightPanelHeader>
      <Container data-test-customer-accounts>
        <Box
          paddingY='medium'
          paddingX={['medium', 'medium', 'medium', 'large']}
          width={1}>
          <SubHeadingContainer>
            <Heading
              data-test-customer-accounts-subheader
              as='h2'
              fontSize='xLarge'>
              {t('customers.accountLookup')}
            </Heading>
            <SearchAndFilterContainer>
              <ScopedSearch
                uniqueName='ProPortalCustomerLookup'
                name='UserLookup'
                placeholderText={t('Lookup user account by ')}
                options={searchOptions}
                suggestionLimit={5}
                initialValue={filter}
                initialOption={filterBy}
                onSearchClick={async (search, option) => {
                  setFilter(search);
                  setFilterBy(option);
                }}
                onClearClick={() => {
                  setFilter('');
                }}
              />
            </SearchAndFilterContainer>
          </SubHeadingContainer>
        </Box>
        <Box paddingX={[0, 0, 'medium', 'large']} paddingBottom='large'>
          <Suspense fallback={<TableLoading />}>
            <CustomersList
              partnerAccountId={partnerAccountId as string}
              filter={filter}
              filterBy={filterBy}
            />
          </Suspense>
        </Box>
      </Container>
    </Titled>
  );
};

export default Customers;
